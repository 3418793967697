import { ReactNode } from 'react';

import Info from 'components/info';

const HelpText = ({ children }: { children: ReactNode }) => (
  <div className='flex flex-row mt-2'>
    <div className='pr-2'>
      <Info className='leading-none' />
    </div>
    <p className='max-w-[720px]'>{children}</p>
  </div>
);

export default HelpText;
