import { useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { useKeyDown } from 'hooks/keyboard';
import { useOutsideClick } from 'hooks/mouse';
import { useLockBodyScroll } from 'hooks/scroll';

// relevant WAI-ARIA attributes (https://www.mediacurrent.com/blog/wai-aria-attributes-best-practices/):
// - tabIndex -- tells the browser that we do not want this to be part of the "sequential focus navigation";
// - roles https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques
// - aria-label and aria-labelledby https://developers.google.com/web/fundamentals/accessibility/semantics-aria/aria-labels-and-relationships

interface Props {
  onClose: () => void;
  role?: 'dialog' | 'alertdialog' | 'alert';
  ariaLabel: string;
  children: ReactNode;
  title?: string;
  darkMode?: boolean;
  className?: string;
}

const ModalContent = ({
  children,
  onClose,
  role = 'dialog',
  ariaLabel,
  title,
  darkMode,
  className = 'w-140'
}: Props) => {
  const ref: any = useRef();
  useKeyDown(27, onClose);
  useOutsideClick(ref, onClose);
  useLockBodyScroll();

  return createPortal(
    <aside
      data-testid='modal-cover'
      aria-modal='true'
      tabIndex={-1}
      role={role}
      aria-label={ariaLabel}
      className='c-modal-cover'
    >
      <div
        className={cn(
          className,
          'c-modal fixed pin-center rounded z-50',
          darkMode ? 'c-modal--query shadow-md' : 'bg-white'
        )}
      >
        <div ref={ref} className='c-modal__body rounded'>
          {title && (
            <h3
              className={cn(
                'p-5 pb-2 inline-block font-semibold',
                darkMode ? 'text-white text-sm' : 'text-md'
              )}
            >
              {title}
            </h3>
          )}
          {children}
        </div>
        <button
          className='c-modal__close absolute cursor-pointer'
          aria-labelledby='close-modal'
          onClick={onClose}
          type='button'
        >
          <div className='relative'>
            <span id='close-modal' className='hide-visually'>
              Close
            </span>
            <span className='close block' data-testid='modal-close' />
          </div>
        </button>
      </div>
    </aside>,
    document.body
  );
};

export default ModalContent;
