import 'regenerator-runtime';
import * as ReactDOM from 'react-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { getConfig, getUrlRevisionInformation } from 'config';

import { initSentry } from 'utils/sentry';

import { SolveUiBaseUrlContext } from 'context/solveBaseUrl';

import App from './app';

import './styles.css';

const root = async () => {
  const uiUrlInfo = getUrlRevisionInformation(window.location);

  initSentry(uiUrlInfo);

  const [clientConfig, LDProvider] = await Promise.all([
    getConfig(),
    asyncWithLDProvider({
      clientSideID: '5ddc645f2bd1ba082af2785d'
    })
  ]);

  const rootEl: HTMLElement = document.getElementById('root')!;
  const render = () => {
    ReactDOM.render(
      <SolveUiBaseUrlContext.Provider value={uiUrlInfo}>
        <LDProvider>
          <App clientConfig={clientConfig} />
        </LDProvider>
      </SolveUiBaseUrlContext.Provider>,
      rootEl
    );
  };

  render();
};
root();
