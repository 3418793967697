const SearchLoader = () => {
  const Block = () => (
    <div className='p-2 flex flex-row mb-3 last:mb-0'>
      <div className='rounded-full mr-3 h-5 w-5 loader-box'></div>
      <div className='w-full'>
        <div className='loader-box h-3 mb-1 rounded w-3/5'></div>
        <div className='loader-box w-2/5 h-3 rounded inline-block'></div>
        <div className='loader-box w-3/5 h-3 rounded inline-block'></div>
        <div className='loader-box  h-3 rounded w-4/5 mt-1'></div>
      </div>
    </div>
  );

  const rows = [];
  for (let i = 0; i <= 2; i++) {
    rows.push(<Block key={i} />);
  }

  return <>{rows}</>;
};

export default SearchLoader;
