import { gql } from '@apollo/client';

const configurationFields = `
id
properties
update_token
created_at
updated_at
`;

export const singleConfigurationPropertyQuery = gql`
  query singleConfigurationProperty($id: String!, $key: String!) {
    configuration_property(id: $id, key: $key) {
      value
    }
  }
`;

export const allConfigsQuery = gql`
query allConfigs {
  configurations {
    nodes {
      ${configurationFields}
    }
  }
}
`;

export const configCreateOrUpdateMutation = gql`
mutation createOrUpdateConfig($input: ConfigurationInput!) {
  createOrUpdateConfiguration: create_or_update_configuration(input: $input) {
    ${configurationFields}
  }
}
`;
