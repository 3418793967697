import { useEffect } from 'react';
import toast from 'react-hot-toast';

import logger from 'utils/logger';

import { GIT_REVISION } from 'utils/revision';

import { useSolveUiBaseUrlContext } from 'context/solveBaseUrl';

import Button from 'components/button';
import fetchRetry from 'utils/fetchRetry';

export type OutdatedRevisionProps = {
  /** This callback is called when the current revision is no longer the current one. */
  callback: () => void;
  /** The current revision, passed in to allow easier testing. */
  currentRevision: string;
};

export const OutdatedRevision = ({
  callback,
  currentRevision
}: OutdatedRevisionProps) => {
  // Use this context to get information about the current URL.
  const baseUrlInfo = useSolveUiBaseUrlContext();

  if (
    process.env.NODE_ENV !== 'production' ||
    !(baseUrlInfo.isLatest || baseUrlInfo.isPr)
  ) {
    // Replace this with a call to `callback` to always show the refresh dialog.
    // Useful for local testing.
    return null;
  }

  useEffect(() => {
    const timer = setInterval(async () => {
      try {
        // rootPath will always end with a `/`.
        const resp = await fetchRetry(`${baseUrlInfo.rootPath}revision`);
        const latestRevision = (await resp.text()).trim();
        if (latestRevision !== currentRevision) {
          callback();
        }
      } catch (e) {
        logger.error(e, 'Unable to fetch latest revision.');
      }
    }, 60_000);

    return () => clearInterval(timer);
  }, [callback, currentRevision, baseUrlInfo]);

  return null;
};

export const showOutdatedToast = () => {
  toast(
    <span>
      {/* Use a 'em' space to provide some distance between the text and button. */}
      A newer version of Solve has been released&emsp;
      <Button
        type='button'
        color='gray'
        className='inline'
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh Page
      </Button>
    </span>,
    {
      // Use a constant ID so we only ever show one tooltip
      id: 'outdated-version',
      position: 'bottom-right',
      // Ensure the tooltip is never dismissed.
      duration: Infinity
    }
  );
};

export const OutdatedVersionToaster = () => {
  return (
    <OutdatedRevision
      callback={showOutdatedToast}
      currentRevision={GIT_REVISION}
    />
  );
};

export default OutdatedVersionToaster;
