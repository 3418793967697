/**
 * This store is used to manage any UI state that is needed for the query engine. At this point this only
 * refers to how tabs are stored and storing whether we are in the loading state when the query engine
 * is first loaded
 */
import { makeObservable, observable, action } from 'mobx';

import logger from 'utils/logger';

import { queryEngineStore } from './engine';
import { savedQueryStore } from './savedQuery';

export type Tab = {
  unsavedQueryIdx?: number;
  queryEngineId?: string;
  queryId: number | null;
};

export type LocalStorageItem = {
  tabs: Tab[];
  activeTabIdx: number;
  nextUnsavedQueryIdx: number;
};

export const localStorageKey = 'solve-query-engine-tabs';
const saveToLocalStorage = (val: LocalStorageItem) => {
  return window.localStorage.setItem(localStorageKey, JSON.stringify(val));
};
const fetchFromLocalStorage: () => LocalStorageItem = () =>
  JSON.parse(
    window.localStorage.getItem(localStorageKey) as string
  ) as LocalStorageItem;

export default class Store {
  loaded = false;
  tabs: Tab[] = [];
  nextUnsavedQueryIdx = 1;
  activeTabIdx = 0;

  constructor() {
    makeObservable(this, {
      loaded: observable,
      tabs: observable,
      addTab: action,
      hydrateTabs: action,
      syncWithLocalStorage: action,
      activeTabIdx: observable,
      setActiveTab: action,
      removeTabViaIdx: action,
      removeTabViaQueryId: action,
      addTabByQueryText: action
    });
  }

  /**
   * Should be called early in the apps lifecycle. We make sure that we have loaded all the saved queries before we
   * show anything to the user (determined in the observing React components via the `loaded property here).
   */
  async hydrateTabs() {
    if (this.loaded) {
      return;
    }

    try {
      await savedQueryStore.fetchAll();
      // To ensure that we have the reference to the correct engine queries, make sure these
      // are also hydrated from the query engine store
      queryEngineStore.hydrateQueries();
      this.syncWithLocalStorage();
    } catch (err) {
      this.addTab();
    }
    this.loaded = true;
  }

  /**
   * Synchronizes any `tabs`, `activeTabIdx`, and `nextUnsavedQueryIdx` with localstorage. It both
   * saves to and retrieves from depending on whether there are any tabs. The use of the empty tab array
   * is used as a proxy to tell whether this has been called on initialisation or whether this function
   * has been called from elsewhere.
   *
   * If there are tabs, the data is saved to localstorage, overriding anything that is already there.
   * If there are no tabs, the data is retrieved from localstorage. If there is data there, the store inherits
   * it. If no data is there, we set an empty tab so that we aren't leaving the UI in an empty state
   */
  syncWithLocalStorage() {
    const filterOutMissingQueries = (tabs: Tab[]) =>
      tabs.filter(tab => {
        if (!tab.queryEngineId) {
          return true;
        }

        return !!queryEngineStore.queries[tab.queryEngineId];
      });

    if (!this.tabs.length) {
      const currentUi = fetchFromLocalStorage();
      if (currentUi) {
        const { tabs, activeTabIdx, nextUnsavedQueryIdx } = currentUi;
        const filteredLocalsStorageTabs = filterOutMissingQueries(tabs);
        if (!filteredLocalsStorageTabs.length) {
          // Adding blank. I'm unsure why there has to be a timeout here.
          setTimeout(() => {
            this.addTab(undefined, false);
          }, 0);
        }

        this.tabs = filteredLocalsStorageTabs;
        this.activeTabIdx = activeTabIdx || 0;
        this.nextUnsavedQueryIdx = nextUnsavedQueryIdx || 1;
      } else {
        this.addTab(undefined, false);
        saveToLocalStorage({
          tabs: this.tabs,
          activeTabIdx: this.activeTabIdx,
          nextUnsavedQueryIdx: this.nextUnsavedQueryIdx
        });
      }
    } else {
      saveToLocalStorage({
        tabs: this.tabs,
        activeTabIdx: this.activeTabIdx,
        nextUnsavedQueryIdx: this.nextUnsavedQueryIdx
      });
    }
  }

  /**
   * Adds a tab to the UI. If a queryId has been passed, we add that query to the query engine store (which
   * will get it from the saved query store). This will load a tab that is already populated with the query
   * that has previously been saved to the DB
   */
  addTab = async (
    queryId?: number,
    shouldSynchronizeWithLocalstorage = true
  ) => {
    let tabToAdd: Tab;
    // Is the query requested to be added already in the tabs array?

    if (queryId) {
      for (let i = 0; i < this.tabs.length; i++) {
        const queryEngineQueryForTab =
          queryEngineStore.queries[this.tabs[i].queryEngineId!];
        if (queryEngineQueryForTab?.savedQueryId === queryId) {
          // We already have this tab. Focus on it.
          this.setActiveTab(i, false);
          return;
        } else {
          let savedQuery;

          const tabQuery = savedQueryStore.tabsQueries.find(query => {
            return query.id === queryId;
          });

          if (tabQuery) {
            savedQuery = tabQuery;
          }

          if (!tabQuery) {
            try {
              savedQuery = await savedQueryStore.fetch(queryId);
              if (!savedQuery) {
                logger.info(`Query with id ${queryId} doesn't exist in DB`);
                return;
              }
              savedQueryStore.addTabsIds(savedQuery.id);
              await savedQueryStore.fetchTabs(savedQueryStore.tabIds);
            } catch (err) {
              logger.error(err);
            }
          }
          if (savedQuery) {
            const isTabExist = this.tabs?.find(tabQuery => {
              return tabQuery?.queryId === queryId;
            });

            if (isTabExist) {
              // focus on tab
              const index = this.tabs.findIndex(tab => tab.queryId === queryId);
              this.setActiveTab(index, false);
              return;
            }

            const queryEngineQueryData = queryEngineStore.addFetchedQuery(
              savedQuery,
              queryId
            );

            tabToAdd = {
              queryEngineId: queryEngineQueryData.id,
              queryId: queryEngineQueryData.savedQueryId
            };

            this.tabs = this.tabs.concat(tabToAdd);

            this.activeTabIdx = this.tabs.length - 1;

            if (shouldSynchronizeWithLocalstorage) {
              this.syncWithLocalStorage();
            }
          }
        }
      }
    }

    if (!queryId) {
      // There was no query id passed. Load a new query into the query engine store and prepare to add a tab
      // for it
      const queryEngineId = queryEngineStore.addQuery();
      tabToAdd = {
        unsavedQueryIdx: this.nextUnsavedQueryIdx,
        queryEngineId,
        queryId: null
      };

      // Increment the next unsaved query index for the next untitled tab
      this.nextUnsavedQueryIdx = this.nextUnsavedQueryIdx + 1;
    } else {
      // Grab the query from the query engine store and store a reference to it here for the tab
      try {
        const queryEngineId = queryEngineStore.addQuery(queryId);
        tabToAdd = {
          queryEngineId,
          queryId
        };
      } catch (e) {
        // If we get here then we have requested adding a query engine id that does not exist. Do nothing
        // in this case
        console.warn(`Saved query of ${queryId} not found in store`);
        return;
      }
    }

    // Add tbe tab. Focus on the last tab
    this.tabs = this.tabs.concat(tabToAdd);
    this.activeTabIdx = this.tabs.length - 1;

    if (shouldSynchronizeWithLocalstorage) {
      this.syncWithLocalStorage();
    }

    return tabToAdd;
  };

  /**
   * Allows for the addition of a new tab with the query text to go with it. ALso creates the
   * corresponding query engine query
   */
  addTabByQueryText = (query: string) => {
    // First create the query engine query

    const queryEngineId = queryEngineStore.addQuery();

    // Then update it with the desired query text
    queryEngineStore.updateQueryText(queryEngineId, query);

    // Then add it to the tabs array
    const tabToAdd = {
      unsavedQueryIdx: this.nextUnsavedQueryIdx,
      queryEngineId,
      queryId: null
    };

    this.tabs = this.tabs.concat(tabToAdd);
    this.activeTabIdx = this.tabs.length - 1;

    // Increment the next unsaved query index for the next untitled tab and sync with localstorage
    this.nextUnsavedQueryIdx = this.nextUnsavedQueryIdx + 1;
    this.syncWithLocalStorage();

    return tabToAdd;
  };

  /**
   * Given an index, set the tab at that index as focused.
   */
  setActiveTab = (idx: number, shouldSynchronizeWithLocalstorage = true) => {
    if (idx < 0) {
      this.activeTabIdx = 0;
      return;
    }

    if (idx > this.tabs.length - 1) {
      this.activeTabIdx = this.tabs.length - 1;
      return;
    }

    this.activeTabIdx = idx;

    if (shouldSynchronizeWithLocalstorage) {
      this.syncWithLocalStorage();
    }

    return this.tabs[idx];
  };

  /**
   * Given an index, remove the tab at that index. Will also remove the corresponding query from the
   * query engine store
   */
  removeTabViaIdx = (idx: number, shouldSynchronizeWithLocalstorage = true) => {
    // Remove the underlying query engine query
    const queryEngineId = this.tabs[idx].queryEngineId;

    if (queryEngineId) {
      queryEngineStore.removeQuery(queryEngineId);
    }

    // Filter out the correct tab.
    const newTabs = this.tabs.filter((_, currIdx) => currIdx !== idx);

    if (this.activeTabIdx >= newTabs.length) {
      // If we just removed the last tab, focus on the new last tab (previously the second to last tab)
      this.activeTabIdx = newTabs.length - 1;
    }

    if (idx < this.activeTabIdx) {
      // We just removed a tab that is before the currently active tab. Decrement the active tab index
      // to keep the same tab content active
      this.activeTabIdx = this.activeTabIdx - 1;
    }

    this.tabs = newTabs;

    // Check whether we have any tabs left that are unsaved. If not, reset the next query index
    // back to one so the next time a tab is opened we start from 1 again
    if (!this.tabs.filter(t => t.unsavedQueryIdx).length) {
      this.nextUnsavedQueryIdx = 1;
    }

    if (shouldSynchronizeWithLocalstorage) {
      this.syncWithLocalStorage();
    }

    return this.tabs[this.activeTabIdx];
  };

  removeTabViaQueryId(queryId: number) {
    // First find the index of this tab
    const idx = this.tabs.findIndex(t => {
      return t.queryId === queryId;
    });

    if (idx < 0) {
      // We cannot identify whether this tab exists. Nothing we can do
      return;
    }

    // Then call `removeTabViaIdx` to do all the removal heavy lifting
    this.removeTabViaIdx(idx);
  }
}

export const queryEngineUiStore = new Store();
