import Button from 'components/button';

import { ReactComponent as ExclamationIcon } from 'resources/icons/alert-triangle.svg';

const Exclamation = () => (
  <ExclamationIcon className='w-6 h-6 mr-3 text-orange-300' />
);

const FailedToStartInstall = ({
  onClose
}: {
  onClose: (event: any) => void;
}) => {
  return (
    <div className='px-5 pt-2 pb-5'>
      <div className='flex flex-col justify-center items-center	py-10'>
        <div className='flex justify-center items-center'>
          <Exclamation />
          <p className='pt-2 pb-2 text-xl text-black-500 center'>Oh no!</p>
        </div>

        <p className='pt-2 pb-2 text-gray-500 text-center'>
          Something went wrong installing your store. <br /> Please try again.
        </p>
      </div>

      <div className='flex flex-row-reverse'>
        <Button
          type='button'
          color='gray'
          className='px-8 uppercase'
          onClick={onClose}
        >
          close
        </Button>
      </div>
    </div>
  );
};

export default FailedToStartInstall;
