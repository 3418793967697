import { useCallback, useState } from 'react';

import ContextMenu from 'components/contextMenu';
import Modal from 'components/modal';

import EditForm from '../forms/edit';
import ViewForm from '../forms/view';
import DeleteForm from '../forms/delete';

interface ModalProps {
  policy: Policy;
  isOpen: boolean;
  onRequestClose: () => void;
}

interface RowContextMenuProps {
  policy: Policy;
}

const EditModal = ({ policy, isOpen, onRequestClose }: ModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={`Edit Policy: ${policy.name}`}
    ariaLabel={`Edit Policy: ${policy.name}`}
  >
    <EditForm policyData={policy} onClose={onRequestClose} />
  </Modal>
);

const DeleteModal = ({ policy, isOpen, onRequestClose }: ModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={`Delete Policy: ${policy.name}`}
    ariaLabel={`Delete Policy: ${policy.name}`}
  >
    <DeleteForm policyData={policy} onClose={onRequestClose} />
  </Modal>
);

const ViewModal = ({ policy, isOpen, onRequestClose }: ModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={`${policy.name}`}
    ariaLabel={`${policy.name}`}
  >
    <ViewForm policyData={policy} />
  </Modal>
);

const RowContextMenu = ({ policy }: RowContextMenuProps) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [viewIsOpen, setViewIsOpen] = useState(false);

  const editHandlerToggle = useCallback(() => {
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);
  }, []);

  const deleteHandlerToggle = useCallback(() => {
    setDeleteIsOpen(prevDeleteIsOpen => !prevDeleteIsOpen);
  }, []);

  const viewHandlerToggle = useCallback(() => {
    setViewIsOpen(prevViewIsOpen => !prevViewIsOpen);
  }, []);

  const arr = [
    {
      label: 'View',
      onClick: viewHandlerToggle
    }
  ];
  if (!policy.is_solve_managed) {
    arr.push(
      {
        label: 'Edit',
        onClick: editHandlerToggle
      },
      {
        label: 'Delete',
        onClick: deleteHandlerToggle
      }
    );
  }

  return (
    <div data-testid={`context-menu-wrapper-${policy.name}`}>
      <ContextMenu data-testid={'context-menu'} type='vdots' menu={arr} />

      <ViewModal
        policy={policy}
        isOpen={viewIsOpen}
        onRequestClose={viewHandlerToggle}
      />
      <EditModal
        policy={policy}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
      <DeleteModal
        policy={policy}
        isOpen={deleteIsOpen}
        onRequestClose={deleteHandlerToggle}
      />
    </div>
  );
};

export default RowContextMenu;
