import { createContext, useContext, Component } from 'react';
import dayjs, { Dayjs } from 'dayjs';

export interface StateDateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}
interface State {
  orders: StateDateRange;

  profiles: StateDateRange;

  profile: StateDateRange;

  channel_report: StateDateRange;

  conversion_report: StateDateRange;

  campaign_reports: StateDateRange;

  // for all graphs, do we want to have separate context?
  graphs: StateDateRange;
}

export interface Context extends State {
  updateDates: (
    startDate: Dayjs | null,
    endDate: Dayjs | null,
    contextType: keyof State
  ) => void;
}

const EventsContext = createContext<Context>({
  orders: {
    startDate: null,
    endDate: null
  },
  profiles: {
    startDate: null,
    endDate: null
  },
  profile: {
    startDate: null,
    endDate: null
  },
  channel_report: {
    startDate: null,
    endDate: null
  },
  conversion_report: {
    startDate: null,
    endDate: null
  },
  campaign_reports: {
    startDate: null,
    endDate: null
  },
  graphs: {
    startDate: null,
    endDate: null
  },
  updateDates: () => {}
});

export class EventsProvider extends Component<Record<string, any>, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      orders: {
        startDate: null,
        endDate: null
      },
      profiles: {
        startDate: null,
        endDate: null
      },
      profile: {
        startDate: null,
        endDate: null
      },
      channel_report: {
        startDate: null,
        endDate: null
      },
      conversion_report: {
        startDate: null,
        endDate: null
      },
      campaign_reports: {
        startDate: null,
        endDate: null
      },
      graphs: {
        startDate: null,
        endDate: null
      }
    };
  }

  updateDates = (
    startDate: Dayjs,
    endDate: Dayjs,
    contextType: keyof State
  ) => {
    this.setState({
      [`${contextType}`]: {
        startDate: dayjs(startDate).isValid() ? startDate : null,
        endDate: dayjs(endDate).isValid() ? endDate : null
      }
    });
  };

  render() {
    const values: any = {
      ...this.state,
      updateDates: this.updateDates
    };

    return (
      <EventsContext.Provider value={values}>
        {this.props.children}
      </EventsContext.Provider>
    );
  }
}

export default EventsContext;
export const EventsConsumer = EventsContext.Consumer;
export const useEventsContext: () => Context = () => useContext(EventsContext);
