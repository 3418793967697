import { gql } from '@apollo/client';

export const pluginIntegrationQuery = gql`
  query PluginQuery {
    pluginIntegration: plugin_integration {
      enabledIntegrations: enabled_integrations {
        __typename
        ... on ShopifyPluginIntegration {
          storeLabel: shop_label
          storeDomain: shop_name
          active
          sdkInstalled: sdk_installed
          webhookInstalled: webhook_installed
          completeLinkingUrl: complete_linking_url
        }
      }
      available_integrations {
        name
      }
    }
  }
`;

export const generateShopifyInstallUrlMutation = gql`
  mutation generateShopifyInstall(
    $storeDomain: String!
    $storeLabel: String!
    $adminUrl: String!
  ) {
    startShopifyPluginInstall: start_shopify_plugin_install(
      input: {
        shop_name: $storeDomain
        shop_label: $storeLabel
        admin_url: $adminUrl
      }
    ) {
      ... on StartShopifyPluginInstallError {
        reason
      }
      ... on StartShopifyPluginInstallSuccess {
        shopifyUrl: oauth_url
      }
    }
  }
`;
