interface Props {
  title: string;
  description: string;
}

const ExampleSearch = () => {
  const data = [
    {
      title: 'first_name:joe',
      description: 'Search by first name'
    }
  ];
  return (
    <div className='py-3 px-4 text-left' data-testid='example-search'>
      <p className='text-gray-500 uppercase pb-2 text-xs'>Example queries</p>
      {data.map((item: Props, idx: number) => {
        return (
          <p key={idx}>
            <span className='text-blue-600 pr-3'>{item.title}</span>
            <span className='text-gray-600'>{item.description}</span>
          </p>
        );
      })}
    </div>
  );
};

export default ExampleSearch;
