import cn from 'classnames';

import RowContextMenu from './rowContextMenu';

import { ReactComponent as TickIcon } from 'resources/icons/check-circle.svg';

interface Props {
  policies: PoliciesList;
}

const PoliciesTable = ({ policies }: Props) => {
  if (policies?.nodes.length === 0) {
    return <div>No policies found.</div>;
  }

  const columnNames: string[] = ['Name', 'Managed by Solve'];

  return (
    <table className='mt-6 w-full' data-testid='policies-table'>
      <thead>
        <tr>
          {columnNames.map((name: string, idx: number) => {
            return (
              <td
                key={`header-column-${idx}`}
                className={cn('whitespace-nowrap p-2 font-bold border-b', {
                  'text-center': idx === 1
                })}
              >
                {name}
              </td>
            );
          })}
          <td className='border-b' />
        </tr>
      </thead>
      <tbody>
        {policies.nodes?.map((policy: Policy) => {
          return (
            <tr
              key={`${policy.id}-table-row`}
              className='policy-item'
              data-testid={`policy-${policy.id}`}
            >
              <td className='p-2 border-b' data-testid='policy-item-name'>
                {policy.name}
              </td>
              <td className='p-2 border-b relative text-center'>
                {policy.is_solve_managed && (
                  <span
                    className='primary inline-block'
                    data-testid='is-solve-managed'
                  >
                    <TickIcon className='w-4 h-4' />
                  </span>
                )}
              </td>
              <td className='pl-2 border-b policy-item-context-menu'>
                <RowContextMenu policy={policy} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PoliciesTable;
