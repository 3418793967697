import { Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import {
  configCreateOrUpdateMutation,
  allConfigsQuery
} from 'queries/configuration';

import Loader from 'components/loader';
import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import FormCustomCodeEditor from 'components/formComponents/formCustomCodeEditor';

import { validateProperties } from './configurationFormUtils';

interface Props {
  configurationData: Configuration;
  onClose: () => void;
}

const OUT_OF_SYNC_MSG =
  'Stored configuration is out of sync with the received update_token. Please refetch the configuration first.';

const stringifyJSONReadable = (object: any) => JSON.stringify(object, null, 4);

const Form = ({ configurationData, values, errors, handleSubmit }: any) => (
  <form
    onSubmit={handleSubmit}
    data-testid='configuration-edit-form'
    className='overflow-y-auto max-h-sm'
  >
    <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
      <div>
        <FormCustomCodeEditor
          name='properties'
          placeholder={JSON.stringify(configurationData.properties, null, 2)}
          label='properties'
          dataTestId='configuration-edit-properties'
          errorDataTestId='configuration-edit-properties-error'
          language='json'
        />
      </div>

      <div className='flex flex-row-reverse mt-2'>
        <Button
          type='submit'
          color='primary'
          className='px-8 uppercase'
          data-testid='submit-edit-configuration'
        >
          Update
        </Button>
      </div>
    </div>
  </form>
);

const Edit = ({ configurationData, onClose }: Props) => {
  return (
    <Mutation mutation={configCreateOrUpdateMutation}>
      {(
        createOrUpdateConfiguration: any,
        { loading, error }: MutationResult
      ) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes(OUT_OF_SYNC_MSG)
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='Your version of this configuration is out of date. Please refresh the page and try again.'
                className='m-2'
              />
            );
          }
          return <ErrorAlert error={error} className='m-2' />;
        }

        return (
          <Formik
            initialValues={{
              ...configurationData,
              properties: stringifyJSONReadable(configurationData.properties)
            }}
            data-testid='configuration-edit-formik'
            validate={validateProperties}
            onSubmit={async (values: Configuration) => {
              await createOrUpdateConfiguration({
                variables: {
                  input: {
                    id: values.id,
                    properties: values.properties,
                    update_token: values.update_token
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: allConfigsQuery }]
              });
              onClose();
            }}
          >
            {props => <Form configurationData={configurationData} {...props} />}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default Edit;
