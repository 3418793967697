import { gql } from '@apollo/client';

export const usersQuery = gql`
  query users {
    users {
      nodes {
        name
        email
        id
        role
        status
        created_at
        updated_at
      }
    }
  }
`;

export const userCreateMutation = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser: create_user(input: $input) {
      name
      email
      id
      role
      created_at
      updated_at
    }
  }
`;

export const userUpdateMutation = gql`
  mutation updateUser($email: Email!, $input: UpdateUserInput!) {
    updateUser: update_user(email: $email, input: $input)
  }
`;

export const userDeleteMutation = gql`
  mutation deleteUser($email: Email!) {
    deleteUser: delete_user(email: $email)
  }
`;

export const userResetPasswordMutation = gql`
  mutation resetPassword($email: Email!) {
    resetPassword: reset_password(email: $email)
  }
`;
