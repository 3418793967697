import cn from 'classnames';
import { useField } from 'formik';
import { Monaco } from '@monaco-editor/react';

import theme from 'utils/tailwind';

import CodeEditor from 'components/codeEditor';

interface Props {
  defaultValue: string;
  language: 'json' | 'js';
  name: string;
  height: string; // ex.: '200px'
  'data-testid': string;
  [x: string]: any;
}

const CustomCodeEditor = ({
  defaultValue,
  language,
  name,
  height,
  'data-testid': dataTestId,
  ...props
}: Props) => {
  const [field, meta, helpers] = useField(name);

  const { setValue } = helpers;
  const onChange = (value?: string) => {
    setValue(value);
  };

  const handleEditorWillMount = (monaco: Monaco) => {
    monaco.editor.defineTheme('solve-theme', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': theme.colors.white,
        'editorIndentGuide.background': theme.colors.white,
        'editorIndentGuide.activeBackground': theme.colors.white
      }
    });
  };

  return (
    <CodeEditor
      className={cn(
        'pt-2 border border-solid border-gray-300 rounded overflow-hidden focus:border-blue-400 focus:outline-none w-full',
        meta.touched && meta.error && '!border-red-500 focus:border-red-600'
      )}
      language={language}
      height={height}
      defaultValue={defaultValue}
      value={field.value}
      beforeMount={handleEditorWillMount}
      data-testid={dataTestId}
      options={{
        readOnly: false,
        contextmenu: false,
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        wordWrap: 'on',
        wrappingStrategy: 'advanced'
      }}
      onChange={onChange}
      onValidate={props.onValidate}
    />
  );
};

export default CustomCodeEditor;
