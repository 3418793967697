import { ReactNode } from 'react';

import Logo from 'components/logo';

interface Props {
  children: ReactNode;
}

const LoginPage = ({ children }: Props) => (
  <div className='h-screen w-screen bg-gray-800'>
    <div className='auth-container mx-auto flex flex-col min-h-screen'>
      <div className='flex-grow'>&nbsp;</div>
      <div className='items-center flex-none'>
        <Logo className='mx-auto mb-12 -mt-24 w-64' />
        {children}
      </div>
      <div className='flex-grow'>&nbsp;</div>
    </div>
  </div>
);

export default LoginPage;
