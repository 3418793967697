import logo from 'resources/icons/logo.svg';

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => (
  <img src={logo} className={className} alt='logo' />
);

export default Logo;
