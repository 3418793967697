import cn from 'classnames';

import DateCell from 'components/dateCell';

import RowContextMenu from './rowContextMenu';
import InfoCell from './infoCell';

interface Props {
  apiKeys: ApiKeysList;
}

const APIKeysLayout = ({ apiKeys }: Props) => {
  if (apiKeys.nodes.length === 0) {
    return (
      <div>
        No API integrations found. Click the button to the right to create one.
      </div>
    );
  }

  const columnNames: string[] = [
    'Integration Name',
    'Type',
    'Access Key',
    'Access Key Age'
  ];

  return (
    <table className='mt-6 w-full' data-testid='api-keys-table'>
      <thead>
        <tr>
          {columnNames.map((name: string, idx: number) => {
            return (
              <td
                key={idx}
                className='whitespace-nowrap p-2 font-bold border-b'
              >
                {name}
              </td>
            );
          })}
          <td className='border-b' />
        </tr>
      </thead>
      <tbody>
        {apiKeys.nodes &&
          apiKeys.nodes.map((apiKey: ApiKey) => {
            return (
              <tr
                key={apiKey.id}
                className={cn('apiKey-item', {
                  'text-gray-300': apiKey.is_disabled
                })}
                data-testid={`apiKey-${apiKey.key}`}
              >
                <td className='p-2 border-b'>
                  <InfoCell apiKey={apiKey} />
                </td>
                <td className='p-2 border-b'>{apiKey.type}</td>
                <td className='p-2 border-b'>{apiKey.key}</td>
                <td className='border-b'>
                  <DateCell
                    data={{ id: apiKey.id, date: apiKey.inserted_at }}
                    isTimeDiff
                    className='p-2'
                    interactive={false}
                  />
                </td>
                <td className='pl-2 border-b'>
                  <RowContextMenu apiKey={apiKey} />
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default APIKeysLayout;
