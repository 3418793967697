import { useContext, createContext, Component, ReactNode } from 'react';
import toast from 'react-hot-toast';

import logger, { Sentry } from 'utils/logger';

import { queryEngineStore } from 'stores/queryEngine/engine';

import AuthError from 'pages/authError';

interface State {
  isAuthenticated: boolean;
  isAdmin: boolean;
  config: ConfigJson | null;
  baseUrls: StackBaseUrlConfig | null;
  user?: AuthUser;
  isLoaded: boolean;
}

export interface Context extends State {
  signIn: (username: string, password: string) => Promise<void>;
  newPassword: (password: string) => Promise<void>;
  signOut: () => Promise<void>;
  authenticatedFetch: (
    url: string,
    options?: RequestInit,
    asJson?: boolean
  ) => Promise<any>;
  forgotPassword: (username: string) => Promise<void>;
  setPassword: (
    username: string,
    code: string,
    password: string
  ) => Promise<void>;
  user?: AuthUser;
}

interface Props {
  auth: AuthInterface;
  error: string | null;
  children: ReactNode;
  config: ConfigJson | null;
  baseUrls: StackBaseUrlConfig | null;
}

const noOp = () => Promise.resolve();
export const AuthContext = createContext<Context>({
  isAuthenticated: false,
  isAdmin: false,
  user: undefined,
  isLoaded: false,
  config: null,
  baseUrls: null,
  // Typescript nuisance here - need to have these no-op functions, even though we replace these
  // before we pass through `values` inside the render function
  signIn: noOp,
  newPassword: noOp,
  forgotPassword: noOp,
  setPassword: noOp,
  signOut: noOp,
  authenticatedFetch: noOp
});

const checkIfUserIsAdmin = (user: any) => {
  const userPayload = user.authUser?.signInUserSession?.idToken?.payload;
  if (userPayload) {
    return userPayload['cognito:groups'].includes('ADMIN');
  } else {
    return false;
  }
};

export class AuthProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAdmin: false,
      isLoaded: false,
      config: props.config,
      baseUrls: props.baseUrls
    };

    if (props.error) {
      // There is an error. Don't bother getting the auth details as it won't work
      return;
    }

    // Get and set all the session/user details. If the user doesn't have a current session
    // then still set the `isLoaded` state to be true
    this.setUserState().finally(() => {
      this.setState({
        isLoaded: true
      });
    });

    /**
     * This is pretty awful. We currently store all auth details in context, which can only be used
     * by a React component (as far as I can tell you cannot get details from a context provider
     * unless you use the `useContext` hook, which can only be used in a component). But the query
     * engine MobX store needs to be able to do authenticated calls to the query engine API. In order
     * for the query engine store to have the ability to do that we have to reach out from here and
     * override the stub function that the query engine store is initialised with. There's no way for
     * the query engine store to reach out and use the function here. Bit gross.
     *
     * Unless we come up with something clever, this will have to stay this way until we stop using
     * context for our authentication. A MobX store which can be used in many places would be more
     * ideal
     */
    queryEngineStore.authenticatedFetch = this.authenticatedFetch;
  }

  setUserState = () => {
    return this.props.auth
      .getJwtToken()
      .then(this.props.auth.getCurrentUser)
      .then(user => {
        const sentryUser = {
          id:
            user.authUser?.username && user.authUser?.pool?.userPoolId
              ? `${user.authUser.pool.userPoolId} : ${user.authUser.username}`
              : undefined,
          email: user.username
        };
        Sentry.setUser(sentryUser);
        this.setState({
          user,
          isAuthenticated: true,
          isAdmin: checkIfUserIsAdmin(user)
        });
      })
      .catch((error: Error) => {
        // We'll get here on every login page. It's an error because the user doesn't exist
        // but don't actually log an error as it's fine. Really. Trust me. It's totally fine.g8
        logger.debug(error, `Error getting current session`);
      });
  };

  authenticatedFetch = async (
    url: string,
    options: RequestInit = {},
    asJson = true
  ) => {
    const authToken = await this.props.auth.getJwtToken();
    if (!authToken) {
      throw new Error('Missing auth token. Cannot make request.');
    }
    const endpoint = `${this.props.baseUrls!.api}/${url}`;
    const headers = {
      Authorization: `Solve ${authToken}`,
      'content-type': 'application/json',
      ...options.headers
    };
    const fetchOptions = {
      ...options,
      headers
    };

    return fetch(endpoint, fetchOptions).then(response =>
      asJson ? response.json() : response
    );
  };

  signOut = async () => {
    const { auth } = this.props;
    await auth.signOut();
    Sentry.setUser(null);

    this.setState({
      isAuthenticated: false,
      isAdmin: false,
      isLoaded: true, // This tells the router "We know we have no user anymore, keep resolving routes" so the user gets redirected to login
      user: undefined
    });
    toast.success(`Signed out successfully`);
  };

  signIn = async (username: string, password: string) => {
    const user = await this.props.auth.signIn(username, password);
    this.setState({
      user,
      isAdmin: checkIfUserIsAdmin(user),
      isLoaded: false,
      isAuthenticated: !user.challenge
    });

    // Ensure we have the correct session details loaded
    await this.setUserState().finally(() => {
      this.setState({
        isLoaded: true
      });
    });
  };

  newPassword = async (password: string) => {
    const { auth } = this.props;
    try {
      const user = await auth.setNewPassword(password);
      this.setState({
        isAuthenticated: true,
        user,
        isAdmin: checkIfUserIsAdmin(user)
      });
    } catch (err) {
      toast.error(`SignIn Error. ${err.message}`);
    }
  };

  forgotPassword = async (username: string) => {
    await this.props.auth.forgotPassword(username);
  };

  setPassword = async (username: string, password: string, code: string) => {
    await this.props.auth.forgotPasswordSubmit(username, password, code);
  };

  render() {
    if (this.props.error) {
      return <AuthError />;
    }

    const values: Context = {
      ...this.state,
      signIn: this.signIn,
      newPassword: this.newPassword,
      forgotPassword: this.forgotPassword,
      signOut: this.signOut,
      setPassword: this.setPassword,
      authenticatedFetch: this.authenticatedFetch
    };
    return (
      <AuthContext.Provider value={values}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export const AuthConsumer = AuthContext.Consumer;
export const useAuthContext: () => Context = () => useContext(AuthContext);
