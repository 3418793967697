import isPlainObject from 'lodash/isPlainObject';

const DOCUMENT_JSON_ERROR = { document: 'Invalid JSON object' };

export const validateDocument = (values: { document: string }) => {
  if (!values.document) {
    return { document: 'Document cannot be empty' };
  }
  try {
    // jump to catch catch if invalid JSON
    const document = JSON.parse(values.document);
    // since we only want object literals (maps), but JSON could be an array too:
    if (isPlainObject(document)) {
      return {};
    }
  } catch (error) {}
  return DOCUMENT_JSON_ERROR;
};

export const validateCreatePolicyValues = (values: {
  name: string;
  document: string;
}) => {
  const errors: { name?: string; document?: string } = {};
  if (values.name === '') {
    errors.name = 'A name is required for a new policy';
  }

  return { ...errors, ...validateDocument(values) };
};
