import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const Footer = ({ children, className }: Props) => (
  <footer
    data-testid='footer'
    className={cn('bg-gray-50 text-center w-full py-6', className)}
  >
    {children}
  </footer>
);

export default Footer;
