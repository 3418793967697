import { gql } from '@apollo/client';

const sdkSnippetFields = `
id
name
content
priority
enabled
inserted_at
updated_at
`;

export const allSdkSnippetsQuery = gql`
query allSdkSnippets {
  sdkSnippets: sdk_snippets {
    nodes {
      ${sdkSnippetFields}
    }
    page_info {
      end_cursor
      has_next_page
    }
  }
}
`;

export const sdkSnippetCreateMutation = gql`
mutation createSdkSnippet($input: SdkSnippetCreateInput!) {
  createSdkSnippet: create_sdk_snippet(input: $input) {
    ${sdkSnippetFields}
  }
}
`;

export const sdkSnippetUpdateMutation = gql`
mutation updateSdkSnippet($id: Int!, $input: SdkSnippetUpdateInput!) {
  updateSdkSnippet: update_sdk_snippet(id: $id, input: $input) {
    ${sdkSnippetFields}
  }
}
`;

export const sdkSnippetDeleteMutation = gql`
mutation deleteSdkSnippet($id: Int!) {
  deleteSdkSnippet: delete_sdk_snippet(id: $id) {
    ${sdkSnippetFields}
  }
}
`;
