import { ReactNode } from 'react';

import { useLockBodyScroll } from 'hooks/scroll';

import ModalContent from './modalContent';

import './styles.css';

interface Props {
  isOpen?: boolean;
  children: ReactNode;
  role?: 'dialog' | 'alertdialog' | 'alert';
  ariaLabel: string;
  title?: string;
  onRequestClose: () => void;
  darkMode?: boolean;
  className?: string;
}

export const toggleScrollLock = () => {
  const html = document.querySelector('html');
  return html!.classList.toggle('lock-scroll');
};

const Modal = ({
  children,
  role,
  ariaLabel,
  title,
  onRequestClose,
  isOpen,
  darkMode,
  className
}: Props) => {
  return (
    <>
      {isOpen && (
        <Content
          role={role}
          className={className}
          darkMode={darkMode}
          ariaLabel={ariaLabel}
          title={title}
          onRequestClose={onRequestClose}
        >
          {children}
        </Content>
      )}
    </>
  );
};

const Content = ({
  children,
  role,
  ariaLabel,
  title,
  onRequestClose,
  darkMode,
  className
}: Props) => {
  useLockBodyScroll();
  return (
    <ModalContent
      className={className}
      onClose={onRequestClose}
      role={role}
      ariaLabel={ariaLabel}
      title={title}
      darkMode={darkMode}
    >
      {children}
    </ModalContent>
  );
};

export default Modal;
