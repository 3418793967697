import { lazy, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes as ReactRoutes
} from 'react-router-dom';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import arraySupport from 'dayjs/plugin/arraySupport';
import ct from 'countries-and-timezones';

import paths, { settingsPaths } from './routes.paths';

import FeatureFlagsContext from 'context/featureFlags';
import LocaleContext from 'context/locale';
import { useAuthContext } from 'context/auth';
import { useSolveUiBaseUrlContext } from 'context/solveBaseUrl';

import { pages as settingsPages } from 'pages/settings';

import AsyncLoader from 'components/asyncLoader';
import AuthPage from 'components/authPage';
import Page from 'components/page';
import PrivateRoute from 'components/privateRoute';

// potentially we can import all locales, but it's a lot of them
// so importing only en
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-ca';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-ie';
import 'dayjs/locale/en-il';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/en-sg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);

const Login = lazy(() => import('pages/auth/login'));
const NewPassword = lazy(() => import('pages/auth/newPassword'));
const ForgotPassword = lazy(() => import('pages/auth/forgotPassword'));
const SetPassword = lazy(() => import('pages/auth/setPassword'));
const Dashboard = lazy(() => import('pages/dashboard'));
const PageviewGraph = lazy(() => import('pages/dashboard/pageview/page'));
const MauGraph = lazy(() => import('pages/dashboard/mau/page'));
const TotalSalesGraph = lazy(() => import('pages/dashboard/totalSales/page'));
const RevenueGraph = lazy(() => import('pages/dashboard/revenue/page'));
const UsersSummaryGraph = lazy(
  () => import('pages/dashboard/usersSummary/page')
);
const CartConversionGraph = lazy(
  () => import('pages/dashboard/cartConversion/page')
);
const Account = lazy(() => import('pages/account'));
const Profiles = lazy(() => import('pages/profiles'));
const Profile = lazy(() => import('pages/profile'));
const QueryEngine = lazy(() => import('pages/queryEngine'));
const Campaigns = lazy(() => import('pages/campaigns'));
const Reports = lazy(() => import('pages/reports'));
const Conversions = lazy(() => import('pages/conversions'));
const Order = lazy(() => import('pages/order'));
const Orders = lazy(() => import('pages/orders'));
const Settings = lazy(() => import('pages/settings'));
const FourOhFour = lazy(() => import('pages/fourOhFour'));

interface Props {
  config: ConfigJson | null;
}

const Routes = ({ config }: Props) => {
  const baseUrlInfo = useSolveUiBaseUrlContext();
  const { user } = useAuthContext();

  const flags = useFlags();
  const ldClient = useLDClient();

  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (user?.username && ldClient) {
      const stackId = config?.solve?.stackId;

      ldClient
        .identify({
          key: user.username,
          custom: {
            stackId: stackId ?? 'unknown'
          }
        })
        /**
         * set loaded to true if client fails or succeeds.
         *
         * We don't want to render block if the flags can't be loaded.
         */
        .finally(() => setLoading(false));
    }
  }, [user, config, ldClient]);

  // Try our best to find a suitable country code based on the timezone.
  // We'll use the first one that has a locale in Day.js, otherwise
  //  use the first country listed, otherwise use an empty string.
  const possibleCountries = ct.getTimezone(dayjs.tz.guess())?.countries || [];
  const fallbackCountry = possibleCountries[0] || '';
  const chosenLocale = dayjs.locale(
    possibleCountries[0] ? `en-${possibleCountries[0]}` : 'en'
  );
  const countryCode = chosenLocale.startsWith('en-')
    ? chosenLocale.substr(3)
    : fallbackCountry;

  return (
    <FeatureFlagsContext.Provider value={{ flags, isLoading }}>
      <LocaleContext.Provider value={{ countryCode }}>
        <Router basename={baseUrlInfo.rootPath}>
          <ReactRoutes>
            <Route path='/' element={<PrivateRoute />}>
              <Route
                index
                element={
                  <Page fullScreen darkMode>
                    <AsyncLoader component={<Dashboard />} />
                  </Page>
                }
              />
              <Route
                path={paths.pageViewGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<PageviewGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.mauGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<MauGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.totalSalesGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<TotalSalesGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.revenueGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<RevenueGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.cartConversionGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<CartConversionGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.userSummaryGraph}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<UsersSummaryGraph />} />
                  </Page>
                }
              />
              <Route
                path={paths.account}
                element={
                  <Page>
                    <AsyncLoader component={<Account />} />
                  </Page>
                }
              />
              <Route
                path={paths.profiles}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<Profiles />} />
                  </Page>
                }
              />
              <Route
                path={paths.profile}
                element={
                  <Page>
                    <AsyncLoader component={<Profile />} />
                  </Page>
                }
              >
                <Route
                  path={'*'}
                  element={
                    <Page>
                      <AsyncLoader component={<Profile />} />
                    </Page>
                  }
                />
              </Route>
              <Route
                path={paths.orders}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<Orders />} />
                  </Page>
                }
              />
              <Route
                path={paths.orderProvider}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<Order />} />
                  </Page>
                }
              />
              <Route
                path={paths.channels}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<Reports />} />
                  </Page>
                }
              />
              <Route
                path={paths.conversions}
                element={
                  <Page fullScreen>
                    <AsyncLoader component={<Conversions />} />
                  </Page>
                }
              />
              <Route
                path={paths.reports}
                element={
                  <Page fullScreen innerClassName='overflow-y-hidden'>
                    <AsyncLoader component={<Campaigns />} />
                  </Page>
                }
              >
                <Route
                  path={paths.campaigns}
                  element={
                    <Page fullScreen>
                      <AsyncLoader component={<Campaigns />} />
                    </Page>
                  }
                />
              </Route>
              <Route
                path={paths.queryEngine}
                element={
                  <Page fullScreen hideFooter darkMode className='h-full'>
                    <AsyncLoader component={<QueryEngine />} />
                  </Page>
                }
              >
                <Route
                  path={paths.queryEngineSavedQueryId}
                  element={
                    <Page fullScreen hideFooter darkMode className='h-full'>
                      <AsyncLoader component={<QueryEngine />} />
                    </Page>
                  }
                />
              </Route>
              <Route
                path={settingsPaths.root}
                element={<PrivateRoute requiresAdmin />}
              >
                <Route
                  element={
                    <Page>
                      <AsyncLoader component={<Settings />} />
                    </Page>
                  }
                >
                  {settingsPages.map(({ id, child }) => (
                    <Route
                      key={id}
                      path={`${settingsPaths[id]}`}
                      element={child}
                    />
                  ))}
                </Route>
                <Route
                  path={settingsPaths.root}
                  element={<Navigate to={settingsPaths.apiIntegrations} />}
                />
              </Route>
            </Route>
            <Route path='/auth'>
              <Route
                path='login'
                element={
                  <AuthPage>
                    <AsyncLoader component={<Login />} />
                  </AuthPage>
                }
              />
              <Route
                path='new-password'
                element={
                  <AuthPage>
                    <AsyncLoader component={<NewPassword />} />
                  </AuthPage>
                }
              />
              <Route
                path='forgot-password'
                element={
                  <AuthPage>
                    <AsyncLoader component={<ForgotPassword />} />
                  </AuthPage>
                }
              />
              <Route
                path='set-password'
                element={
                  <AuthPage>
                    <AsyncLoader component={<SetPassword />} />
                  </AuthPage>
                }
              />
            </Route>

            <Route
              path='*'
              element={
                <Page>
                  <AsyncLoader component={<FourOhFour />} />
                </Page>
              }
            />
          </ReactRoutes>
        </Router>
      </LocaleContext.Provider>
    </FeatureFlagsContext.Provider>
  );
};

export default Routes;
