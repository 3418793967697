import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { apiKeysQuery, apiKeysQueryUpdate } from 'queries/apiKey';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import Label from 'components/label';
import Loader from 'components/loader';

export type Props = {
  apiKey: ApiKey;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function EnableDisableForm({ apiKey, onClose }: Props) {
  const { name, is_disabled, notes } = apiKey;

  return (
    <Mutation mutation={apiKeysQueryUpdate}>
      {(updateApiKey: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }
        if (error) {
          return <ErrorAlert error={error} className='m-2' />;
        }

        return (
          <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
            <div data-testid='api-key-enable-disable-info'>
              Please confirm that you want to
              {is_disabled ? ' enable ' : ' disable '}
              the API Key{' '}
              <code
                className='font-bold'
                data-testid='api-key-enable-disable-name'
              >
                {name}
              </code>
              .
            </div>
            <div className='flex flex-col gap-2'>
              <Label>description</Label>
              <p data-testid='api-key-enable-disable-notes'>{notes}</p>
            </div>
            <div className='flex flex-row-reverse'>
              <Button
                type='button'
                color='primary'
                data-testid='api-key-enable-disable-button'
                onClick={async event => {
                  await updateApiKey({
                    variables: {
                      id: apiKey.id,
                      input: {
                        is_disabled: !apiKey.is_disabled
                      }
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [{ query: apiKeysQuery }]
                  });
                  onClose(event);
                }}
              >
                {is_disabled ? 'Enable' : 'Disable'}
              </Button>
            </div>
          </div>
        );
      }}
    </Mutation>
  );
}
