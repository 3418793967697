import { gql } from '@apollo/client';

export const apiKeyQuery = gql`
  query apiKey($id: Integer!) {
    apiKey: api_key(id: $id) {
      id
      inserted_at
      is_disabled
      key
      name
      notes
      type
      updated_at
    }
  }
`;

export const apiKeysQuery = gql`
  query apiKeys($after: Cursor, $first: Int) {
    apiKeys: api_keys(after: $after, first: $first) {
      nodes {
        id
        inserted_at
        is_disabled
        key
        name
        notes
        type
        updated_at
      }
      page_info {
        end_cursor
        has_next_page
        page_index
      }
    }
  }
`;

export const apiKeysQueryCreate = gql`
  mutation createApiKey($input: ApiKeyCreateInput!) {
    createApiKey: create_api_key(input: $input) {
      name
      notes
      type
      key
      secret
    }
  }
`;

export const apiKeysQueryUpdate = gql`
  mutation updateApiKey($id: Int!, $input: ApiKeyUpdateInput!) {
    updateApiKey: update_api_key(id: $id, input: $input) {
      id
      inserted_at
    }
  }
`;

export const apiKeysQueryDisable = gql`
  mutation disableApiKey($id: Int!) {
    disableApiKey: disable_api_key(id: $id) {
      id
      is_disabled
    }
  }
`;

export const apiKeysQueryEnable = gql`
  mutation enableApiKey($id: Int!) {
    enableApiKey: enable_api_key(id: $id) {
      id
      is_disabled
    }
  }
`;
