import { createContext, useContext } from 'react';

export interface Context {
  countryCode: any;
}

const LocaleContext = createContext({
  countryCode: ''
});

export default LocaleContext;
export const useLocaleContext: () => Context = () => useContext(LocaleContext);
