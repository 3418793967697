import { createLogger, INFO, DEBUG, stdSerializers } from 'browser-bunyan';
import { ConsoleFormattedStream } from '@browser-bunyan/console-formatted-stream';
import { ConsolePlainStream } from '@browser-bunyan/console-plain-stream';

export const initLogger = ({
  name,
  level = INFO
}: {
  name: string;
  level: string | number;
}) => {
  // Only format with colours in dev mode. Else we get all sorts of colour characters and variables in
  // the Sentry reports and test output. Bit noisy
  const StreamType =
    process.env.NODE_ENV === 'development'
      ? ConsoleFormattedStream
      : ConsolePlainStream;

  return createLogger({
    name,
    streams: [
      {
        level,
        stream: new StreamType()
      }
    ],
    serializers: stdSerializers,
    src: true
  });
};

const logger = initLogger({
  name: 'solveLogger',
  level: process.env.NODE_ENV === 'development' ? DEBUG : INFO
});

export default logger;
// Re-export Sentry to make it easy to access some of the additional Sentry functionality, if needed:
export { Sentry } from './sentry';
