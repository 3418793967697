import { ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/types/errorboundary';

import Button from 'components/button';

interface Props {
  children: ReactNode;
  fallback?: ErrorBoundaryProps['fallback'];
}

const IS_DEV = process.env.NODE_ENV === 'development';

const FallBack: ErrorBoundaryProps['fallback'] = props => {
  return (
    <div className='h-full w-full flex body-reset'>
      <div className='flex flex-col items-center justify-center h-full w-full sm:w-1/2 mx-auto'>
        <h1 className='pt-8 text-lg'>Something went wrong</h1>
        {IS_DEV && (
          <div className='text-gray-600 bg-red-100 rounded mt-8 py-4 flex justify-between items-center'>
            <span className='text-center'>
              <p className='pb-2'>
                This error will only be shown in development
              </p>
              <code>{String(props.error)}</code>
            </span>
          </div>
        )}
        <p className='pt-8 pb-8'>
          We&#39;re really sorry, but something unexpected has happened. Our
          team have been notified. If this continues to happen you can get in
          touch with our support team at{' '}
          <a href='mailto:support@solvedata.io'>support@solvedata.io</a>.
        </p>
        <Button
          type='button'
          color='primary'
          onClick={() => window.location.reload()}
        >
          Reload page
        </Button>
      </div>
    </div>
  );
};

const SolveErrorBoundary = ({ children, fallback }: Props) => {
  return (
    <ErrorBoundary fallback={fallback || FallBack} showDialog>
      {children}
    </ErrorBoundary>
  );
};

export default SolveErrorBoundary;
