function wait(n: number) {
  return new Promise(resolve => setTimeout(resolve, n));
}
export const TIMEOUT = 4000;

const fetchRetry = (
  url: string,
  options: RequestInit = {},
  retries = 2,
  timeout = 4000
): Promise<Response> => {
  return fetch(url, options)
    .then(res => {
      if (res.ok) {
        return res;
      }
      if (retries > 0) {
        return fetchRetry(url, options, retries - 1);
      }
      return res;
    })
    .catch(e => {
      if (retries > 0) {
        return wait(timeout).then(() => {
          return fetchRetry(url, options, retries - 1);
        });
      } else {
        throw e;
      }
    });
};

export default fetchRetry;
