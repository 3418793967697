import cn from 'classnames';

import DateCell from 'components/dateCell';
import DocsLink from 'components/docsLink';

import RowContextMenu from './rowContextMenu';

interface Props {
  sdkSnippets: SdkSnippetsList;
}

const SdkSnippetsTable = ({ sdkSnippets }: Props) => {
  const docsLink = (
    <div className='mt-2'>
      You can find out more about SDK Snippets and how to use them in the{' '}
      <DocsLink href='/web-sdk/sdk-snippets'>documentation</DocsLink>
    </div>
  );
  if (!sdkSnippets.nodes || sdkSnippets.nodes.length === 0) {
    return (
      <div>
        <div>No SDK snippets found.</div>
        {docsLink}
      </div>
    );
  }

  const columnNames: string[] = ['Name', 'Priority', 'Updated At'];

  return (
    <>
      <table className='mt-6 w-full' data-testid='sdk-snippets-table'>
        <thead>
          <tr>
            {columnNames.map((name: string, idx: number) => {
              return (
                <td
                  key={`header-column-${name}`}
                  className='whitespace-nowrap p-2 font-bold border-b'
                >
                  {name}
                </td>
              );
            })}
            <td className='border-b' />
          </tr>
        </thead>
        <tbody>
          {sdkSnippets.nodes?.map((sdkSnippet: SdkSnippet) => {
            return (
              <tr
                key={`${sdkSnippet.id}-table-row`}
                className={cn('sdk-snippet-item', {
                  'sdk-snippet-item-enabled': sdkSnippet.enabled,
                  'sdk-snippet-item-disabled text-gray-300': !sdkSnippet.enabled
                })}
                data-testid={`sdk-snippet-${sdkSnippet.id}`}
              >
                <td
                  className='p-2 border-b'
                  data-testid='sdk-snippet-item-name'
                >
                  {sdkSnippet.name}
                </td>
                <td
                  className='p-2 border-b'
                  data-testid='sdk-snippet-item-priority'
                >
                  {sdkSnippet.priority}
                </td>
                <td
                  className='border-b'
                  data-testid='sdk-snippet-item-updated-at'
                >
                  <DateCell
                    data={{
                      id: sdkSnippet.id,
                      date: sdkSnippet.updated_at
                    }}
                    isTimeDiff
                    className='p-2'
                    interactive={false}
                  />
                </td>
                <td className='pl-2 border-b sdk-snippet-item-context-menu'>
                  <RowContextMenu sdkSnippet={sdkSnippet} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {docsLink}
    </>
  );
};

export default SdkSnippetsTable;
