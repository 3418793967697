import AWSCognitoAuth from 'auth/cognito';

import logger from 'utils/logger';
import { updateSentryWithStackConfig } from 'utils/sentry';

export const extractStackFromHostname = (hostname: string) => {
  const domainSuffix = '.solvedata.app';
  if (hostname.endsWith(domainSuffix)) {
    // Turns `env.client.solvedata.app` into `env.client`
    const envClientSubstr = hostname.substring(
      0,
      hostname.length - domainSuffix.length
    );
    const envClientParts = envClientSubstr.split('.', 3);
    if (envClientParts.length === 2) {
      return { clientName: envClientParts[1], environment: envClientParts[0] };
    } else if (envClientParts.length === 1) {
      return {
        clientName: envClientParts[0],
        environment: undefined
      };
    }
  }
  return undefined;
};

export const getUrlRevisionInformation = ({
  pathname,
  hostname
}: {
  hostname: string;
  pathname: string;
}): UiBaseUrlInfo => {
  const domainInfo = extractStackFromHostname(hostname);

  if (pathname.indexOf('/_/') === 0) {
    // This will grab the first path segment after the `/_/`.
    // So for the URL of `http://localhost/_/abc123/profiles/` this will be set to `abc123`.
    const urlRevision = pathname.substring('/_/'.length).split('/', 1)[0];
    return {
      domainInfo,
      rootPath: '/_/' + urlRevision + '/',
      urlRevision,
      isLatest: false,
      isPr: urlRevision.startsWith('PR')
    };
  } else {
    // UI is accessed at the root of the domain, so must be `latest`.
    return {
      domainInfo,
      rootPath: '/',
      urlRevision: 'latest',
      isLatest: true,
      isPr: false
    };
  }
};

export const getStackBaseUrls = (config: any): StackBaseUrlConfig | null => {
  try {
    const apiUri = config.api.endpoint;
    const queryEngineUrl = config.queryEngine.endpoint;
    const protocol =
      apiUri.indexOf('.solvestack.net') === -1
        ? window.location.protocol
        : 'https:';
    // Am talking to a real solve stack, we must use HTTPS.
    return {
      api: `${protocol}//${apiUri}`,
      queryEngine: `${protocol}//${queryEngineUrl}`,
      static: `${protocol}//${config.static.endpoint}`
    };
  } catch (e) {
    return null;
  }
};

export const getCognitoAuth: (
  auth: any,
  config: ConfigJson
) => Promise<AuthInterface> = (auth, config) =>
  Promise.resolve(new AWSCognitoAuth(auth));

const getConfigUrl = (host: string, protocol: string) => {
  if (process.env.NODE_ENV === 'development' && process.env.CONFIG_PATH) {
    // allows for custom config paths to be used
    // cp public/config.json public/shared-01.config.json
    // export CONFIG_PATH=shared-01.config.json to point and shared-01
    return `${protocol}//${host}/${process.env.CONFIG_PATH}`;
  } else {
    return `${protocol}//${host}/config.json`;
  }
};

export const getConfig: () => Promise<ConfigRetrieved> = async () => {
  const { host, protocol } = window.location;
  const configUrl = getConfigUrl(host, protocol);
  try {
    const response = await fetch(configUrl, {
      method: 'GET'
    });
    const json = await response.json();

    // Add Sentry details to Logger (if provided)
    updateSentryWithStackConfig(json);

    // If json is undefined here then this code will throw, resulting in our default
    if (json.auth.provider === 'cognito') {
      const authConfig = { ...json.auth };
      delete authConfig.provider;

      logger.debug(`Loading cognito auth with id ${authConfig.id}`);
      const auth = await getCognitoAuth(authConfig, json);
      if (auth) {
        return {
          auth,
          error: null,
          config: json,
          baseUrls: getStackBaseUrls(json)
        };
      }
    }

    throw new Error(`Unexpected error in auth config retrieval`);
  } catch (err) {
    logger.error(`Error getting config. Setting to Solve default`, err.message);
    return Promise.resolve({
      auth: null,
      error: err.message,
      config: null,
      baseUrls: null
    });
  }
};
