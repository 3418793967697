import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  children?: ReactNode;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
  onMouseEnter?(event: React.MouseEvent<HTMLElement>): void;
  onMouseLeave?(event: React.MouseEvent<HTMLElement>): void;
  className?: string;
  'data-testid'?: string;
}

const Info = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  'data-testid': dataTestId
}: Props) => {
  const classes = cn(
    'inline-block rounded-full text-center bg-gray-300 h-4 w-4 hover:bg-gray-200',
    { 'cursor-pointer': !!onClick },
    className
  );
  return (
    <span
      data-testid={dataTestId}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes}
    >
      <span className='block mt-px text-white text-xs italic font-bold'>i</span>
    </span>
  );
};

export default Info;
