import cn from 'classnames';
import { Monaco } from '@monaco-editor/react';

import theme from 'utils/tailwind';

import CodeEditor from 'components/codeEditor';

interface Props {
  defaultValue: string;
  language: 'json' | 'js';
  height: string; // ex.: '200px'
  'data-testid': string;
  [x: string]: any;
}

const CustomCodeViewer = ({
  defaultValue,
  language,
  height,
  'data-testid': dataTestId
}: Props) => {
  const handleEditorWillMount = (monaco: Monaco) => {
    monaco.editor.defineTheme('solve-theme', {
      base: 'vs',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': theme.colors.white
      }
    });
  };

  return (
    <CodeEditor
      className={cn(
        'pt-2 border border-solid border-gray-300 rounded overflow-hidden focus:border-blue-400 focus:outline-none w-full'
      )}
      language={language}
      height={height}
      defaultValue={defaultValue}
      beforeMount={handleEditorWillMount}
      data-testid={dataTestId}
      options={{
        readOnly: true,
        contextmenu: false,
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        wordWrap: 'on',
        wrappingStrategy: 'advanced'
      }}
    />
  );
};

export default CustomCodeViewer;
