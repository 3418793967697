import cn from 'classnames';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import Editor, { EditorProps } from '@monaco-editor/react';

import './styles.css';

interface Props extends EditorProps {
  'data-testid'?: string;
}

const defaultOptions: editor.IStandaloneEditorConstructionOptions = {
  colorDecorators: true,
  fontSize: 13,
  formatOnPaste: true,
  formatOnType: true,
  quickSuggestions: true,
  quickSuggestionsDelay: 100,
  acceptSuggestionOnEnter: 'off',
  scrollbar: {
    verticalScrollbarSize: 8,
    verticalHasArrows: false,
    useShadows: false
  },
  minimap: {
    enabled: false
  },
  scrollBeyondLastLine: false
};

const CodeEditor = ({
  height,
  language,
  defaultValue,
  value,
  onChange,
  beforeMount,
  onMount,
  options,
  className,
  'data-testid': dataTestId,
  onValidate
}: Props) => {
  const extendedOptions = Object.assign(defaultOptions, options);

  return (
    <div data-testid={dataTestId}>
      <Editor
        data-testid={dataTestId}
        className={cn(className)}
        height={height}
        language={language}
        theme='solve-theme'
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        beforeMount={beforeMount}
        onMount={onMount}
        options={extendedOptions}
        onValidate={onValidate}
      />
    </div>
  );
};

export default CodeEditor;
