import { useState } from 'react';
import { Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { usersQuery, userCreateMutation } from 'queries/user';

import { createUserValidation } from 'validation/user';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';
import Label from 'components/label';
import Input from 'components/input';
import FormTextField from 'components/formComponents/formTextField';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

interface FormValuesProps {
  name: string;
  role: string;
  email: string;
}

const DEFAULT_VALUES = {
  name: '',
  role: 'USER',
  email: ''
};

const Form = ({ values, handleSubmit, setFieldValue }: any) => {
  return (
    <form onSubmit={handleSubmit} data-testid='new-user-form'>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <div className='flex flex-col gap-4'>
          <p>
            Only create user accounts for trusted parties. Anyone with an
            account can access your data.
          </p>
          <div>
            <FormTextField
              name='name'
              placeholder='Give the new user a name'
              label='name'
              dataTestId='create-user-name-input'
              errorDataTestId='create-user-name-error'
              type='text'
            />
          </div>
          <div>
            <FormTextField
              name='email'
              placeholder="Enter the new user's email"
              label='email'
              dataTestId='create-user-email-input'
              errorDataTestId='create-user-email-error'
              type='text'
            />
          </div>
        </div>

        <Label>role</Label>
        <div className='flex' onClick={() => setFieldValue('role', 'USER')}>
          <div className='flex-1'>
            <Input
              type='radio'
              name='role'
              value='USER'
              checked={values.role === 'USER'}
              onChange={() => setFieldValue('role', 'USER')}
              className='mx-2 cursor-pointer'
              data-testid='user-permissions-radio'
            />
          </div>
          <div className='flex-auto'>
            <p className='font-bold cursor-pointer'>User</p>
            <p className='cursor-pointer'>
              A user can search, view, query and download data, but they cannot
              manage Users and API Integrations. This is the default permission
              level.
            </p>
          </div>
        </div>
        <div className='flex' onClick={() => setFieldValue('role', 'ADMIN')}>
          <div className='flex-1'>
            <Input
              type='radio'
              name='role'
              value='ADMIN'
              checked={values.role === 'ADMIN'}
              onChange={() => setFieldValue('role', 'ADMIN')}
              className='mx-2 cursor-pointer'
              data-testid='admin-permissions-radio'
            />
          </div>
          <div className='flex-auto'>
            <p className='font-bold cursor-pointer'>Admin</p>
            <p className='cursor-pointer'>
              An Admin has the same permissions as a User, but can also manage
              Users and API integrations. This is the highest permission level.
            </p>
          </div>
        </div>
        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='submit'
            color='primary'
            className='px-8 uppercase'
            data-testid='create-user'
          >
            create
          </Button>
        </div>
      </div>
    </form>
  );
};

const setupScreen = (payload: UserPayload, onClose: Props['onClose']) => {
  return (
    <div className='mt-2 mb-4 mx-5'>
      <div className='mb-5'>
        <p>
          User <strong>{payload.name}</strong> has been successfully created
          with the <strong>{payload.role}</strong> role.{' '}
        </p>
        <p>An email with a temporary password has been sent to the new user.</p>
      </div>
      <div className='flex justify-end'>
        <Button
          type='button'
          color='primary'
          onClick={onClose}
          className='w-20'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const Setup = ({ onClose }: Props) => {
  const [setup, setSetup] = useState(0);

  const [payload, setPayload] = useState(DEFAULT_VALUES);

  return (
    <Mutation mutation={userCreateMutation}>
      {(createUser: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          return (
            <div className='mt-2 mb-4 mx-4'>
              {error.message?.includes(
                'This email address is already associated with an existing user'
              ) ? (
                <ErrorAlert
                  error={error}
                  message='This email address already belongs to another user. No user was created.'
                />
              ) : (
                <ErrorAlert error={error} />
              )}
            </div>
          );
        }
        return setup === 0 ? (
          <Formik
            initialValues={payload} // the validation schema considers empty strings invalid: initial values cannot be accidentally submitted
            validationSchema={createUserValidation}
            onSubmit={async (values: FormValuesProps) => {
              const gqlRes = await createUser({
                variables: {
                  input: {
                    name: values.name,
                    email: values.email,
                    role: values.role
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: usersQuery }]
              });
              setPayload(gqlRes.data.createUser);
              setSetup(1);
            }}
          >
            {props => <Form {...props} />}
          </Formik>
        ) : (
          setupScreen(payload, onClose) || null
        );
      }}
    </Mutation>
  );
};

export default Setup;
