import { useCallback, useState } from 'react';

import ContextMenu from 'components/contextMenu';
import Modal from 'components/modal';

import EditForm from '../forms/edit';
import EnableDisableForm from '../forms/enableDisable';
import DeleteForm from '../forms/delete';

interface RowContextMenuProps {
  sdkSnippet: SdkSnippet;
}

interface ModalProps {
  sdkSnippet: SdkSnippet;
  isOpen: boolean;
  onRequestClose: () => void;
}

const EditModal = ({ sdkSnippet, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Edit SDK Snippet'
      ariaLabel='Edit SDK Snippet'
      className='w-4/6 h-5/6 overflow-y-auto max-h-[892px] [@media(min-width:796px)_and_(max-width:978px)]:max-h-[872px] [@media(min-width:978px)]:max-h-[848px]'
    >
      <EditForm sdkSnippet={sdkSnippet} onClose={onRequestClose} />
    </Modal>
  );
};

const EnableDisableModal = ({
  sdkSnippet,
  isOpen,
  onRequestClose
}: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={sdkSnippet.enabled ? 'Disable SDK Snippet' : 'Enable SDK Snippet'}
      ariaLabel={
        sdkSnippet.enabled ? 'Disable SDK Snippet' : 'Enable SDK Snippet'
      }
    >
      <EnableDisableForm sdkSnippet={sdkSnippet} onClose={onRequestClose} />
    </Modal>
  );
};
const DeleteModal = ({ sdkSnippet, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Delete SDK Snippet'
      ariaLabel='Delete SDK Snippet'
    >
      <DeleteForm sdkSnippet={sdkSnippet} onClose={onRequestClose} />
    </Modal>
  );
};

const RowContextMenu = ({ sdkSnippet }: RowContextMenuProps) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [enableDisableIsOpen, setEnableDisableIsOpen] = useState(false);

  const editHandlerToggle = useCallback(() => {
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);
  }, []);

  const deleteHandlerToggle = useCallback(() => {
    setDeleteIsOpen(prevDeleteIsOpen => !prevDeleteIsOpen);
  }, []);

  const enableDisableHandlerToggle = useCallback(() => {
    setEnableDisableIsOpen(prevEnableDisableIsOpen => !prevEnableDisableIsOpen);
  }, []);

  return (
    <div data-testid={`context-menu-wrapper`}>
      <ContextMenu
        data-testid={`context-menu`}
        type='vdots'
        menu={[
          {
            label: 'Edit',
            onClick: editHandlerToggle
          },
          {
            label: sdkSnippet.enabled ? 'Disable' : 'Enable',
            onClick: enableDisableHandlerToggle
          },
          {
            label: 'Delete',
            onClick: deleteHandlerToggle
          }
        ]}
      />
      <EditModal
        sdkSnippet={sdkSnippet}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
      <EnableDisableModal
        sdkSnippet={sdkSnippet}
        isOpen={enableDisableIsOpen}
        onRequestClose={enableDisableHandlerToggle}
      />
      <DeleteModal
        sdkSnippet={sdkSnippet}
        isOpen={deleteIsOpen}
        onRequestClose={deleteHandlerToggle}
      />
    </div>
  );
};

export default RowContextMenu;
