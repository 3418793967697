import { Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { usersQuery, userUpdateMutation } from 'queries/user';

import { editUserValidation } from 'validation/user';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import Loader from 'components/loader';
import Label from 'components/label';
import Input from 'components/input';
import FormTextField from 'components/formComponents/formTextField';

interface Props {
  userData: User;
  onClose: () => void;
}

interface FormValuesProps {
  name: string;
  role: string;
}

const Form = ({ userData, values, handleSubmit, setFieldValue }: any) => {
  return (
    <form onSubmit={handleSubmit} data-testid='user-edit'>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <div>
          <FormTextField
            name='name'
            placeholder='Give this user a new name'
            label='name'
            type='text'
            dataTestId='user-edit-name'
          />
        </div>
        <Label>role</Label>
        <div className='flex' onClick={() => setFieldValue('role', 'USER')}>
          <div className='flex-1'>
            <Input
              type='radio'
              name='role'
              value='USER'
              checked={values.role === 'USER'}
              onChange={() => setFieldValue('role', 'USER')}
              className='mx-2 cursor-pointer'
              data-testid='user-edit-role-user'
            />
          </div>
          <div className='flex-auto'>
            <p className='font-bold cursor-pointer'>User</p>
            <p className='cursor-pointer'>
              A user can search, view, query and download data, but they cannot
              manage Users and API Integrations. This is the default permission
              level.
            </p>
          </div>
        </div>
        <div className='flex' onClick={() => setFieldValue('role', 'ADMIN')}>
          <div className='flex-1'>
            <Input
              type='radio'
              name='role'
              value='ADMIN'
              checked={values.role === 'ADMIN'}
              onChange={() => setFieldValue('role', 'ADMIN')}
              className='mx-2 cursor-pointer'
              data-testid='user-edit-role-admin'
            />
          </div>
          <div className='flex-auto'>
            <p className='font-bold cursor-pointer'>Admin</p>
            <p className='cursor-pointer'>
              An Admin has the same permissions as a User, but can also manage
              Users and API integrations. This is the highest permission level.
            </p>
          </div>
        </div>
        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='submit'
            color='primary'
            isDisabled={
              values.name === '' ||
              (values.name === userData.name && values.role === userData.role)
            }
            className='px-8 uppercase'
            data-testid='submit-edit-user'
          >
            Update
          </Button>
        </div>
      </div>
    </form>
  );
};

const Edit = ({ userData, onClose }: Props) => {
  return (
    <Mutation mutation={userUpdateMutation}>
      {(updateUser: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          return <ErrorAlert error={error} />;
        }

        return (
          <Formik
            initialValues={{
              name: userData.name === 'Unknown' ? '' : userData.name,
              role: userData.role
            }}
            data-testid='user-edit'
            validationSchema={editUserValidation}
            onSubmit={async (values: FormValuesProps) => {
              await updateUser({
                variables: {
                  email: userData.email,
                  input: {
                    name: values.name,
                    role: values.role
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: usersQuery }]
              });
              onClose();
            }}
          >
            {props => <Form userData={userData} {...props} />}
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default Edit;
