import { useCallback, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';

import { allConfigsQuery } from 'queries/configuration';

import Title from 'components/title';
import ErrorAlert from 'components/errorAlert';
import Button from 'components/button';
import Modal from 'components/modal';

import Setup from './forms/setup';
import Skeleton from './skeleton';
import ConfigurationsTable from './configurationsTable';

const ConfigurationsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggle = useCallback(() => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  }, []);

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <Title size='large'>Configurations</Title>
        </div>
        <div className='relative'>
          <Button
            type='button'
            color='primary'
            className='uppercase'
            onClick={handleToggle}
            data-testid='new-configuration'
          >
            new configuration
          </Button>
        </div>
      </div>
      <Query query={allConfigsQuery} fetchPolicy='cache-and-network'>
        {(props: QueryResult) => {
          const { data, error, loading } = props;
          if (loading) {
            return <Skeleton />;
          }

          if (error) {
            return <ErrorAlert error={error} />;
          }

          return <ConfigurationsTable configurations={data.configurations} />;
        }}
      </Query>
      <Modal
        ariaLabel='New Configuration Setup'
        title='New Configuration Setup'
        isOpen={isModalOpen}
        onRequestClose={handleToggle}
      >
        <Setup onClose={handleToggle} />
      </Modal>
    </>
  );
};

export default ConfigurationsSection;
