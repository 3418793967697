import { useCallback, useState } from 'react';

import ContextMenu from 'components/contextMenu';
import Modal from 'components/modal';

import DeleteForm from '../forms/delete';
import EditForm from '../forms/edit';
import ResetForm from '../forms/reset';

interface RowContextMenuProps {
  user: User;
}

interface ModalProps {
  user: User;
  isOpen: boolean;
  onRequestClose: () => void;
}

/*
  This modal currently only serves to change user privileges but will probably be used to
  change other user details in the future, hence the name.
*/
const EditModal = ({ user, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Edit User'
      ariaLabel='Edit User'
    >
      <EditForm userData={user} onClose={onRequestClose} />
    </Modal>
  );
};

const DeleteModal = ({ user, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Delete User'
      ariaLabel='Delete User'
    >
      <DeleteForm userData={user} onClose={onRequestClose} />
    </Modal>
  );
};

// This allows the user to reset the password for the user
const ResetModal = ({ user, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Reset Password'
      ariaLabel='Reset Password'
    >
      <ResetForm userData={user} onClose={onRequestClose} />
    </Modal>
  );
};

const RowContextMenu = ({ user }: RowContextMenuProps) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [resetIsOpen, setResetIsOpen] = useState(false);

  const editHandlerToggle = useCallback(() => {
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);
  }, []);

  const deleteHandlerToggle = useCallback(() => {
    setDeleteIsOpen(prevDeleteIsOpen => !prevDeleteIsOpen);
  }, []);

  const resetHandlerToggle = useCallback(() => {
    setResetIsOpen(prevResetIsOpen => !prevResetIsOpen);
  }, []);

  return (
    <div data-testid={`${user.name}-context-menu`}>
      <ContextMenu
        data-testid={`${user.id}-context-menu`}
        type='vdots'
        menu={[
          {
            label: 'Edit',
            onClick: editHandlerToggle
          },
          {
            label: 'Delete',
            onClick: deleteHandlerToggle
          },
          {
            label: 'Reset Password',
            onClick: resetHandlerToggle
          }
        ]}
      />
      <EditModal
        user={user}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
      <DeleteModal
        user={user}
        isOpen={deleteIsOpen}
        onRequestClose={deleteHandlerToggle}
      />
      <ResetModal
        user={user}
        isOpen={resetIsOpen}
        onRequestClose={resetHandlerToggle}
      />
    </div>
  );
};

export default RowContextMenu;
