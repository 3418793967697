import { Form, Formik } from 'formik';
import { ApolloError, useMutation } from '@apollo/client';

import { apiKeysQuery, apiKeysQueryCreate } from 'queries/apiKey';

import { createApiKeyValidation } from 'validation/apiKey';

import ServerAPI from './serverAPI';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';
import FormTextField from 'components/formComponents/formTextField';
import FormTextArea from 'components/formComponents/formTextArea';

import Pixel from './pixel';
import WebSDK from './webSDK';

const useCreateApiKeyMutation = () => {
  return useMutation(apiKeysQueryCreate, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: apiKeysQuery,
        variables: { first: 100 }
      }
    ]
  });
};

enum Types {
  'PROTECTED',
  'PUBLIC',
  'PIXEL'
}

interface Props {
  type?: keyof typeof Types;
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

interface FormValuesProps {
  name: string;
  notes: string;
}

const ApiKeyForm = ({ handleSubmit, type }: any) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <div>
          {type === 'PROTECTED' && (
            <p className='mb-4'>
              This key should only be given to trusted sources as anyone with
              the key can access your data. Never use this key to integrate the
              Solve Web SDK into your website.
            </p>
          )}
          <FormTextField
            name='name'
            placeholder='Give this integration a name'
            label='integration name'
            errorDataTestId='create-api-key-error'
            type='text'
          />
        </div>
        <div>
          <FormTextArea
            name='notes'
            placeholder='Enter a description of what this integration does'
            label='description'
          />
        </div>
        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='submit'
            color='primary'
            className='px-8 uppercase'
            data-testid='create-api-key'
          >
            create
          </Button>
        </div>
      </div>
    </Form>
  );
};

const CreateApiKeyContainer = ({
  loading,
  error,
  createApiKey,
  type
}: {
  loading: boolean;
  error?: ApolloError;
  createApiKey: ReturnType<typeof useCreateApiKeyMutation>[0];
  type: Props['type'];
}) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <Formik
      initialValues={{ name: '', notes: '' }}
      validationSchema={createApiKeyValidation}
      onSubmit={async (values: FormValuesProps) => {
        await createApiKey({
          variables: {
            input: {
              name: values.name,
              notes: values.notes,
              type
            }
          }
        });
      }}
    >
      {props => <ApiKeyForm type={type} {...props} />}
    </Formik>
  );
};

const SetupScreen = ({
  type,
  ...rest
}: {
  payload: ApiKeyPayload;
  onClose: Props['onClose'];
  type?: Props['type'];
}) => {
  if (type === 'PROTECTED') {
    return <ServerAPI {...rest} />;
  }
  return <WebSDK {...rest} />;
};

const ApiKeySetup = ({ type, onClose }: Props) => {
  const [createApiKey, { data, loading, error }] = useCreateApiKeyMutation();

  if (data?.createApiKey && !loading) {
    const payload = data.createApiKey;
    return <SetupScreen type={type} payload={payload} onClose={onClose} />;
  }

  return (
    <CreateApiKeyContainer
      type={type}
      createApiKey={createApiKey}
      loading={loading}
      error={error}
    />
  );
};

const Setup = ({ type, onClose }: Props) => {
  if (type === 'PIXEL') {
    /*
    Pixel doesn't require any server side resources
    */
    return <Pixel onClose={onClose} />;
  } else {
    return <ApiKeySetup type={type} onClose={onClose} />;
  }
};

export default Setup;
