import { useEffect } from 'react';

export const useKeyDown = (
  keyCode: number,
  callback: (event: KeyboardEvent) => void
) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.keyCode === keyCode) {
        callback(event);
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  }, [keyCode, callback]);
};
