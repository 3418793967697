import { useLayoutEffect, useRef, useState, ReactNode } from 'react';
import cn from 'classnames';

const buttonClasses = {
  primary:
    'bg-blue-400 text-white hover:bg-blue-300 border border-solid border-transparent',
  secondary:
    'bg-gray-300 text-white hover:bg-gray-200 border border-solid border-transparent',
  danger:
    'bg-red-500 text-white hover:bg-red-400 border border-solid border-transparent',
  success:
    'bg-green-400 text-white hover:bg-green-300 border border-solid border-transparent',
  transparent: 'border border-transparent',
  gray: 'bg-gray-200 hover:bg-gray-300 border border-solid border-transparent'
};

interface Props {
  className?: string;
  children: ReactNode;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
  type: 'button' | 'submit' | 'reset';
  'data-testid'?: string;
  color: keyof typeof buttonClasses;
  submitting?: boolean;
  isDisabled?: boolean;
}

const Button = ({
  className,
  children,
  onClick,
  type,
  color,
  submitting,
  isDisabled,
  'data-testid': dataTestId
}: Props) => {
  const buttonRef = useRef(null);
  const [width, setWidth] = useState<number | null>(null);
  useLayoutEffect(() => {
    // Determines whether we should show the `...` loading state on the button.
    // Not only do we need `submitting` to be true, but we also need to calculate
    // the width of the already rendered button so that we don't get a jump in
    // button width as we change from text to `...`
    if (!submitting || !buttonRef.current) {
      setWidth(null);
      return;
    }
    const buttonEl = buttonRef.current! as HTMLButtonElement;
    setWidth(buttonEl.offsetWidth);
  }, [submitting]);

  const inner = width !== null ? '...' : children;
  const styles =
    width !== null
      ? {
          width: `${width}px`
        }
      : undefined;
  const classes = cn(
    buttonClasses[color],
    className,
    'py-2 px-4 rounded inline-block cursor-pointer font-normal',
    {
      'text-center': width !== null
    }
  );

  return (
    <button
      ref={buttonRef}
      style={styles}
      type={type}
      onClick={onClick}
      data-testid={dataTestId}
      className={classes}
      disabled={submitting || isDisabled}
    >
      {inner}
    </button>
  );
};

export default Button;
