import * as Yup from 'yup';

import { UserRole } from 'api-types';

export const createUserValidation = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  email: Yup.string()
    .email('That seems to be an invalid email address')
    .required('An email address is required')
});

export const editUserValidation = Yup.object().shape({
  name: Yup.string().required('A name is required'),
  role: Yup.mixed<any>()
    .oneOf(Object.values(UserRole))
    .required('A role is required')
});
