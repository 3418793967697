import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useQuery as useApolloQuery } from '@apollo/client';

import { settingsPaths } from 'routes.paths';

import useQueryParameters from 'hooks/useQueryParameters';

import { pluginIntegrationQuery } from 'queries/pluginIntegration';

import Title from 'components/title';
import Button from 'components/button';
import Loader from 'components/loader';
import Modal from 'components/modal';
import ErrorAlert from 'components/errorAlert';
import Tooltip from 'components/tooltip';

import Setup from './forms/setup';
import PluginsTable from './pluginsTable';

// NEEDS TO STAY IN SYNC WITH PLUGINS_SHOPIFY_APP REPO
export const ERROR_REASONS = {
  authFailure: 'shopify-auth-failure',
  sdkInstallFailure: 'shopify-sdk-install-failure',
  webHookInstallFailure: 'shopify-webhook-install-failure',
  missingAccessToken: 'missing-access-token'
};

export const useQueryParametersToastStatus = () => {
  const navigate = useNavigate();
  const query = useQueryParameters();
  useEffect(() => {
    const isSuccess = query.get('success');
    const isError = query.get('error');

    if (isSuccess === 'true') {
      toast.success(`Successfully installed Shopify.`);
    } else if (isError) {
      switch (isError) {
        case ERROR_REASONS.authFailure:
          toast.error(`Auth failed. Please uninstall and try again.`);
          break;
        case ERROR_REASONS.sdkInstallFailure:
          toast.error(
            `Failed to install Solve SDK. Please uninstall and try again.`
          );
          break;
        case ERROR_REASONS.webHookInstallFailure:
          toast.error(
            `Failed to install webhooks to Solve. Please uninstall and try again.`
          );
          break;
        case ERROR_REASONS.missingAccessToken:
          toast.error(
            `Fail to get access from Shopify. Please uninstall and try again.`
          );
          break;
        default:
          toast.error(`Something went wrong. Please uninstall and try again.`);
      }
    }
    // removes the error or success parameter from query string
    navigate(settingsPaths.solveIntegrations);
  }, []);
};
const PluginIntegration = () => {
  useQueryParametersToastStatus();

  const { loading, error, data } = useApolloQuery<{
    pluginIntegration: PluginIntegration;
  }>(pluginIntegrationQuery);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return (
      <ErrorAlert
        error={new Error('Integrations query returned an unexpected error.')}
        message='Unable to load the integrations at this time.'
      />
    );
  }
  if (!data || !data?.pluginIntegration?.enabledIntegrations) {
    return <ErrorAlert error={new Error('Could not fetch integrations')} />;
  }

  return (
    <>
      <AddIntegration />
      <PluginsTable plugins={data.pluginIntegration.enabledIntegrations} />
    </>
  );
};

const AddIntegration = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalToggle = () => setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);

  const tooltipToggle = () => setIsOpen(prevIsOpen => !prevIsOpen);
  const [modalProps, setModalProps] = useState<{
    ariaLabel: string;
    title: string;
    type: string | undefined;
  }>({
    ariaLabel: '',
    title: '',
    type: undefined
  });

  const onSelect = (type: string) => {
    tooltipToggle();
    modalToggle();

    let title: string;
    switch (type) {
      case 'SHOPIFY':
        title = 'Shopify Setup';
        break;

      default:
        throw new Error(`Unsupported Plugin Integration modal type: ${type}`);
    }

    setModalProps({ ariaLabel: title, title, type });
  };

  return (
    <div className='flex'>
      <Title size='large' className='flex-grow'>
        Integrations
      </Title>
      <Tooltip
        className='flex-none'
        position='bottom-end'
        trigger='manual'
        open={isOpen}
        onRequestClose={tooltipToggle}
        html={
          <div
            className='text-left text-sm py-2 w-72'
            data-testid='profile-dropdown'
          >
            <div
              onClick={() => onSelect('SHOPIFY')}
              data-testid='SHOPIFY'
              className='py-2 px-4 cursor-pointer hover:bg-gray-200 border-gray-200'
            >
              <h4 className='font-bold m-0 p-0'>
                <span className='mr-2'>Shopify</span>
              </h4>
              <p>Links Solve with Shopify. Can link to multiple shops.</p>
            </div>
          </div>
        }
      >
        <Button
          type='button'
          color='primary'
          className='uppercase'
          onClick={tooltipToggle}
          data-testid='create-new-integration'
        >
          New Integration
        </Button>
      </Tooltip>
      <Modal {...modalProps} isOpen={isModalOpen} onRequestClose={modalToggle}>
        <Setup onClose={modalToggle} />
      </Modal>
    </div>
  );
};
export default PluginIntegration;
