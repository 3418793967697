import { useAuthContext } from 'context/auth';

import Button from 'components/button';
import Copy from 'components/copy';
import DocsLink from 'components/docsLink';
import Label from 'components/label';
import Input from 'components/input';

interface Props {
  payload: {
    key: string;
    secret: string;
  };
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const PUBLIC_CORE_GRAPHQL_PATH = '/graphql';

const ServerAPI = ({ payload, onClose }: Props) => {
  const { baseUrls } = useAuthContext();
  return (
    <>
      <div className='px-5 pt-2 pb-5'>
        <p>Use the following credentials to connect to the API</p>

        <div className='bg-gray-100 rounded p-4 mt-2 flex justify-between items-center'>
          <div className='relative'>
            <span className='pr-6' id='apiEndpoint'>
              {baseUrls!.api}
              {PUBLIC_CORE_GRAPHQL_PATH}
            </span>
            <Copy
              className='w-4 h-4 absolute top-0 right-0 mt-0.5 text-gray-400 hover:text-gray-300'
              el='apiEndpoint'
            />
          </div>
        </div>

        <div className='bg-yellow-200 p-2 my-4'>
          <strong>Note:</strong> Take note of this password as it is the only
          time that it can be viewed or copied. You cannot view it later,
          however you can create a new API key and password at any time.
        </div>

        <div>
          <Label>api key</Label>
          <div className='relative'>
            <Input
              className='bg-gray-800 focus:outline-none py-2 px-3 w-full text-orange-400 rounded border-none'
              type='text'
              id='apiKeyId'
              readOnly
              value={payload.key}
            />
            <Copy
              className='w-4 h-4 absolute top-0 right-0 mt-2 mr-2 text-white'
              el='apiKeyId'
              showText={false}
            />
          </div>
        </div>

        <div>
          <Label className='mt-4'>password</Label>
          <div className='relative'>
            <textarea
              rows={4}
              className='bg-gray-800 focus:outline-none py-2 px-3 w-full text-orange-400 pr-8 rounded border-none'
              id='apiKeyPassword'
              readOnly
              value={payload.secret}
            />
            <Copy
              className='absolute top-0 right-0 mt-2 mr-2 text-white'
              el='apiKeyPassword'
              showText={false}
            />
          </div>
        </div>

        <p>
          Refer to the <DocsLink href='/api'>Solve API docs</DocsLink> for more
          information
        </p>
        <div className='flex flex-row-reverse'>
          <Button
            type='button'
            color='primary'
            className='px-8 mt-2 uppercase'
            onClick={onClose}
            data-testid='confirm-server-api-key'
          >
            ok
          </Button>
        </div>
      </div>
    </>
  );
};

export default ServerAPI;
