import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { usersQuery, userDeleteMutation } from 'queries/user';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import Label from 'components/label';

interface Props {
  userData: User;
  onClose: () => void;
}

const Delete = ({ userData, onClose }: Props) => {
  const { email, name, role }: User = userData;

  return (
    <Mutation mutation={userDeleteMutation}>
      {(deleteUser: any, { loading, error }: MutationResult) => {
        if (error) {
          return <ErrorAlert error={error} />;
        }

        return (
          <form
            data-testid='user-delete'
            onSubmit={async (e: React.BaseSyntheticEvent) => {
              e.preventDefault();
              await deleteUser({
                variables: {
                  email
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: usersQuery }]
              });
              onClose();
            }}
          >
            <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
              <div className='flex flex-col gap-2'>
                <Label>user name</Label>
                <p data-testid='user-delete-name'>{name}</p>
              </div>
              <div className='flex flex-col gap-2'>
                <Label>role</Label>
                <p data-testid='user-delete-role'>{role}</p>
              </div>
              <div className='flex flex-row-reverse mt-2'>
                <Button
                  type='submit'
                  color='danger'
                  className='px-8 ml-4 uppercase'
                  data-testid='delete-user'
                  submitting={loading}
                >
                  Delete
                </Button>
                <Button
                  type='button'
                  color='secondary'
                  className='px-8 uppercase'
                  data-testid='cancel-delete-user'
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Mutation>
  );
};

export default Delete;
