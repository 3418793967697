import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';
import { Form, Formik } from 'formik';

import { apiKeysQuery, apiKeysQueryUpdate } from 'queries/apiKey';

import { createApiKeyValidation } from 'validation/apiKey';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import Label from 'components/label';
import FormTextArea from 'components/formComponents/formTextArea';
import FormTextField from 'components/formComponents/formTextField';

import WebSdkCode from './webSdkCode';

interface Props {
  apiKeyData: ApiKey;
  onClose: () => void;
}

const Edit = ({ apiKeyData, onClose }: Props) => {
  return (
    <Mutation mutation={apiKeysQueryUpdate}>
      {(updateApiKey: any, { loading, error }: MutationResult) => {
        if (error) {
          return <ErrorAlert error={error} />;
        }
        return (
          <div className='w-full flex-grow overflow-y-auto c-modal__body--inner'>
            <Formik
              initialValues={{
                name: apiKeyData.name,
                notes: apiKeyData.notes ?? ''
              }}
              validationSchema={createApiKeyValidation}
              onSubmit={async (values: any) => {
                await updateApiKey({
                  variables: {
                    id: apiKeyData.id,
                    input: {
                      name: values.name,
                      notes: values.notes
                    }
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [{ query: apiKeysQuery }]
                });
                onClose();
              }}
            >
              <Form data-testid='api-key-edit'>
                <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
                  <div>
                    <FormTextField
                      type='text'
                      name='name'
                      placeholder='Give this integration a name'
                      label='integration name'
                      data-testid='api-key-edit-name'
                    />
                  </div>
                  <div>
                    <FormTextArea
                      name='notes'
                      placeholder='Enter a description of what this integration does'
                      label='description'
                      data-testid='api-key-edit-notes'
                    />
                  </div>
                  {apiKeyData.type === 'PUBLIC' && (
                    <div>
                      <Label>initialization code</Label>
                      <WebSdkCode data={apiKeyData} />
                    </div>
                  )}
                  <div className='flex flex-row-reverse mt-2'>
                    <Button
                      type='submit'
                      color='primary'
                      className='px-8 uppercase'
                      submitting={loading}
                      data-testid='edit-api-key'
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        );
      }}
    </Mutation>
  );
};

export default Edit;
