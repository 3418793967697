import { toast } from 'react-hot-toast';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import logger from 'utils/logger';

import { userResetPasswordMutation, usersQuery } from 'queries/user';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import Label from 'components/label';

interface Props {
  userData: User;
  onClose: () => void;
}

const Reset = ({ userData, onClose }: Props) => {
  const { email, name, role }: User = userData;

  return (
    <Mutation mutation={userResetPasswordMutation}>
      {(resetPassword: any, { loading, error }: MutationResult) => {
        if (error) {
          return <ErrorAlert error={error} />;
        }

        return (
          <form
            data-testid='user-reset'
            onSubmit={async (e: React.BaseSyntheticEvent) => {
              e.preventDefault();
              try {
                await resetPassword({
                  variables: {
                    email
                  },
                  awaitRefetchQueries: true,
                  refetchQueries: [{ query: usersQuery }]
                });
                onClose();
                toast.success(`Password Reset Successfully`);
              } catch (err) {
                toast.error(`Password Reset Error.`);
                logger.error('Error resetting user password', err);
              }
            }}
          >
            <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
              <div className='flex flex-col gap-2'>
                <Label>user name</Label>
                <p data-testid='user-reset-name'>{name}</p>
              </div>
              <div className='flex flex-col gap-2'>
                <Label>role</Label>
                <p data-testid='user-reset-role'>{role}</p>
              </div>
              <div className='flex flex-row-reverse mt-2'>
                <Button
                  type='submit'
                  color='danger'
                  className='px-8 ml-4 uppercase'
                  data-testid='reset-user-password'
                  submitting={loading}
                >
                  Reset Password
                </Button>
                <Button
                  type='button'
                  color='secondary'
                  className='px-8 uppercase'
                  data-testid='cancel-reset-user-password'
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        );
      }}
    </Mutation>
  );
};
export default Reset;
