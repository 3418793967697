import { ReactNode } from 'react';
import cn from 'classnames';
import sample from 'lodash/sample';

const ROW_COUNT = 15;

const Header = () => (
  <>
    {/* Integration Name */}
    <td className='p-2 w-76'>
      <div className='loader-box rounded w-[120px] h-4'></div>
    </td>
    {/* Type */}
    <td className='p-2 w-[120px]'>
      <div className='loader-box rounded w-12 h-4'></div>
    </td>
    {/* Access Key */}
    <td className='p-2 w-116'>
      <div className='loader-box rounded w-24 h-4'></div>
    </td>
    {/* Access Key Age */}
    <td className='p-2'>
      <div className='loader-box rounded w-[100px] h-4'></div>
    </td>
  </>
);

const Row = () => {
  const firstRowWidth = sample(['w-36', 'w-24']);
  const secondRowWidth = sample(['w-20', 'w-12']);

  return (
    <>
      {/* Integration Name */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className={cn('loader-box rounded h-4', firstRowWidth)}></div>
      </td>
      {/* Type */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className={cn('loader-box rounded h-4', secondRowWidth)}></div>
      </td>
      {/* Access Key */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className='loader-box rounded w-[200px] h-4'></div>
      </td>
      {/* Access Key Age */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className='loader-box rounded w-14 h-4'></div>
      </td>
    </>
  );
};

interface TableProps {
  headerComponent: ReactNode;
  rowComponent: ReactNode;
  rowCount?: number;
}

export const Table = ({
  headerComponent,
  rowComponent,
  rowCount = ROW_COUNT
}: TableProps) => (
  <table className='w-full'>
    <thead>
      <tr className='border-b border-gray-200'>{headerComponent}</tr>
    </thead>
    <tbody>
      {[...Array(rowCount)].map((_x, i: number) => (
        <tr className='border-b border-gray-200' key={i}>
          {rowComponent}
        </tr>
      ))}
    </tbody>
  </table>
);

const Skeleton = () => (
  <div data-testid='skeleton-loader'>
    <div className='relative mt-7 pt-px'>
      <Table headerComponent={<Header />} rowComponent={<Row />} />

      <div className='absolute bg-gradient-to-t from-white top-0 w-full h-full z-50'></div>
    </div>
  </div>
);

export default Skeleton;
