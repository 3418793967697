import { ReactNode } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { pluralize } from 'utils/pluralize';
import { formatDateToTz, timeDiff } from 'utils/date';

import { useAuthContext } from 'context/auth';

import Tooltip, { TooltipPosition } from 'components/tooltip';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface DataProps {
  date: Date | string;
  id: string | number;
}

interface Props {
  data: DataProps;
  isTimeDiff?: boolean; // represent time as N days if true
  className?: string;
  position?: TooltipPosition;
  interactive?: boolean;
  format?: string;
  alwaysEnableTooltip?: boolean;
  'data-testid'?: string;
}

const DateCell = ({
  data,
  isTimeDiff = false,
  className,
  position = 'top-end',
  interactive = true,
  format,
  alwaysEnableTooltip = false,
  'data-testid': dataTestId
}: Props) => {
  const { config } = useAuthContext();
  const { id, date } = data;

  const timezone = config?.solve.timezone;

  // time formatted accordingly tz from config
  // for example: show datetime with America/New_York tz
  const datetime = date ? formatDateToTz(date, format, timezone) : '';

  // get tz where user is located
  // for example: Pacific/Auckland
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // time formatted accordingly tz from user location
  // for example: show datetime with Pacific/Auckland tz
  const localDateTime = date
    ? dayjs.utc(date).tz(localTz).format('Do MMMM YYYY, hh:mm A')
    : '';

  // show N days instead of datetime
  const diff: number = timeDiff(date, 'days');

  // compare local time and config time
  const isSameTz: boolean = timezone === localTz;

  const TooltipItem = ({
    children,
    html
  }: {
    children: ReactNode;
    html?: ReactNode;
  }) => (
    <Tooltip
      position={position}
      trigger='mouseenter'
      interactive={interactive}
      theme='solve'
      className='inline'
      html={
        <div className='py-2 px-3 text-left text-xs'>
          {html}
          {(!isSameTz || alwaysEnableTooltip) && (
            <>
              <span data-testid={`local-time-${id}`}>{localDateTime}</span>
              <span className='font-bold pl-1'>{localTz}</span>
            </>
          )}
        </div>
      }
    >
      <>{children}</>
    </Tooltip>
  );

  return (
    <span className='relative' data-testid={id}>
      {/* Example: settings page renders an access key age with a tooltip that
      contains time when a key has been created + time if local timezone is
      different to config timezone */}
      {isTimeDiff && (
        <TooltipItem
          html={
            <div data-testid={`is-time-created-${id}`}>
              {dayjs(date).format('Do MMMM YYYY, hh:mm A')}
            </div>
          }
        >
          <span
            className={
              (cn(`date-tooltip ${id} relative cursor-pointer inline-block`),
              className)
            }
            data-testid={`time-diff-days-${id}`}
          >
            {diff} {pluralize(diff, 'Day')}
          </span>
        </TooltipItem>
      )}

      {/* Example: profiles page with first seen date which renders a tooltip
      because local timezone is different to config timezone. */}
      {!isTimeDiff && (!isSameTz || alwaysEnableTooltip) && (
        <TooltipItem>
          <span
            data-testid={dataTestId}
            className={cn('whitespace-nowrap', className)}
          >
            {datetime}
          </span>
        </TooltipItem>
      )}
      {/* Example: profiles page with first seen date which doesn't render a tooltip
      because local timezone and config timezone are the same. */}
      {!isTimeDiff && isSameTz && !alwaysEnableTooltip && (
        <span className={cn('whitespace-nowrap', className)}>{datetime}</span>
      )}
    </span>
  );
};

export default DateCell;
