import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  'data-testid'?: string;
  className?: string;
  children: ReactNode;
  htmlFor?: string;
}

const Label = ({
  className,
  children,
  'data-testid': dataTestId,
  htmlFor
}: Props) => (
  <label
    data-testid={dataTestId}
    className={cn(
      className,
      'block text-gray-500 uppercase tracking-wide font-semibold text-xs'
    )}
    htmlFor={htmlFor}
  >
    {children}
  </label>
);

export default Label;
