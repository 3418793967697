import { humanize } from 'utils/dataFormat';

import { useAuthContext } from 'context/auth';

import DateCell from 'components/dateCell';

import RowContextMenu from './rowContextMenu';

interface Props {
  users: UsersList;
}

const UsersTable = ({ users }: Props) => {
  const auth = useAuthContext();

  if (users.nodes.length === 0) {
    return (
      <div>No users found. Click the button to the right to create one.</div>
    );
  }

  const columnNames: string[] = ['Name', 'Email', 'Role', 'Status', 'Created'];

  return (
    <table className='mt-6 w-full' data-testid='users-table'>
      <thead>
        <tr>
          {columnNames.map((name: string, idx: number) => {
            return (
              <td
                key={idx}
                className='whitespace-nowrap p-2 font-bold border-b'
              >
                {name}
              </td>
            );
          })}
          <td className='border-b' />
        </tr>
      </thead>
      <tbody>
        {users.nodes &&
          users.nodes.map((user: User) => {
            return (
              <tr
                key={user.email}
                className='user-item'
                data-testid={`user-${user.name}`}
              >
                <td className='p-2 border-b w-30'>{user.name}</td>
                <td className='p-2 border-b w-30'>{user.email}</td>
                <td className='p-2 border-b w-20'>{user.role}</td>
                <td className='p-2 border-b w-30'>{humanize(user.status)}</td>
                <td className='border-b w-20'>
                  <DateCell
                    data={{ id: user.id, date: user.created_at }}
                    isTimeDiff
                    className='p-2'
                    interactive={false}
                  />
                </td>
                <td className='pl-2 border-b'>
                  {user.email !== auth.user?.authUser?.attributes?.email && (
                    <RowContextMenu user={user} />
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default UsersTable;
