export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ConfigurationValue: any;
  CurrencyCode: string;
  Cursor: string;
  Date: string;
  DateTime: string;
  Email: string;
  Json: any;
  JsonStringPairsMap: any;
  Money: string;
  NonNegativeFloat: number;
  NonNegativeInt: number;
  PhoneNumber: string;
  StringOrInteger: string | number;
  UUID: string;
};

export type AbandonCartOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** An object representing an association between a click ID and a campaign (via campaign name + ad source). This data typically comes from an external advertising provider like google. */
export type AdSourceAssociation = {
  /** The source of the `ad_source_id`, e.g. if google click IDs are used this would be `gclid`. */
  ad_source: Scalars['String'];
  /** A list of IDs from interactions with online ads. For example, each click on a google ad has a gclid (google click ID) associated with it. */
  ad_source_ids: Array<Scalars['String']>;
  /** The campaign_segment id. This is generated and returned via the `create_campaign` mutation and is used for primary key reference to the campaign_segment in other queries and mutations. */
  campaign_segment_id: Scalars['Int'];
};

export type Address = {
  __typename?: 'Address';
  /**
   * Any arbitrary information that you would like to be stored against a address.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  address_attributes?: Maybe<Scalars['Json']>;
  /** The town or city for the address. */
  city?: Maybe<Scalars['String']>;
  /** The short country code. */
  country_code?: Maybe<Scalars['String']>;
  /** The full name of the country that this address is located in. */
  country_name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** The postal or ZIP code for the address, if applicable. */
  postal_code?: Maybe<Scalars['String']>;
  /** The province that the address is located in, if applicable. */
  province?: Maybe<Scalars['String']>;
  /** The code of the province for the address, if applicable. */
  province_code?: Maybe<Scalars['String']>;
  /** Primary street name and number. */
  street?: Maybe<Scalars['String']>;
  /** Secondary street information. Suburb/unit number etc. */
  street2?: Maybe<Scalars['String']>;
  /** The type of the address, generally `BILLING` or `SHIPPING`. Enforced to always be uppercase. */
  type: Scalars['String'];
};

/** The return object for multiple address. The `nodes` property contains all the address themselves, while the `page_info` property contains all information used for pagination. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of addresses. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Address>;
  /** Not yet implemented. This will contain all the paginiation info that you will need for reference when navigating through a large list of addresses. */
  page_info: BlankPageInfo;
};

/** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
export type Adjustment = {
  __typename?: 'Adjustment';
  /** The monetary value of this adjustment. Can be positive or negative. */
  amount?: Maybe<Scalars['Money']>;
  /** The monetary value of this adjustment. Can be positive or negative. Represented in minor units. */
  amount_minor?: Maybe<Scalars['Money']>;
  /** A description of what this adjustment was. E.g., discount. */
  description?: Maybe<Scalars['String']>;
};

/** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
export type AdjustmentInput = {
  /** The monetary value of this adjustment. Can be positive or negative. */
  amount: Scalars['Money'];
  /** A description of what this adjustment was. E.g., discount. */
  description: Scalars['String'];
};

export type AffiliateRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'AffiliateRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

/** What age group the product is designed for. */
export enum AgeGroup {
  /** Typically teens or older. All sizes within this age group have been manufactured to fit an adult or teen. */
  Adult = 'ADULT',
  /** 3–12 months old. Infant sizes are often identified by the age range in months (3–12). */
  Infant = 'INFANT',
  /** 5–13 years old. All sizes within this age group have been manufactured to fit a child in that age range. */
  Kids = 'KIDS',
  /** Up to 3 months old. Newborn sizes are often identified by the age range in months (0–3) or just 'newborn'. */
  Newborn = 'NEWBORN',
  /** 1–5 years old. Toddler sizes are often identified by the age range in months (12–24) or years (1–5). */
  Toddler = 'TODDLER'
}

/** A single API key record. It is created using the `create_api_key` mutation. */
export type ApiKey = {
  __typename?: 'ApiKey';
  /** The API key id. This is generated and returned via the `create_api_key` mutation and is used for primary key reference to the API key in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this API key record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Whether the API key record has been set to a `disabled` state. Defaults to `false` on API key creation. */
  is_disabled?: Maybe<Scalars['Boolean']>;
  /** The public key string for this API Key. This can be used for `PUBLIC` API keys without leaking sensitive information. */
  key: Scalars['String'];
  /** A name for this API Key. It does not have to be unique, but uniqueness is recommended. */
  name: Scalars['String'];
  /** Optional free form notes related to the API Key. */
  notes?: Maybe<Scalars['String']>;
  /** The API Policy that is applied to requests using this API Key. */
  policy: ApiPolicy;
  /** Whether this API Key is `PUBLIC` or `PROTECTED`. */
  type: ApiKeyType;
  /** The datetime that this API key record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple API keys. The `nodes` property contains all the API keys themselves, while the `page_info` property contains all information used for pagination. */
export type ApiKeyConnection = {
  __typename?: 'ApiKeyConnection';
  /** A list of API key. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<ApiKey>;
  /** All the paginiation info that you will need for reference when navigating through a large list of API key. */
  page_info: PageInfo;
};

/** The input type necessary for creating a API Key. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ApiKeyCreateInput = {
  /** A name for this API Key. It does not have to be unique, but uniqueness is recommended. */
  name: Scalars['String'];
  /** Optional free form notes related to the API Key. */
  notes?: InputMaybe<Scalars['String']>;
  /** The ID of the API Policy that is used by this API Key. Use the `api_policies` query to list all policies, or the `create_api_policy` mutation to create a new one. */
  policy_id: Scalars['ID'];
  /** Whether this API Key is `PUBLIC` or `PROTECTED`. */
  type: ApiKeyType;
};

/** There are two valid types of an API key, either `PUBLIC` or `PRIVATE`. */
export enum ApiKeyType {
  /** A `PROTECTED` API Key can both submit events and retrieve private information from the GraphQL API. */
  Protected = 'PROTECTED',
  /** A `PUBLIC` API Key can only submit events. It cannot retrieve any other information out of Solve. */
  Public = 'PUBLIC'
}

/** The input type necessary for updating an existing API Key. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ApiKeyUpdateInput = {
  /** Whether the API key record has been set to a `disabled` state. Defaults to `false` on API key creation. */
  is_disabled?: InputMaybe<Scalars['Boolean']>;
  /** A name for this API Key. It does not have to be unique, but uniqueness is recommended. */
  name?: InputMaybe<Scalars['String']>;
  /** Optional free form notes related to the API Key. */
  notes?: InputMaybe<Scalars['String']>;
  /** The ID of the API Policy that is used by this API Key. Use the `api_policies` query to list all policies, or the `create_api_policy` mutation to create a new one. */
  policy_id?: InputMaybe<Scalars['ID']>;
};

/** A single API Policy record. It is created using the `create_api_policy` mutation. */
export type ApiPolicy = {
  __typename?: 'ApiPolicy';
  /** The API Keys that are associated with this policy. */
  api_keys: ApiPolicyApiKeysConnection;
  /** A JSON encoded API Policy. More information about the policies can be found in the Solve Documentation */
  document: Scalars['String'];
  /** The API Policy id. This is generated and returned via the `create_api_policy` mutation and is used for primary key reference to the API Policy in other queries and mutations. */
  id: Scalars['ID'];
  /** Is this a Solve managed policy. Solve managed policies cannot be edited. */
  is_solve_managed: Scalars['Boolean'];
  /** The name of the policy. This must be unique. */
  name: Scalars['String'];
};

/** The return object for multiple API Keys. The `nodes` property contains all the API Keys themselves, while the `page_info` property contains all information used for pagination. */
export type ApiPolicyApiKeysConnection = {
  __typename?: 'ApiPolicyApiKeysConnection';
  /** A list of API Keys. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<ApiKey>;
  /** Not yet implemented. This will contain all the paginiation info that you will need for reference when navigating through a large list of API Keys. */
  page_info: BlankPageInfo;
};

/** The return object for multiple API Policies. The `nodes` property contains all the API Policies themselves, while the `page_info` property contains all information used for pagination. */
export type ApiPolicyConnection = {
  __typename?: 'ApiPolicyConnection';
  /** A list of API Policies. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<ApiPolicy>;
  /** All the paginiation info that you will need for reference when navigating through a large list of API Policies. */
  page_info: PageInfo;
};

/** The input type necessary for creating a API Policy. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ApiPolicyCreateInput = {
  /** A JSON encoded API Policy. More information about the policies can be found in the Solve Documentation */
  document: Scalars['String'];
  /** The name of the policy. This must be unique. */
  name: Scalars['String'];
};

/** The input type necessary for updating an existing API Policy. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ApiPolicyUpdateInput = {
  /** A JSON encoded API Policy. More information about the policies can be found in the Solve Documentation */
  document?: InputMaybe<Scalars['String']>;
  /** The name of the policy. This must be unique. */
  name?: InputMaybe<Scalars['String']>;
};

/** A single audience record. It is created using the `audiences` mutation. */
export type Audience = {
  __typename?: 'Audience';
  /** The datetime that this audience record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** Audience ID. An audience id is trimmed, downcased and slugified. On input, comma-separated strings will be treated as an array of audiences. Audience ids will be trimmed, downcased and slugified if not already. */
  id: Scalars['String'];
  /** The datetime that this audience record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Profiles associated with the audience. */
  profiles: ProfileConnection;
  /** The datetime that this audience record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple audiences. The `nodes` property contains all the audiences themselves, while the `page_info` property contains all information used for pagination. */
export type AudienceConnection = {
  __typename?: 'AudienceConnection';
  nodes: Array<Maybe<Audience>>;
};

/** Audiences add mutation input */
export type AudiencesAddMutationInput = {
  /** Audience IDs. An audience id is trimmed, downcased and slugified. On input, comma-separated strings will be treated as an array of audiences. Audience ids will be trimmed, downcased and slugified if not already. */
  audience_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Profile IDs used as input. */
  profile_ids?: InputMaybe<Array<Scalars['UUID']>>;
};

/** Audience mutation results. */
export type AudiencesMutationConnection = {
  __typename?: 'AudiencesMutationConnection';
  /** Audience ids involved in the mutation. */
  audience_ids: Array<Scalars['String']>;
  /** The audiences involved in the mutation. */
  audiences: AudienceConnection;
  /** The profile ids involved in the mutation. */
  profile_ids: Array<Maybe<Scalars['UUID']>>;
  /** The profiles involved in the mutation. */
  profiles: ProfileConnection;
};

/** Audiences query results. */
export type AudiencesQueryConnection = {
  __typename?: 'AudiencesQueryConnection';
  /** The audiences matching the query parameters. */
  audiences: AudienceConnection;
  /** The profiles matching the query parameters. */
  profiles: ProfileConnection;
};

/** Audiences query input */
export type AudiencesQueryInput = {
  /** Audience IDs. An audience id is trimmed, downcased and slugified. On input, comma-separated strings will be treated as an array of audiences. Audience ids will be trimmed, downcased and slugified if not already. */
  audience_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Profile IDs used as input. */
  profile_ids?: InputMaybe<Array<Scalars['UUID']>>;
};

/** Audiences remove mutation input */
export type AudiencesRemoveMutationInput = {
  /** Audience IDs. An audience id is trimmed, downcased and slugified. On input, comma-separated strings will be treated as an array of audiences. Audience ids will be trimmed, downcased and slugified if not already. */
  audience_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Profile IDs used as input. */
  profile_ids?: InputMaybe<Array<Scalars['UUID']>>;
};

/** List of possible plugin integrations */
export type AvailablePluginIntegrations = {
  __typename?: 'AvailablePluginIntegrations';
  name: PluginName;
};

export type BlankPageInfo = {
  __typename?: 'BlankPageInfo';
  end_cursor?: Maybe<Scalars['Cursor']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  /** The ids of the campaign segments that have the same name as this campaign */
  campaign_segment_ids: Array<Maybe<Scalars['Int']>>;
  /** The campaign segment records to for the campaign */
  campaign_segments?: Maybe<CampaignSegmentsConnection>;
  /** The name of the campaign segment(s) that resulted in this campaign record being generated */
  name: Scalars['String'];
};

/** In beta - this type is expected to change and is not recommended for production use. */
export type CampaignReport = {
  __typename?: 'CampaignReport';
  account?: Maybe<Scalars['String']>;
  ad_click_count: Scalars['Int'];
  campaign_segment_id?: Maybe<Scalars['Int']>;
  campaign_segment_spend: Scalars['Json'];
  cart_created_count: Scalars['Int'];
  cart_reached_checkout_count: Scalars['Int'];
  content?: Maybe<Scalars['String']>;
  day?: Maybe<Scalars['DateTime']>;
  first_order_created_count: Scalars['Int'];
  first_order_total_sales: Scalars['Json'];
  impression_count: Scalars['Int'];
  medium?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  order_created_count: Scalars['Int'];
  order_total_sales: Scalars['Json'];
  pageview_count: Scalars['Int'];
  placement?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_ad_group_id?: Maybe<Scalars['String']>;
  provider_ad_group_name?: Maybe<Scalars['String']>;
  provider_ad_id?: Maybe<Scalars['String']>;
  provider_ad_name?: Maybe<Scalars['String']>;
  provider_campaign_id?: Maybe<Scalars['String']>;
  roas?: Maybe<Scalars['Float']>;
  session_count: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  term?: Maybe<Scalars['String']>;
  type: CampaignReportType;
};

export type CampaignReportColumn = {
  /** The campaign report column id that is used to return the same columns that we show in UI. */
  accessor: Scalars['String'];
  /** Optional campaign report column header, used for csv only */
  csv_report_header?: InputMaybe<Scalars['String']>;
  /** The campaign report column user-friendly title. */
  header: Scalars['String'];
};

export type CampaignReportDownloadLink = {
  __typename?: 'CampaignReportDownloadLink';
  /** A link to the file that contains campaign report data */
  file_url: Scalars['String'];
};

/** campaign report download input */
export type CampaignReportInput = {
  /** Columns to show in the campaign report. */
  columns: Array<CampaignReportColumn>;
  /** If set, will only return campaign report data from the given time (inclusive). */
  from_time: Scalars['DateTime'];
  /** The grouping that can be applied when retrieving campaign report data. */
  group_by?: InputMaybe<Array<CampaignReportsGroupBy>>;
  /** The sort order that can be applied when retrieving campaign report. */
  order_by?: InputMaybe<CampaignReportsOrderBy>;
  /** Filter campaign report data with name or/and provider. */
  search_term?: InputMaybe<Scalars['String']>;
  /** If set, will only return campaign report data from the given time (inclusive). */
  to_time: Scalars['DateTime'];
  type: CampaignReportType;
};

/** The type of the campaign report. Defines the attribution method for the Campaigns */
export enum CampaignReportType {
  SessionAttribution = 'SESSION_ATTRIBUTION',
  SevenDayAttribution = 'SEVEN_DAY_ATTRIBUTION'
}

export type CampaignReportsConnection = {
  __typename?: 'CampaignReportsConnection';
  nodes: Array<CampaignReport>;
  page_info: PageInfo;
};

/** The grouping that can be applied when retrieving campaign reports. */
export enum CampaignReportsGroupBy {
  Account = 'ACCOUNT',
  Content = 'CONTENT',
  Day = 'DAY',
  Medium = 'MEDIUM',
  Name = 'NAME',
  Placement = 'PLACEMENT',
  Provider = 'PROVIDER',
  ProviderAdGroupId = 'PROVIDER_AD_GROUP_ID',
  ProviderAdGroupName = 'PROVIDER_AD_GROUP_NAME',
  ProviderAdId = 'PROVIDER_AD_ID',
  ProviderAdName = 'PROVIDER_AD_NAME',
  ProviderCampaignId = 'PROVIDER_CAMPAIGN_ID',
  Source = 'SOURCE',
  Term = 'TERM'
}

/** The sort order that can be applied when retrieving campaign reports. */
export enum CampaignReportsOrderBy {
  AccountAsc = 'ACCOUNT_ASC',
  AccountDesc = 'ACCOUNT_DESC',
  AdClickCountAsc = 'AD_CLICK_COUNT_ASC',
  AdClickCountDesc = 'AD_CLICK_COUNT_DESC',
  CampaignSegmentSpendSumAsc = 'CAMPAIGN_SEGMENT_SPEND_SUM_ASC',
  CampaignSegmentSpendSumDesc = 'CAMPAIGN_SEGMENT_SPEND_SUM_DESC',
  CartCreatedCountAsc = 'CART_CREATED_COUNT_ASC',
  CartCreatedCountDesc = 'CART_CREATED_COUNT_DESC',
  CartReachedCheckoutCountAsc = 'CART_REACHED_CHECKOUT_COUNT_ASC',
  CartReachedCheckoutCountDesc = 'CART_REACHED_CHECKOUT_COUNT_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  DayAsc = 'DAY_ASC',
  DayDesc = 'DAY_DESC',
  FirstOrderCreatedCountAsc = 'FIRST_ORDER_CREATED_COUNT_ASC',
  FirstOrderCreatedCountDesc = 'FIRST_ORDER_CREATED_COUNT_DESC',
  FirstOrderTotalSalesSumAsc = 'FIRST_ORDER_TOTAL_SALES_SUM_ASC',
  FirstOrderTotalSalesSumDesc = 'FIRST_ORDER_TOTAL_SALES_SUM_DESC',
  ImpressionCountAsc = 'IMPRESSION_COUNT_ASC',
  ImpressionCountDesc = 'IMPRESSION_COUNT_DESC',
  MediumAsc = 'MEDIUM_ASC',
  MediumDesc = 'MEDIUM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  OrderCreatedCountAsc = 'ORDER_CREATED_COUNT_ASC',
  OrderCreatedCountDesc = 'ORDER_CREATED_COUNT_DESC',
  OrderTotalSalesSumAsc = 'ORDER_TOTAL_SALES_SUM_ASC',
  OrderTotalSalesSumDesc = 'ORDER_TOTAL_SALES_SUM_DESC',
  PageviewCountAsc = 'PAGEVIEW_COUNT_ASC',
  PageviewCountDesc = 'PAGEVIEW_COUNT_DESC',
  PlacementAsc = 'PLACEMENT_ASC',
  PlacementDesc = 'PLACEMENT_DESC',
  ProviderAdGroupIdAsc = 'PROVIDER_AD_GROUP_ID_ASC',
  ProviderAdGroupIdDesc = 'PROVIDER_AD_GROUP_ID_DESC',
  ProviderAdGroupNameAsc = 'PROVIDER_AD_GROUP_NAME_ASC',
  ProviderAdGroupNameDesc = 'PROVIDER_AD_GROUP_NAME_DESC',
  ProviderAdIdAsc = 'PROVIDER_AD_ID_ASC',
  ProviderAdIdDesc = 'PROVIDER_AD_ID_DESC',
  ProviderAdNameAsc = 'PROVIDER_AD_NAME_ASC',
  ProviderAdNameDesc = 'PROVIDER_AD_NAME_DESC',
  ProviderAsc = 'PROVIDER_ASC',
  ProviderCampaignIdAsc = 'PROVIDER_CAMPAIGN_ID_ASC',
  ProviderCampaignIdDesc = 'PROVIDER_CAMPAIGN_ID_DESC',
  ProviderDesc = 'PROVIDER_DESC',
  RoasAsc = 'ROAS_ASC',
  RoasDesc = 'ROAS_DESC',
  SessionCountAsc = 'SESSION_COUNT_ASC',
  SessionCountDesc = 'SESSION_COUNT_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TermAsc = 'TERM_ASC',
  TermDesc = 'TERM_DESC'
}

/** A single campaign segment record. It is created using the `create_campaign_segment` mutation. */
export type CampaignSegment = {
  __typename?: 'CampaignSegment';
  /** If this campaign segment came from a third party source, this can be set to group by an account within that third party source. */
  account?: Maybe<Scalars['String']>;
  /** The campaign segment metric records to for the campaign segment */
  campaign_segment_metrics?: Maybe<CampaignSegmentMetricsConnection>;
  /**
   * If there are multiple versions of an ad or differently placed ads in an email this field can be used to differentiate between campaign segments for each of them.
   * Examples: 'top-right', 'blue', 'red', 'colorful' or 'banner250'. Defaults to "other".
   */
  content?: Maybe<Scalars['String']>;
  /** A description to help identify the purpose of the campaign segment. */
  description?: Maybe<Scalars['String']>;
  /** The campaign segment id. This is generated and returned via the `create_campaign_segment` mutation and is used for primary key reference to the campaign segment in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this campaign segment record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** A one word descriptor of the campaign segment's medium. For example: 'email', 'social', 'blog-post' or 'organic'. Defaults to "other". */
  medium?: Maybe<Scalars['String']>;
  /** The human readable name of the campaign segment. */
  name: Scalars['String'];
  /** Where on the page the ad was placed. Defaults to "other". */
  placement?: Maybe<Scalars['String']>;
  /** Where this segment comes from. Example - `google` or `utm`. Defaults to "other". */
  provider?: Maybe<Scalars['String']>;
  /** The id of the ad group this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_group_id?: Maybe<Scalars['String']>;
  /** The name/label of the ad group this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_group_name?: Maybe<Scalars['String']>;
  /** The id of the ad this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_id?: Maybe<Scalars['String']>;
  /** The name/label of the ad this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_name?: Maybe<Scalars['String']>;
  /** The id of the campaign this segment is for as given by the ad provider. Defaults to "other". */
  provider_campaign_id?: Maybe<Scalars['String']>;
  /** The source key for this segment's provider. For example, for a provider of UTM, this will be the utm_source value (if present). */
  source?: Maybe<Scalars['String']>;
  /** This field is used if this campaign segment relates to any paid keywords. Defaults to "other". */
  term?: Maybe<Scalars['String']>;
  /** The datetime that this campaign segment record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** A single campaign segment record. It is created using the `create_campaign_segment` mutation. */
export type CampaignSegmentCampaign_Segment_MetricsArgs = {
  from_time?: InputMaybe<Scalars['DateTime']>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

/** The return object for multiple campaign segments. The `nodes` property contains all the campaign segments themselves, while the `page_info` property contains all information used for pagination. */
export type CampaignSegmentConnection = {
  __typename?: 'CampaignSegmentConnection';
  /** A list of campaign segments. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<CampaignSegment>;
};

/** The input type necessary for creating a campaign segment. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CampaignSegmentCreateInput = {
  /** If this campaign segment came from a third party source, this can be set to group by an account within that third party source. */
  account?: InputMaybe<Scalars['String']>;
  /**
   * If there are multiple versions of an ad or differently placed ads in an email this field can be used to differentiate between campaign segments for each of them.
   * Examples: 'top-right', 'blue', 'red', 'colorful' or 'banner250'. Defaults to "other".
   */
  content?: InputMaybe<Scalars['String']>;
  /** A description to help identify the purpose of the campaign segment. */
  description?: InputMaybe<Scalars['String']>;
  /** A one word descriptor of the campaign segment's medium. For example: 'email', 'social', 'blog-post' or 'organic'. Defaults to "other". */
  medium?: InputMaybe<Scalars['String']>;
  /** The human readable name of the campaign segment. Does not have to be unique. */
  name: Scalars['String'];
  /** Where on the page the ad was placed. Defaults to "other". */
  placement?: InputMaybe<Scalars['String']>;
  /** Where this segment comes from. Example - `google` or `utm`. Defaults to "other". If this is set you will also need `source` to be set. */
  provider?: InputMaybe<Scalars['String']>;
  /** The id of the ad group this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_group_id?: InputMaybe<Scalars['String']>;
  /** The name/label of the ad group this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_group_name?: InputMaybe<Scalars['String']>;
  /** The id of the ad this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_id?: InputMaybe<Scalars['String']>;
  /** The name/label of the ad this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_name?: InputMaybe<Scalars['String']>;
  /** The id of the campaign this segment is for as given by the ad provider. Defaults to "other". */
  provider_campaign_id?: InputMaybe<Scalars['String']>;
  /** The source key for this segment's provider. For example, for a provider of UTM, this will be the utm_source value (if present). */
  source?: InputMaybe<Scalars['String']>;
  /** This field is used if this campaign segment relates to any paid keywords. Defaults to "other". */
  term?: InputMaybe<Scalars['String']>;
};

/** A single campaign segment metric record. It is created using the `create_campaign_segment_metric` mutation. */
export type CampaignSegmentMetric = {
  __typename?: 'CampaignSegmentMetric';
  /** The spend in major units of the campaign segment on the provided date. */
  amount: Scalars['Money'];
  /** The spend in minor units of the campaign segment on the provided date. */
  amount_minor: Scalars['Int'];
  /** The campaign record to which this campaign segment metric belongs to. */
  campaign_segment?: Maybe<CampaignSegment>;
  /** The primary key of the campaign segment this metrics is for. */
  campaign_segment_id: Scalars['Int'];
  /** A valid currency code following ISO 4217. */
  currency: Currency;
  /** What date the campaign segment metric is calculated for. */
  date: Scalars['DateTime'];
  /** The campaign segment metric id. This is generated and returned via the `create_campaign_segment_metric` mutation and is used for primary key reference to the campaign segment metric in other queries and mutations. */
  id: Scalars['Int'];
  /** The number of impressions for this campaign segment on the provided date. */
  impression_count: Scalars['Int'];
  /** The datetime that this campaign segment metric record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** The datetime that this campaign segment metric record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The input type necessary for creating a campaign segment metric. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CampaignSegmentMetricCreateInput = {
  /** The spend in major units of the campaign segment on the provided date. */
  amount: Scalars['Money'];
  /** The primary key of the campaign segment this metrics is for. */
  campaign_segment_id: Scalars['Int'];
  /** A valid currency code following ISO 4217. */
  currency: Currency;
  /** What date the campaign segment metric is calculated for. */
  date: Scalars['DateTime'];
  /** The number of impressions for this campaign segment on the provided date. */
  impression_count: Scalars['Int'];
};

/** The input type necessary for updating an existing campaign segment metric. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CampaignSegmentMetricUpdateInput = {
  /** The spend in major units of the campaign segment on the provided date. */
  amount?: InputMaybe<Scalars['Money']>;
  /** The primary key of the campaign segment this metrics is for. */
  campaign_segment_id?: InputMaybe<Scalars['Int']>;
  /** A valid currency code following ISO 4217. */
  currency?: InputMaybe<Scalars['String']>;
  /** What date the campaign segment metric is calculated for. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** The number of impressions for this campaign segment on the provided date. */
  impression_count?: InputMaybe<Scalars['Int']>;
};

/** The return object for multiple campaign segment metrics. The `nodes` property contains all the campaign segment metrics records themselves. */
export type CampaignSegmentMetricsConnection = {
  __typename?: 'CampaignSegmentMetricsConnection';
  /** A list of campaign segment metrics. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<CampaignSegmentMetric>;
  /** All the paginiation info that you will need for reference when navigating through a large list of campaign segment metrics. */
  page_info: PageInfo;
};

/** The sort order that can be applied when retrieving campaign segment metrics. */
export enum CampaignSegmentMetricsOrderBy {
  /** Sort the campaign segment metrics alphabetically by name, ascending. */
  NameAsc = 'NAME_ASC',
  /** Sort the campaign segment metrics alphabetically by name, descending. */
  NameDesc = 'NAME_DESC'
}

/** The input type necessary for updating an existing campaign segment. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CampaignSegmentUpdateInput = {
  /** If this campaign segment came from a third party source, this can be set to group by an account within that third party source. */
  account?: InputMaybe<Scalars['String']>;
  /**
   * If there are multiple versions of an ad or differently placed ads in an email this field can be used to differentiate between campaign segments for each of them.
   * Examples: 'top-right', 'blue', 'red', 'colorful' or 'banner250'. Defaults to "other".
   */
  content?: InputMaybe<Scalars['String']>;
  /** A description to help identify the purpose of the campaign segment. */
  description?: InputMaybe<Scalars['String']>;
  /** A one word descriptor of the campaign segment's medium. For example: 'email', 'social', 'blog-post' or 'organic'. Defaults to "other". */
  medium?: InputMaybe<Scalars['String']>;
  /** The human readable name of the campaign segment. */
  name?: InputMaybe<Scalars['String']>;
  /** Where on the page the ad was placed. Defaults to "other". */
  placement?: InputMaybe<Scalars['String']>;
  /** Where this segment comes from. Example - `google` or `utm`. Defaults to "other". If this is set you will also need `source` to be set. */
  provider?: InputMaybe<Scalars['String']>;
  /** The id of the ad group this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_group_id?: InputMaybe<Scalars['String']>;
  /** The name/label of the ad group this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_group_name?: InputMaybe<Scalars['String']>;
  /** The id of the ad this segment is for as given by the ad provider. Defaults to "other". */
  provider_ad_id?: InputMaybe<Scalars['String']>;
  /** The name/label of the ad this segment is for as given by the ad provider. Optional, for display only. Defaults to "other". */
  provider_ad_name?: InputMaybe<Scalars['String']>;
  /** The id of the campaign this segment is for as given by the ad provider. Defaults to "other". */
  provider_campaign_id?: InputMaybe<Scalars['String']>;
  /** The source key for this segment's provider. For example, for a provider of UTM, this will be the utm_source value (if present). */
  source?: InputMaybe<Scalars['String']>;
  /** This field is used if this campaign segment relates to any paid keywords. Defaults to "other". */
  term?: InputMaybe<Scalars['String']>;
};

/** The return object for multiple campaign segments. The `nodes` property contains all the campaign segments themselves, while the `page_info` property contains all information used for pagination. */
export type CampaignSegmentsConnection = {
  __typename?: 'CampaignSegmentsConnection';
  /** A list of campaign segments. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<CampaignSegment>;
  /** All the paginiation info that you will need for reference when navigating through a large list of campaign segments. */
  page_info: PageInfo;
};

/** The sort order that can be applied when retrieving campaign segments. */
export enum CampaignSegmentsOrderBy {
  /** Sort the campaign segments alphabetically by name, ascending. */
  NameAsc = 'NAME_ASC',
  /** Sort the campaign segments alphabetically by name, descending. */
  NameDesc = 'NAME_DESC'
}

/** A single cart record. It is created using the `create_or_update_cart` mutation. */
export type Cart = {
  __typename?: 'Cart';
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
  adjustments: Array<Adjustment>;
  /**
   * Any arbitrary information that you would like to be stored against a cart.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** Optional external URL to shopping cart on the `provider`'s website. */
  cart_url?: Maybe<Scalars['String']>;
  /** Required on creation. The datetime this cart was created on the `provider`'s platform, not in Solve. */
  created_at: Scalars['DateTime'];
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency: Currency;
  /** The ID of the cart (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** The date the record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Purchasable items in the cart. */
  items: Array<OrderItem>;
  /** The last time the user interacted with the cart by adding or removing something. The information for this field should be given to Solve by the integration, and is therefore not automatically updated. Will be set to the time of the `create_or_update_cart` mutation if not given. */
  last_interaction_at: Scalars['DateTime'];
  /** Optional free form notes related to the cart. */
  notes?: Maybe<Scalars['String']>;
  /** The id of this `Cart`'s corresponding `Order`, if such an order exists. The corresponding `Order` is assumed to have the same `provider` as this `Cart`. It is the responsibility of the integration to update this `Cart` to set the `order_id` when an `Order` is created. */
  order_id?: Maybe<Scalars['String']>;
  /** Profile ID of the associated profile if known. */
  profile_id?: Maybe<Scalars['UUID']>;
  /** Source store of this cart (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** Whether the user has entered the checkout phase of the cart. Defaults to `false` for a new cart. */
  reached_checkout: Scalars['Boolean'];
  /** Deprecated. Please use `profile_id`. */
  sid?: Maybe<Scalars['UUID']>;
  /** Describes the most-recent action done to a cart. Is automatically set by Solve. See the type `CartStatus` for more information. */
  status: CartStatus;
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. */
  subtotal_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. Represented in minor units. */
  subtotal_price_minor: Scalars['Int'];
  /** Number of items in the cart. Ignores the quantity values for each item. */
  total_item_quantity: Scalars['Int'];
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. */
  total_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. Represented in minor units. */
  total_price_minor: Scalars['Int'];
  /** The date the record was last updated in the database. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple carts. The `nodes` property contains all the carts themselves, while the `page_info` property contains all information used for pagination. */
export type CartConnection = {
  __typename?: 'CartConnection';
  nodes: Array<Cart>;
  page_info: PageInfo;
};

/** The input type necessary for creating or updating a cart. View the fields inside this type for more information about which fields are mandatory and which are optional. Note that the `subtotal_price` and `total_price` values are calculated based on the `adjustments` and `items` so should not be passed in. */
export type CartInput = {
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. On update, the existing array of `adjustments` are replaced with the new array if passed. */
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  /**
   * Any arbitrary information that you would like to be stored against a cart.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** Optional external URL to shopping cart on the `provider`'s website. */
  cart_url?: InputMaybe<Scalars['String']>;
  /** Required on creation. The datetime this cart was created on the `provider`'s platform, not in Solve. */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency?: InputMaybe<Currency>;
  /** The ID of the cart (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** Purchasable items in the cart. Required on creation. On update, the existing array of `items` are replaced with the new array if passed. */
  items?: InputMaybe<Array<OrderItemInput>>;
  /** The last time the user interacted with the cart by adding or removing something. The information for this field should be given to Solve by the integration, and is therefore not automatically updated. Will be set to the time of the `create_or_update_cart` mutation if not given. */
  last_interaction_at?: InputMaybe<Scalars['DateTime']>;
  /** Optional free form notes related to the cart. */
  notes?: InputMaybe<Scalars['String']>;
  /** Profile ID of the associated profile if known. */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** Source store of this cart (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** Whether the user has entered the checkout phase of the cart. Defaults to `false` for a new cart. */
  reached_checkout?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated. Please use `profile_id`. */
  sid?: InputMaybe<Scalars['UUID']>;
};

/** Describes the most recent action done to a cart. Is automatically set by Solve. See the API Documentation for Carts for information about the transitions between statuses. */
export enum CartStatus {
  /** The cart was abandoned. It is no longer in use by the user of the ecommerce website. */
  Abandoned = 'ABANDONED',
  /** The cart is has just been created or is still in use. This is the default value when creating a cart. */
  Active = 'ACTIVE',
  /** The cart was checked-out. It was converted to an order. */
  Converted = 'CONVERTED'
}

/** The sort order that can be applied when retrieving carts. */
export enum CartsOrderBy {
  /** Sort the carts by the datetime that they were created at, from oldest to newest. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort the carts by the datetime that they were created at, from newest to oldest. */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort the carts by the datetime that they were last interaction at, from oldest to newest. */
  LastInteractionAtAsc = 'LAST_INTERACTION_AT_ASC',
  /** Sort the carts by the datetime that they were last interaction at, from newest to oldest. */
  LastInteractionAtDesc = 'LAST_INTERACTION_AT_DESC',
  /** Sort the carts by the datetime that they were updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the carts by the datetime that they were updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** What channel was used to create the order. */
export enum Channel {
  /** The order was created via a mobile app. */
  App = 'APP',
  /** The order was created via an email. */
  Email = 'EMAIL',
  /** The order was created via the mobile phone. */
  Mobile = 'MOBILE',
  /** The order was created in person. */
  Offline = 'OFFLINE',
  /** The order was created via an indeterminate method. */
  Other = 'OTHER',
  /** The order was created via a phone call. */
  Voice = 'VOICE',
  /** The order was created via the web. */
  Web = 'WEB'
}

export type ChannelReport = {
  __typename?: 'ChannelReport';
  channel_breakdown?: Maybe<Array<Maybe<ChannelRevenueBreakdown>>>;
  report_currency: Scalars['CurrencyCode'];
  revenue_overview: ChannelReportRevenueOverview;
  shop_name: Scalars['String'];
};

export enum ChannelReportDifferenceDirection {
  Decrease = 'DECREASE',
  Increase = 'INCREASE',
  NoChange = 'NO_CHANGE'
}

/** Represents a ratio. eg 2.0% */
export type ChannelReportDirectionalPercent = {
  __typename?: 'ChannelReportDirectionalPercent';
  direction?: Maybe<ChannelReportDifferenceDirection>;
  /** Ratio formatted with symbol. */
  localized?: Maybe<Scalars['String']>;
  value: Scalars['NonNegativeFloat'];
};

/** Represents a monetary value. eg $4.00. The currency is referenced at the report overview level */
export type ChannelReportMonetary = {
  __typename?: 'ChannelReportMonetary';
  currency_code: Scalars['CurrencyCode'];
  rounded: Scalars['NonNegativeInt'];
  value: Scalars['NonNegativeFloat'];
};

/** Represents a ratio. eg 2.0% */
export type ChannelReportPercent = {
  __typename?: 'ChannelReportPercent';
  /** Percent formatted with symbol. */
  localized: Scalars['String'];
  /** Percent as a value */
  value: Scalars['NonNegativeFloat'];
};

export type ChannelReportRevenueOverview = {
  __typename?: 'ChannelReportRevenueOverview';
  mer: Mer;
  new_revenue: ChannelReportRevenueSegmentation;
  returning_revenue: ChannelReportRevenueSegmentation;
  roas: Roas;
  total_new_and_returning_revenue: ChannelReportTotalNewAndReturningRevenue;
  total_spend: ChannelReportMonetary;
};

export type ChannelReportRevenueSegmentation = {
  __typename?: 'ChannelReportRevenueSegmentation';
  difference: ChannelReportPercent;
  total: ChannelReportMonetary;
};

export type ChannelReportTotalNewAndReturningRevenue = {
  __typename?: 'ChannelReportTotalNewAndReturningRevenue';
  total: ChannelReportMonetary;
  variance: ChannelReportDirectionalPercent;
};

export type ChannelRevenueBreakdown =
  | AffiliateRevenueBreakdown
  | DirectRevenueBreakdown
  | DisplayRevenueBreakdown
  | EmailRevenueBreakdown
  | OrganicSearchRevenueBreakdown
  | OrganicSocialRevenueBreakdown
  | PaidSearchRevenueBreakdown
  | PaidSocialRevenueBreakdown
  | ReferralRevenueBreakdown
  | SmsRevenueBreakdown
  | UnknownRevenueBreakdown;

export type ChannelRevenueBreakdownBase = {
  /** User facing label for the channel */
  channel_label: Scalars['String'];
  /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
  new_revenue?: Maybe<ChannelReportMonetary>;
  /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
  returning_revenue?: Maybe<ChannelReportMonetary>;
};

/** A single configuration record. It is created using the `create_or_update_configuration` mutation. */
export type Configuration = {
  __typename?: 'Configuration';
  /** The datetime that this configuration record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** The configuration id. This is generated and returned via the `create_or_update_configuration` mutation and is used for primary key reference to the configuration in other queries and mutations. */
  id: Scalars['String'];
  /** The datetime that this configuration record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** The configuration values as a JSON object. */
  properties?: Maybe<Scalars['Json']>;
  /**
   * This token is required when updating the configuration.
   * Note that a token obtained from fetching a value by key
   * will only permit modifications to that specific key-value pair.
   * See <https://docs.solvedata.app/latest/api/configurations#update-tokens>
   * for more information.
   */
  update_token: Scalars['String'];
  /** The datetime that this configuration record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple configurations. The `nodes` property contains all the configurations themselves, while the `page_info` property contains all information used for pagination. */
export type ConfigurationConnection = {
  __typename?: 'ConfigurationConnection';
  /** A list of configurations. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Configuration>;
  /** All the paginiation info that you will need for reference when navigating through a large list of configurations. */
  page_info: PageInfo;
};

/** Return a single configuration value */
export type ConfigurationEntry = {
  __typename?: 'ConfigurationEntry';
  /** The datetime that this configuration record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** The configuration id. This is generated and returned via the `create_or_update_configuration` mutation and is used for primary key reference to the configuration in other queries and mutations. */
  id: Scalars['String'];
  /** The datetime that this configuration record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** The configuration key. */
  key: Scalars['String'];
  /**
   * This token is required when updating the configuration.
   * Note that a token obtained from fetching a value by key
   * will only permit modifications to that specific key-value pair.
   * See <https://docs.solvedata.app/latest/api/configurations#update-tokens>
   * for more information.
   */
  update_token: Scalars['String'];
  /** The datetime that this configuration record was last modified. */
  updated_at: Scalars['DateTime'];
  /** The configuration value. */
  value?: Maybe<Scalars['ConfigurationValue']>;
};

/** The input type necessary for creating a configuration. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ConfigurationInput = {
  /** The datetime that this configuration record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). Defaults to the current time if omitted on creation. Not required when updating a configuration. */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** The unique configuration identifier. */
  id: Scalars['String'];
  /** The configuration values as a JSON object. */
  properties: Scalars['Json'];
  /**
   * This token is required when updating the configuration.
   * Note that a token obtained from fetching a value by key
   * will only permit modifications to that specific key-value pair.
   * See <https://docs.solvedata.app/latest/api/configurations#update-tokens>
   * for more information.
   */
  update_token?: InputMaybe<Scalars['String']>;
};

/** The sort order that can be applied when retrieving configurations. */
export enum ConfigurationsOrderBy {
  /** Sort the configurations by the datetime that they were created at, from oldest to newest. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort the configurations by the datetime that they were created at, from newest to oldest. */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort the configurations by the datetime that they were updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the configurations by the datetime that they were updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ConversionReport = {
  __typename?: 'ConversionReport';
  conversion_overview: ConversionReportOverview;
  customer_stages?: Maybe<Array<Maybe<ConversionReportStages>>>;
  shop_name: Scalars['String'];
};

/** Represents an amount of customers. eg 3000. */
export type ConversionReportAmount = {
  __typename?: 'ConversionReportAmount';
  /** Percent change as a value */
  difference: Scalars['Float'];
  total: ConversionReportTotal;
};

export type ConversionReportAnonymousSessions = ConversionStageBase & {
  __typename?: 'ConversionReportAnonymousSessions';
  /** Amount of people */
  amount: ConversionReportAmount;
  /** User facing description for the conversion stage */
  description: Scalars['String'];
  /** User facing label for the conversion stage */
  stage_label: Scalars['String'];
};

export type ConversionReportCarts = ConversionStage &
  ConversionStageBase & {
    __typename?: 'ConversionReportCarts';
    /** Amount of people */
    amount: ConversionReportAmount;
    /** Converted percentage of people as a number */
    converted: ConversionReportConverted;
    /** User facing description for the conversion stage */
    description: Scalars['String'];
    /** User facing label for the conversion stage */
    stage_label: Scalars['String'];
  };

export type ConversionReportChartDataPoint = {
  __typename?: 'ConversionReportChartDataPoint';
  /** Data for the point */
  data: ConversionReportChartDataValue;
  /** Date of the data point */
  time: Scalars['String'];
};

export type ConversionReportChartDataValue = {
  __typename?: 'ConversionReportChartDataValue';
  /** Number of new customers */
  new: Scalars['NonNegativeInt'];
  /** Number of returning customers */
  returning: Scalars['NonNegativeInt'];
};

export type ConversionReportCheckouts = ConversionStage &
  ConversionStageBase & {
    __typename?: 'ConversionReportCheckouts';
    /** Amount of people */
    amount: ConversionReportAmount;
    /** Converted percentage of people as a number */
    converted: ConversionReportConverted;
    /** User facing description for the conversion stage */
    description: Scalars['String'];
    /** User facing label for the conversion stage */
    stage_label: Scalars['String'];
  };

/** Represents a percentage of customers converted. eg 2.0% */
export type ConversionReportConverted = {
  __typename?: 'ConversionReportConverted';
  /** Percent change as a value */
  difference: Scalars['Float'];
  /** Percent as a value */
  percentage: ConversionReportPercent;
};

export type ConversionReportIdentifiedSessions = ConversionStage &
  ConversionStageBase & {
    __typename?: 'ConversionReportIdentifiedSessions';
    /** Amount of people */
    amount: ConversionReportAmount;
    /** Converted percentage of people as a number */
    converted: ConversionReportConverted;
    /** User facing description for the conversion stage */
    description: Scalars['String'];
    /** User facing label for the conversion stage */
    stage_label: Scalars['String'];
  };

export type ConversionReportOrders = ConversionStage &
  ConversionStageBase & {
    __typename?: 'ConversionReportOrders';
    /** Amount of people */
    amount: ConversionReportAmount;
    /** Converted percentage of people as a number */
    converted: ConversionReportConverted;
    /** User facing description for the conversion stage */
    description: Scalars['String'];
    /** User facing label for the conversion stage */
    stage_label: Scalars['String'];
  };

export type ConversionReportOverview = {
  __typename?: 'ConversionReportOverview';
  /** Sessions over the period */
  chart_data: Array<Maybe<ConversionReportChartDataPoint>>;
  /** Converted percentage of people */
  converted: ConversionReportConverted;
  /** Amount of people */
  sessions: ConversionReportAmount;
};

/** Represents a ratio. eg 2.0% */
export type ConversionReportPercent = {
  __typename?: 'ConversionReportPercent';
  /** Percent formatted with symbol. */
  localized: Scalars['String'];
  /** Percent as a value */
  value: Scalars['Float'];
};

export type ConversionReportRepeatPurchasers = ConversionStage &
  ConversionStageBase & {
    __typename?: 'ConversionReportRepeatPurchasers';
    /** Amount of people */
    amount: ConversionReportAmount;
    /** Converted percentage of people as a number */
    converted: ConversionReportConverted;
    /** User facing description for the conversion stage */
    description: Scalars['String'];
    /** User facing label for the conversion stage */
    stage_label: Scalars['String'];
  };

export type ConversionReportStages =
  | ConversionReportAnonymousSessions
  | ConversionReportCarts
  | ConversionReportCheckouts
  | ConversionReportIdentifiedSessions
  | ConversionReportOrders
  | ConversionReportRepeatPurchasers;

/** Represents a total amount of customers */
export type ConversionReportTotal = {
  __typename?: 'ConversionReportTotal';
  /** Number formatted with decimal separator. */
  localized: Scalars['String'];
  /** Number of customers */
  value: Scalars['NonNegativeInt'];
};

export type ConversionStage = {
  /** Amount of people */
  amount: ConversionReportAmount;
  /** Converted percentage of people as a number */
  converted: ConversionReportConverted;
  /** User facing description for the conversion stage */
  description: Scalars['String'];
  /** User facing label for the conversion stage */
  stage_label: Scalars['String'];
};

export type ConversionStageBase = {
  /** Amount of people */
  amount: ConversionReportAmount;
  /** User facing description for the conversion stage */
  description: Scalars['String'];
  /** User facing label for the conversion stage */
  stage_label: Scalars['String'];
};

export type ConvertCartOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOrUpdateCartOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** The input type necessary for creating or updating a order. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CreateOrUpdateOrderInput = {
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here.. On update, the existing list of `adjustments` are replaced with the new list if passed. */
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The billing address, if any, for this order. */
  billing_address?: InputMaybe<InputAddress>;
  /** The marketing channel that was used that ended up in the order being created. Example: WEB. */
  channel?: InputMaybe<Channel>;
  /** The datetime that this order record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency?: InputMaybe<Currency>;
  /**
   * A list of discount codes that were used on this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  discount_codes?: InputMaybe<Array<Scalars['String']>>;
  /** Fulfillments associated with this order. See the types `Fulfillment` and `FulfillmentStatus` for more information. */
  fulfillments?: InputMaybe<Array<FulfillmentInput>>;
  /** The ID of the order (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** Purchasable items in the order. */
  items?: InputMaybe<Array<OrderItemInput>>;
  /** Optional free form notes related to the order. */
  notes?: InputMaybe<Scalars['String']>;
  /** Deprecated. The payment status is now automatically calculated. Please do not use. */
  payment_status?: InputMaybe<PaymentStatus>;
  /**
   * The id of the profile that the order belongs to , if known.The profile_id of the profile that this order belongs to. This is optional for where the order has been made by an unknown profile.
   * If the `profile_id` is not set when creating an order, then it is taken from this order's corresponding cart, if it exists.
   * When updating the order's `profile_id` to a non-null value, the corresponding cart and any corresponding returns will have their `profile_id` set to the same value.
   * If the profile_id has already been set, then the profile_id will not be changed.
   */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** Source store of this order (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** The shipping address, if any, for this order. Can be different from the billing address. */
  shipping_address?: InputMaybe<InputAddress>;
  /** Deprecated. Please use `profile_id`. */
  sid?: InputMaybe<Scalars['UUID']>;
  /** The marketing attribution source that was used that ended up in this order being created. */
  source?: InputMaybe<Scalars['String']>;
  /** The current status of the order. Default value is 'CREATED'. */
  status?: InputMaybe<Status>;
  /** Deprecated. Has been made redundant. Please use `provider` instead */
  store_identifier?: InputMaybe<Scalars['String']>;
  /** The id of the subscription associated with the order. Omit if the order does not belong to a subscription. */
  subscription_id?: InputMaybe<Scalars['String']>;
  /**
   * The subscription type of the order. Default value is 'NOT_APPLICABLE'.
   *
   * Only certain values of `subscription_type` are allowed based on the `type`. See the description of `type` for the set of various combinations.
   */
  subscription_type?: InputMaybe<OrderSubscriptionType>;
  /**
   * A list of tags for this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The type of the order. Default value is 'STANDARD'.
   *
   *   The `type` and `subscription_type` are only allowed certain combinations. Valid combinations are the following:
   *
   *   | `type`         | `subscription_type` |
   *   |----------------|---------------------|
   *   | `STANDARD`     | `NOT_APPLICABLE`    |
   *   | `SUBSCRIPTION` | `FIRST_TIME`        |
   *   | `SUBSCRIPTION` | `ONE_OFF`           |
   *   | `SUBSCRIPTION` | `RECURRING`         |
   *   | `SUBSCRIPTION` | `UNKNOWN`           |
   *   | `ERASED`       | `ERASED`            |
   *
   *   Note that `STANDARD` order type can only have a `subscription_type` of `NOT_APPLICABLE`, whereas
   *   `SUBSCRIPTION` order `type` can never have a `subscription_type` of `NOT_APPLICABLE`.
   *
   *   Note that an erased order will have a `type` and `subscription_type` of `ERASED`. However, the GraphQL API does not
   *   allow the explicit setting of order `type`/`subscription_type` values to `ERASED`.
   */
  type?: InputMaybe<OrderType>;
};

export type CreateOrUpdateOrderOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOrUpdatePaymentOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOrUpdateReturnOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOrUpdateSubscriptionOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** The input type necessary for creating a order. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CreateOrderInput = {
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The billing address, if any, for this order. */
  billing_address?: InputMaybe<InputAddress>;
  /** The marketing channel that was used that ended up in the order being created. Example: WEB. */
  channel?: InputMaybe<Channel>;
  /** The datetime that this order record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency: Currency;
  /**
   * A list of discount codes that were used on this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  discount_codes?: InputMaybe<Array<Scalars['String']>>;
  /** Fulfillments associated with this order. See the types `Fulfillment` and `FulfillmentStatus` for more information. */
  fulfillments?: InputMaybe<Array<FulfillmentInput>>;
  /** The ID of the order (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** Purchasable items in the order. Required on creation. On update, the existing list of `items` are replaced with the new list if passed. */
  items: Array<OrderItemInput>;
  /** Optional free form notes related to the order. */
  notes?: InputMaybe<Scalars['String']>;
  /** The current payment status of the order. This is automatically generated from any payments against the order. */
  payment_status?: InputMaybe<PaymentStatus>;
  /**
   * The id of the profile that the order belongs to , if known.The profile_id of the profile that this order belongs to. This is optional for where the order has been made by an unknown profile.
   * If the `profile_id` is not set when creating an order, then it is taken from this order's corresponding cart, if it exists.
   * When updating the order's `profile_id` to a non-null value, the corresponding cart and any corresponding returns will have their `profile_id` set to the same value.
   * If the profile_id has already been set, then the profile_id will not be changed.
   */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** Source store of this order (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** The shipping address, if any, for this order. Can be different from the billing address. */
  shipping_address?: InputMaybe<InputAddress>;
  /** The marketing attribution source that was used that ended up in this order being created. */
  source?: InputMaybe<Scalars['String']>;
  /** The current status of the order. Default value is 'CREATED'. */
  status: Status;
  /** Deprecated. Has been made redundant. Please use `provider` instead */
  store_identifier?: InputMaybe<Scalars['String']>;
  /** The id of the subscription associated with the order. If this field is set, `type` must be "SUBSCRIPTION". Omit if the order does not belong to a subscription. */
  subscription_id?: InputMaybe<Scalars['String']>;
  /**
   * The subscription type of the order. Default value is 'NOT_APPLICABLE'.
   *
   * Only certain values of `subscription_type` are allowed based on the `type`. See the description of `type` for the set of various combinations.
   */
  subscription_type?: InputMaybe<OrderSubscriptionType>;
  /**
   * A list of tags for this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The type of the order. Default value is 'STANDARD'.
   *
   *   The `type` and `subscription_type` are only allowed certain combinations. Valid combinations are the following:
   *
   *   | `type`         | `subscription_type` |
   *   |----------------|---------------------|
   *   | `STANDARD`     | `NOT_APPLICABLE`    |
   *   | `SUBSCRIPTION` | `FIRST_TIME`        |
   *   | `SUBSCRIPTION` | `ONE_OFF`           |
   *   | `SUBSCRIPTION` | `RECURRING`         |
   *   | `SUBSCRIPTION` | `UNKNOWN`           |
   *   | `ERASED`       | `ERASED`            |
   *
   *   Note that `STANDARD` order type can only have a `subscription_type` of `NOT_APPLICABLE`, whereas
   *   `SUBSCRIPTION` order `type` can never have a `subscription_type` of `NOT_APPLICABLE`.
   *
   *   Note that an erased order will have a `type` and `subscription_type` of `ERASED`. However, the GraphQL API does not
   *   allow the explicit setting of order `type`/`subscription_type` values to `ERASED`.
   */
  type?: InputMaybe<OrderType>;
};

export type CreateOrderOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSubscriptionOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** The input type necessary for creating a user. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type CreateUserInput = {
  /** The email that this user uses to log in. */
  email: Scalars['Email'];
  /** The name for this user. */
  name: Scalars['String'];
  /** The user's role. Determines what a user is allowed to do in Solve. */
  role: UserRole;
};

/** A single API Key that is returned by the `createApiKey` mutation. This return type is the only time the `secret` field will be returned. */
export type CreatedApiKey = {
  __typename?: 'CreatedApiKey';
  /** The API key id. This is generated and returned via the `create_api_key` mutation and is used for primary key reference to the API key in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this API key record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Whether the API key record has been set to a `disabled` state. Defaults to `false` on API key creation. */
  is_disabled?: Maybe<Scalars['Boolean']>;
  /** The public key string for this API Key. This can be used for `PUBLIC` API keys without leaking sensitive information. */
  key: Scalars['String'];
  /** A name for this API Key. It does not have to be unique, but uniqueness is recommended. */
  name: Scalars['String'];
  /** Optional free form notes related to the API Key. */
  notes?: Maybe<Scalars['String']>;
  /** The API Policy that is applied to requests using this API Key. */
  policy: ApiPolicy;
  /** The secret key for this API Key. This is private and should be treated as such. The secret is only given on creation of an API Key and cannot be retrieved via the GraphQL API after that. It is also stored hashed in the database. */
  secret: Scalars['String'];
  /** Whether this API Key is `PUBLIC` or `PROTECTED`. */
  type: ApiKeyType;
  /** The datetime that this API key record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** All valid currency codes, following ISO 4217. */
export enum Currency {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Chf = 'CHF',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xfu = 'XFU',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export type DeleteOrderOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type DeletePaymentOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteReturnOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type DeleteSubscriptionOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

export type DeletedQuery = {
  __typename?: 'DeletedQuery';
  /** The saved query id. This is generated and returned via the `create_query` mutation and is used for primary key reference to the saved query in other queries and mutations. */
  id: Scalars['Int'];
};

export type DirectRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'DirectRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

export type DisplayRevenueBreakdown = ChannelRevenueBreakdownBase &
  PaidChannelRevenueBreakdown & {
    __typename?: 'DisplayRevenueBreakdown';
    /** Total ad spend for channel */
    ad_spend?: Maybe<ChannelReportMonetary>;
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
    /** Return on ad spend for channel */
    roas: Scalars['Float'];
    /** Return on investment for channel */
    roi?: Maybe<ChannelReportMonetary>;
  };

export type DownloadLinksResponse = {
  __typename?: 'DownloadLinksResponse';
  /** A list of all the identifiers that resolve to the profile downloaded. */
  identifiers: IdentifierConnection;
  /** The profile_id of the profile downloaded. */
  profile_id: Scalars['UUID'];
  /** A link to the a compressed zip file that contains all the data related to the profile. */
  zip_url: Scalars['String'];
};

export type EmailRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'EmailRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

/** Energy efficiency class options. */
export enum EnergyEfficiencyClass {
  /** Class A. */
  A = 'A',
  /** Class A+. */
  Ap = 'AP',
  /** Class A++. */
  App = 'APP',
  /** Class A+++. */
  Appp = 'APPP',
  /** Class B. */
  B = 'B',
  /** Class C. */
  C = 'C',
  /** Class D. */
  D = 'D',
  /** Class E. */
  E = 'E',
  /** Class F. */
  F = 'F',
  /** Class G. */
  G = 'G'
}

/** A single event record. It is created using the `queue_event` mutation. */
export type Event = {
  __typename?: 'Event';
  /** Information on what integration, API key or user account created the event. */
  _created_by?: Maybe<Scalars['String']>;
  /** The API key that was used to create this event, if known. */
  api_key?: Maybe<ApiKey>;
  /** The id of the Solve Campaign that this event is associated with. */
  campaign_id?: Maybe<Scalars['String']>;
  /** The campaign segment data returned for this event. */
  campaign_segment?: Maybe<CampaignSegment>;
  /** This is the id of the Cart that this event is related to. In conjunction with the `store` you can determine which Cart this event was generated for. */
  cart_id?: Maybe<Scalars['String']>;
  /** The time at which the event occurred. Will be set to the time that the event was ingested into the system if not provided. */
  event_time: Scalars['DateTime'];
  /** The event id. This is generated and returned via the `queue_event` mutation and is used for primary key reference to the event in other queries and mutations. */
  id: Scalars['UUID'];
  /** For internal use. A linking id is a long lived id that can be used to link a number of sessions. */
  linking_id?: Maybe<Scalars['String']>;
  /** This is the id of the Order that this event is related to. In conjunction with the `store` you can determine which Order this event was generated for. */
  order_id?: Maybe<Scalars['String']>;
  /** The payload is where the main data is located for an event. It contains all the information that is collected by Solve for the event. See <https://docs.solvedata.app/latest/api/event-schemas> for a breakdown on what payloads to expect from what type. */
  payload: Scalars['Json'];
  /** The id of the product associated with this event. Should correspond to the id of a product already in Solve. */
  product_id?: Maybe<Scalars['String']>;
  /** The id of the profile that the event belongs to , if known. */
  profile_id?: Maybe<Scalars['UUID']>;
  /** This is the id of the Return that this event is related to. In conjunction with the `store` you can determine which Return this event was generated for. */
  return_id?: Maybe<Scalars['String']>;
  /** A session id is used to group a number of events to a users session. */
  session_id?: Maybe<Scalars['String']>;
  /** The store of the Cart/Order that this event is related to. */
  store?: Maybe<Scalars['String']>;
  /** The id of the Solve Subscription that this event is associated with. */
  subscription_id?: Maybe<Scalars['String']>;
  /** Describes what the event is for and defines the payload that the event should have. See <https://docs.solvedata.app/latest/api/event-schemas> for a breakdown of the different event payloads. */
  type: Scalars['String'];
};

/** The return object for multiple events. The `nodes` property contains all the events themselves, while the `page_info` property contains all information used for pagination. */
export type EventConnection = {
  __typename?: 'EventConnection';
  nodes: Array<Event>;
  /** All the paginiation info that you will need for reference when navigating through a large list of events. */
  page_info: PageInfo;
};

/** The input type necessary for creating a event. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type EventInput = {
  /** This is the id of the Cart that this event is related to. In conjunction with the `store` you can determine which Cart this event was generated for. */
  cart_id?: InputMaybe<Scalars['String']>;
  /** The time at which the event occurred. Will be set to the time that the event was ingested into the system if not provided. */
  event_time?: InputMaybe<Scalars['DateTime']>;
  /** The event id. This is generated and returned via the `queue_event` mutation and is used for primary key reference to the event in other queries and mutations. */
  id?: InputMaybe<Scalars['UUID']>;
  /** For internal use. A linking id is a long lived id that can be used to link a number of sessions. */
  linking_id?: InputMaybe<Scalars['String']>;
  /** This is the id of the Order that this event is related to. In conjunction with the `store` you can determine which Order this event was generated for. */
  order_id?: InputMaybe<Scalars['String']>;
  /** The payload is where the main data is located for an event. It contains all the information that is collected by Solve for the event. See <https://docs.solvedata.app/latest/api/event-schemas> for a breakdown on what payloads to expect from what type. */
  payload: Scalars['Json'];
  /** The id of the product associated with this event. Should correspond to the id of a product already in Solve. This should only be set if the event can unambiguously be associated with a single product, e.g. a pageview on a product page or a `cart_item_added` event. */
  product_id?: InputMaybe<Scalars['String']>;
  /** The id of the profile that the event belongs to , if known. */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** This is the id of the Return that this event is related to. In conjunction with the `store` you can determine which Return this event was generated for. */
  return_id?: InputMaybe<Scalars['String']>;
  /** A session id is used to group a number of events to a users session. */
  session_id?: InputMaybe<Scalars['String']>;
  /** A JSON string containing session ids under identifying keys. Session ids are used to group a number of events to a users session. Currently only the id under the 'solve.sdk' key is respected. */
  sessions?: InputMaybe<Scalars['Json']>;
  /** The store of the Cart/Order that this event is related to. */
  store?: InputMaybe<Scalars['String']>;
  /** Describes what the event is for and defines the payload that the event should have. See <https://docs.solvedata.app/latest/api/event-schemas> for a breakdown of the different event payloads. */
  type: Scalars['String'];
};

/** A single fulfillment - a shipment/delivery. */
export type Fulfillment = {
  __typename?: 'Fulfillment';
  /** When the fulfillment is expected to be delivered. */
  estimated_delivery_at?: Maybe<Scalars['DateTime']>;
  /** A unique identifier for this fulfillment. This id only has to be unique for fulfillments within this order. */
  id: Scalars['String'];
  /**
   * The current status of this fulfillment.
   * See the type `FulfillmentStatus` for more information about the possible values.
   * When a fulfillment is created or has its `status` changed, an event is automatically emitted.
   * The type of the event corresponds to the new `status`, i.e., one of `order_fulfillment_pending`, `order_fulfillment_delivered`, `order_fulfillment_failed`.
   */
  status: FulfillmentStatus;
  /** The name of the company that does the delivery. */
  tracking_company?: Maybe<Scalars['String']>;
  /** The id or number for the fulfillment in the tracking company's system. */
  tracking_id?: Maybe<Scalars['String']>;
  /** A url to the tracking information webpage, most probably on the tracking company's website. */
  tracking_url?: Maybe<Scalars['String']>;
};

export type FulfillmentInput = {
  /** When the fulfillment is expected to be delivered. */
  estimated_delivery_at?: InputMaybe<Scalars['DateTime']>;
  /** A unique identifier for this fulfillment. This id only has to be unique for fulfillments within this order. */
  id: Scalars['String'];
  /**
   * The current status of this fulfillment.
   * See the type `FulfillmentStatus` for more information about the possible values.
   * When a fulfillment is created or has its `status` changed, an event is automatically emitted.
   * The type of the event corresponds to the new `status`, i.e., one of `order_fulfillment_pending`, `order_fulfillment_delivered`, `order_fulfillment_failed`.
   */
  status: FulfillmentStatus;
  /** The name of the company that does the delivery. */
  tracking_company?: InputMaybe<Scalars['String']>;
  /** The id or number for the fulfillment in the tracking company's system. */
  tracking_id?: InputMaybe<Scalars['String']>;
  /** A url to the tracking information webpage, most probably on the tracking company's website. */
  tracking_url?: InputMaybe<Scalars['String']>;
};

/**
 * The current status of this fulfillment.
 * See the type `FulfillmentStatus` for more information about the possible values.
 * When a fulfillment is created or has its `status` changed, an event is automatically emitted.
 * The type of the event corresponds to the new `status`, i.e., one of `order_fulfillment_pending`, `order_fulfillment_delivered`, `order_fulfillment_failed`.
 */
export enum FulfillmentStatus {
  /** The delivery has been made. */
  Delivered = 'DELIVERED',
  /** Some problem occurred which meant that the delivery couldn't be made. */
  Failed = 'FAILED',
  /** The fulfillment is being prepared or is making progress or has made partial progress towards the final destination. */
  Pending = 'PENDING'
}

/** A graph is a collection of stats that share the same type. */
export type Graph = {
  __typename?: 'Graph';
  /** The data returned for this graph. */
  data?: Maybe<Array<Maybe<GraphData>>>;
  /** A description to explain the data that this graph contains. */
  description?: Maybe<Scalars['String']>;
  /** The label used for the summary value. For example, 'Total Revenue' on the revenue graph. */
  label: Scalars['String'];
  /** The legend to give an indication as to what the different data points refer to. */
  legends: Array<Maybe<GraphLegends>>;
  /** The period of time that this graph contains data for. */
  period: Scalars['String'];
  /** A short title for the graph. */
  title: Scalars['String'];
  /** The stat `type` that this graph corresponds to. */
  type: Scalars['String'];
  /** This value is the summary value of all the datapoints returned over the period defined. */
  value: Scalars['String'];
};

/** A single datapoint for display in a graph. This contains both the date of the datapoint as well as it's numerical value. */
export type GraphData = {
  __typename?: 'GraphData';
  /** The time that this data point represents. */
  time?: Maybe<Scalars['DateTime']>;
  /** The actual value for the data point. */
  value?: Maybe<Scalars['Json']>;
};

/** The legends that are shown along side the graph. */
export type GraphLegends = {
  __typename?: 'GraphLegends';
  /** A readable name for the legend. */
  text?: Maybe<Scalars['String']>;
  /** The stat `type` that this legend corresponds to. */
  value?: Maybe<Scalars['String']>;
};

/** A graph represents the stats for a given type and time range. */
export type Graphs = {
  __typename?: 'Graphs';
  /** A description to explain the data that this graph contains. */
  description?: Maybe<Scalars['String']>;
  /** The legend to give an indication as to what the different data points refer to. */
  legends: Array<Maybe<GraphLegends>>;
  /** A list of the stat types that have been used to make up the graph. */
  stats: Array<Maybe<Scalars['String']>>;
  /** A short title for the graph. */
  title: Scalars['String'];
  /** The stat `type` that this graph corresponds to. */
  type: Scalars['String'];
};

/** The return object for multiple graphs. The `nodes` property contains all the graphs themselves, while the `page_info` property contains all information used for pagination. */
export type GraphsConnection = {
  __typename?: 'GraphsConnection';
  /** The return object for multiple graphs. The `nodes` property contains all the graphs themselves. */
  nodes: Array<Graphs>;
};

export type Identifier = {
  __typename?: 'Identifier';
  /**
   * Any arbitrary information that you would like to be stored against a profile identifier.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  identifier_attributes?: Maybe<Scalars['Json']>;
  /** The profile identifier key is the actual value of the identifier. */
  key: Scalars['String'];
  /** The id of the profile that the profile identifier belongs to , if known. */
  profile_id: Scalars['UUID'];
  /** The type of the profile identifier, either unhashed or a md5/sha_256 hash. */
  type: Scalars['String'];
};

/** The return object for multiple profile identifiers. The `nodes` property contains all the profile identifiers themselves, while the `page_info` property contains all information used for pagination. */
export type IdentifierConnection = {
  __typename?: 'IdentifierConnection';
  /** A list of profile identifiers. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Identifier>;
  /** Not yet implemented. This will contain all the paginiation info that you will need for reference when navigating through a large list of profile identifiers. */
  page_info: BlankPageInfo;
};

export type IdentifierQueryInput = {
  /** The profile identifier key is the actual value of the identifier. */
  key: Scalars['String'];
  /** The type of the profile identifier, either unhashed or a md5/sha_256 hash. */
  type: Scalars['String'];
};

export type InputAddress = {
  /**
   * Any arbitrary information that you would like to be stored against a address.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  address_attributes?: InputMaybe<Scalars['Json']>;
  /** The town or city for the address. */
  city?: InputMaybe<Scalars['String']>;
  /** The short country code. */
  country_code?: InputMaybe<Scalars['String']>;
  /** The short country code. */
  country_name?: InputMaybe<Scalars['String']>;
  /** The postal or ZIP code for the address, if applicable. */
  postal_code?: InputMaybe<Scalars['String']>;
  /** The province that the address is located in, if applicable. */
  province?: InputMaybe<Scalars['String']>;
  /** The code of the province for the address, if applicable. */
  province_code?: InputMaybe<Scalars['String']>;
  /** Primary street name and number. */
  street?: InputMaybe<Scalars['String']>;
  /** Secondary street information. Suburb/unit number etc. */
  street2?: InputMaybe<Scalars['String']>;
  /** The type of the address, generally `BILLING` or `SHIPPING`. Enforced to always be uppercase. */
  type: Scalars['String'];
};

export type InputIdentifier = {
  /**
   * Any arbitrary information that you would like to be stored against a profile identifiers.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  identifier_attributes?: InputMaybe<Scalars['Json']>;
  /** The profile identifier key is the actual value of the identifier. */
  key: Scalars['String'];
  /** The type of the profile identifier, either unhashed or a md5/sha_256 hash. */
  type: Scalars['String'];
};

/** The subset of the profile `processing_state`s that are allowed as inputs for updating profiles via GraphQL. Determines how events are linked to the profile as per <https://docs.solvedata.app/latest/profiles/profile-processing-states>. */
export enum InputProcessingState {
  /** The profile is active. */
  Active = 'ACTIVE',
  /** The profile has been deactivated (made dormant) and will be erased after a set period of time. */
  Dormant = 'DORMANT',
  /** Processing of the profile has been paused. */
  Paused = 'PAUSED',
  /** The profile will be erased after a set period of time. */
  PreErase = 'PRE_ERASE'
}

/** The input type necessary for creating a profile field fragment. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type InputProfileFieldFragment = {
  /** The name of the data stored in the fragment. */
  field_name?: InputMaybe<Scalars['String']>;
  /**
   * Any arbitrary information that you would like to be stored against a profile field fragment.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  fragment_attributes?: InputMaybe<Scalars['Json']>;
  /** The value of this fragment. */
  value?: InputMaybe<Scalars['String']>;
};

/** Parameters to increase a specified interest on a profile. */
export type InterestInput = {
  /** The time of the event that altered the interest. */
  event_time: Scalars['DateTime'];
  /**
   * The list of interests whose score should be increased.
   * Note that empty strings and strings containing
   * only whitespace are ignored. If interests are not getting
   * through to a profile this could be a likely cause.
   */
  interests: Array<Scalars['String']>;
  /** The profile_ids is the unique identifier for the profile. It is used to link other resources to the profile. */
  profile_id: Scalars['UUID'];
  /** Type of event incurring the interest. */
  type: Scalars['String'];
};

export type Mer = MarketingMetric & {
  __typename?: 'MER';
  value: Scalars['NonNegativeFloat'];
  variance: ChannelReportDirectionalPercent;
};

export type MarketingMetric = {
  value: Scalars['NonNegativeFloat'];
  variance: ChannelReportDirectionalPercent;
};

export type Metadata = {
  __typename?: 'Metadata';
  created?: Maybe<Scalars['Date']>;
  pageviews?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Marks the cart with status: `'ABANDONED'`. */
  abandon_cart?: Maybe<Cart>;
  /**
   * Associates a campaign segment in Solve with a click ID from an external advertising provider like google. This updates the campaign ID of any events that are already associated with the given `ad_source_id`.
   * Note that this is an asynchronous operation. It returns `true` if the association information was accepted successfully and null with errors otherwise.
   */
  associate_ad_source?: Maybe<Scalars['Boolean']>;
  /** Adds audience(s) to an array of profiles. */
  audiences_add?: Maybe<AudiencesMutationConnection>;
  /** Removes audience(s) from the array of profiles */
  audiences_remove?: Maybe<AudiencesMutationConnection>;
  /** Call this when an order has been created for the cart. Note: Solve does not verify that the `order_id` matches an existing `Order`. */
  convert_cart?: Maybe<Cart>;
  convert_public_cart?: Maybe<PublicCart>;
  /** Creates a new API key record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_api_key?: Maybe<CreatedApiKey>;
  /** Creates a new API Policy record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_api_policy?: Maybe<ApiPolicy>;
  /** Creates a new campaign segment record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_campaign_segment?: Maybe<CampaignSegment>;
  /** Creates a new campaign segment metric record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_campaign_segment_metric?: Maybe<CampaignSegmentMetric>;
  /** Updates a campaign segment if it exists, otherwise creates it. Note that most of the time this will create a segment, since the only updatable field is `description`. */
  create_or_update_campaign_segment?: Maybe<CampaignSegment>;
  /** Updates a campaign segment metric if it exists, otherwise creates it. This mutation first checks whether there is an existing campaign segment metric with the input's id. If one exists, the campaign segment metric is updated. If not, a new campaign segment metric is created. */
  create_or_update_campaign_segment_metric?: Maybe<CampaignSegmentMetric>;
  /** Creates a new cart or updates an existing cart with the same `id`. */
  create_or_update_cart?: Maybe<Cart>;
  /**
   * Creates a new configuration or updates an existing configuration with the given `id`. Note that for updates,
   * this mutation requires an update token. For more information on update tokens see the Solve API Reference.
   * Caution: on update, properties from the old configuration that aren't contained in the new configuration are lost!
   */
  create_or_update_configuration?: Maybe<Configuration>;
  /** Updates a order if it exists, otherwise creates it. This mutation first checks whether there is an existing order with the input's id and provider. If one exists, the order is updated. If not, a new order is created. */
  create_or_update_order?: Maybe<Order>;
  /** Creates a new payment or updates an existing payment with the same `id` */
  create_or_update_payment?: Maybe<Payment>;
  /** Updates a product if it exists, otherwise creates it. This mutation first checks whether there is an existing product with the input's id and store. If one exists, the product is updated. If not, a new product is created. */
  create_or_update_product?: Maybe<Product>;
  /** Updates a profile if it exists, otherwise creates it. This mutation first checks whether there is an existing profile with the input's profile ID or other identifier (i.e email). If one exists, the profile is updated. If not, a new profile is created. */
  create_or_update_profile?: Maybe<Profile>;
  create_or_update_public_cart?: Maybe<PublicCart>;
  /** Creates a new return or updates an existing return with the same `id` */
  create_or_update_return?: Maybe<Return>;
  /** Updates a subscription if it exists, otherwise creates it. This mutation first checks whether there is an existing subscription with the input's id and store. If one exists, the subscription is updated. If not, a new subscription is created. */
  create_or_update_subscription?: Maybe<SubscriptionObject>;
  /** Creates a new order record. */
  create_order?: Maybe<Order>;
  /** Creates a new product. Products do not generate their own id so you must supply one inside the `input` for this mutation. */
  create_product?: Maybe<Product>;
  /** Creates a new saved query record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_query?: Maybe<SingleQuery>;
  /** Creates a new SDK snippet record. The ID that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_sdk_snippet?: Maybe<SdkSnippet>;
  /** Creates a new stat record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_stat?: Maybe<Stat>;
  /** Creates a new subscription record. The id and store that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_subscription?: Maybe<SubscriptionObject>;
  /** Creates a new user record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_user?: Maybe<User>;
  /** Creates a new webhook record. The id that can be retrieved back from this mutation is the primary key that be used in many other mutations for the record. */
  create_webhook?: Maybe<Webhook>;
  /** Hard deletes a API Policy record as referenced by its id. This mutation is not reversible. */
  delete_api_policy?: Maybe<ApiPolicy>;
  /** Hard deletes a campaign segment record as referenced by its id. This mutation is not reversible. */
  delete_campaign_segment?: Maybe<CampaignSegment>;
  /** Hard deletes a campaign segment metric record as referenced by its id. This mutation is not reversible. */
  delete_campaign_segment_metric?: Maybe<CampaignSegmentMetric>;
  /** Hard deletes a configuration record as referenced by its id. This mutation is not reversible. */
  delete_configuration?: Maybe<Configuration>;
  /**
   * Deletes an identifier and all its variants from a profile.
   *
   * For any given identifier (e.g., where `type == 'email'`), there are several variants. These include:
   * - Hash identifiers, e.g. `email_md5`, `email_sha256`
   * - Normalized identifiers, e.g. for uppercase or mixed-case emails, there is a "normalized", lowercase email identifier (`type` is still `email`).
   * - Hash identifiers for the normalized identifiers
   *
   * Whether you are deleting a normalized, non-normalized or hash identifier (or even a hash of a normalized identifier), all of them will be deleted.
   *
   * If the profile would end up with no identifiers, `delete_identifier` will fail.
   *
   * For more information about identifiers, please see <https://docs.solvedata.app/latest/profiles/profile-identifiers>.
   */
  delete_identifier: Array<Identifier>;
  /** Deletes an order as referenced by it's unique id and provider combination. It responds with the deleted order if successful, or null if the order didn't exist. This is a hard delete, use with caution. */
  delete_order?: Maybe<Order>;
  /** Deletes a payment as referenced by it's id. It responds with the deleted payment if successful, or null if the payment didn't exist. This is a hard delete, use with caution. */
  delete_payment?: Maybe<Payment>;
  /** Hard deletes a product record as referenced by its id and store. This mutation is not reversible. */
  delete_product?: Maybe<Product>;
  /** Hard deletes a saved query record as referenced by its id. This mutation is not reversible. */
  delete_query?: Maybe<DeletedQuery>;
  /** Deletes a return as referenced by it's unique id and provider combination. It responds with the deleted return if successful, or null if the return didn't exist. This is a hard delete, use with caution. */
  delete_return?: Maybe<Return>;
  /** Hard deletes a SDK snippet record as referenced by its ID. This mutation is not reversible. */
  delete_sdk_snippet?: Maybe<SdkSnippet>;
  /** Hard deletes a subscription record as referenced by its id and store. This mutation is not reversible.If the subscription does not exist the result is `null`. */
  delete_subscription?: Maybe<SubscriptionObject>;
  /** Hard deletes a user record as referenced by its email. This mutation is not reversible.Returns true if the update was successful, false otherwise. */
  delete_user?: Maybe<Scalars['Boolean']>;
  /**
   * Disables an existing API key record as referenced by its id.
   *     This sets the `is_archived` flag to `true` and can be flipped again by the corresponding `archive` mutation. If the API key is already archived then this will have no effect.
   */
  disable_api_key?: Maybe<ApiKey>;
  /**
   * Disables an existing webhook record as referenced by its id.
   *     This sets the `is_disabled` flag to `true` and can be flipped again by the corresponding `disable` mutation. If the webhook is already disabled then this will have no effect.
   */
  disable_webhook?: Maybe<Webhook>;
  /** Restores a currently archived API key record as referenced by its id. This sets the `is_archived` flag to `false`. If the API key is not already archived then this will have no effect. */
  enable_api_key?: Maybe<ApiKey>;
  /** Enables a currently enabled webhook record as referenced by its id. This sets the `is_disabled` flag to `false`. If the webhook is not already enabled then this will have no effect. */
  enable_webhook?: Maybe<Webhook>;
  /** Increase the score of the specified interests on the profile with the given profile ID. */
  increase_interest?: Maybe<Profile>;
  /** Queue an event */
  queue_event?: Maybe<Event>;
  refresh_campaign_report?: Maybe<ScheduleQueryResponses>;
  /** Returns true if the password reset was successful, false otherwise.Returns true if the update was successful, false otherwise. */
  reset_password?: Maybe<Scalars['Boolean']>;
  /** Schedules a query to be run via the Query Runner. */
  schedule_query?: Maybe<ScheduleQueryResponses>;
  start_shopify_plugin_install?: Maybe<StartShopifyPluginInstallPayload>;
  /** Write the updated Web SDK out. This is done by default on every change to the SDK snippets. This call should only be used after performing bulk insertion when `update_sdk` is set to `false`. */
  synchronize_sdk_snippets?: Maybe<Scalars['Boolean']>;
  /** Updates an existing API key record as referenced by its id. The input given will override any existing properties on the API key. */
  update_api_key?: Maybe<ApiKey>;
  /** Updates an existing API Policy record as referenced by its id. The input given will override any existing properties on the API Policy. */
  update_api_policy?: Maybe<ApiPolicy>;
  /** Updates an existing campaign segment record as referenced by its id. The input given will override any existing properties on the campaign segment. */
  update_campaign_segment?: Maybe<CampaignSegment>;
  /** Updates an existing campaign segment metric record as referenced by its id. The input given will override any existing properties on the campaign segment metric. */
  update_campaign_segment_metric?: Maybe<CampaignSegmentMetric>;
  /** Updates an existing order record as referenced by its id and provider. The input given will override any existing properties on the order. */
  update_order?: Maybe<Order>;
  /** Updates an existing product record as referenced by its id and store. The input given will override any existing properties on the product. */
  update_product?: Maybe<Product>;
  /** Updates an existing saved query record as referenced by its id. The input given will override any existing properties on the saved query. */
  update_query?: Maybe<SingleQuery>;
  /** Updates an existing SDK snippet record as referenced by its ID. The input given will override any existing properties on the SDK snippet. */
  update_sdk_snippet?: Maybe<SdkSnippet>;
  /**
   * Updates a single key-value pair in the configuration with the given `id`. If the key does not exist in
   * the configuration, it is created. Note that this mutation requires an update token. For more information
   * on update tokens see the Solve API Reference.
   */
  update_single_configuration_value?: Maybe<Configuration>;
  /** Updates an existing subscription record as referenced by its id and store. The input given will override any existing properties on the subscription. */
  update_subscription?: Maybe<SubscriptionObject>;
  /** Updates an existing user record as referenced by its email. The input given will override any existing properties on the user.Returns true if the update was successful, false otherwise. */
  update_user?: Maybe<Scalars['Boolean']>;
  /** Updates an existing webhook record as referenced by its id. The input given will override any existing properties on the webhook. */
  update_webhook?: Maybe<Webhook>;
};

export type MutationAbandon_CartArgs = {
  id: Scalars['String'];
  options?: InputMaybe<AbandonCartOptions>;
  provider: Scalars['String'];
};

export type MutationAssociate_Ad_SourceArgs = {
  input: AdSourceAssociation;
};

export type MutationAudiences_AddArgs = {
  audiences_add_mutation_input: AudiencesAddMutationInput;
};

export type MutationAudiences_RemoveArgs = {
  audiences_remove_mutation_input: AudiencesRemoveMutationInput;
};

export type MutationConvert_CartArgs = {
  id: Scalars['String'];
  options?: InputMaybe<ConvertCartOptions>;
  order_id?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
};

export type MutationConvert_Public_CartArgs = {
  id: Scalars['String'];
  options?: InputMaybe<PublicCartOptions>;
  order_id?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
};

export type MutationCreate_Api_KeyArgs = {
  input: ApiKeyCreateInput;
};

export type MutationCreate_Api_PolicyArgs = {
  input: ApiPolicyCreateInput;
};

export type MutationCreate_Campaign_SegmentArgs = {
  input: CampaignSegmentCreateInput;
};

export type MutationCreate_Campaign_Segment_MetricArgs = {
  input: CampaignSegmentMetricCreateInput;
};

export type MutationCreate_Or_Update_Campaign_SegmentArgs = {
  input: CampaignSegmentCreateInput;
};

export type MutationCreate_Or_Update_Campaign_Segment_MetricArgs = {
  input: CampaignSegmentMetricCreateInput;
};

export type MutationCreate_Or_Update_CartArgs = {
  input: CartInput;
  options?: InputMaybe<CreateOrUpdateCartOptions>;
};

export type MutationCreate_Or_Update_ConfigurationArgs = {
  input: ConfigurationInput;
};

export type MutationCreate_Or_Update_OrderArgs = {
  input: CreateOrUpdateOrderInput;
  options?: InputMaybe<CreateOrUpdateOrderOptions>;
};

export type MutationCreate_Or_Update_PaymentArgs = {
  input: PaymentInput;
  options?: InputMaybe<CreateOrUpdatePaymentOptions>;
};

export type MutationCreate_Or_Update_ProductArgs = {
  input: ProductInput;
};

export type MutationCreate_Or_Update_ProfileArgs = {
  input: ProfileInput;
};

export type MutationCreate_Or_Update_Public_CartArgs = {
  input: PublicCartInput;
  options?: InputMaybe<PublicCartOptions>;
};

export type MutationCreate_Or_Update_ReturnArgs = {
  input: ReturnInput;
  options?: InputMaybe<CreateOrUpdateReturnOptions>;
};

export type MutationCreate_Or_Update_SubscriptionArgs = {
  input: SubscriptionCreateInput;
  options?: InputMaybe<CreateOrUpdateSubscriptionOptions>;
};

export type MutationCreate_OrderArgs = {
  input: CreateOrderInput;
  options?: InputMaybe<CreateOrderOptions>;
};

export type MutationCreate_ProductArgs = {
  input: ProductInput;
};

export type MutationCreate_QueryArgs = {
  input: QueryCreateInput;
};

export type MutationCreate_Sdk_SnippetArgs = {
  input: SdkSnippetCreateInput;
  update_sdk: Scalars['Boolean'];
};

export type MutationCreate_StatArgs = {
  input: StatCreateInput;
};

export type MutationCreate_SubscriptionArgs = {
  input: SubscriptionCreateInput;
  options?: InputMaybe<CreateSubscriptionOptions>;
};

export type MutationCreate_UserArgs = {
  input: CreateUserInput;
};

export type MutationCreate_WebhookArgs = {
  input: WebhookInput;
};

export type MutationDelete_Api_PolicyArgs = {
  id: Scalars['ID'];
};

export type MutationDelete_Campaign_SegmentArgs = {
  id: Scalars['Int'];
};

export type MutationDelete_Campaign_Segment_MetricArgs = {
  id: Scalars['Int'];
};

export type MutationDelete_ConfigurationArgs = {
  id: Scalars['String'];
};

export type MutationDelete_IdentifierArgs = {
  key: Scalars['String'];
  profile_id: Scalars['UUID'];
  type: Scalars['String'];
};

export type MutationDelete_OrderArgs = {
  id: Scalars['String'];
  options?: InputMaybe<DeleteOrderOptions>;
  provider: Scalars['String'];
};

export type MutationDelete_PaymentArgs = {
  id: Scalars['String'];
  options?: InputMaybe<DeletePaymentOptions>;
};

export type MutationDelete_ProductArgs = {
  id: Scalars['String'];
  store: Scalars['String'];
};

export type MutationDelete_QueryArgs = {
  id: Scalars['Int'];
};

export type MutationDelete_ReturnArgs = {
  id: Scalars['String'];
  options?: InputMaybe<DeleteReturnOptions>;
  provider: Scalars['String'];
};

export type MutationDelete_Sdk_SnippetArgs = {
  id: Scalars['Int'];
  update_sdk: Scalars['Boolean'];
};

export type MutationDelete_SubscriptionArgs = {
  id: Scalars['String'];
  options?: InputMaybe<DeleteSubscriptionOptions>;
  store: Scalars['String'];
};

export type MutationDelete_UserArgs = {
  email: Scalars['Email'];
};

export type MutationDisable_Api_KeyArgs = {
  id: Scalars['Int'];
};

export type MutationDisable_WebhookArgs = {
  id: Scalars['Int'];
};

export type MutationEnable_Api_KeyArgs = {
  id: Scalars['Int'];
};

export type MutationEnable_WebhookArgs = {
  id: Scalars['Int'];
};

export type MutationIncrease_InterestArgs = {
  input: InterestInput;
};

export type MutationQueue_EventArgs = {
  input: EventInput;
};

export type MutationRefresh_Campaign_ReportArgs = {
  type: CampaignReportType;
};

export type MutationReset_PasswordArgs = {
  email: Scalars['Email'];
};

export type MutationSchedule_QueryArgs = {
  id: Scalars['Int'];
  input: ScheduledQueryInput;
};

export type MutationStart_Shopify_Plugin_InstallArgs = {
  input: ShopifyInstallInput;
};

export type MutationUpdate_Api_KeyArgs = {
  id: Scalars['Int'];
  input: ApiKeyUpdateInput;
};

export type MutationUpdate_Api_PolicyArgs = {
  id: Scalars['ID'];
  input: ApiPolicyUpdateInput;
};

export type MutationUpdate_Campaign_SegmentArgs = {
  id: Scalars['Int'];
  input: CampaignSegmentUpdateInput;
};

export type MutationUpdate_Campaign_Segment_MetricArgs = {
  id: Scalars['Int'];
  input: CampaignSegmentMetricUpdateInput;
};

export type MutationUpdate_OrderArgs = {
  id: Scalars['String'];
  input: UpdateOrderInput;
  options?: InputMaybe<UpdateOrderOptions>;
  provider: Scalars['String'];
};

export type MutationUpdate_ProductArgs = {
  id: Scalars['String'];
  input: UpdateProductInput;
  store: Scalars['String'];
};

export type MutationUpdate_QueryArgs = {
  id: Scalars['Int'];
  input: QueryUpdateInput;
};

export type MutationUpdate_Sdk_SnippetArgs = {
  id: Scalars['Int'];
  input: SdkSnippetUpdateInput;
  update_sdk: Scalars['Boolean'];
};

export type MutationUpdate_Single_Configuration_ValueArgs = {
  id: Scalars['String'];
  key: Scalars['String'];
  update_token: Scalars['String'];
  value: Scalars['ConfigurationValue'];
};

export type MutationUpdate_SubscriptionArgs = {
  id: Scalars['String'];
  input: SubscriptionUpdateInput;
  options?: InputMaybe<UpdateSubscriptionOptions>;
  store: Scalars['String'];
};

export type MutationUpdate_UserArgs = {
  email: Scalars['Email'];
  input: UpdateUserInput;
};

export type MutationUpdate_WebhookArgs = {
  id: Scalars['Int'];
  input: WebhookInput;
};

/** A single order record. It is created using the `create_order` mutation. */
export type Order = {
  __typename?: 'Order';
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
  adjustments?: Maybe<Array<Adjustment>>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** Returns a list of the Campaign Segments that lead up to this particular order with this particular Order, ordered by first-seen first */
  attribution: CampaignSegmentConnection;
  /** The billing address, if any, for this order. */
  billing_address?: Maybe<Address>;
  /** The marketing channel that was used that ended up in the order being created. Example: WEB. */
  channel?: Maybe<Channel>;
  /** The time between first_seen and the first order time in days */
  conversion_period?: Maybe<Scalars['Int']>;
  /** The datetime that this order record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency: Currency;
  /** A list of discount codes that were used on this order. */
  discount_codes: Array<Scalars['String']>;
  /**
   * Summary describing the overall fulfillment status of an order.
   * This field is calculated automatically by looking at the status of each fulfillment on an order.
   * This summary describes the fulfillment status from the customer's point of view, i.e., it is `FULFILLED` when all of the fulfillments have been `DELIVERED`.
   * It is therefore not a fulfillment status from the shop's point of view (where an order may be fulfilled before all items are delivered).
   * See the types `Fulfillment` and `FulfillmentStatus` for more information about fulfillments.
   */
  fulfillment_status: OrderFulfillmentStatus;
  /** Fulfillments associated with this order. See the types `Fulfillment` and `FulfillmentStatus` for more information. */
  fulfillments: Array<Fulfillment>;
  /** The ID of the order (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** The datetime that this order record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Purchasable items in the order. */
  items: Array<OrderItem>;
  /** Optional free form notes related to the order. */
  notes?: Maybe<Scalars['String']>;
  /** The sum of all orders from first_seen to this order */
  order_count?: Maybe<Scalars['Int']>;
  /** Deprecated. The payment status is now automatically calculated. Please do not use. */
  payment_status: PaymentStatus;
  /** Returns all payments associated with this order. This query returns a `connection` object which will have the payments as a list contained within the `nodes` property. */
  payments: PaymentConnection;
  /** The previous order created_at time */
  previous_order_created_at?: Maybe<Scalars['DateTime']>;
  /** The profile record to which this order belongs to. */
  profile?: Maybe<Profile>;
  /**
   * The id of the profile that the order belongs to , if known.The profile_id of the profile that this order belongs to. This is optional for where the order has been made by an unknown profile.
   * If the `profile_id` is not set when creating an order, then it is taken from this order's corresponding cart, if it exists.
   * When updating the order's `profile_id` to a non-null value, the corresponding cart and any corresponding returns will have their `profile_id` set to the same value.
   * If the profile_id has already been set, then the profile_id will not be changed.
   */
  profile_id?: Maybe<Scalars['UUID']>;
  /** Source store of this order (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** The sum value of all gift cards purchased in this order. This is automatically calculated. */
  purchased_gift_card_amount?: Maybe<Scalars['Money']>;
  /** The sum value of all gift cards purchased in this order. This is automatically calculated. Represented in minor units. */
  purchased_gift_card_amount_minor?: Maybe<Scalars['Money']>;
  /** The current refund status of the order. This is automatically generated from any returns and payments against the order. */
  refund_status: RefundStatus;
  /** Returns all returns associated with this order. This query returns a `connection` object which will have the returns as a list contained within the `nodes` property. */
  returns: ReturnConnection;
  /** The sum of all sessions from last order to this order */
  session_count?: Maybe<Scalars['Int']>;
  /** The session id of this order. */
  session_id?: Maybe<Scalars['String']>;
  /** The shipping address, if any, for this order. Can be different from the billing address. */
  shipping_address?: Maybe<Address>;
  /** Deprecated. Please use `profile_id`. */
  sid?: Maybe<Scalars['UUID']>;
  /** The marketing attribution source that was used that ended up in this order being created. */
  source?: Maybe<Scalars['String']>;
  /** The current status of the order. Default value is 'CREATED'. */
  status: Status;
  /** Deprecated. Has been made redundant. Please use `provider` instead */
  store_identifier?: Maybe<Scalars['String']>;
  /** The id of the subscription associated with the order. This value is `null` if the order does not belong to a subscription. */
  subscription_id?: Maybe<Scalars['String']>;
  /**
   * The subscription type of the order. Default value is 'NOT_APPLICABLE'.
   *
   * Only certain values of `subscription_type` are allowed based on the `type`. See the description of `type` for the set of various combinations.
   */
  subscription_type?: Maybe<OrderSubscriptionType>;
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. */
  subtotal_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. Represented in minor units. */
  subtotal_price_minor: Scalars['Int'];
  /** A list of tags for this order. */
  tags: Array<Scalars['String']>;
  /**
   * The amount left for the customer to pay. This is automatically calculated.
   *   If the `status` is `CANCELED`, then `total_due` is 0.
   *   Otherwise, it is `total_price - total_paid`.
   */
  total_due?: Maybe<Scalars['Money']>;
  /**
   * The amount left for the customer to pay. This is automatically calculated.
   *   If the `status` is `CANCELED`, then `total_due` is 0.
   *   Otherwise, it is `total_price - total_paid`. Represented in minor units.
   */
  total_due_minor?: Maybe<Scalars['Money']>;
  /** The sum of all the quantities in each item on the Order. */
  total_item_quantity?: Maybe<Scalars['Float']>;
  /** The sum of the associated payments' `amounts`s. This is automatically calculated. */
  total_paid?: Maybe<Scalars['Money']>;
  /** The sum of the associated payments' `amounts`s. This is automatically calculated. Represented in minor units. */
  total_paid_minor?: Maybe<Scalars['Money']>;
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. */
  total_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. Represented in minor units. */
  total_price_minor: Scalars['Int'];
  /** The sum of the associated returns' `price`s. This is automatically calculated. */
  total_refunded?: Maybe<Scalars['Money']>;
  /** The sum of the associated returns' `price`s. This is automatically calculated. Represented in minor units. */
  total_refunded_minor?: Maybe<Scalars['Money']>;
  /** The net paid amount: `total_paid - total_refunded - purchased_gift_card_amount`. This is automatically calculated. */
  total_revenue?: Maybe<Scalars['Money']>;
  /** The net paid amount: `total_paid - total_refunded - purchased_gift_card_amount`. This is automatically calculated. Represented in minor units. */
  total_revenue_minor?: Maybe<Scalars['Money']>;
  /**
   * The type of the order. Default value is 'STANDARD'.
   *
   *   The `type` and `subscription_type` are only allowed certain combinations. Valid combinations are the following:
   *
   *   | `type`         | `subscription_type` |
   *   |----------------|---------------------|
   *   | `STANDARD`     | `NOT_APPLICABLE`    |
   *   | `SUBSCRIPTION` | `FIRST_TIME`        |
   *   | `SUBSCRIPTION` | `ONE_OFF`           |
   *   | `SUBSCRIPTION` | `RECURRING`         |
   *   | `SUBSCRIPTION` | `UNKNOWN`           |
   *   | `ERASED`       | `ERASED`            |
   *
   *   Note that `STANDARD` order type can only have a `subscription_type` of `NOT_APPLICABLE`, whereas
   *   `SUBSCRIPTION` order `type` can never have a `subscription_type` of `NOT_APPLICABLE`.
   *
   *   Note that an erased order will have a `type` and `subscription_type` of `ERASED`. However, the GraphQL API does not
   *   allow the explicit setting of order `type`/`subscription_type` values to `ERASED`.
   */
  type?: Maybe<OrderType>;
  /** The datetime that this order record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple orders. The `nodes` property contains all the orders themselves, while the `page_info` property contains all information used for pagination. */
export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** A list of orders. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Order>;
  /** All the paginiation info that you will need for reference when navigating through a large list of orders. */
  page_info: PageInfo;
};

/**
 * Summary describing the overall fulfillment status of an order.
 * This field is calculated automatically by looking at the status of each fulfillment on an order.
 * This summary describes the fulfillment status from the customer's point of view, i.e., it is `FULFILLED` when all of the fulfillments have been `DELIVERED`.
 * It is therefore not a fulfillment status from the shop's point of view (where an order may be fulfilled before all items are delivered).
 * See the types `Fulfillment` and `FulfillmentStatus` for more information about fulfillments.
 *
 *
 * The following rules describe how the field is calculated (in top-down order):
 * - If all of the fulfillments on the order are `DELIVERED`, the order `fulfillment_status` is `FULFILLED`.
 * - If any of the fulfillments are `FAILED`, the order `fulfillment_status` is `FAILED`.
 * - If one or more fulfillments on the order are `DELIVERED` and the remaining are `PENDING`, then the order `fulfillment_status` is `PARTIALLY_FULFILLED`
 * - In any other case, the order `fulfillment_status` is `PENDING`. This includes the case where there are no fulfillments on the order.
 */
export enum OrderFulfillmentStatus {
  /** One or more fulfillments have a status of "FAILED" */
  Failed = 'FAILED',
  /** Every item in this order has been "FULFILLED". */
  Fulfilled = 'FULFILLED',
  /** At least one item in the order has been "FULFILLED", but not all of them. */
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  /** No part of the order has been "FULFILLED". */
  Pending = 'PENDING'
}

/** Purchasable items in the order, cart, or return. */
export type OrderItem = {
  __typename?: 'OrderItem';
  /** An optional free form description of the product that the customer would have seen when placing the order. */
  description?: Maybe<Scalars['String']>;
  /** `true` if this order item is a gift card, false otherwise */
  is_gift_card?: Maybe<Scalars['Boolean']>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  item_attributes?: Maybe<Scalars['Json']>;
  /** The price of this item at time of order. Does not include any tax. */
  price: Scalars['Money'];
  /** The price of this item at time of order. Does not include any tax. Represented in minor units. */
  price_minor: Scalars['Money'];
  /**
   * The product that corresponds to this order item.
   * Solve attempts to find a product with the same `product_id` and `store` (the parent entity's `provider` is used as the `store` value).
   * If this is not found, Solve attempts to find the product with the same `sku` and `store`, with the most recent `updated_at`.
   * It is possible for this to be null if the products table does not contain the corresponding product.
   *
   * NOTE: This will find the current product data, not the data that was available at the time this order, return, or cart was created.
   */
  product?: Maybe<Product>;
  /**
   * The id of the product for this item.
   * Should correspond to the id of a product already in Solve.
   * Note that the parent entity's `provider` should be the same as the product's `store`.
   * This corresponds to the non-variant product.
   * See `Product.is_variant` for more information about variants.
   */
  product_id?: Maybe<Scalars['String']>;
  /** How many of this item have been ordered. */
  quantity: Scalars['Float'];
  /** The SKU, if any, of the item that has been ordered. */
  sku?: Maybe<Scalars['String']>;
  /** The title of this product. This is the title that the customer would have seen when placing the order. */
  title?: Maybe<Scalars['String']>;
  /** The id of the variant product. See `Product.is_variant` for more information about variants. */
  variant_id?: Maybe<Scalars['String']>;
  /** The title of the variant product. See `Product.is_variant` for more information about variants. */
  variant_title?: Maybe<Scalars['String']>;
};

/** Purchasable items in the order, cart, or return. */
export type OrderItemInput = {
  /** An optional free form description of the product that the customer would have seen when placing the order. */
  description?: InputMaybe<Scalars['String']>;
  /** Set this to `true` if this order item is a gift card. Defaults to `false`. */
  is_gift_card?: InputMaybe<Scalars['Boolean']>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  item_attributes?: InputMaybe<Scalars['Json']>;
  /** The price of this item at time of order. Does not include any tax. */
  price: Scalars['Money'];
  /**
   * The id of the product for this item.
   * Should correspond to the id of a product already in Solve.
   * Note that the parent entity's `provider` should be the same as the product's `store`.
   * This corresponds to the non-variant product.
   * See `Product.is_variant` for more information about variants.
   */
  product_id?: InputMaybe<Scalars['String']>;
  /** How many of this item have been ordered. */
  quantity: Scalars['Float'];
  /** The SKU, if any, of the item that has been ordered. */
  sku?: InputMaybe<Scalars['String']>;
  /** The title of this product. This is the title that the customer would have seen when placing the order. */
  title?: InputMaybe<Scalars['String']>;
  /** The id of the variant product. See `Product.is_variant` for more information about variants. */
  variant_id?: InputMaybe<Scalars['String']>;
  /** The title of the variant product. See `Product.is_variant` for more information about variants. */
  variant_title?: InputMaybe<Scalars['String']>;
};

/**
 * The subscription type of the order. Default value is 'NOT_APPLICABLE'.
 *
 * Only certain values of `subscription_type` are allowed based on the `type`. See the description of `type` for the set of various combinations.
 */
export enum OrderSubscriptionType {
  /** The order has been erased. No subscription type information is retained. */
  Erased = 'ERASED',
  /** The first order due to a subscription. */
  FirstTime = 'FIRST_TIME',
  /** A standard (non-subscription) order. */
  NotApplicable = 'NOT_APPLICABLE',
  /** A one-off subscription order. */
  OneOff = 'ONE_OFF',
  /** A recurring subscription order. */
  Recurring = 'RECURRING',
  /** A subscription order of unknown subscription type. */
  Unknown = 'UNKNOWN'
}

/**
 * The type of the order. Default value is 'STANDARD'.
 *
 *   The `type` and `subscription_type` are only allowed certain combinations. Valid combinations are the following:
 *
 *   | `type`         | `subscription_type` |
 *   |----------------|---------------------|
 *   | `STANDARD`     | `NOT_APPLICABLE`    |
 *   | `SUBSCRIPTION` | `FIRST_TIME`        |
 *   | `SUBSCRIPTION` | `ONE_OFF`           |
 *   | `SUBSCRIPTION` | `RECURRING`         |
 *   | `SUBSCRIPTION` | `UNKNOWN`           |
 *   | `ERASED`       | `ERASED`            |
 *
 *   Note that `STANDARD` order type can only have a `subscription_type` of `NOT_APPLICABLE`, whereas
 *   `SUBSCRIPTION` order `type` can never have a `subscription_type` of `NOT_APPLICABLE`.
 *
 *   Note that an erased order will have a `type` and `subscription_type` of `ERASED`. However, the GraphQL API does not
 *   allow the explicit setting of order `type`/`subscription_type` values to `ERASED`.
 */
export enum OrderType {
  /** The order has been erased. No type information is retained. */
  Erased = 'ERASED',
  /** A standard order. */
  Standard = 'STANDARD',
  /** The order is due to a subscription. */
  Subscription = 'SUBSCRIPTION'
}

/** The sort order that can be applied when retrieving orders. */
export enum OrdersOrderBy {
  /** Sort the orders by the datetime that they were placed at, from oldest to newest. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort the orders by the datetime that they were placed at, from newest to oldest. */
  CreatedAtDesc = 'CREATED_AT_DESC',
  FulfillmentStatusAsc = 'FULFILLMENT_STATUS_ASC',
  FulfillmentStatusDesc = 'FULFILLMENT_STATUS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PaymentStatusAsc = 'PAYMENT_STATUS_ASC',
  PaymentStatusDesc = 'PAYMENT_STATUS_DESC',
  TotalDueAsc = 'TOTAL_DUE_ASC',
  TotalDueDesc = 'TOTAL_DUE_DESC',
  TotalPriceAsc = 'TOTAL_PRICE_ASC',
  TotalPriceDesc = 'TOTAL_PRICE_DESC',
  TotalRevenueAsc = 'TOTAL_REVENUE_ASC',
  TotalRevenueDesc = 'TOTAL_REVENUE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  /** Sort the orders by the datetime that they were last updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the orders by the datetime that they were last updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type OrganicChannelRevenueBreakdown = {
  /** User facing label for the channel */
  channel_label: Scalars['String'];
  /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
  new_revenue?: Maybe<ChannelReportMonetary>;
  /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
  returning_revenue?: Maybe<ChannelReportMonetary>;
};

export type OrganicSearchRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'OrganicSearchRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

export type OrganicSocialRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'OrganicSocialRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

export type PageInfo = {
  __typename?: 'PageInfo';
  /** A representation of the end of the current set of results. */
  end_cursor: Scalars['Cursor'];
  /** Whether or not there is another page of results. */
  has_next_page: Scalars['Boolean'];
  /** The 1-based index of the page. */
  page_index?: Maybe<Scalars['Int']>;
};

export type PaidChannelRevenueBreakdown = {
  /** Total ad spend for channel */
  ad_spend?: Maybe<ChannelReportMonetary>;
  /** User facing label for the channel */
  channel_label: Scalars['String'];
  /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
  new_revenue?: Maybe<ChannelReportMonetary>;
  /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
  returning_revenue?: Maybe<ChannelReportMonetary>;
  /** Return on ad spend for channel */
  roas: Scalars['Float'];
  /** Return on investment for channel */
  roi?: Maybe<ChannelReportMonetary>;
};

export type PaidSearchRevenueBreakdown = ChannelRevenueBreakdownBase &
  PaidChannelRevenueBreakdown & {
    __typename?: 'PaidSearchRevenueBreakdown';
    /** Total ad spend for channel */
    ad_spend?: Maybe<ChannelReportMonetary>;
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
    /** Return on ad spend for channel */
    roas: Scalars['Float'];
    /** Return on investment for channel */
    roi?: Maybe<ChannelReportMonetary>;
  };

export type PaidSocialRevenueBreakdown = ChannelRevenueBreakdownBase &
  PaidChannelRevenueBreakdown & {
    __typename?: 'PaidSocialRevenueBreakdown';
    /** Total ad spend for channel */
    ad_spend?: Maybe<ChannelReportMonetary>;
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
    /** Return on ad spend for channel */
    roas: Scalars['Float'];
    /** Return on investment for channel */
    roi?: Maybe<ChannelReportMonetary>;
  };

/** Payment */
export type Payment = {
  __typename?: 'Payment';
  /** The monetary value of a payment. The currency for this value is inherited from the payment's `Order`. Must be positive. */
  amount: Scalars['Money'];
  /** The monetary value of a payment. The currency for this value is inherited from the payment's `Order`. Must be positive. Represented in minor units. */
  amount_minor: Scalars['Money'];
  /**
   * Any arbitrary information that you would like to be stored against a payment.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** The datetime that this payment record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** The currency this payment was made with, inherited from the payment's order */
  currency?: Maybe<Scalars['String']>;
  /** The ID of the payment (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** The datetime that this payment record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** An optional description for a payment`. */
  notes?: Maybe<Scalars['String']>;
  /** The order that this payment belongs to. */
  order?: Maybe<Order>;
  /** The id of this `Payment`'s corresponding `Order`. */
  order_id?: Maybe<Scalars['String']>;
  /** Source store of this payment (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** The datetime that this payment record was last modified. */
  updated_at: Scalars['DateTime'];
};

export type PaymentConnection = {
  __typename?: 'PaymentConnection';
  nodes: Array<Payment>;
  page_info: PageInfo;
};

/** Parameters to create or update a payment. */
export type PaymentInput = {
  /** The monetary value of a payment. The currency for this value is inherited from the payment's `Order`. Must be positive. */
  amount: Scalars['Money'];
  /**
   * Any arbitrary information that you would like to be stored against a payment.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The datetime that this payment record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** The ID of the payment (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** An optional description for a payment`. */
  notes?: InputMaybe<Scalars['String']>;
  /** The id of this `Payment`'s corresponding `Order`. */
  order_id: Scalars['String'];
  /** Source store of this payment (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
};

/** The current payment status of the order. This is automatically generated from any payments against the order. */
export enum PaymentStatus {
  /** The order payment was canceled before it could be completed. */
  Canceled = 'CANCELED',
  /** The order has been fully paid. */
  Paid = 'PAID',
  /** The order has at least one payment, but the total payment values are less than the total cost of the order. */
  PartiallyPaid = 'PARTIALLY_PAID',
  /** The order is yet to receive any payment. */
  Pending = 'PENDING'
}

/** The order that the `payments` query can by sorted by */
export enum PaymentsOrderBy {
  /** Sort the payments by the datetime that they were created at, from oldest to newest. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort the payments by the datetime that they were created at, from newest to oldest. */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort the payments by the datetime that they were last updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the payments by the datetime that they were last updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PluginIntegration = {
  __typename?: 'PluginIntegration';
  available_integrations: Array<Maybe<AvailablePluginIntegrations>>;
  enabled_integrations: Array<Maybe<PluginIntegrationUnion>>;
};

/** The union type current integration enabled */
export type PluginIntegrationUnion = ShopifyPluginIntegration;

/** Name of the integration */
export enum PluginName {
  /** Facebook */
  Facebook = 'FACEBOOK',
  /** Shopify */
  Shopify = 'SHOPIFY'
}

/** The profile `processing_state` type. Determines how events are linked to the profile as per <https://docs.solvedata.app/latest/profiles/profile-processing-states>. */
export enum ProcessingState {
  /** The profile is active. */
  Active = 'ACTIVE',
  /** The profile has been deactivated (made dormant) and will be erased after a set period of time. */
  Dormant = 'DORMANT',
  /** The profile has been erased and contains minimal data. */
  Erased = 'ERASED',
  /** The profile and associated data is currently being erased. */
  Erasing = 'ERASING',
  /** Processing of the profile has been paused. */
  Paused = 'PAUSED',
  /** The profile will be erased after a set period of time. */
  PreErase = 'PRE_ERASE'
}

/** A single product that may be cross referenced to products you are selling via your ecommerce provider. */
export type Product = {
  __typename?: 'Product';
  /** If this product is being used in Google Ads, this is the absolute URL that the ad would take them. This can be different to `link` or `mobile_link`. */
  ad_link?: Maybe<Scalars['String']>;
  /** What age group, if any, this product is suitable for. */
  age_group?: Maybe<AgeGroup>;
  /**
   * Any arbitrary information that you would like to be stored against a product.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** The availability status of this product. */
  availability?: Maybe<ProductAvailability>;
  /** An array of images for this product. */
  brand?: Maybe<Scalars['String']>;
  /** For Google Ads, what product category from Google's product taxonomy this product sits under. */
  category?: Maybe<Scalars['String']>;
  /** The primary color of this product. Solve does not support multiple colors per product. */
  color?: Maybe<Scalars['String']>;
  /** What condition this product is expected to be in */
  condition?: Maybe<ProductCondition>;
  /** The cost of goods sole (COGS) for this product to help with reporting, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  cost_of_goods_sold?: Maybe<Scalars['String']>;
  /** An optional free form description of the product. */
  description?: Maybe<Scalars['String']>;
  /** The energy efficiency class of this product. */
  energy_efficiency_class?: Maybe<EnergyEfficiencyClass>;
  /** For Google Ads, this can be used to prevent this product from participating in certain types of ads. */
  excluded_destination?: Maybe<Scalars['String']>;
  /** The expiry date of this product, if any. */
  expiration_date?: Maybe<Scalars['DateTime']>;
  /** What gender, if any, this product is suitable for. This is a free form string. */
  gender?: Maybe<Scalars['String']>;
  /** The Global Trade Item Numbers (GTIN). A GTIN uniquely identifies your product. */
  gtin?: Maybe<Scalars['String']>;
  /** The id of this product as set in your ecommerce provider. This is not generated by Solve and needs to be provided. */
  id: Scalars['String'];
  /** An array of images for this product. */
  images?: Maybe<Array<Maybe<ProductImage>>>;
  /** For Google Ads, this can be used to ensure this product only participates in certain types of ads. */
  included_destination?: Maybe<Scalars['String']>;
  /** The datetime that this product record was inserted into the database. */
  inserted_at?: Maybe<Scalars['DateTime']>;
  /** If this product can be paid for installments, this defines how much per month a purchaser can expect to pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  installment_amount?: Maybe<Scalars['String']>;
  /** If this product can be paid for installments, this defines how many months a purchaser can expect to be paying. */
  installment_months?: Maybe<Scalars['Int']>;
  /** How many of this product you have in stock. */
  inventory?: Maybe<Scalars['Int']>;
  /** Whether this is an adults only product. */
  is_adult?: Maybe<Scalars['Boolean']>;
  /** Indicates that you’ve created a bundle: a main product that you’ve grouped with other, different products, sold together as one package for a single price. */
  is_bundle?: Maybe<Scalars['Boolean']>;
  /**
   * Set to `false` if this product is a base product, `true` if this product is a variant of a base product.
   * Variants would include different size and colour combinations of the base product.
   * Variants have a `variant_group_id` which is the `id` of their corresponding base product.
   */
  is_variant?: Maybe<Scalars['Boolean']>;
  /** This determines the date the product becomes available. */
  launch_date?: Maybe<Scalars['DateTime']>;
  /** An absolute URL for this product. */
  link?: Maybe<Scalars['String']>;
  /** The name of any loyalty points program. */
  loyalty_points_name?: Maybe<Scalars['String']>;
  /** The ratio of earned points to currency. */
  loyalty_points_ratio?: Maybe<Scalars['Float']>;
  /** The number of points that this product gives the purchaser under the relevant loyalty points program. */
  loyalty_points_value?: Maybe<Scalars['Float']>;
  /** The primary material of this product. Solve does not support multiple materials per product. */
  material?: Maybe<Scalars['String']>;
  /** The maximum energy efficiency class of this product. */
  max_energy_efficiency_class?: Maybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the largest possible timespan between purchasing and shipment. */
  max_handling_time?: Maybe<Scalars['String']>;
  /** The minimum energy efficiency class of this product. */
  min_energy_efficiency_class?: Maybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the smallest possible timespan between purchasing and shipment. */
  min_handling_time?: Maybe<Scalars['String']>;
  /** An absolute URL for this product for mobile devices. */
  mobile_link?: Maybe<Scalars['String']>;
  /** The Manufacturer Part Number (MPN). MPNs are used to uniquely identify a specific product among all products from the same manufacturer. */
  mpn?: Maybe<Scalars['String']>;
  /** Indicates that you have grouped multiple products together for sale. */
  multipack?: Maybe<Scalars['Int']>;
  /** How much this product costs during a one off special offer. An offer can exist for a product at the same time as a sale. */
  offer_price?: Maybe<Scalars['String']>;
  /** The end of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_end_date?: Maybe<Scalars['DateTime']>;
  /** The start of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_start_date?: Maybe<Scalars['DateTime']>;
  /** The primary display pattern of this product. Solve does not support multiple patterns per product. */
  pattern?: Maybe<Scalars['String']>;
  /** The selling price of this product, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  price?: Maybe<Scalars['String']>;
  /** If this product uses Google's Merchant Promotions, this can be used in both your product data and promotions data to match products to promotions. */
  promotion_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An optional free form rich text description of the product. As opposed to just the `description`, here you can include control characters and HTML tags, such as line breaks, italics, and bulleted lists. */
  rich_text_description?: Maybe<Scalars['String']>;
  /** How much this product costs during a sale. A product can be on sale the same time it has a special offer. */
  sale_price?: Maybe<Scalars['String']>;
  /** The end of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_end_date?: Maybe<Scalars['DateTime']>;
  /** The start of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_start_date?: Maybe<Scalars['DateTime']>;
  /** For use in Google Ads. Separate from `inventory`, this is how many of this product you will sell via Google Ads. */
  sell_on_google_quantity?: Maybe<Scalars['Int']>;
  /** All available shipping options for this product. */
  shipping?: Maybe<Array<Maybe<ProductShipping>>>;
  /** The height of the product when shipping. */
  shipping_height?: Maybe<Scalars['String']>;
  /** This allows the grouping of multiple products together under the same shipping rates. For example, `oversized`. */
  shipping_label?: Maybe<Scalars['String']>;
  /** The weight of the product when shipping. */
  shipping_weight?: Maybe<Scalars['String']>;
  /** The width of the product when shipping. */
  shipping_width?: Maybe<Scalars['String']>;
  /** The size of the product. Used in conjunction with `size_system`. For example, `xl`. */
  size?: Maybe<Scalars['String']>;
  /** Describes which country's size system this product uses. */
  size_system?: Maybe<SizeSystem>;
  /** The size type can be used to describe the cut of the product. Used in conjunction with `size_system`. For example, `regular`. */
  size_type?: Maybe<Scalars['String']>;
  /** The Stock Keeping Unit for the product. This is optional. It is not required to be unique among all products saved in Solve. */
  sku?: Maybe<Scalars['String']>;
  /** The store or catalog this product is part of. It will be used to differentiate products with the same SKU or ID across different regions, catalogs or stores (for example, if the description is different between a UK and US store). If this is not given it will be set to `default`. */
  store: Scalars['String'];
  /** If this product is a subscription, this defines how much per period the purchaser must pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  subscription_cost_amount?: Maybe<Scalars['String']>;
  /** If this product is a subscription, this defines what that period is. */
  subscription_cost_period?: Maybe<SubscriptionCostPeriod>;
  /** If this product is a subscription, this defines the number of subscription periods that the purchaser must pay */
  subscription_cost_period_length?: Maybe<Scalars['Int']>;
  /** An array of tags related to this product. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Used if you want to set different tax rates for specific groups of products. For example, 'perishable_foods' or 'clothing'. */
  tax_category?: Maybe<Scalars['String']>;
  /** Any taxes applicable to this product. */
  taxes?: Maybe<Array<Maybe<ProductTax>>>;
  /** The title of this product. This is the title that you would display to your customers. */
  title: Scalars['String'];
  /** The transit time labels help give your customers a more accurate estimated delivery time. */
  transit_time_label?: Maybe<Scalars['String']>;
  /** What type of product this is. This is free form and not restricted to any particular values. */
  type?: Maybe<Scalars['String']>;
  /** This defines the denominator for your unit price. For example, you might be selling 150ml of perfume, but users are interested in seeing the price per 100ml. */
  unit_price_base_measure?: Maybe<Scalars['String']>;
  /** This defines the measure and dimension of the product. This value allows users to understand the exact cost per unit for your product. For example, 750ml. */
  unit_price_measure?: Maybe<Scalars['String']>;
  /** The datetime that this product record was last modified. */
  updated_at?: Maybe<Scalars['DateTime']>;
  /** For a variant, it is set to the `id` of the base product. `Is `null` for a base product. */
  variant_group_id?: Maybe<Scalars['String']>;
  /** The visibility status of this product. */
  visibility?: Maybe<ProductVisibility>;
};

/** Availability options. Used to confirm whether a product is out of stock. */
export enum ProductAvailability {
  /** Orders for this product are accepted and can be fulfilled within 1-2 weeks. */
  AvailableForOrder = 'AVAILABLE_FOR_ORDER',
  Discontinued = 'DISCONTINUED',
  /** Orders for this product are accepted and can be fulfilled immediately. */
  InStock = 'IN_STOCK',
  /** There is no stock for this product so the product cannot be ordered.. */
  OutOfStock = 'OUT_OF_STOCK',
  /** Orders for this product are accepted and can be fulfilled when the product becomes available. */
  Preorder = 'PREORDER'
}

/** Defines what condition the product is in. */
export enum ProductCondition {
  /** The product is new an unused. */
  New = 'NEW',
  /** The product is used but has been refurbished to a like-new state. */
  Refurbished = 'REFURBISHED',
  /** The product has been used and is in an unknown state. */
  Used = 'USED',
  /** The product has been used and is in a fair state. */
  UsedFair = 'USED_FAIR',
  /** The product has been used and is in a good state. */
  UsedGood = 'USED_GOOD',
  /** The product has been used and is a like-new state. */
  UsedLikeNew = 'USED_LIKE_NEW'
}

/** The return object for multiple products. The `nodes` property contains all the products themselves, while the `page_info` property contains all information used for pagination. */
export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of products. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Product>;
  /** All the paginiation info that you will need for reference when navigating through a large list of products. */
  page_info: PageInfo;
};

/** An image that may be used to show users what the product looks like. */
export type ProductImage = {
  __typename?: 'ProductImage';
  /** What type of image this is. For example, `thumbnail` or `mobile`. */
  type?: Maybe<Scalars['String']>;
  /** The URL for this image. */
  url?: Maybe<Scalars['String']>;
};

/** The input type necessary for defining product images. */
export type ProductImageInput = {
  /** What type of image this is. For example, `thumbnail` or `mobile`. */
  type?: InputMaybe<Scalars['String']>;
  /** The URL for this image. */
  url?: InputMaybe<Scalars['String']>;
};

/** Parameters to create a new product */
export type ProductInput = {
  /** If this product is being used in Google Ads, this is the absolute URL that the ad would take them. This can be different to `link` or `mobile_link`. */
  ad_link?: InputMaybe<Scalars['String']>;
  /** What age group, if any, this product is suitable for. */
  age_group?: InputMaybe<AgeGroup>;
  /**
   * Any arbitrary information that you would like to be stored against a product.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The availability status of this product. */
  availability?: InputMaybe<ProductAvailability>;
  /** An array of images for this product. */
  brand?: InputMaybe<Scalars['String']>;
  /** For Google Ads, what product category from Google's product taxonomy this product sits under. */
  category?: InputMaybe<Scalars['String']>;
  /** The primary color of this product. Solve does not support multiple colors per product. */
  color?: InputMaybe<Scalars['String']>;
  /** What condition this product is expected to be in */
  condition?: InputMaybe<ProductCondition>;
  /** The cost of goods sole (COGS) for this product to help with reporting, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  cost_of_goods_sold?: InputMaybe<Scalars['String']>;
  /** An optional free form description of the product. */
  description?: InputMaybe<Scalars['String']>;
  /** The energy efficiency class of this product. */
  energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** For Google Ads, this can be used to prevent this product from participating in certain types of ads. */
  excluded_destination?: InputMaybe<Scalars['String']>;
  /** The expiry date of this product, if any. */
  expiration_date?: InputMaybe<Scalars['DateTime']>;
  /** What gender, if any, this product is suitable for. This is a free form string. */
  gender?: InputMaybe<Scalars['String']>;
  /** The Global Trade Item Numbers (GTIN). A GTIN uniquely identifies your product. */
  gtin?: InputMaybe<Scalars['String']>;
  /** The id of this product as set in your ecommerce provider. This is not generated by Solve and needs to be provided. */
  id: Scalars['String'];
  /** An array of images for this product. */
  images?: InputMaybe<Array<InputMaybe<ProductImageInput>>>;
  /** For Google Ads, this can be used to ensure this product only participates in certain types of ads. */
  included_destination?: InputMaybe<Scalars['String']>;
  /** If this product can be paid for installments, this defines how much per month a purchaser can expect to pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  installment_amount?: InputMaybe<Scalars['String']>;
  /** If this product can be paid for installments, this defines how many months a purchaser can expect to be paying. */
  installment_months?: InputMaybe<Scalars['Int']>;
  /** How many of this product you have in stock. */
  inventory?: InputMaybe<Scalars['Int']>;
  /** Whether this is an adults only product. */
  is_adult?: InputMaybe<Scalars['Boolean']>;
  /** Indicates that you’ve created a bundle: a main product that you’ve grouped with other, different products, sold together as one package for a single price. */
  is_bundle?: InputMaybe<Scalars['Boolean']>;
  /**
   * Set to `false` if this product is a base product, `true` if this product is a variant of a base product.
   * Variants would include different size and colour combinations of the base product.
   * Variants have a `variant_group_id` which is the `id` of their corresponding base product.
   */
  is_variant?: InputMaybe<Scalars['Boolean']>;
  /** This determines the date the product becomes available. */
  launch_date?: InputMaybe<Scalars['DateTime']>;
  /** An absolute URL for this product. */
  link?: InputMaybe<Scalars['String']>;
  /** The name of any loyalty points program. */
  loyalty_points_name?: InputMaybe<Scalars['String']>;
  /** The ratio of earned points to currency. */
  loyalty_points_ratio?: InputMaybe<Scalars['Float']>;
  /** The number of points that this product gives the purchaser under the relevant loyalty points program. */
  loyalty_points_value?: InputMaybe<Scalars['Float']>;
  /** The primary material of this product. Solve does not support multiple materials per product. */
  material?: InputMaybe<Scalars['String']>;
  /** The maximum energy efficiency class of this product. */
  max_energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the largest possible timespan between purchasing and shipment. */
  max_handling_time?: InputMaybe<Scalars['String']>;
  /** The minimum energy efficiency class of this product. */
  min_energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the smallest possible timespan between purchasing and shipment. */
  min_handling_time?: InputMaybe<Scalars['String']>;
  /** An absolute URL for this product for mobile devices. */
  mobile_link?: InputMaybe<Scalars['String']>;
  /** The Manufacturer Part Number (MPN). MPNs are used to uniquely identify a specific product among all products from the same manufacturer. */
  mpn?: InputMaybe<Scalars['String']>;
  /** Indicates that you have grouped multiple products together for sale. */
  multipack?: InputMaybe<Scalars['Int']>;
  /** How much this product costs during a one off special offer. An offer can exist for a product at the same time as a sale. */
  offer_price?: InputMaybe<Scalars['String']>;
  /** The end of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_end_date?: InputMaybe<Scalars['DateTime']>;
  /** The start of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_start_date?: InputMaybe<Scalars['DateTime']>;
  /** The primary display pattern of this product. Solve does not support multiple patterns per product. */
  pattern?: InputMaybe<Scalars['String']>;
  /** The selling price of this product, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  price?: InputMaybe<Scalars['String']>;
  /** If this product uses Google's Merchant Promotions, this can be used in both your product data and promotions data to match products to promotions. */
  promotion_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An optional free form rich text description of the product. As opposed to just the `description`, here you can include control characters and HTML tags, such as line breaks, italics, and bulleted lists. */
  rich_text_description?: InputMaybe<Scalars['String']>;
  /** How much this product costs during a sale. A product can be on sale the same time it has a special offer. */
  sale_price?: InputMaybe<Scalars['String']>;
  /** The end of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_end_date?: InputMaybe<Scalars['DateTime']>;
  /** The start of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_start_date?: InputMaybe<Scalars['DateTime']>;
  /** For use in Google Ads. Separate from `inventory`, this is how many of this product you will sell via Google Ads. */
  sell_on_google_quantity?: InputMaybe<Scalars['Int']>;
  /** All available shipping options for this product. */
  shipping?: InputMaybe<Array<InputMaybe<ProductShippingInput>>>;
  /** The height of the product when shipping. */
  shipping_height?: InputMaybe<Scalars['String']>;
  /** This allows the grouping of multiple products together under the same shipping rates. For example, `oversized`. */
  shipping_label?: InputMaybe<Scalars['String']>;
  /** The weight of the product when shipping. */
  shipping_weight?: InputMaybe<Scalars['String']>;
  /** The width of the product when shipping. */
  shipping_width?: InputMaybe<Scalars['String']>;
  /** The size of the product. Used in conjunction with `size_system`. For example, `xl`. */
  size?: InputMaybe<Scalars['String']>;
  /** Describes which country's size system this product uses. */
  size_system?: InputMaybe<SizeSystem>;
  /** The size type can be used to describe the cut of the product. Used in conjunction with `size_system`. For example, `regular`. */
  size_type?: InputMaybe<Scalars['String']>;
  /** The Stock Keeping Unit for the product. This is optional. It is not required to be unique among all products saved in Solve. */
  sku?: InputMaybe<Scalars['String']>;
  /** The store or catalog this product is part of. It will be used to differentiate products with the same SKU or ID across different regions, catalogs or stores (for example, if the description is different between a UK and US store). If this is not given it will be set to `default`. */
  store: Scalars['String'];
  /** If this product is a subscription, this defines how much per period the purchaser must pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  subscription_cost_amount?: InputMaybe<Scalars['String']>;
  /** If this product is a subscription, this defines what that period is. */
  subscription_cost_period?: InputMaybe<SubscriptionCostPeriod>;
  /** If this product is a subscription, this defines the number of subscription periods that the purchaser must pay */
  subscription_cost_period_length?: InputMaybe<Scalars['Int']>;
  /** An array of tags related to this product. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Used if you want to set different tax rates for specific groups of products. For example, 'perishable_foods' or 'clothing'. */
  tax_category?: InputMaybe<Scalars['String']>;
  /** Any taxes applicable to this product. */
  taxes?: InputMaybe<Array<InputMaybe<ProductTaxInput>>>;
  /** The title of this product. This is the title that you would display to your customers. */
  title: Scalars['String'];
  /** The transit time labels help give your customers a more accurate estimated delivery time. */
  transit_time_label?: InputMaybe<Scalars['String']>;
  /** What type of product this is. This is free form and not restricted to any particular values. */
  type?: InputMaybe<Scalars['String']>;
  /** This defines the denominator for your unit price. For example, you might be selling 150ml of perfume, but users are interested in seeing the price per 100ml. */
  unit_price_base_measure?: InputMaybe<Scalars['String']>;
  /** This defines the measure and dimension of the product. This value allows users to understand the exact cost per unit for your product. For example, 750ml. */
  unit_price_measure?: InputMaybe<Scalars['String']>;
  /** For a variant, it is set to the `id` of the base product. `Is `null` for a base product. */
  variant_group_id?: InputMaybe<Scalars['String']>;
  /** The visibility status of this product. */
  visibility?: InputMaybe<ProductVisibility>;
};

/** Defines the shipping costs for a product. */
export type ProductShipping = {
  __typename?: 'ProductShipping';
  /** The country that the product can be delivered to. */
  country?: Maybe<Scalars['String']>;
  /** A location group that has been setup and named through Google's Merchant Center settings. For use in Google advertising. */
  location_group_name?: Maybe<Scalars['String']>;
  /** The numeric criteria ID of your location defined by Google Ads API (AdWords API). For use in Google advertising. */
  location_id?: Maybe<Scalars['Int']>;
  /** A postal code or postal code range that this product can be delivered to. */
  postal_code?: Maybe<Scalars['String']>;
  /** The fixed shipping price consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  price: Scalars['String'];
  /** The state, territory, or province that this product can be shipped to. */
  region?: Maybe<Scalars['String']>;
  /** The service class or speed that will be used. For example, Express. */
  service?: Maybe<Scalars['String']>;
};

/** The input type necessary for defining product shipping information. */
export type ProductShippingInput = {
  /** The country that the product can be delivered to. */
  country?: InputMaybe<Scalars['String']>;
  /** A location group that has been setup and named through Google's Merchant Center settings. For use in Google advertising. */
  location_group_name?: InputMaybe<Scalars['String']>;
  /** The numeric criteria ID of your location defined by Google Ads API (AdWords API). For use in Google advertising. */
  location_id?: InputMaybe<Scalars['Int']>;
  /** A postal code or postal code range that this product can be delivered to. */
  postal_code?: InputMaybe<Scalars['String']>;
  /** The fixed shipping price consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  price: Scalars['String'];
  /** The state, territory, or province that this product can be shipped to. */
  region?: InputMaybe<Scalars['String']>;
  /** The service class or speed that will be used. For example, Express. */
  service?: InputMaybe<Scalars['String']>;
};

/** Any tax that is required to be paid upon purchase of this product. */
export type ProductTax = {
  __typename?: 'ProductTax';
  /** The country this tax pertains to upon delivery. */
  country?: Maybe<Scalars['String']>;
  /** The numeric criteria ID of your location defined by Google Ads API (AdWords API). For use in Google advertising. */
  location_id?: Maybe<Scalars['Int']>;
  /** A postal code or postal code range that this tax applies to. */
  postal_code?: Maybe<Scalars['String']>;
  /** Tax rate as a percentage of the price. For example, 8.75. */
  rate?: Maybe<Scalars['Float']>;
  /** The state, territory, or province that this tax applies to. */
  region?: Maybe<Scalars['String']>;
  /** Whether tax also applies to any shipping costs. */
  tax_on_shipping?: Maybe<Scalars['Boolean']>;
};

/** The input type necessary for defining product tax information. */
export type ProductTaxInput = {
  /** The country this tax pertains to upon delivery. */
  country?: InputMaybe<Scalars['String']>;
  /** The numeric criteria ID of your location defined by Google Ads API (AdWords API). For use in Google advertising. */
  location_id?: InputMaybe<Scalars['Int']>;
  /** A postal code or postal code range that this tax applies to. */
  postal_code?: InputMaybe<Scalars['String']>;
  /** Tax rate as a percentage of the price. For example, 8.75. */
  rate?: InputMaybe<Scalars['Float']>;
  /** The state, territory, or province that this tax applies to. */
  region?: InputMaybe<Scalars['String']>;
  /** Whether tax also applies to any shipping costs. */
  tax_on_shipping?: InputMaybe<Scalars['Boolean']>;
};

/** Whether this product is visible in your ecommerce provider. */
export enum ProductVisibility {
  /** This product remains hidden from the public, but remains visible in your product catalog. */
  Hidden = 'HIDDEN',
  /** This product is visible to everyone. */
  Published = 'PUBLISHED',
  /** This product remains hidden from the public, but remains visible in your product catalog. */
  Staging = 'STAGING',
  /** This product remains hidden from all users, unless the user is manually added to an approve list in the ecommerce provider. */
  WhitelistOnly = 'WHITELIST_ONLY'
}

/** A single profile record. It is created using the `create_or_update_profile` mutation. A profile can also be generated through events that have profile resolvable information, i.e email addresses. */
export type Profile = {
  __typename?: 'Profile';
  addresses: AddressConnection;
  /** Calculated from the profiles birth date, if existing, and the current date. */
  age?: Maybe<Scalars['Int']>;
  /** Other names that the profile is known by. */
  alias_names?: Maybe<Scalars['JsonStringPairsMap']>;
  /**
   * Any arbitrary information that you would like to be stored against a profile.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** Ids of audiences associated with the profile. */
  audience_ids: Array<Maybe<Scalars['String']>>;
  /** Audiences associated with the profile. */
  audiences: AudienceConnection;
  /** The birth date of the profile. */
  birth_date?: Maybe<Scalars['Date']>;
  /** A `cartConnection` containing carts that have been resolved to the profile. By default, only ACTIVE carts get included. */
  carts: CartConnection;
  /** The list of identifiers with the `emails` type that are associated with the profile. */
  emails?: Maybe<Array<Maybe<Scalars['Email']>>>;
  /** The list of identifiers with the `email_md5` type that are associated with the profile. */
  emails_md5?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The list of identifiers with the `email_sha256` type that are associated with the profile. */
  emails_sha256?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An `eventConnection` containing events that have been resolved to the profile. */
  events: EventConnection;
  /** The profile's first name. */
  first_name?: Maybe<Scalars['String']>;
  /** The date this profile first showed activity (which may not be the first time the profile was created). */
  first_seen: Scalars['DateTime'];
  /** The full name of the profiles behind the profile. If both the first and last name are supplied to the API then this is generated as `first_name` + `last_name` otherwise is left blank. */
  full_name?: Maybe<Scalars['String']>;
  /** A free entry field that denotes the gender of the profiles. */
  gender?: Maybe<Scalars['String']>;
  /** The profile's title, i.e Mr, Ms, Dr etc. */
  greeting?: Maybe<Scalars['String']>;
  /** Preferred name, can be the same as the `first_name` or any arbritrary name. */
  greeting_name?: Maybe<Scalars['String']>;
  /** The profile_ids is the unique identifier for the profile. It is used to link other resources to the profile. */
  id?: Maybe<Scalars['UUID']>;
  identifiers: IdentifierConnection;
  /** The datetime that this profile record was inserted into the database. */
  inserted_at?: Maybe<Scalars['DateTime']>;
  /** A key value map describing the level of interest registered against a profile. */
  interests?: Maybe<Scalars['Json']>;
  /** The profile's last name. */
  last_name?: Maybe<Scalars['String']>;
  /** The most recent date this profile showed activity. */
  last_seen: Scalars['DateTime'];
  /** The profile_ids of the profiles that have been merged to create this profile. */
  merge_source_sids: Array<Scalars['UUID']>;
  metadata?: Maybe<Metadata>;
  /** An `orderConnection` containing orders that have been resolved to the profile. */
  orders: OrderConnection;
  /** The list of identifiers with the `phone_number` type that are associated with the profile. */
  phone_numbers?: Maybe<Array<Maybe<Scalars['PhoneNumber']>>>;
  /** The list of identifiers with the `phone_number_md5` type that are associated with the profile. */
  phone_numbers_md5?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The list of identifiers with the `phone_number_sha256` type that are associated with the profile. */
  phone_numbers_sha256?: Maybe<Array<Maybe<Scalars['String']>>>;
  processing_state?: Maybe<ProcessingState>;
  profile_field_fragments: ProfileFieldFragmentConnection;
  /** The profile_id of the profile that this profile has been replaced by during a profile merge. */
  replaced_by_sid?: Maybe<Scalars['UUID']>;
  /** Deprecated. Please use `id`. */
  sid?: Maybe<Scalars['UUID']>;
  /** A list of strings that can be used to put additional information about the profile. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Customer defined profile type, examples could be `lead`, `prospect`, `vip`. */
  type?: Maybe<Scalars['String']>;
  /** The datetime that this profile record was last modified. */
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** A single profile record. It is created using the `create_or_update_profile` mutation. A profile can also be generated through events that have profile resolvable information, i.e email addresses. */
export type ProfileCartsArgs = {
  status?: InputMaybe<CartStatus>;
};

/** The return object for multiple profiles. The `nodes` property contains all the profiles themselves, while the `page_info` property contains all information used for pagination. */
export type ProfileConnection = {
  __typename?: 'ProfileConnection';
  /** A list of profiles. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Profile>;
  /** All the paginiation info that you will need for reference when navigating through a large list of profiles. */
  page_info: PageInfo;
};

/** Any fields that make up part of the profile but do not fit into the existing schema. It is not used by Solve itself but contains other customer provided information. */
export type ProfileFieldFragment = {
  __typename?: 'ProfileFieldFragment';
  /** The name of the data stored in the fragment. */
  field_name?: Maybe<Scalars['String']>;
  /**
   * Any arbitrary information that you would like to be stored against a profile field fragment.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  fragment_attributes?: Maybe<Scalars['Json']>;
  /** The value of this fragment. */
  value?: Maybe<Scalars['String']>;
};

/** The return object for multiple profile field fragments. The `nodes` property contains all the profile field fragments themselves, while the `page_info` property contains all information used for pagination. */
export type ProfileFieldFragmentConnection = {
  __typename?: 'ProfileFieldFragmentConnection';
  nodes: Array<ProfileFieldFragment>;
  page_info: BlankPageInfo;
};

/** A profile id or email identifier for a profile. */
export type ProfileIdentifier = {
  /** An email identifier that resolves to a profile. */
  email?: InputMaybe<Scalars['Email']>;
  /** An md5 hashed email identifier that resolves to a profile. */
  email_md5?: InputMaybe<Scalars['String']>;
  /** An sha256 hashed email identifier that resolves to a profile. */
  email_sha256?: InputMaybe<Scalars['String']>;
  /** The profile id. */
  profile_id?: InputMaybe<Scalars['UUID']>;
};

/** The input type necessary for creating or updating a profile. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type ProfileInput = {
  addresses?: InputMaybe<Array<InputMaybe<InputAddress>>>;
  /** Other names that the profile is known by. */
  alias_names?: InputMaybe<Scalars['JsonStringPairsMap']>;
  /**
   * Any arbitrary information that you would like to be stored against a profile.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The birth date of the profile. */
  birth_date?: InputMaybe<Scalars['Date']>;
  /** An identifier with the `email` type that is resolvable to the profile. */
  email?: InputMaybe<Scalars['Email']>;
  /** An md5 hashed email identifier with the `email_md5` type that is resolvable to the profile. */
  email_md5?: InputMaybe<Scalars['String']>;
  /** An sha256 hashed email identifier with the `email_sha256` type that is resolvable to the profile. */
  email_sha256?: InputMaybe<Scalars['String']>;
  /** The list of identifiers with the `email` type that are associated with the profile. */
  emails?: InputMaybe<Array<InputMaybe<Scalars['Email']>>>;
  /** A list of md5 hashed email identifiers that are resolvable to the profile. They have the `email_md5` type. */
  emails_md5?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A list of sha256 hashed email identifiers that are resolvable to the profile. They have the `email_sha256` type. */
  emails_sha256?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The profile's first name. */
  first_name?: InputMaybe<Scalars['String']>;
  /** The date this profile first showed activity (which may not be the first time the profile was created). */
  first_seen?: InputMaybe<Scalars['DateTime']>;
  /** The full name of the profiles behind the profile. If both the first and last name are supplied to the API then this is generated as `first_name` + `last_name` otherwise is left blank. */
  full_name?: InputMaybe<Scalars['String']>;
  /** A free entry field that denotes the gender of the profiles. */
  gender?: InputMaybe<Scalars['String']>;
  /** The profile's title, i.e Mr, Ms, Dr etc. */
  greeting?: InputMaybe<Scalars['String']>;
  /** Preferred name, can be the same as the `first_name` or any arbritrary name. */
  greeting_name?: InputMaybe<Scalars['String']>;
  /** The profile_ids is the unique identifier for the profile. It is used to link other resources to the profile. */
  id?: InputMaybe<Scalars['UUID']>;
  /** A list of all identifiers that are resolvable to the profile */
  identifiers?: InputMaybe<Array<InputMaybe<InputIdentifier>>>;
  /** The datetime that this profile record was inserted into the database. */
  inserted_at?: InputMaybe<Scalars['DateTime']>;
  /** The profile's last name. */
  last_name?: InputMaybe<Scalars['String']>;
  /** The most recent date this profile showed activity. */
  last_seen?: InputMaybe<Scalars['DateTime']>;
  /** An identifier with the `phone_number` type that is resolvable to the profile. */
  phone_number?: InputMaybe<Scalars['PhoneNumber']>;
  /** An md5 hashed phone_number identifier with the `phone_number_md5` type that is resolvable to the profile. */
  phone_number_md5?: InputMaybe<Scalars['String']>;
  /** An sha256 hashed phone_number identifier with the `phone_number_sha256` type that is resolvable to the profile. */
  phone_number_sha256?: InputMaybe<Scalars['String']>;
  /** The list of identifiers with the `phone_number` type that are associated with the profile. */
  phone_numbers?: InputMaybe<Array<InputMaybe<Scalars['PhoneNumber']>>>;
  /** A list of md5 hashed phone_number identifiers that are resolvable to the profile. They have the `phone_number_md5` type. */
  phone_numbers_md5?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A list of sha256 hashed phone_number identifiers that are resolvable to the profile. They have the `phone_number_sha256` type. */
  phone_numbers_sha256?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  processing_state?: InputMaybe<InputProcessingState>;
  profile_field_fragments?: InputMaybe<
    Array<InputMaybe<InputProfileFieldFragment>>
  >;
  /** Deprecated. Please use id. */
  sid?: InputMaybe<Scalars['UUID']>;
  /** A list of strings that can be used to put additional information about the profile. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Customer defined profile type, examples could be `lead`, `prospect`, `vip`. */
  type?: InputMaybe<Scalars['String']>;
  /** The datetime that this profile record was last modified. */
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

/** The sort order that can be applied when retrieving profiles. */
export enum ProfileOrderBy {
  /** Sort the profiles by the datetime that they were first seen at, from oldest to newest. */
  FirstSeenAtAsc = 'FIRST_SEEN_AT_ASC',
  /** Sort the profiles by the datetime that they were first seen at, from newest to oldest. */
  FirstSeenAtDesc = 'FIRST_SEEN_AT_DESC',
  /** Sort the profiles by the datetime that they were inserted at, from oldest to newest. */
  InsertedAtAsc = 'INSERTED_AT_ASC',
  /** Sort the profiles by the datetime that they were inserted at, from newest to oldest. */
  InsertedAtDesc = 'INSERTED_AT_DESC',
  /** Sort the profiles by the datetime that they were last seen at, from oldest to newest. */
  LastSeenAtAsc = 'LAST_SEEN_AT_ASC',
  /** Sort the profiles by the datetime that they were last seen at, from newest to oldest. */
  LastSeenAtDesc = 'LAST_SEEN_AT_DESC',
  /** Sort the profiles by the datetime that they were updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the profiles by the datetime that they were updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PublicCart = {
  __typename?: 'PublicCart';
  adjustments?: Maybe<Array<Adjustment>>;
  attributes?: Maybe<Scalars['Json']>;
  cart_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  currency: Currency;
  id: Scalars['String'];
  items: Array<OrderItem>;
  order_id?: Maybe<Scalars['UUID']>;
  provider: Scalars['String'];
  reached_checkout: Scalars['Boolean'];
  status?: Maybe<CartStatus>;
};

export type PublicCartInput = {
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  attributes?: InputMaybe<Scalars['Json']>;
  cart_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['DateTime']>;
  currency: Currency;
  id: Scalars['String'];
  items: Array<OrderItemInput>;
  provider: Scalars['String'];
};

export type PublicCartOptions = {
  session_id?: InputMaybe<Scalars['UUID']>;
};

/** The public order information. */
export type PublicOrder = {
  __typename?: 'PublicOrder';
  created_at: Scalars['DateTime'];
  currency: Currency;
  subtotal_price: Scalars['Money'];
  subtotal_price_minor: Scalars['Int'];
  total_price: Scalars['Money'];
  total_price_minor: Scalars['Int'];
};

/** The public profile information. */
export type PublicProfile = {
  __typename?: 'PublicProfile';
  /** All the types of identifiers on this profile. Where a profile has multiple identifiers of the same type, the list does not contain the types multiple times. */
  identifier_types: Array<Scalars['String']>;
};

export type PublicProfileWrapper = {
  __typename?: 'PublicProfileWrapper';
  /** Does a profile exist for the given parameters. */
  exists: Scalars['Boolean'];
  /** The public order information, populated only if the profile exists and they have a previous order. */
  last_order?: Maybe<PublicOrder>;
  /** The public profile information, populated only if a profile exists for the given parameters. */
  profile?: Maybe<PublicProfile>;
};

/** The sort order that can be applied when retrieving saved queries. */
export enum QueriesOrderBy {
  /** Sort the queries alphabetically by name, ascending. */
  NameAsc = 'NAME_ASC',
  /** Sort the queries alphabetically by name, descending. */
  NameDesc = 'NAME_DESC'
}

export type Query = {
  __typename?: 'Query';
  /** Returns a single API key record based on the given id. */
  api_key?: Maybe<ApiKey>;
  /** Returns all API keys. This query returns a `connection` object which will have the API keys as a list contained within the `nodes` property. */
  api_keys?: Maybe<ApiKeyConnection>;
  /** Returns all API Policies. This query returns a `connection` object which will have the API Policies as a list contained within the `nodes` property. */
  api_policies: ApiPolicyConnection;
  /** Returns a single API Policy record based on the given [id], [name], or [name, is_solve_managed]. */
  api_policy?: Maybe<ApiPolicy>;
  /** Returns all audiences. This query returns a `connection` object which will have the audiences as a list contained within the `nodes` property. */
  audiences?: Maybe<AudiencesQueryConnection>;
  campaign?: Maybe<Campaign>;
  /** Returns the download link for a campaign report */
  campaign_report_download_link?: Maybe<CampaignReportDownloadLink>;
  /**
   * In beta - this query is expected to change and is not recommended for production use.
   *
   * Note that when the optional `group_by` argument is used, only the fields included there
   * will have values. Any of the other fields that can be grouped by will be `null` in the
   * results.
   * To get all values, omit the `group_by` argument.
   */
  campaign_reports?: Maybe<CampaignReportsConnection>;
  /** Returns a single campaign segment record based on the given id. */
  campaign_segment?: Maybe<CampaignSegment>;
  /** Returns a single campaign_segment_metric record based on the given id. */
  campaign_segment_metric?: Maybe<CampaignSegmentMetric>;
  campaign_segment_metrics: CampaignSegmentMetricsConnection;
  /** Returns all campaign_segments. This query returns a `connection` object which will have the campaign_segments as a list contained within the `nodes` property. */
  campaign_segments: CampaignSegmentsConnection;
  /** Returns a single cart record based on the given id and provider. */
  cart?: Maybe<Cart>;
  /** Returns all carts. This query returns a `connection` object which will have the carts as a list contained within the `nodes` property. By default only carts with status=`ACTIVE` are returned. */
  carts: CartConnection;
  channel_report: Array<Maybe<ChannelReport>>;
  /** Returns a single configuration record based on the given id. */
  configuration?: Maybe<Configuration>;
  /** Returns a single configuration record based on the given id and key. */
  configuration_property?: Maybe<ConfigurationEntry>;
  /** Returns all configurations. This query returns a `connection` object which will have the configurations as a list contained within the `nodes` property. */
  configurations: ConfigurationConnection;
  /** Returns all events. This query returns a `connection` object which will have the events as a list contained within the `nodes` property. */
  events: EventConnection;
  /** Returns a single graph record. All input parameters are required. */
  graph?: Maybe<Graph>;
  /** Returns all graphs. This query returns a `connection` object which will have the graphs as a list contained within the `nodes` property. */
  graphs: GraphsConnection;
  heartbeat: Scalars['String'];
  /** Returns a single order record based on the given id and provider. */
  order?: Maybe<Order>;
  /** Returns a list of the Campaign Segments that lead up to this particular order with this particular Order, ordered by first-seen first */
  order_attribution: CampaignSegmentConnection;
  /** Returns all orders. This query returns a `connection` object which will have the orders as a list contained within the `nodes` property. */
  orders: OrderConnection;
  /** Returns a single payment record based on the given id and provider. */
  payment?: Maybe<Payment>;
  /** Returns all payments. This query returns a `connection` object which will have the payments as a list contained within the `nodes` property. */
  payments: PaymentConnection;
  plugin_integration: PluginIntegration;
  /**
   * Get a single product record via the `id` or `sku`. The query will accept one or both of those identifiers.
   * If both are present, `id` takes precedence (`sku` is ignored if a match on `id` is found).
   * If neither is present a GraphQL error is returned.
   */
  product?: Maybe<Product>;
  /** Returns all products. This query returns a `connection` object which will have the products as a list contained within the `nodes` property. Can be filtered via `variant_group_id`. */
  products: ProductConnection;
  /** Returns a single profile record based on the given profile ID or email identifier. */
  profile?: Maybe<Profile>;
  /** Returns the download links for obtaining the data about a profile. */
  profile_download_links?: Maybe<DownloadLinksResponse>;
  /** Returns all profiles. This query returns a `connection` object which will have the profiles as a list contained within the `nodes` property. */
  profiles: ProfileConnection;
  public_cart?: Maybe<PublicCart>;
  /** Fetch public profile information related to the given session and/or linking ids. */
  public_profile: PublicProfileWrapper;
  queries: QueryConnection;
  /** Returns a single saved query record based on the given id. */
  query?: Maybe<SingleQuery>;
  /** Returns a single return record based on the given id and provider. */
  return?: Maybe<Return>;
  /** Returns all returns. This query returns a `connection` object which will have the returns as a list contained within the `nodes` property. */
  returns: ReturnConnection;
  /** Returns a single SDK snippet record based on the given ID or name. */
  sdk_snippet?: Maybe<SdkSnippet>;
  /** Returns all SDK snippets. This query returns a `connection` object which will have the SDK snippets as a list contained within the `nodes` property. */
  sdk_snippets: SdkSnippetConnection;
  /** Get a single Stat type for a certain period. Pass through `key` or a `from_time`/`to_time` pair to limit results or omit them to get back unfiltered results. */
  stats: StatConnection;
  /** Returns a single subscription record based on the given id and store. */
  subscription?: Maybe<SubscriptionObject>;
  /** Returns all subscriptions. This query returns a `connection` object which will have the subscriptions as a list contained within the `nodes` property. */
  subscriptions: SubscriptionConnection;
  /** Returns all users. This query returns a `connection` object which will have the users as a list contained within the `nodes` property. */
  users?: Maybe<UserConnection>;
  /** Returns a single webhook record based on the given id. */
  webhook?: Maybe<Webhook>;
  /** Returns all webhooks. This query returns a `connection` object which will have the webhooks as a list contained within the `nodes` property. */
  webhooks: WebhookConnection;
};

export type QueryApi_KeyArgs = {
  id: Scalars['Int'];
};

export type QueryApi_KeysArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QueryApi_PoliciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  is_solve_managed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QueryApi_PolicyArgs = {
  id?: InputMaybe<Scalars['ID']>;
  is_solve_managed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QueryAudiencesArgs = {
  audiences_query_input: AudiencesQueryInput;
};

export type QueryCampaignArgs = {
  name?: InputMaybe<Scalars['String']>;
};

export type QueryCampaign_Report_Download_LinkArgs = {
  input: CampaignReportInput;
};

export type QueryCampaign_ReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time: Scalars['DateTime'];
  group_by?: InputMaybe<Array<CampaignReportsGroupBy>>;
  order_by?: InputMaybe<CampaignReportsOrderBy>;
  search_term?: InputMaybe<Scalars['String']>;
  to_time: Scalars['DateTime'];
  type: CampaignReportType;
};

export type QueryCampaign_SegmentArgs = {
  account?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  medium?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  placement?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_ad_group_id?: InputMaybe<Scalars['String']>;
  provider_ad_group_name?: InputMaybe<Scalars['String']>;
  provider_ad_id?: InputMaybe<Scalars['String']>;
  provider_ad_name?: InputMaybe<Scalars['String']>;
  provider_campaign_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
};

export type QueryCampaign_Segment_MetricArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryCampaign_Segment_MetricsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  campaign_segment_id?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  order_by?: InputMaybe<CampaignSegmentMetricsOrderBy>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

export type QueryCampaign_SegmentsArgs = {
  account?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['Cursor']>;
  content?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  medium?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order_by?: InputMaybe<CampaignSegmentsOrderBy>;
  placement?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_ad_group_id?: InputMaybe<Scalars['String']>;
  provider_ad_group_name?: InputMaybe<Scalars['String']>;
  provider_ad_id?: InputMaybe<Scalars['String']>;
  provider_ad_name?: InputMaybe<Scalars['String']>;
  provider_campaign_id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  term?: InputMaybe<Scalars['String']>;
};

export type QueryCartArgs = {
  id?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
};

export type QueryCartsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  order_by?: InputMaybe<CartsOrderBy>;
  profile_id?: InputMaybe<Scalars['UUID']>;
  provider?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CartStatus>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

export type QueryChannel_ReportArgs = {
  end_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};

export type QueryConfigurationArgs = {
  id: Scalars['String'];
  ignore_cache?: InputMaybe<Scalars['Boolean']>;
};

export type QueryConfiguration_PropertyArgs = {
  id: Scalars['String'];
  ignore_cache?: InputMaybe<Scalars['Boolean']>;
  key: Scalars['String'];
};

export type QueryConfigurationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<ConfigurationsOrderBy>;
};

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  cart_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['Email']>;
  email_md5?: InputMaybe<Scalars['String']>;
  email_sha256?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['UUID']>;
  identifiers?: InputMaybe<Array<InputMaybe<IdentifierQueryInput>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  linking_id?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['String']>;
  page_index?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['UUID']>;
  profile_ids?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  return_id?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
  session_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  store?: InputMaybe<Scalars['String']>;
  to_time?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGraphArgs = {
  from_time: Scalars['DateTime'];
  period: Scalars['String'];
  timezone: Scalars['String'];
  to_time: Scalars['DateTime'];
  type: Scalars['String'];
};

export type QueryOrderArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};

export type QueryOrder_AttributionArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};

export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  discount_codes?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  fulfillment_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  order_by?: InputMaybe<OrdersOrderBy>;
  payment_status?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['UUID']>;
  profile_ids?: InputMaybe<Array<Scalars['UUID']>>;
  provider?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

export type QueryPaymentArgs = {
  id: Scalars['String'];
};

export type QueryPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  order_by?: InputMaybe<PaymentsOrderBy>;
  order_id?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

export type QueryProductArgs = {
  id?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  store: Scalars['String'];
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  sku?: InputMaybe<Scalars['String']>;
  store?: InputMaybe<Scalars['String']>;
  variant_group_id?: InputMaybe<Scalars['String']>;
};

export type QueryProfileArgs = {
  id: Scalars['UUID'];
};

export type QueryProfile_Download_LinksArgs = {
  input: ProfileIdentifier;
};

export type QueryProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  email?: InputMaybe<Scalars['Email']>;
  email_md5?: InputMaybe<Scalars['String']>;
  email_sha256?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  identifiers?: InputMaybe<Array<InputMaybe<IdentifierQueryInput>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  order_by?: InputMaybe<ProfileOrderBy>;
  phone_number?: InputMaybe<Scalars['PhoneNumber']>;
  phone_number_md5?: InputMaybe<Scalars['String']>;
  phone_number_sha256?: InputMaybe<Scalars['String']>;
};

export type QueryPublic_CartArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};

export type QueryPublic_ProfileArgs = {
  linking_id?: InputMaybe<Scalars['String']>;
  session_id?: InputMaybe<Scalars['String']>;
};

export type QueryQueriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  order_by?: InputMaybe<QueriesOrderBy>;
  search_term?: InputMaybe<Scalars['String']>;
};

export type QueryQueryArgs = {
  id?: InputMaybe<Scalars['Int']>;
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  is_visible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type QueryReturnArgs = {
  id: Scalars['String'];
  provider: Scalars['String'];
};

export type QueryReturnsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  from_time?: InputMaybe<Scalars['DateTime']>;
  order_by?: InputMaybe<ReturnsOrderBy>;
  order_id?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['UUID']>;
  provider?: InputMaybe<Scalars['String']>;
  rma_id?: InputMaybe<Scalars['String']>;
  to_time?: InputMaybe<Scalars['DateTime']>;
};

export type QuerySdk_SnippetArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type QuerySdk_SnippetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryStatsArgs = {
  from_time?: InputMaybe<Scalars['DateTime']>;
  key?: InputMaybe<Scalars['String']>;
  padding?: InputMaybe<Scalars['Boolean']>;
  period: Scalars['String'];
  timezone: Scalars['String'];
  to_time?: InputMaybe<Scalars['DateTime']>;
  type: Scalars['String'];
};

export type QuerySubscriptionArgs = {
  id: Scalars['String'];
  store: Scalars['String'];
};

export type QuerySubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<SubscriptionsOrderBy>;
  profile_id?: InputMaybe<Scalars['UUID']>;
  status?: InputMaybe<SubscriptionStatus>;
  store?: InputMaybe<Scalars['String']>;
};

export type QueryWebhookArgs = {
  id: Scalars['Int'];
};

export type QueryWebhooksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  is_disabled?: InputMaybe<Scalars['Boolean']>;
  provider?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** The return object for multiple saved queries. The `nodes` property contains all the query records themselves. */
export type QueryConnection = {
  __typename?: 'QueryConnection';
  /** A list of saved query. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<SingleQuery>;
  /** All the paginiation info that you will need for reference when navigating through a large list of saved queries. */
  page_info: PageInfo;
};

/** The input type necessary for creating a saved query. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type QueryCreateInput = {
  /** When the query is run via the Query Runner it will generate another X queries when run with the start time set to 1 period behind the initial `start_time`. */
  backfill_window?: InputMaybe<Scalars['Int']>;
  /** When generating a stat via the Query Runner, the value that is in the `date_column` column in the query results will be used as the date for the generated stat. */
  date_column?: InputMaybe<Scalars['String']>;
  /** The default parameters used by the Query Runner when this query is run. */
  default_params?: InputMaybe<Array<InputMaybe<Scalars['StringOrInteger']>>>;
  /** A description to help identify the purpose of the query. */
  description?: InputMaybe<Scalars['String']>;
  /** Whether or not an event is generated when the query is run via the Query Runner. The event that is generated will have an event type of `scheduled_query_results`. */
  generate_event?: InputMaybe<Scalars['Boolean']>;
  /** For internal use. Whether the results of this query, is scheduled, will be inserted into another table. */
  generate_query_table?: InputMaybe<Scalars['String']>;
  /** Whether or not a stat is generated when the query is run via the Query Runner. */
  generate_stat?: InputMaybe<Scalars['Boolean']>;
  /** Whether this query is to be scheduled to periodically run via the Query Runner. */
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not to only include queries that are not hidden by default (i.e not internal queries). */
  is_visible?: InputMaybe<Scalars['Boolean']>;
  /** When generating a stat via the Query Runner, the value that is in the `key` column in the query results will be used as the key for the generated stat. */
  key_column?: InputMaybe<Scalars['String']>;
  /** The name of the saved query. Does not have to be unique. */
  name: Scalars['String'];
  /** How long the saved query should cover when run via the Query Runner. One of 'hour', 'day', 'week', or 'month'. */
  period?: InputMaybe<Scalars['String']>;
  /** The raw SQL query, with parameters if required. */
  query: Scalars['String'];
  /** A cron formatted string denoting how often this query is run on the Query Runner, if the `schedule` field is set. */
  schedule?: InputMaybe<Scalars['String']>;
  /** The `stat_type` is the same as the `type` of the stat that will be saved when this query is run via the Query Scheduler. */
  stat_type?: InputMaybe<Scalars['String']>;
  /** When generating a stat via the Query Runner, this is what timezone the stat will be saved and started with. */
  timezone?: InputMaybe<Scalars['String']>;
  /** When generating a stat via the Query Runner, the value that is in the `value` column in the query results will be used as the value for the generated stat. */
  value_column?: InputMaybe<Scalars['String']>;
};

/** The input type necessary for updating an existing saved query. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type QueryUpdateInput = {
  /** When the query is run via the Query Runner it will generate another X queries when run with the start time set to 1 period behind the initial `start_time`. */
  backfill_window?: InputMaybe<Scalars['Int']>;
  /** When generating a stat via the Query Runner, the value that is in the `date_column` column in the query results will be used as the date for the generated stat. */
  date_column?: InputMaybe<Scalars['String']>;
  /** The default parameters used by the Query Runner when this query is run. */
  default_params?: InputMaybe<Array<InputMaybe<Scalars['StringOrInteger']>>>;
  /** A description to help identify the purpose of the query. */
  description?: InputMaybe<Scalars['String']>;
  /** Whether or not an event is generated when the query is run via the Query Runner. The event that is generated will have an event type of `scheduled_query_results`. */
  generate_event?: InputMaybe<Scalars['Boolean']>;
  /** For internal use. Whether the results of this query, is scheduled, will be inserted into another table. */
  generate_query_table?: InputMaybe<Scalars['String']>;
  /** Whether or not a stat is generated when the query is run via the Query Runner. */
  generate_stat?: InputMaybe<Scalars['Boolean']>;
  /** Whether this query is to be scheduled to periodically run via the Query Runner. */
  is_scheduled?: InputMaybe<Scalars['Boolean']>;
  /** Whether or not to only include queries that are not hidden by default (i.e not internal queries). */
  is_visible?: InputMaybe<Scalars['Boolean']>;
  /** When generating a stat via the Query Runner, the value that is in the `key` column in the query results will be used as the key for the generated stat. */
  key_column?: InputMaybe<Scalars['String']>;
  /** The name of the saved query. Does not have to be unique. */
  name?: InputMaybe<Scalars['String']>;
  /** How long the saved query should cover when run via the Query Runner. One of 'hour', 'day', 'week', or 'month'. */
  period?: InputMaybe<Scalars['String']>;
  /** The raw SQL query, with parameters if required. */
  query?: InputMaybe<Scalars['String']>;
  /** A cron formatted string denoting how often this query is run on the Query Runner, if the `schedule` field is set. */
  schedule?: InputMaybe<Scalars['String']>;
  /** The `stat_type` is the same as the `type` of the stat that will be saved when this query is run via the Query Scheduler. */
  stat_type?: InputMaybe<Scalars['String']>;
  /** When generating a stat via the Query Runner, this is what timezone the stat will be saved and started with. */
  timezone?: InputMaybe<Scalars['String']>;
  /** When generating a stat via the Query Runner, the value that is in the `value` column in the query results will be used as the value for the generated stat. */
  value_column?: InputMaybe<Scalars['String']>;
};

export type Roas = MarketingMetric & {
  __typename?: 'ROAS';
  value: Scalars['NonNegativeFloat'];
  variance: ChannelReportDirectionalPercent;
};

export type ReferralRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'ReferralRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

/** The current refund status of the order. This is automatically generated from any returns and payments against the order. */
export enum RefundStatus {
  /** There are no refunds against this order. */
  NoRefund = 'NO_REFUND',
  /** There has been at least one refund against this order, but with total values less than the order amount. */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** There has been at least one refund against this order, with total values equal or greater than the full order amount. */
  Refunded = 'REFUNDED'
}

/** Return */
export type Return = {
  __typename?: 'Return';
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
  adjustments?: Maybe<Array<Adjustment>>;
  /**
   * Any arbitrary information that you would like to be stored against a return.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: Maybe<Scalars['Json']>;
  /** The datetime that this return record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency?: Maybe<Currency>;
  /** The ID of the return (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** The datetime that this return record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Purchasable items in the return. These are the items that were returned. May be empty. */
  items?: Maybe<Array<OrderItem>>;
  /** Optional free form notes related to the return. */
  notes?: Maybe<Scalars['String']>;
  /** The order that this return belongs to. */
  order?: Maybe<Order>;
  /** The id of this `Return`'s corresponding `Order`, if such an order exists. The corresponding `Order` is assumed to have the same `provider` as this `Return`. */
  order_id?: Maybe<Scalars['String']>;
  /**
   * The profile_id of the profile that this return belongs to. This is optional for where the return has been made by an unknown profile.
   * If the profile_id is not set when creating a return, then it is taken from this return's corresponding order, if it exists.
   */
  profile_id?: Maybe<Scalars['UUID']>;
  /** Source store of this return (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** A description of the reason for the return. E.g. `"The tablet screen did not turn on"` */
  return_reason?: Maybe<Scalars['String']>;
  /** A description of how the return was resolved. E.g. `"Tablet was replaced"` */
  return_resolution?: Maybe<Scalars['String']>;
  /** A return merchandise authorization identifier for this return. */
  rma_id?: Maybe<Scalars['String']>;
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. */
  subtotal_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities). Does not include the `adjustments`. Represented in minor units. */
  subtotal_price_minor: Scalars['Int'];
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. */
  total_price: Scalars['Money'];
  /** The summed price of the `items` (multiplied by their quantities) and the `adjustments`. Represented in minor units. */
  total_price_minor: Scalars['Int'];
  /** The datetime that this return record was last modified. */
  updated_at: Scalars['DateTime'];
};

export type ReturnConnection = {
  __typename?: 'ReturnConnection';
  nodes: Array<Return>;
  page_info: PageInfo;
};

/** Parameters to create or update a return. */
export type ReturnInput = {
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here. */
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  /**
   * Any arbitrary information that you would like to be stored against a return.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The datetime that this return record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. The currency must be specified unless there is an `order_id`. When the currency is specified and there is an `order_id`, the currency must match the currency of the order. When the currency is not specified and there is an `order_id`, the currency of the order is used. */
  currency?: InputMaybe<Currency>;
  /** The ID of the return (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** Purchasable items in the return. These are the items that were returned. May be empty. */
  items?: InputMaybe<Array<OrderItemInput>>;
  /** Optional free form notes related to the return. */
  notes?: InputMaybe<Scalars['String']>;
  /** The id of this `Return`'s corresponding `Order`, if such an order exists. The corresponding `Order` is assumed to have the same `provider` as this `Return`. */
  order_id?: InputMaybe<Scalars['String']>;
  /**
   * The profile_id of the profile that this return belongs to. This is optional for where the return has been made by an unknown profile.
   * If the profile_id is not set when creating a return, then it is taken from this return's corresponding order, if it exists.
   */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** Source store of this return (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  provider: Scalars['String'];
  /** A description of the reason for the return. E.g. `"The tablet screen did not turn on"` */
  return_reason?: InputMaybe<Scalars['String']>;
  /** A description of how the return was resolved. E.g. `"Tablet was replaced"` */
  return_resolution?: InputMaybe<Scalars['String']>;
  /** A return merchandise authorization identifier for this return. */
  rma_id?: InputMaybe<Scalars['String']>;
};

/** The order that the `returns` query can by sorted by */
export enum ReturnsOrderBy {
  /** Sort the returns by the datetime that they were created at, from oldest to newest. */
  CreatedAtAsc = 'CREATED_AT_ASC',
  /** Sort the returns by the datetime that they were created at, from newest to oldest. */
  CreatedAtDesc = 'CREATED_AT_DESC',
  /** Sort the returns by the datetime that they were last updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the returns by the datetime that they were last updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type SmsRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'SMSRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

export type SavedQueryExportUrls = {
  __typename?: 'SavedQueryExportUrls';
  /** URL to the CSV format */
  csv: Scalars['String'];
  /** URL to the JSON format */
  json: Scalars['String'];
};

/** All the responses generated by scheduling a query. This contains information relevant to all scheduled queries to be run (which could be one or more records). */
export type ScheduleQueryResponses = {
  __typename?: 'ScheduleQueryResponses';
  /** The `end_time` from the last run query. */
  end_time: Scalars['DateTime'];
  /** The parameters that this saved query was run with. */
  parameters?: Maybe<Array<Maybe<Scalars['StringOrInteger']>>>;
  /** All computed responses that are the result of multiple queries being scheduled. */
  responses: Array<Maybe<ScheduledQueryResponse>>;
  /** The saved query resource that has been scheduled. */
  saved_query: SingleQuery;
  /** The `start_time` from the first run query. */
  start_time: Scalars['DateTime'];
};

/** The input type for scheduling a query to be run one or more times. */
export type ScheduledQueryInput = {
  /** This is how many queries will be run, if not given the Query's backfill_window will be used. The start_time will be shifted back 1 `period` for each run */
  number_to_run?: InputMaybe<Scalars['Int']>;
  /** Any extra parameters that may be used by the scheduled query. If not provided, this will default to the saved queries default parameters. */
  parameters?: InputMaybe<Array<InputMaybe<Scalars['StringOrInteger']>>>;
  /** The start time of the first query to run. If the corresponding `number_to_run` is greater than one, all other queries will be run with a start time which is 1 period before the provided start time. */
  start_time: Scalars['DateTime'];
};

/** The computed `parameters`, `start_time` and `end_time` that each query will be run with when scheduled using the `schedule_query` mutation. */
export type ScheduledQueryResponse = {
  __typename?: 'ScheduledQueryResponse';
  /** The computed end time that the saved query was run with. */
  end_time: Scalars['DateTime'];
  /** A list of parameters that the saved query was run with. */
  parameters: Array<Maybe<Scalars['StringOrInteger']>>;
  /** The start time that the saved query was run with. */
  start_time: Scalars['DateTime'];
};

/** A single SDK snippet record. It is created using the `create_sdk_snippet` mutation. */
export type SdkSnippet = {
  __typename?: 'SdkSnippet';
  /** The JavaScript content to add to the SDK. This will not be checked for validity. */
  content: Scalars['String'];
  /** Is this snippet enabled. Disabled snippets are not inserted into the SDK. */
  enabled: Scalars['Boolean'];
  /** The SDK snippet id. This is generated and returned via the `create_sdk_snippet` mutation and is used for primary key reference to the SDK snippet in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this SDK snippet record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** A human readable name of the snippet. This will be used to sort (A-Z) snippets with the same priority. */
  name: Scalars['String'];
  /** A number representing the order of the snippets to be inserted. Lower numbers are inserted first, and the name field acts as a tie-breaker when two snippets share priorities. */
  priority: Scalars['Int'];
  /** The datetime that this SDK snippet record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The return object for multiple SDK snippet. The `nodes` property contains all the SDK snippet themselves, while the `page_info` property contains all information used for pagination. */
export type SdkSnippetConnection = {
  __typename?: 'SdkSnippetConnection';
  /** A list of SDK snippets. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<SdkSnippet>;
  /** All the paginiation info that you will need for reference when navigating through a large list of SDK snippets. */
  page_info: PageInfo;
};

/** The input type necessary for creating a SDK snippet. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type SdkSnippetCreateInput = {
  /** The JavaScript content to add to the SDK. This will not be checked for validity. */
  content: Scalars['String'];
  /** Is this snippet enabled. Disabled snippets are not inserted into the SDK. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** A human readable name of the snippet. This will be used to sort (A-Z) snippets with the same priority. */
  name: Scalars['String'];
  /** A number representing the order of the snippets to be inserted. Lower numbers are inserted first, and the name field acts as a tie-breaker when two snippets share priorities. */
  priority?: InputMaybe<Scalars['Int']>;
};

/** The input type necessary for creating a SDK snippet. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type SdkSnippetUpdateInput = {
  /** The JavaScript content to add to the SDK. This will not be checked for validity. */
  content?: InputMaybe<Scalars['String']>;
  /** Is this snippet enabled. Disabled snippets are not inserted into the SDK. */
  enabled?: InputMaybe<Scalars['Boolean']>;
  /** A human readable name of the snippet. This will be used to sort (A-Z) snippets with the same priority. */
  name?: InputMaybe<Scalars['String']>;
  /** A number representing the order of the snippets to be inserted. Lower numbers are inserted first, and the name field acts as a tie-breaker when two snippets share priorities. */
  priority?: InputMaybe<Scalars['Int']>;
};

export type ShopifyInstallInput = {
  /** the url the user should be redirect to after succesfull installation */
  admin_url: Scalars['String'];
  /** friendly name to give the shopify integration */
  shop_label: Scalars['String'];
  /** shop name in the form myshop.myshopify.com */
  shop_name: Scalars['String'];
};

export type ShopifyPluginIntegration = {
  __typename?: 'ShopifyPluginIntegration';
  active: Scalars['Boolean'];
  complete_linking_url?: Maybe<Scalars['String']>;
  sdk_installed: Scalars['Boolean'];
  shop_label: Scalars['String'];
  shop_name: Scalars['String'];
  webhook_installed: Scalars['Boolean'];
};

/** A single saved query record. It is created using the `create_query` mutation. */
export type SingleQuery = {
  __typename?: 'SingleQuery';
  /** When the query is run via the Query Runner it will generate another X queries when run with the start time set to 1 period behind the initial `start_time`. */
  backfill_window?: Maybe<Scalars['Int']>;
  /** When generating a stat via the Query Runner, the value that is in the `date_column` column in the query results will be used as the date for the generated stat. */
  date_column?: Maybe<Scalars['String']>;
  /** The default parameters used by the Query Runner when this query is run. */
  default_params?: Maybe<Array<Maybe<Scalars['StringOrInteger']>>>;
  /** A description to help identify the purpose of the query. */
  description?: Maybe<Scalars['String']>;
  /** The URLs to the query's latest results */
  exports_urls?: Maybe<SavedQueryExportUrls>;
  /** Whether or not an event is generated when the query is run via the Query Runner. The event that is generated will have an event type of `scheduled_query_results`. */
  generate_event?: Maybe<Scalars['Boolean']>;
  /** For internal use. Whether the results of this query, is scheduled, will be inserted into another table. */
  generate_query_table?: Maybe<Scalars['String']>;
  /** Whether or not a stat is generated when the query is run via the Query Runner. */
  generate_stat?: Maybe<Scalars['Boolean']>;
  /** The saved query id. This is generated and returned via the `create_query` mutation and is used for primary key reference to the saved query in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this saved query record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Whether this query is to be scheduled to periodically run via the Query Runner. */
  is_scheduled?: Maybe<Scalars['Boolean']>;
  /** Whether or not to only include queries that are not hidden by default (i.e not internal queries). */
  is_visible?: Maybe<Scalars['Boolean']>;
  /** When generating a stat via the Query Runner, the value that is in the `key` column in the query results will be used as the key for the generated stat. */
  key_column?: Maybe<Scalars['String']>;
  /** The date at which the query was last ran on the Query Runner. */
  last_run_at?: Maybe<Scalars['DateTime']>;
  /** The name of the saved query. Does not have to be unique. */
  name: Scalars['String'];
  /** How long the saved query should cover when run via the Query Runner. One of 'hour', 'day', 'week', or 'month'. */
  period?: Maybe<Scalars['String']>;
  /** The raw SQL query, with parameters if required. */
  query: Scalars['String'];
  /** A cron formatted string denoting how often this query is run on the Query Runner, if the `schedule` field is set. */
  schedule?: Maybe<Scalars['String']>;
  /** The `stat_type` is the same as the `type` of the stat that will be saved when this query is run via the Query Scheduler. */
  stat_type?: Maybe<Scalars['String']>;
  /** When generating a stat via the Query Runner, this is what timezone the stat will be saved and started with. */
  timezone?: Maybe<Scalars['String']>;
  /** The datetime that this saved query record was last modified. */
  updated_at: Scalars['DateTime'];
  /** When generating a stat via the Query Runner, the value that is in the `value` column in the query results will be used as the value for the generated stat. */
  value_column?: Maybe<Scalars['String']>;
};

/** Explains which country’s sizing system your product uses. */
export enum SizeSystem {
  Au = 'AU',
  Br = 'BR',
  Cn = 'CN',
  De = 'DE',
  Eu = 'EU',
  Fr = 'FR',
  It = 'IT',
  Jp = 'JP',
  Mex = 'MEX',
  Uk = 'UK',
  Us = 'US'
}

export type StartShopifyPluginInstallError = {
  __typename?: 'StartShopifyPluginInstallError';
  reason: Scalars['String'];
};

/** The resulting type of whether the mutation was succesfull or not */
export type StartShopifyPluginInstallPayload =
  | StartShopifyPluginInstallError
  | StartShopifyPluginInstallSuccess;

export type StartShopifyPluginInstallSuccess = {
  __typename?: 'StartShopifyPluginInstallSuccess';
  oauth_url: Scalars['String'];
};

/** A single stat record. It is created using the `create_stat` mutation. */
export type Stat = {
  __typename?: 'Stat';
  /** The length of time that this stat covers. Usualy one of `hour`, `day`, `week`, `month`. */
  period: Scalars['String'];
  /** What time the stat is calculated for. */
  time: Scalars['DateTime'];
  /** The timezone that this stat was saved in and calculated for. */
  timezone: Scalars['String'];
  /** The type of stat that this refers to. */
  type: Scalars['String'];
  /** The value of the stat itself. */
  value: Scalars['Json'];
};

/** The return object for multiple stats. The `nodes` property contains all the stats themselves, while the `page_info` property contains all information used for pagination. */
export type StatConnection = {
  __typename?: 'StatConnection';
  nodes: Array<Stat>;
};

/** The input type necessary for creating a stat. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type StatCreateInput = {
  /** The key used to separate out different values for one stat `type`. For example a stat with type 'revenue' may have a 'USD' key and a 'EUR' key. */
  key: Scalars['String'];
  /** The length of time that this stat covers. Usualy one of `hour`, `day`, `week`, `month`. */
  period: Scalars['String'];
  /** What time the stat is calculated for. */
  time: Scalars['DateTime'];
  /** The timezone that this stat was saved in and calculated for. */
  timezone: Scalars['String'];
  /** The type of stat that this refers to. */
  type: Scalars['String'];
  /** The value of the stat itself. */
  value: Scalars['Int'];
};

/** The current status of the order. Default value is 'CREATED'. */
export enum Status {
  /** The order has been cancelled. */
  Canceled = 'CANCELED',
  /** The order has just been created. */
  Created = 'CREATED',
  /** The order has been processed. If appropriate, further tracking can be done via the `fulfillment_status`. */
  Processed = 'PROCESSED',
  /** The order has been returned by the customer. */
  Returned = 'RETURNED'
}

/** The return object for multiple subscriptions. The `nodes` property contains all the subscriptions themselves, while the `page_info` property contains all information used for pagination. */
export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  /** A list of subscriptions. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<SubscriptionObject>;
  /** All the paginiation info that you will need for reference when navigating through a large list of subscriptions. */
  page_info: PageInfo;
};

/** Options for the details of a monthly or annual payment plan. */
export enum SubscriptionCostPeriod {
  /** The product subscription is paid monthly. */
  Month = 'MONTH',
  /** The product subscription is paid annually. */
  Year = 'YEAR'
}

/** The input type necessary for creating a subscription. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type SubscriptionCreateInput = {
  /**
   * Any arbitrary information that you would like to be stored against a subscription.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The currency of the price. Must be a valid currency code following ISO 4217. This should match the next order's currency. */
  currency: Currency;
  /** The ID of the subscription (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** Number of `interval_unit`s between the next order and the one after that. See `SubscriptionIntervalUnit` for more information. */
  interval: Scalars['Int'];
  /** The unit used to measure the interval. See the values of this enum for more details. */
  interval_unit: SubscriptionIntervalUnit;
  /** The human-readable name of the subscription plan, e.g. 'Vegetarian Dinner Box for Two'. */
  plan: Scalars['String'];
  /**
   * The cost of a regular subscription order for the customer.
   * If only the next order will have a particular addon, the price of the addon should not be included here.
   * If all future orders (ignoring future changes to the subscription) will have the addon, then the price of that should be included.
   */
  price: Scalars['String'];
  /** The profile_id of the profile that this subscription belongs to. This is optional for where the subscription has been made by an unknown profile. */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** When the user signed up to the subscription. */
  started_at: Scalars['DateTime'];
  /** The status of the subscription. See the values of this enum for more details. */
  status: SubscriptionStatus;
  /** Source store of this subscription (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  store: Scalars['String'];
};

/** The unit used to measure the interval. See the values of this enum for more details. */
export enum SubscriptionIntervalUnit {
  /**
   * Where `interval` is an integer, there is `interval` DAYS between orders.
   * The next order will be at the same time of day as the most recent order.
   */
  Days = 'DAYS',
  /**
   * Where `interval` is an integer, there is `interval` MONTHS between orders.
   * The next order will be at the same time of day as the most recent order, and on the same day of the month.
   */
  Months = 'MONTHS',
  /**
   * Where `interval` is an integer, there is `interval` WEEKS between orders.
   * The next order will be at the same time of day as the most recent order, and on the same day of the week.
   */
  Weeks = 'WEEKS'
}

/**
 * Represents a subscription for repeating orders.
 *
 * A single subscription record. It is created using the `create_or_update_subscription` mutation.
 */
export type SubscriptionObject = {
  __typename?: 'SubscriptionObject';
  /**
   * Any arbitrary information that you would like to be stored against a subscription.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes: Scalars['Json'];
  /** The currency of the price. Must be a valid currency code following ISO 4217. This should match the next order's currency. */
  currency: Currency;
  /** The ID of the subscription (e.g `"NUM_001"`). This value can often be retrieved from the external provider. */
  id: Scalars['String'];
  /** The datetime that this subscription record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Number of `interval_unit`s between the next order and the one after that. See `SubscriptionIntervalUnit` for more information. */
  interval: Scalars['Int'];
  /** The unit used to measure the interval. See the values of this enum for more details. */
  interval_unit: SubscriptionIntervalUnit;
  /** The human-readable name of the subscription plan, e.g. 'Vegetarian Dinner Box for Two'. */
  plan: Scalars['String'];
  /**
   * The cost of a regular subscription order for the customer.
   * If only the next order will have a particular addon, the price of the addon should not be included here.
   * If all future orders (ignoring future changes to the subscription) will have the addon, then the price of that should be included.
   */
  price: Scalars['String'];
  /**
   * The cost of a regular subscription order for the customer.
   * If only the next order will have a particular addon, the price of the addon should not be included here.
   * If all future orders (ignoring future changes to the subscription) will have the addon, then the price of that should be included.
   * . Represented in minor units.
   */
  price_minor: Scalars['Int'];
  /** The profile_id of the profile that this subscription belongs to. This is optional for where the subscription has been made by an unknown profile. */
  profile_id?: Maybe<Scalars['UUID']>;
  /** When the user signed up to the subscription. */
  started_at: Scalars['DateTime'];
  /** The status of the subscription. See the values of this enum for more details. */
  status: SubscriptionStatus;
  /** Source store of this subscription (e.g., `my_store_nz`, or `my_store_au`). It is part of a compound key `id` + `provider`. The value of `provider` must match between ecommerce entities in order for them to be linked together. Note that `provider` is legacy terminology for `store`. */
  store: Scalars['String'];
  /** The datetime that this subscription record was last modified. */
  updated_at: Scalars['DateTime'];
};

/** The status of the subscription. See the values of this enum for more details. */
export enum SubscriptionStatus {
  /** The subscription is currently ongoing. This is the default when a subscription is created */
  Active = 'ACTIVE',
  /**
   * The subscription has been canceled and will not recur.
   *
   * Note that if there are still outstanding services this should be indicated in the
   * subscription's `attributes`.
   */
  Canceled = 'CANCELED',
  /** The subscription has been erased, most likely for data privacy reasons. */
  Erased = 'ERASED',
  /** The subscription is still ongoing, but the customer has deactivated it for a limited time. */
  Paused = 'PAUSED'
}

/** The input type necessary for updating an existing subscription. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type SubscriptionUpdateInput = {
  /**
   * Any arbitrary information that you would like to be stored against a subscription.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The currency of the price. Must be a valid currency code following ISO 4217. This should match the next order's currency. */
  currency?: InputMaybe<Currency>;
  /** Number of `interval_unit`s between the next order and the one after that. See `SubscriptionIntervalUnit` for more information. */
  interval?: InputMaybe<Scalars['Int']>;
  /** The unit used to measure the interval. See the values of this enum for more details. */
  interval_unit?: InputMaybe<SubscriptionIntervalUnit>;
  /** The human-readable name of the subscription plan, e.g. 'Vegetarian Dinner Box for Two'. */
  plan?: InputMaybe<Scalars['String']>;
  /**
   * The cost of a regular subscription order for the customer.
   * If only the next order will have a particular addon, the price of the addon should not be included here.
   * If all future orders (ignoring future changes to the subscription) will have the addon, then the price of that should be included.
   */
  price?: InputMaybe<Scalars['String']>;
  /** The profile_id of the profile that this subscription belongs to. This is optional for where the subscription has been made by an unknown profile. */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** When the user signed up to the subscription. */
  started_at?: InputMaybe<Scalars['DateTime']>;
  /** The status of the subscription. See the values of this enum for more details. */
  status?: InputMaybe<SubscriptionStatus>;
};

/** The sort order that can be applied when retrieving subscriptions. */
export enum SubscriptionsOrderBy {
  /** Sort the subscriptions by the datetime that they were started at, from oldest to newest. */
  StartedAtAsc = 'STARTED_AT_ASC',
  /** Sort the subscriptions by the datetime that they were started at, from newest to oldest. */
  StartedAtDesc = 'STARTED_AT_DESC',
  /** Sort the subscriptions by the datetime that they were last updated at, from oldest to newest. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Sort the subscriptions by the datetime that they were last updated at, from newest to oldest. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type UnknownRevenueBreakdown = ChannelRevenueBreakdownBase &
  OrganicChannelRevenueBreakdown & {
    __typename?: 'UnknownRevenueBreakdown';
    /** User facing label for the channel */
    channel_label: Scalars['String'];
    /** New revenue for a channel. This is the sum of all revenue from first-time orders in the period */
    new_revenue?: Maybe<ChannelReportMonetary>;
    /** Returning revenue for a channel. This is the sum of all revenue from non-first-time orders in the period */
    returning_revenue?: Maybe<ChannelReportMonetary>;
  };

export type UpdateOrderInput = {
  /** Adjustments made to the final price. For example shipping, discounts, tax etc. Anything that changes the total of the items is be stored here.. On update, the existing list of `adjustments` are replaced with the new list if passed. */
  adjustments?: InputMaybe<Array<AdjustmentInput>>;
  /**
   * Any arbitrary information that you would like to be stored against a order.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The billing address, if any, for this order. */
  billing_address?: InputMaybe<InputAddress>;
  /** The marketing channel that was used that ended up in the order being created. Example: WEB. */
  channel?: InputMaybe<Channel>;
  /** The datetime that this order record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at?: InputMaybe<Scalars['DateTime']>;
  /** A valid currency code following ISO 4217. The `adjustments` and `items` are assumed to have this currency. */
  currency?: InputMaybe<Currency>;
  /**
   * A list of discount codes that were used on this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  discount_codes?: InputMaybe<Array<Scalars['String']>>;
  /** Fulfillments associated with this order. See the types `Fulfillment` and `FulfillmentStatus` for more information. */
  fulfillments?: InputMaybe<Array<FulfillmentInput>>;
  /** Purchasable items in the order. */
  items?: InputMaybe<Array<OrderItemInput>>;
  /** Optional free form notes related to the order. */
  notes?: InputMaybe<Scalars['String']>;
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
  /** Deprecated. The payment status is now automatically calculated. Please do not use. */
  payment_status?: InputMaybe<PaymentStatus>;
  /**
   * The id of the profile that the order belongs to , if known.The profile_id of the profile that this order belongs to. This is optional for where the order has been made by an unknown profile.
   * If the `profile_id` is not set when creating an order, then it is taken from this order's corresponding cart, if it exists.
   * When updating the order's `profile_id` to a non-null value, the corresponding cart and any corresponding returns will have their `profile_id` set to the same value.
   * If the profile_id has already been set, then the profile_id will not be changed.
   */
  profile_id?: InputMaybe<Scalars['UUID']>;
  /** The shipping address, if any, for this order. Can be different from the billing address. */
  shipping_address?: InputMaybe<InputAddress>;
  /** The marketing attribution source that was used that ended up in this order being created. */
  source?: InputMaybe<Scalars['String']>;
  /** The current status of the order. Default value is 'CREATED'. */
  status?: InputMaybe<Status>;
  /** Deprecated. Has been made redundant. Please use `provider` instead */
  store_identifier?: InputMaybe<Scalars['String']>;
  /** The id of the subscription associated with the order. If this field is set, `type` must be "SUBSCRIPTION". Omit if the order does not belong to a subscription. */
  subscription_id?: InputMaybe<Scalars['String']>;
  /**
   * The subscription type of the order. Default value is 'NOT_APPLICABLE'.
   *
   * Only certain values of `subscription_type` are allowed based on the `type`. See the description of `type` for the set of various combinations.
   */
  subscription_type?: InputMaybe<OrderSubscriptionType>;
  /**
   * A list of tags for this order.
   *
   * Note that while the GraphQL spec allows for passing in single strings as string array arguments, this is not recommended.
   * To avoid ambiguity we recommend always passing in a list, even if it only has a single element.
   */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The type of the order. Default value is 'STANDARD'.
   *
   *   The `type` and `subscription_type` are only allowed certain combinations. Valid combinations are the following:
   *
   *   | `type`         | `subscription_type` |
   *   |----------------|---------------------|
   *   | `STANDARD`     | `NOT_APPLICABLE`    |
   *   | `SUBSCRIPTION` | `FIRST_TIME`        |
   *   | `SUBSCRIPTION` | `ONE_OFF`           |
   *   | `SUBSCRIPTION` | `RECURRING`         |
   *   | `SUBSCRIPTION` | `UNKNOWN`           |
   *   | `ERASED`       | `ERASED`            |
   *
   *   Note that `STANDARD` order type can only have a `subscription_type` of `NOT_APPLICABLE`, whereas
   *   `SUBSCRIPTION` order `type` can never have a `subscription_type` of `NOT_APPLICABLE`.
   *
   *   Note that an erased order will have a `type` and `subscription_type` of `ERASED`. However, the GraphQL API does not
   *   allow the explicit setting of order `type`/`subscription_type` values to `ERASED`.
   */
  type?: InputMaybe<OrderType>;
};

export type UpdateOrderOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** Parameters to update a product */
export type UpdateProductInput = {
  /** If this product is being used in Google Ads, this is the absolute URL that the ad would take them. This can be different to `link` or `mobile_link`. */
  ad_link?: InputMaybe<Scalars['String']>;
  /** What age group, if any, this product is suitable for. */
  age_group?: InputMaybe<AgeGroup>;
  /**
   * Any arbitrary information that you would like to be stored against a product.
   *     Note that for input this will need to be a stringified JSON object.
   *     You can also expect to get a stringified JSON object upon `attributes` retrieval.
   */
  attributes?: InputMaybe<Scalars['Json']>;
  /** The availability status of this product. */
  availability?: InputMaybe<ProductAvailability>;
  /** An array of images for this product. */
  brand?: InputMaybe<Scalars['String']>;
  /** For Google Ads, what product category from Google's product taxonomy this product sits under. */
  category?: InputMaybe<Scalars['String']>;
  /** The primary color of this product. Solve does not support multiple colors per product. */
  color?: InputMaybe<Scalars['String']>;
  /** What condition this product is expected to be in */
  condition?: InputMaybe<ProductCondition>;
  /** The cost of goods sole (COGS) for this product to help with reporting, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  cost_of_goods_sold?: InputMaybe<Scalars['String']>;
  /** An optional free form description of the product. */
  description?: InputMaybe<Scalars['String']>;
  /** The energy efficiency class of this product. */
  energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** For Google Ads, this can be used to prevent this product from participating in certain types of ads. */
  excluded_destination?: InputMaybe<Scalars['String']>;
  /** The expiry date of this product, if any. */
  expiration_date?: InputMaybe<Scalars['DateTime']>;
  /** What gender, if any, this product is suitable for. This is a free form string. */
  gender?: InputMaybe<Scalars['String']>;
  /** The Global Trade Item Numbers (GTIN). A GTIN uniquely identifies your product. */
  gtin?: InputMaybe<Scalars['String']>;
  /** An array of images for this product. */
  images?: InputMaybe<Array<InputMaybe<ProductImageInput>>>;
  /** For Google Ads, this can be used to ensure this product only participates in certain types of ads. */
  included_destination?: InputMaybe<Scalars['String']>;
  /** If this product can be paid for installments, this defines how much per month a purchaser can expect to pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  installment_amount?: InputMaybe<Scalars['String']>;
  /** If this product can be paid for installments, this defines how many months a purchaser can expect to be paying. */
  installment_months?: InputMaybe<Scalars['Int']>;
  /** How many of this product you have in stock. */
  inventory?: InputMaybe<Scalars['Int']>;
  /** Whether this is an adults only product. */
  is_adult?: InputMaybe<Scalars['Boolean']>;
  /** Indicates that you’ve created a bundle: a main product that you’ve grouped with other, different products, sold together as one package for a single price. */
  is_bundle?: InputMaybe<Scalars['Boolean']>;
  /**
   * Set to `false` if this product is a base product, `true` if this product is a variant of a base product.
   * Variants would include different size and colour combinations of the base product.
   * Variants have a `variant_group_id` which is the `id` of their corresponding base product.
   */
  is_variant?: InputMaybe<Scalars['Boolean']>;
  /** This determines the date the product becomes available. */
  launch_date?: InputMaybe<Scalars['DateTime']>;
  /** An absolute URL for this product. */
  link?: InputMaybe<Scalars['String']>;
  /** The name of any loyalty points program. */
  loyalty_points_name?: InputMaybe<Scalars['String']>;
  /** The ratio of earned points to currency. */
  loyalty_points_ratio?: InputMaybe<Scalars['Float']>;
  /** The number of points that this product gives the purchaser under the relevant loyalty points program. */
  loyalty_points_value?: InputMaybe<Scalars['Float']>;
  /** The primary material of this product. Solve does not support multiple materials per product. */
  material?: InputMaybe<Scalars['String']>;
  /** The maximum energy efficiency class of this product. */
  max_energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the largest possible timespan between purchasing and shipment. */
  max_handling_time?: InputMaybe<Scalars['String']>;
  /** The minimum energy efficiency class of this product. */
  min_energy_efficiency_class?: InputMaybe<EnergyEfficiencyClass>;
  /** Used to give users accurate information about how long it will take for a product to arrive at its destination. This is the smallest possible timespan between purchasing and shipment. */
  min_handling_time?: InputMaybe<Scalars['String']>;
  /** An absolute URL for this product for mobile devices. */
  mobile_link?: InputMaybe<Scalars['String']>;
  /** The Manufacturer Part Number (MPN). MPNs are used to uniquely identify a specific product among all products from the same manufacturer. */
  mpn?: InputMaybe<Scalars['String']>;
  /** Indicates that you have grouped multiple products together for sale. */
  multipack?: InputMaybe<Scalars['Int']>;
  /** How much this product costs during a one off special offer. An offer can exist for a product at the same time as a sale. */
  offer_price?: InputMaybe<Scalars['String']>;
  /** The end of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_end_date?: InputMaybe<Scalars['DateTime']>;
  /** The start of the date that this product is on special offer. You can only have one offer date range at a time per product. */
  offer_price_start_date?: InputMaybe<Scalars['DateTime']>;
  /** The primary display pattern of this product. Solve does not support multiple patterns per product. */
  pattern?: InputMaybe<Scalars['String']>;
  /** The selling price of this product, consisting of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  price?: InputMaybe<Scalars['String']>;
  /** If this product uses Google's Merchant Promotions, this can be used in both your product data and promotions data to match products to promotions. */
  promotion_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** An optional free form rich text description of the product. As opposed to just the `description`, here you can include control characters and HTML tags, such as line breaks, italics, and bulleted lists. */
  rich_text_description?: InputMaybe<Scalars['String']>;
  /** How much this product costs during a sale. A product can be on sale the same time it has a special offer. */
  sale_price?: InputMaybe<Scalars['String']>;
  /** The end of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_end_date?: InputMaybe<Scalars['DateTime']>;
  /** The start of the date that this product is on sale. You can only have one sale date range at a time per product. */
  sale_price_start_date?: InputMaybe<Scalars['DateTime']>;
  /** For use in Google Ads. Separate from `inventory`, this is how many of this product you will sell via Google Ads. */
  sell_on_google_quantity?: InputMaybe<Scalars['Int']>;
  /** All available shipping options for this product. */
  shipping?: InputMaybe<Array<InputMaybe<ProductShippingInput>>>;
  /** The height of the product when shipping. */
  shipping_height?: InputMaybe<Scalars['String']>;
  /** This allows the grouping of multiple products together under the same shipping rates. For example, `oversized`. */
  shipping_label?: InputMaybe<Scalars['String']>;
  /** The weight of the product when shipping. */
  shipping_weight?: InputMaybe<Scalars['String']>;
  /** The width of the product when shipping. */
  shipping_width?: InputMaybe<Scalars['String']>;
  /** The size of the product. Used in conjunction with `size_system`. For example, `xl`. */
  size?: InputMaybe<Scalars['String']>;
  /** Describes which country's size system this product uses. */
  size_system?: InputMaybe<SizeSystem>;
  /** The size type can be used to describe the cut of the product. Used in conjunction with `size_system`. For example, `regular`. */
  size_type?: InputMaybe<Scalars['String']>;
  /** The Stock Keeping Unit for the product. This is optional. It is not required to be unique among all products saved in Solve. */
  sku?: InputMaybe<Scalars['String']>;
  /** If this product is a subscription, this defines how much per period the purchaser must pay. It should consist of the amount and the ISO 4217 currency code. For example, 100.00 USD. */
  subscription_cost_amount?: InputMaybe<Scalars['String']>;
  /** If this product is a subscription, this defines what that period is. */
  subscription_cost_period?: InputMaybe<SubscriptionCostPeriod>;
  /** If this product is a subscription, this defines the number of subscription periods that the purchaser must pay */
  subscription_cost_period_length?: InputMaybe<Scalars['Int']>;
  /** An array of tags related to this product. */
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Used if you want to set different tax rates for specific groups of products. For example, 'perishable_foods' or 'clothing'. */
  tax_category?: InputMaybe<Scalars['String']>;
  /** Any taxes applicable to this product. */
  taxes?: InputMaybe<Array<InputMaybe<ProductTaxInput>>>;
  /** The title of this product. This is the title that you would display to your customers. */
  title?: InputMaybe<Scalars['String']>;
  /** The transit time labels help give your customers a more accurate estimated delivery time. */
  transit_time_label?: InputMaybe<Scalars['String']>;
  /** What type of product this is. This is free form and not restricted to any particular values. */
  type?: InputMaybe<Scalars['String']>;
  /** This defines the denominator for your unit price. For example, you might be selling 150ml of perfume, but users are interested in seeing the price per 100ml. */
  unit_price_base_measure?: InputMaybe<Scalars['String']>;
  /** This defines the measure and dimension of the product. This value allows users to understand the exact cost per unit for your product. For example, 750ml. */
  unit_price_measure?: InputMaybe<Scalars['String']>;
  /** For a variant, it is set to the `id` of the base product. `Is `null` for a base product. */
  variant_group_id?: InputMaybe<Scalars['String']>;
  /** The visibility status of this product. */
  visibility?: InputMaybe<ProductVisibility>;
};

export type UpdateSubscriptionOptions = {
  /** The time that this action actually occurred. When provided, the time that is emitted on the events generated by this mutation will be this value. If the mutation creates something, the `created_at` (if provided) time will override the provided `occurred_at` time. If neither are provided then NOW() is used for both created_at and the emitted Event time */
  occurred_at?: InputMaybe<Scalars['DateTime']>;
};

/** The input type necessary for updating an existing user. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type UpdateUserInput = {
  /** The name for this user. */
  name?: InputMaybe<Scalars['String']>;
  /** The user's role. Determines what a user is allowed to do in Solve. */
  role?: InputMaybe<UserRole>;
};

/** A single user record. It is created using the `create_user` mutation. */
export type User = {
  __typename?: 'User';
  /** The datetime that this user record was created (as opposed to the `inserted_at` value which is the time this record was inserted into the database). */
  created_at: Scalars['DateTime'];
  /** The email that this user uses to log in. */
  email: Scalars['Email'];
  /** The user id. This is generated and returned via the `create_user` mutation and is used for primary key reference to the user in other queries and mutations. */
  id: Scalars['UUID'];
  /** The name for this user. */
  name: Scalars['String'];
  /** The user's role. Determines what a user is allowed to do in Solve. */
  role: UserRole;
  /** The user's status. This is automatic and not configurable. */
  status: UserStatus;
  /** The datetime that this user record was last modified. */
  updated_at?: Maybe<Scalars['DateTime']>;
};

/** The return object for multiple users. The `nodes` property contains all the users themselves, while the `page_info` property contains all information used for pagination. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of users. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<User>;
  /** All the paginiation info that you will need for reference when navigating through a large list of users. */
  page_info: BlankPageInfo;
};

/**
 * The possible values for a user's `role`. This determines what a user is allowed to do in Solve.
 * - Both roles can see data
 * - Only ADMINs can manage users and API keys
 */
export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

/**
 * The user's status. If anything is out of order with the user account,
 * this indicates any actions required.
 */
export enum UserStatus {
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Confirmed = 'CONFIRMED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN'
}

/** A single webhook record. It is created using the `create_webhook` mutation. */
export type Webhook = {
  __typename?: 'Webhook';
  /** A JSON object that defines when the data is sent to the webhook. The only currently supported filter is `event_types` */
  filter?: Maybe<Scalars['Json']>;
  /** The webhook id. This is generated and returned via the `create_webhook` mutation and is used for primary key reference to the webhook in other queries and mutations. */
  id: Scalars['Int'];
  /** The datetime that this webhook record was inserted into the database. */
  inserted_at: Scalars['DateTime'];
  /** Whether the webhook record has been set to a `disabled` state. Defaults to `false` on webhook creation. */
  is_disabled: Scalars['Boolean'];
  /** The name of the webhook. */
  name: Scalars['String'];
  /** The name of the webhook provider. */
  provider?: Maybe<Scalars['String']>;
  /** The datetime that this webhook record was last modified. */
  updated_at: Scalars['DateTime'];
  /** The url of the webhook that the data is sent to. */
  url: Scalars['String'];
};

/** The return object for multiple webhooks. The `nodes` property contains all the webhooks themselves, while the `page_info` property contains all information used for pagination. */
export type WebhookConnection = {
  __typename?: 'WebhookConnection';
  /** A list of webhooks. Whenever you use the API to retrieve a list of records, by convention the records are inside the `nodes` property which is inside a parent `connection` type. */
  nodes: Array<Webhook>;
  /** All the paginiation info that you will need for reference when navigating through a large list of webhooks. */
  page_info: PageInfo;
};

/** The input type necessary for creating or updating a webhook. View the fields inside this type for more information about which fields are mandatory and which are optional. */
export type WebhookInput = {
  /** A JSON object that defines when the data is sent to the webhook. The only currently supported filter is `event_types` */
  filter?: InputMaybe<Scalars['Json']>;
  /** The name of the webhook. */
  name: Scalars['String'];
  /** The name of the webhook provider. */
  provider?: InputMaybe<Scalars['String']>;
  /** The url of the webhook that the data is sent to. */
  url: Scalars['String'];
};
