import cn from 'classnames';
import sample from 'lodash/sample';

import { Table } from '../apiKeySection/apiKeysTable/skeleton';

const Header = () => (
  <>
    {/* ID */}
    <td className='p-2 w-[590px]'>
      <div className='loader-box rounded w-4 h-4'></div>
    </td>
    {/* Last Updated */}
    <td className='p-2 w-[285px]'>
      <div className='loader-box rounded w-24 h-4'></div>
    </td>
  </>
);

const Row = () => {
  const firstRowWidth = sample(['w-36', 'w-24']);

  return (
    <>
      {/* ID */}
      <td className='px-2 py-2.5'>
        <div className={cn('loader-box rounded h-4', firstRowWidth)}></div>
      </td>
      {/* Last Updated */}
      <td className='px-2 py-2.5'>
        <div className='loader-box rounded h-4 w-16'></div>
      </td>
      {/* Edit button */}
      <td className='px-2 py-[9px]'>
        <div className='loader-box rounded w-14 h-[39px]'></div>
      </td>
    </>
  );
};

const Skeleton = () => (
  <div data-testid='skeleton-loader'>
    <div className='relative mt-7 pt-px'>
      <Table headerComponent={<Header />} rowComponent={<Row />} />

      <div className='absolute bg-gradient-to-t from-white top-0 w-full h-full z-50'></div>
    </div>
  </div>
);

export default Skeleton;
