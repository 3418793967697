import InfiniteScroll from 'react-infinite-scroller';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';

import { apiKeysQuery } from 'queries/apiKey';

import ErrorAlert from 'components/errorAlert';

import Skeleton from './skeleton';
import APIKeysLayout from './layout';

const APIKeysTable = () => (
  <Query
    query={apiKeysQuery}
    variables={{ first: 100, page_index: 1 }}
    fetchPolicy='network-only'
  >
    {(props: QueryResult) => {
      const { data, error, loading, fetchMore } = props;
      if (loading) {
        return <Skeleton />;
      }

      if (error) {
        return <ErrorAlert error={error} />;
      }

      const { apiKeys } = data;

      const onLoadMore = () =>
        fetchMore({
          variables: {
            after: apiKeys.page_info.end_cursor,
            page_index: apiKeys.page_info.page_index + 1
          },
          updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
            const newApiKeys = fetchMoreResult.apiKeys.nodes;
            const page_info = fetchMoreResult.apiKeys.page_info;
            return {
              apiKeys: {
                __typename: previousResult.apiKeys.__typename,
                nodes: [...previousResult.apiKeys.nodes, ...newApiKeys],
                page_info
              }
            };
          }
        });
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={apiKeys.page_info.has_next_page}
          loader={
            apiKeys.page_info.has_next_page && (
              <div className='text-center text-blue-dark p-2' key={0}>
                Loading more apiKeys ...
              </div>
            )
          }
        >
          <APIKeysLayout apiKeys={data.apiKeys} />
        </InfiniteScroll>
      );
    }}
  </Query>
);

export default APIKeysTable;
