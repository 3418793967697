import { useState } from 'react';
import { Formik, Form } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { allPoliciesQuery, policyCreateMutation } from 'queries/policy';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';
import FormTextField from 'components/formComponents/formTextField';
import FormCustomCodeEditor from 'components/formComponents/formCustomCodeEditor';

import { validateCreatePolicyValues } from './policyFormUtils';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const EXISTING_POLICY_MSG = 'name: has already been taken';

export interface FormInputProps {
  document: string;
  id: string;
  is_solve_managed: boolean;
  name: string;
}

const DEFAULT_VALUES = {
  name: '',
  document: '',
  id: '',
  is_solve_managed: false
};

const AddPolicyForm = () => {
  return (
    <Form>
      <div className='px-5 pt-2 pb-5'>
        <FormTextField
          name='name'
          placeholder='Choose a name.'
          label='name'
          dataTestId='create-policy-name-input'
          errorDataTestId='create-policy-name-errors'
          type='text'
        />
        <div className='my-4'>
          <FormCustomCodeEditor
            name='document'
            placeholder='Write the API policy. Must be a JSON object. Defaults to "{"version":"V1","policy":[{"action":["*"],"resource":["api_policy"]}]}" if left empty.'
            label='document'
            dataTestId='create-policy-document-input'
            errorDataTestId='create-policy-document-errors'
            language={'json'}
          />
        </div>
        <div className='flex flex-row-reverse'>
          <Button
            type='submit'
            color='primary'
            className='px-8 mt-2 uppercase'
            data-testid='create-policy'
          >
            create
          </Button>
        </div>
      </div>
    </Form>
  );
};

const setupScreen = (payload: PolicyPayload, onClose: Props['onClose']) => {
  return (
    <div className='mt-2 mb-4 mx-5' data-testid='policy-form-info'>
      <p className='mb-5' data-testid='policy-form-name'>
        API Policy <strong>{payload.name}</strong> has been successfully
        created.
      </p>
      <div className='flex justify-end'>
        <Button
          type='button'
          color='primary'
          onClick={onClose}
          className='w-20'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const Setup = ({ onClose }: Props) => {
  const [setup, setSetup] = useState(0);
  const [payload, setPayload] = useState(DEFAULT_VALUES);

  return (
    <Mutation mutation={policyCreateMutation}>
      {(createPolicy: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes(EXISTING_POLICY_MSG)
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='A policy with this name already exists. Please choose a different name.'
                className='m-2'
              />
            );
          }

          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes('document:')
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='Invalid policy document. Please include: version, policy, action and resource'
                className='m-2'
              />
            );
          }
          return <ErrorAlert error={error} className='m-2' />;
        }

        return setup === 0 ? (
          <Formik
            initialValues={payload}
            validate={validateCreatePolicyValues}
            onSubmit={async (values: FormInputProps) => {
              // We need the defaultValue to be '' to show the placeholder but we want the default to be an empty map
              const policyDocument = JSON.parse(
                values.document ||
                  JSON.stringify({
                    version: 'V1',
                    policy: [{ action: ['*'], resource: ['api_policy'] }]
                  })
              );
              const gqlRes = await createPolicy({
                variables: {
                  input: {
                    name: values.name,
                    document: JSON.stringify({
                      ...policyDocument,
                      version: 'v1'
                    })
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: allPoliciesQuery }]
              });
              setPayload(gqlRes.data.create_api_policy);
              setSetup(1);
            }}
          >
            <AddPolicyForm />
          </Formik>
        ) : (
          setupScreen(payload, onClose) || null
        );
      }}
    </Mutation>
  );
};

export default Setup;
