import { useAuthContext } from 'context/auth';

import Copy from 'components/copy';

// Original source code(before minification):
/*
!(function (config, sdkUrl) {
  var script, ready;
  try {
    if (!window._solve) {
      window._solve = config;
      // appendChild returns the given node.
      script = document.head.appendChild(document.createElement("script"));
      script.src = sdkUrl;
    }
    if (!window.solve) {
      // Bind the `window._solve.ready` array's push method so
      //  when `window.solve.ready` is called the given function
      //  is added to the array.
      window.solve = { ready: [].push.bind((window._solve.ready = [])) };
    }
  } catch (e) {
    console.error(e);
  }
})(
  // Use `window.` to prevent terser from inlining the variables
  {
    apiKey: window.abc,
    apiUrl: window.cde,
  },
  window.efg
);
*/
function sdkSnippet(apiKey: string, apiUrl: string, sdkUrl: string) {
  return (
    `<script>` +
    // Create an immediately invoked function
    `!function(e,o){try{` +
    // Assign the configuration
    `window._solve||(window._solve=e,` +
    // Create a new script tag in the document's head and set the source to the SDK's URL
    `document.head.appendChild(document.createElement("script")).src=o),` +
    // Create a `window.solve.ready` function which pushes it's arguments onto the `window._solve.ready` array.
    `window.solve||(window.solve={ready:[].push.bind(window._solve.ready=[])})` +
    // Catch any errors we may have caused and print them to console.
    `}catch(e){console.error(e)}}(` +
    // The SDK configuration, use JSON.stringify to wrap the strings in quotes
    `{apiKey:${JSON.stringify(apiKey)},apiUrl:${JSON.stringify(apiUrl)}},` +
    // The SDK URL
    `${JSON.stringify(sdkUrl)}` +
    `)` +
    `</script>`
  );
}

const WebSdkCode = ({ data }: any) => {
  const { baseUrls } = useAuthContext();

  const script = sdkSnippet(
    data.key,
    baseUrls!.api,
    `${baseUrls!.static}/sdk/sdk.js`
  );
  return (
    <>
      <div className='px-5 pt-2 pb-5'>
        <p>
          This is your Solve initialization code. Copy and paste the following
          snippet into the head of your HTML page.
        </p>
      </div>
      <div className='relative'>
        <textarea
          rows={8}
          className='p-6 text-gray-200 text-xs w-full border-0 bg-gray-800 font-mono rounded'
          id='publicCode'
          readOnly
          value={script}
          data-testid='web-sdk-key-script'
        />

        <Copy
          className='absolute top-0 right-0 mt-2 mr-2 text-white'
          el='publicCode'
          showText={false}
        />
      </div>
    </>
  );
};

export default WebSdkCode;
