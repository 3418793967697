/**
 * This store is used to keep track of saved queries. It does not store any UI state or
 * deal with executing these queries.
 */
import { client } from 'apolloClient';
import orderBy from 'lodash/orderBy';
import { makeObservable, observable, runInAction, action } from 'mobx';

import {
  allSavedQueriesQuery,
  createQuery,
  updateQuery,
  deleteQuery,
  singleSavedQuery
} from 'queries/query';

interface FilterInputProps {
  order_by: string;
  search_term?: string;
  is_visible?: boolean;
  after?: string;
  first: number;
}

export type CreateInput = {
  name: string;
  description?: string;
  query: string;
};

export default class Store {
  queries: Query[] = [];
  tabsQueries: Query[] = [];
  scheduledQuery?: boolean = false;
  after = '';
  cursors: Array<string> = [];
  has_prev_page = false;
  savedQueriesPagination = {} as Pagination;
  fetching = false;
  searchData = '';
  visibility = true;
  tabIds: number[] = [];
  query = {};

  constructor() {
    makeObservable(this, {
      query: observable,
      queries: observable,
      tabsQueries: observable,
      searchData: observable,
      visibility: observable,
      setVisibility: action,
      setSearchData: action,
      tabIds: observable,
      addTabsIds: action
    });
  }

  getById = (savedQueryId: number) => {
    const tabQuery = this.tabsQueries.find(q => {
      return q.id === savedQueryId;
    });

    if (tabQuery) {
      return tabQuery;
    } else {
      return this.queries.find(query => {
        return query.id === savedQueryId;
      });
    }
  };

  getFeatureFlag = (data: boolean) => {
    this.scheduledQuery = data;
    return this.scheduledQuery;
  };

  /**
   * Fetches all saved queries from the DB
   */
  async fetchAll() {
    this.fetching = true;

    if (!client) {
      throw new Error('Saved Query store - Apollo client not set');
    }

    const filterInput = {} as FilterInputProps;

    Object.assign(
      filterInput,
      !!this.after && { after: this.after },
      !!this.searchData && { search_term: this.searchData },
      this.scheduledQuery && {
        is_visible: this.visibility
      },

      { order_by: 'NAME_ASC', first: 20 }
    );

    const result = await client.query({
      query: allSavedQueriesQuery,
      variables: filterInput,
      fetchPolicy: 'network-only'
    });

    // when cursors list is empty it means that we are on the first page
    // and we should disable previous button
    if (this.cursors.length === 0) {
      this.has_prev_page = false;
    }

    runInAction(() => {
      // TODO - what happens if there are no `nodes` due to an error?
      this.queries = result.data.queries.nodes;
      this.savedQueriesPagination = result.data.queries.page_info;
      this.sortQueriesByName();
      this.fetching = false;
    });
  }

  /**
   * Fetches single saved query from the DB
   */
  async fetch(savedQueryId: number) {
    this.fetching = true;

    if (!client) {
      throw new Error('Saved Query store - Apollo client not set');
    }

    const result = await client.query({
      query: singleSavedQuery,
      variables: { id: savedQueryId, is_visible: null },
      fetchPolicy: 'network-only'
    });

    return new Promise<Query>((resolve, reject) => {
      try {
        runInAction(() => {
          this.query = result.data.query;
          resolve(result.data.query);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Fetches tabs by given query ids
   */
  async fetchTabs(ids: number[]) {
    this.fetching = true;

    if (!client) {
      throw new Error('Saved Query store - Apollo client not set');
    }

    const result = await client.query({
      query: allSavedQueriesQuery,
      variables: { ids, is_visible: null },
      fetchPolicy: 'network-only'
    });

    runInAction(() => {
      this.tabsQueries = result.data.queries.nodes;
      this.fetching = false;
    });
  }

  /**
   * Add query id to tabs list
   */
  addTabsIds = (tabId: number) => {
    if (!this.tabIds.includes(tabId)) {
      this.tabIds = this.tabIds.concat(tabId);
    }
  };

  /**
   * Creates a new saved query in the DB
   */
  async create(values: CreateInput) {
    const result = await client!.mutate({
      mutation: createQuery,
      variables: {
        input: values
      }
    });

    return new Promise<Query>((resolve, reject) => {
      try {
        runInAction(() => {
          this.queries = [result.data.createQuery].concat(this.queries);
          this.tabsQueries = [result.data.createQuery].concat(this.tabsQueries);

          this.sortQueriesByName();
          resolve(result.data.createQuery);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Updates an existing saved query in the DB
   */
  async update(savedQueryId: number, values: CreateInput) {
    const result = await client!.mutate({
      mutation: updateQuery,
      variables: {
        id: savedQueryId,
        input: values
      }
    });

    return new Promise((resolve, reject) => {
      try {
        runInAction(() => {
          this.queries = this.queries.map(q => {
            if (q.id !== savedQueryId) {
              return q;
            }

            return {
              ...q,
              ...values
            };
          });

          this.tabsQueries = this.tabsQueries.map(q => {
            if (q.id !== savedQueryId) {
              return q;
            }

            return {
              ...q,
              ...values
            };
          });
          this.sortQueriesByName();
          resolve(result.data.updateQuery);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Delete an existing saved query in the DB
   */
  async delete(savedQueryId: number) {
    const result = await client!.mutate({
      mutation: deleteQuery,
      variables: {
        id: savedQueryId
      }
    });
    return new Promise((resolve, reject) => {
      try {
        runInAction(() => {
          // Filter out the deleted query
          this.queries = this.queries.filter(q => q.id !== savedQueryId);
          this.tabsQueries = this.tabsQueries.filter(
            q => q.id !== savedQueryId
          );
          resolve(result.data.deleteQuery);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  sortQueriesByName() {
    this.queries = orderBy(this.queries, ['name']);
  }

  setSearchData = (value: string) => {
    this.searchData = value;
  };

  setVisibility = (value: boolean) => {
    this.visibility = value;
  };

  setDirection = (direction?: string) => {
    switch (direction) {
      case 'forward':
        this.cursors.push(this.savedQueriesPagination.end_cursor);
        this.after = this.savedQueriesPagination.end_cursor;
        // we have a prev page enable now
        if (this.savedQueriesPagination.has_next_page) {
          this.has_prev_page = true;
        }
        break;
      case 'back':
        // when push back button
        // remove last cursor from the list of cursors
        // get last element in cursors list
        if (this.cursors.length > 0) {
          this.cursors = this.cursors.slice(0, -1);
          this.after = this.cursors[this.cursors.length - 1];
        }
        break;
      default:
        this.cursors = [];
        this.after = '';
    }
  };
}

export const savedQueryStore = new Store();
