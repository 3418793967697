import cn from 'classnames';
import { ErrorMessage, Field, FieldProps } from 'formik';

import Label from 'components/label';
import CustomCodeEditor from 'components/formik/customCodeEditor';

const FormError = ({
  name,
  dataTestId
}: {
  name: string;
  dataTestId: string;
}) => (
  <ErrorMessage
    name={name}
    component='p'
    className='flex-auto self-end pl-1 text-red-500'
    data-testid={dataTestId}
  />
);

interface Props {
  name: string;
  placeholder: string;
  label: string;
  dataTestId: string;
  errorDataTestId: string;
  language: 'json' | 'js';
  height?: string;
}

const FormCustomCodeEditor = ({
  name,
  placeholder,
  label,
  dataTestId,
  errorDataTestId,
  language,
  height
}: Props) => (
  <Field name={name} type='text'>
    {({ meta, field }: FieldProps) => (
      <>
        <Label
          htmlFor={name}
          className={cn({
            '!text-red-500': meta.error && meta.touched
          })}
        >
          {label}
        </Label>
        <div className='mt-1'>
          <CustomCodeEditor
            configurationData={field}
            values={field}
            name={name}
            defaultValue={placeholder}
            data-testid={dataTestId}
            language={language}
            height={height ?? '200px'}
          />
          <FormError name={name} dataTestId={errorDataTestId} />
        </div>
      </>
    )}
  </Field>
);

export default FormCustomCodeEditor;
