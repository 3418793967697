import Label from 'components/label';
import CustomCodeViewer from 'components/formik/customCodeViewer';

import { FormInputProps } from './setup';

const stringifyJSONReadable = (object: Record<string, any>) => {
  return JSON.stringify(object, null, 4);
};

const View = ({ policyData }: { policyData: FormInputProps }) => (
  <div
    className='px-5 pt-2 pb-5 overflow-y-auto max-h-sm'
    data-testid='policy-view-form'
  >
    <Label>document</Label>
    <div className='mt-1'>
      <CustomCodeViewer
        defaultValue={stringifyJSONReadable(JSON.parse(policyData.document))}
        data-testid='policy-view-document'
        language='json'
        height='200px'
      />
    </div>
  </div>
);
export default View;
