import { Link } from 'react-router-dom';
import { components } from 'react-select';

import { derivedFullName } from 'pages/profiles/helper';

import MatchItem from './matchItem';

import { ReactComponent as Profile } from 'resources/icons/user.svg';

const ProfileItem = ({ data, innerProps, innerRef, props }: any) => {
  const {
    first_name,
    last_name,
    full_name,
    profile_id,
    addresses,
    email,
    phone
  }: ProfileResultRecord = data.record;

  const {
    first_name: highlight_first_name,
    last_name: highlight_last_name,
    full_name: highlight_full_name,
    addresses: highlight_addresses,
    email: highlight_email,
    phone: highlight_phone
  }: ProfileResultHighlight = data.highlightHtml;

  const resultProfileFullName = derivedFullName(data.record);

  // build fullName for highlighted result
  const firstName = highlight_first_name || first_name || '';
  const lastName = highlight_last_name || last_name || '';

  const highlightProfileFullName =
    highlight_full_name ||
    (firstName || lastName ? `${firstName} ${lastName}` : full_name);

  // results look ugly with long lists of addresses, so we only show the
  // first one in the search dropdown. If there is a match on an address,
  // the first match is shown instead.
  const resultAddress = addresses?.slice(0, 1) || null;
  const highlightAddress = highlight_addresses?.slice(0, 1) || null;

  return (
    <components.Option {...props}>
      <div
        className='p-1 custom-option'
        ref={innerRef}
        {...innerProps}
        data-testid={
          props.isFocused ? 'active-profile-item' : 'non-active-profile-item'
        }
      >
        <Link to={`profiles/${profile_id}`} className='flex flex-row gap-2'>
          <div>
            <Profile className='w-5 h-5' />
          </div>
          <div>
            <MatchItem
              resultItem={resultProfileFullName}
              highlightItem={highlightProfileFullName}
              className='font-bold text-gray-700'
            />
            <MatchItem
              resultItem={email}
              highlightItem={highlight_email}
              className='text-gray-600 pr-2'
            />
            <MatchItem
              resultItem={phone}
              highlightItem={highlight_phone}
              className='text-gray-600 pr-2'
            />
            <MatchItem
              resultItem={resultAddress}
              highlightItem={highlightAddress}
              className='text-gray-500 pr-2'
            />
          </div>
        </Link>
      </div>
    </components.Option>
  );
};

export default ProfileItem;
