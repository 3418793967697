import { Auth } from '@aws-amplify/auth';

import logger from 'utils/logger';

interface PoolData {
  id: string;
  webClientId: string;
}

export default class AWSCognito implements AuthInterface {
  private poolData: PoolData;
  private currentUser?: AuthUser;

  constructor(poolData: PoolData) {
    this.poolData = poolData;
    Auth.configure({
      userPoolId: poolData.id,
      userPoolWebClientId: poolData.webClientId
    });
  }

  getPoolData = () => {
    return this.poolData;
  };

  signIn = async (username: string, password: string) => {
    const user = await Auth.signIn({ username, password });
    this.currentUser = {
      authUser: user,
      username: user.attributes ? user.attributes.email : null,
      challenge: user.challengeName
    };

    return this.currentUser;
  };

  signOut = async () => {
    await Auth.signOut();
  };

  // `bypassCache` - will bypass the Cognito cache. Needed
  // if emails have changed or been verified, for example
  getCurrentUser = async (bypassCache = false) => {
    if (this.currentUser && !bypassCache) {
      return this.currentUser;
    }

    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true
    });
    this.currentUser = {
      authUser: user,
      username: user.attributes ? user.attributes.email : null,
      challenge: user.challengeName
    };

    return this.currentUser;
  };

  getJwtToken = async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken();

  forgotPassword = async (username: string) => {
    await Auth.forgotPassword(username);
  };

  forgotPasswordSubmit = async (
    username: string,
    password: string,
    code?: string
  ) => {
    if (!code) {
      throw new Error('Code is required for AWS Cognito');
    }

    await Auth.forgotPasswordSubmit(username, code, password);

    // Revoke all existing access & refresh tokens from all sessions.
    // This is to ensure any compromised sessions cannot authenticate with the
    // Cognito IDP endpoint to update the current user.
    // Nor can they use their existing refresh token to reissue new ID tokens
    // for authenticating with the Solve API.
    //
    // Note the existing session's ID tokens will be valid for authenticating with the Solve API
    // until they expire in at most an hours time. This is because the Solve API relies on short
    // lived JWTs (1 hour) instead relying on checking whether the JWT has been revoked.
    try {
      await Auth.signIn({ username, password });
      await Auth.signOut({ global: true });
    } catch (err) {
      // Log a Sentry error with an explicit message so we will know if this fails
      // under certain circumstances.
      logger.error(
        err,
        'Failed to sign out all sessions after forgot password reset'
      );
    }
  };

  setNewPassword = async (password: string) => {
    await Auth.completeNewPassword(this.currentUser!.authUser, password, {});
    const user = await this.getCurrentUser();
    return user;
  };
}
