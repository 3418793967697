import cn from 'classnames';

import Panel from 'components/panel';

interface LoaderProps {
  className?: string;
  darkMode?: boolean;
}

const Item = ({ darkMode, className }: LoaderProps) => (
  <div
    className={cn('loader-box h-5 mb-3', className, {
      'loader-box--dark': darkMode
    })}
  />
);

const Loader = ({ className, darkMode }: LoaderProps) => (
  <Panel className={cn('p-6', className)} data-testid='loader' darkMode>
    <Item darkMode={darkMode} className='w-32' />
    <div className='pl-6'>
      <Item darkMode={darkMode} className='w-full' />
      <Item darkMode={darkMode} className='w-full' />
      <Item darkMode={darkMode} className='w-1/3' />
    </div>
  </Panel>
);

export default Loader;
