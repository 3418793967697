import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useAuthContext } from 'context/auth';
import { useFeatureFlagsContext } from 'context/featureFlags';

import paths, { settingsPaths } from 'routes.paths';

import Tooltip from 'components/tooltip';
import Wrapper from 'components/wrapper';

import ReportsSubNav from './reportsSubNav';

import { ReactComponent as MenuIcon } from 'resources/icons/menu.svg';

interface MenuItemType {
  url: ValueOf<typeof paths>;
  text: string;
  isHidden?: boolean;
  subNav?: ReactElement;
}

interface Props {
  className?: string;
  dropdownClassName?: string;
}

export const MenuTooltip = (menuItems: MenuItemType[]) => {
  return (
    <ul className='w-32 text-sm py-2'>
      {menuItems.map(item => (
        <li
          key={item.url}
          className={cn('text-left', {
            hidden: item.isHidden
          })}
          data-testid={`${item.url.replace(/\//gi, '')}-menu-item`}
        >
          <Wrapper
            condition={!!item.subNav}
            wrap={(children: ReactElement) => (
              <Tooltip
                html={item.subNav}
                position='left-start'
                trigger='mouseenter'
                distance={0}
              >
                {children}
              </Tooltip>
            )}
          >
            <NavLink
              key={item.url}
              to={item.url}
              className={({ isActive }) =>
                cn(
                  'block py-1 px-4 hover:bg-gray-200 cursor-pointer',
                  isActive && 'text-blue-400'
                )
              }
            >
              {item.text}
            </NavLink>
          </Wrapper>
        </li>
      ))}
    </ul>
  );
};

const Menu = ({ className, dropdownClassName }: Props) => {
  const { flags } = useFeatureFlagsContext();
  const location = useLocation();

  const channelsPath = flags.channelReport && paths.channels;
  const conversionsPath = flags.conversionReport && paths.conversions;
  const campaignsLinkPath = location.search
    ? location.pathname + location.search
    : paths.campaigns;

  const { config } = useAuthContext();
  const region = config?.solve.region;
  const clientName = config?.solve.clientName;

  const quickSightUrl = `https://${region}.quicksight.aws.amazon.com/sn/start/dashboards?directory_alias=solve-${clientName}`;

  const menuItems: MenuItemType[] = [
    {
      url: paths.profiles,
      text: 'Profiles'
    },
    {
      url: paths.orders,
      text: 'Orders'
    },
    {
      url: paths.reports,
      text: 'Reports',
      subNav: (
        <ReportsSubNav
          urls={{
            ...(channelsPath && { channels: channelsPath }),
            ...(conversionsPath && { conversions: conversionsPath }),
            campaigns: campaignsLinkPath,
            ...(quickSightUrl && { biDashboards: quickSightUrl })
          }}
        />
      )
    },
    {
      url: paths.queryEngine,
      text: 'Query Engine'
    }
  ];

  const { isAdmin } = useAuthContext();

  if (isAdmin) {
    menuItems.push({
      url: settingsPaths.root,
      text: 'Settings'
    });
  }

  return (
    <>
      <ul className={cn('my-0 p-0', className)}>
        {menuItems.map(item => (
          <li
            key={item.url}
            className={cn('inline-block', {
              hidden: item.isHidden
            })}
            data-testid={`${item.url.replace(/\//gi, '')}-menu-item`}
          >
            <Wrapper
              condition={!!item.subNav}
              wrap={(children: ReactElement) => (
                <Tooltip
                  html={item.subNav}
                  position='bottom-start'
                  trigger='mouseenter'
                  distance={-5}
                >
                  {children}
                </Tooltip>
              )}
            >
              <NavLink
                key={item.url}
                to={item.url}
                className={({ isActive }) =>
                  cn(
                    'menu-item px-4 inline-block align-middle lh-16 relative text-gray-300 hover:text-white',
                    isActive && 'menu-item-active text-gray-100'
                  )
                }
              >
                {item.text}
              </NavLink>
            </Wrapper>
          </li>
        ))}
      </ul>
      <Tooltip
        className={dropdownClassName}
        position='bottom-end'
        trigger='click'
        useContext
        html={MenuTooltip(menuItems)}
        popperOptions={{
          modifiers: {
            preventOverflow: { enabled: false },
            flip: { enabled: false },
            hide: { enabled: false },
            arrow: { padding: { right: 100 } }
          }
        }}
      >
        <MenuIcon className='w-8 h-8 text-gray-500 hover:text-white cursor-pointer' />
      </Tooltip>
    </>
  );
};

export default Menu;
