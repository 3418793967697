import { ReactNode } from 'react';
import cn from 'classnames';

const buttonClasses = {
  primary:
    'text-blue-400 border-2 border-blue-400 hover:border-blue-300 hover:text-blue-300 hover:underline hover:decoration-solid',
  secondary:
    'text-gray-300 border-2 border-gray-300 hover:border-gray-200 hover:text-gray-200 hover:underline hover:decoration-solid',
  danger:
    'text-red-500 border-2 border-red-500 hover:border-red-400 hover:text-red-400 hover:underline hover:decoration-solid',
  success:
    'text-green-400 border-2 border-green-400 hover:border-green-300 hover:text-green-300 hover:underline hover:decoration-solid'
};

interface Props {
  className?: string;
  children: ReactNode;
  href: string;
  'data-testid'?: string;
  color: keyof typeof buttonClasses;
  isDisabled?: boolean;
}

const ButtonLink = ({
  className,
  children,
  href,
  color,
  'data-testid': dataTestId
}: Props) => {
  const classes = cn(
    buttonClasses[color],
    className,
    'py-2 px-4 rounded inline-block cursor-pointer text-center'
  );

  return (
    <a href={href} data-testid={dataTestId} className={classes}>
      {children}
    </a>
  );
};

export default ButtonLink;
