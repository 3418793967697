import { Link } from 'react-router-dom';
import cn from 'classnames';

import { useAuthContext } from 'context/auth';

import Tooltip from 'components/tooltip';
import DocsLink from 'components/docsLink';

import { ReactComponent as ProfileIcon } from 'resources/icons/profile-icon.svg';

interface Props {
  className?: string;
}
const ProfileDropdown = ({ className }: Props) => {
  const { user, signOut, config } = useAuthContext();
  return (
    <div className={cn('relative', className)}>
      <Tooltip
        position='bottom-end'
        trigger='click'
        className='h-full'
        useContext
        html={
          <div
            data-testid='profile-dropdown'
            className='text-left text-sm py-2'
          >
            <Link
              className='block hover:bg-gray-200'
              data-testid='account-page'
              to='/account'
            >
              <span className='block py-1 px-4'>My Account</span>
            </Link>
            <DocsLink href='/' className='block hover:bg-gray-200'>
              <span className='block py-1 px-4'>Documentation</span>
            </DocsLink>
            <a
              data-testid='profile-signout'
              href='#'
              className='block hover:bg-gray-200 cursor-pointer'
              onClick={signOut}
            >
              <span className='block py-1 px-4'>Logout</span>
            </a>
          </div>
        }
      >
        <div
          data-testid='profile-open'
          className={cn('flex flex-row h-full cursor-pointer')}
        >
          <div className='mx-3 justify-center hidden xl:flex xl:flex-col'>
            <div
              className='text-gray-400 text-right text-sm transition duration-300 group-hover:text-white'
              data-testid='profile-username'
            >
              {user?.username}
            </div>
            <div
              className='text-gray-500 text-right text-xs transition duration-300 group-hover:text-gray-400'
              data-testid='profile-companyname'
            >
              {config?.solve.clientName}
            </div>
          </div>
          <ProfileIcon
            className='w-9 h-9 transition-all duration-300 fill-current text-gray-700 group-hover:text-white'
            data-testid='profile-img'
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default ProfileDropdown;
