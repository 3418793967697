import { CORE_GRAPHQL_PATH } from 'routes.paths';

import { useAuthContext } from 'context/auth';

import DocsLink from 'components/docsLink';
import Copy from 'components/copy';

const Settings = () => {
  const { baseUrls } = useAuthContext();
  return (
    <div className='text-gray-600 bg-gray-100 rounded p-4 mt-2 flex justify-between items-center'>
      <div>
        <p>Use this URL with your credentials below to connect to the API</p>
        <p className='pt-1'>
          For more information see the
          <DocsLink className='pl-1 text-blue-400 underline' href='/api'>
            API docs
          </DocsLink>
        </p>
      </div>
      <div className='relative'>
        <span className='pr-6' id='apiEndpoint'>
          {baseUrls!.api}
          {CORE_GRAPHQL_PATH}
        </span>
        <Copy
          className='w-4 h-4 absolute top-0 right-0 mt-0.5 text-gray-400 hover:text-gray-300'
          el='apiEndpoint'
        />
      </div>
    </div>
  );
};

export default Settings;
