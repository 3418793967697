import { ReactNode } from 'react';
import cn from 'classnames';

import ErrorBoundary from 'containers/errorBoundary';

import Header from 'components/header';
import Footer from 'components/footer';

export interface State {
  search: string;
  setSearchValue: (value: string) => void;
}

interface Props {
  className?: string;
  innerClassName?: string;
  children: ReactNode;
  fullScreen?: boolean;
  hideFooter?: boolean;
  darkMode?: boolean;
}

const Page = ({
  children,
  className,
  innerClassName,
  fullScreen,
  hideFooter,
  darkMode
}: Props) => {
  return (
    <div className={cn(`min-h-screen flex flex-col`, className)}>
      <Header />
      <div
        className={cn('flex flex-col flex-grow', {
          'bg-gray-800': darkMode
        })}
      >
        <div
          className={`flex-grow w-full mx-auto h-full mt-0 ${
            fullScreen ? 'flex' : 'max-w-container px-6'
          }`}
        >
          <div
            className={cn(`${fullScreen ? 'w-full' : 'my-3'}`, innerClassName)}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
        {!hideFooter && (
          <Footer
            className={cn({
              'bg-gray-850 text-white': darkMode
            })}
          >
            © Solve Data Inc.
          </Footer>
        )}
      </div>
    </div>
  );
};

export default Page;
