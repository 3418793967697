import { InputHTMLAttributes } from 'react';
import cn from 'classnames';

const Input = ({
  className,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    {...props}
    className={cn('border border-solid border-gray-200', className)}
  />
);

Input.defaultProps = {
  type: 'text'
};

export default Input;
