import {
  allSdkSnippetsQuery,
  sdkSnippetCreateMutation
} from 'queries/sdkSnippet';

import SdkSnippetForm, { FormData } from './snippetForm';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const DEFAULT_VALUES: FormData = {
  name: '',
  content: '',
  priority: 100,
  enabled: true
};

export default function CreateForm({ onClose }: Props) {
  return (
    <SdkSnippetForm
      mutation={sdkSnippetCreateMutation}
      onClose={onClose}
      initialValues={DEFAULT_VALUES}
      formMode='create'
      refetchQuery={allSdkSnippetsQuery}
      sdkSnippetId={0}
    />
  );
}
