import { Form, Formik } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { policyUpdateMutation, allPoliciesQuery } from 'queries/policy';

import Loader from 'components/loader';
import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import FormCustomCodeEditor from 'components/formComponents/formCustomCodeEditor';

import { validateDocument } from './policyFormUtils';

interface Props {
  policyData: Policy;
  onClose: () => void;
}

const OUT_OF_SYNC_MSG =
  'Stored policy is out of sync with the received update_token. Please refetch the policy first.';

const stringifyJSONReadable = (object: any) => JSON.stringify(object, null, 4);

const EditPolicyForm = () => (
  <Form data-testid='policy-edit-form' className='overflow-y-auto max-h-sm'>
    <div className='px-5 pt-2 pb-5'>
      <div className='mb-4'>
        <FormCustomCodeEditor
          name='document'
          placeholder='Edit this API Policy. Must use JSON syntax.'
          label='document'
          dataTestId='policy-edit-document'
          errorDataTestId='policy-edit-document-errors'
          language='json'
        />
      </div>
      <div className='flex flex-row-reverse'>
        <Button
          type='submit'
          color='primary'
          className='px-8 mt-2 uppercase'
          data-testid='submit-edit-policy'
        >
          Update
        </Button>
      </div>
    </div>
  </Form>
);

const Edit = ({ policyData, onClose }: Props) => {
  return (
    <Mutation mutation={policyUpdateMutation}>
      {(updatePolicy: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes(OUT_OF_SYNC_MSG)
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='Your version of this policy is out of date. Please refresh the page and try again.'
                className='m-2'
              />
            );
          }
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes('document:')
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='Invalid policy document. Please include: version, policy, action and resource'
                className='m-2'
              />
            );
          }
          return <ErrorAlert error={error} className='m-2' />;
        }

        return (
          <Formik
            initialValues={{
              ...policyData,
              document: stringifyJSONReadable(JSON.parse(policyData.document))
            }}
            data-testid='policy-edit-formik'
            validate={validateDocument}
            onSubmit={async (values: Policy) => {
              await updatePolicy({
                variables: {
                  id: values.id,
                  input: {
                    document: values.document
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: allPoliciesQuery }]
              });
              onClose();
            }}
          >
            <EditPolicyForm />
          </Formik>
        );
      }}
    </Mutation>
  );
};

export default Edit;
