import { useAuthContext } from 'context/auth';

import Button from 'components/button';
import DocsLink from 'components/docsLink';
import Copy from 'components/copy';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const Pixel = ({ onClose }: Props) => {
  const { baseUrls } = useAuthContext();
  const pixelUrl = `${baseUrls!.api}/pixel.gif`;
  return (
    <>
      <div className='px-5 pt-2 pb-4'>
        <p>
          This is the URL for your image pixel. Place this URL, along with
          identifying parameters like <code>email</code>, <code>email_md5</code>{' '}
          or <code>email_sha256</code>, in places where the Web SDK is not
          supported.
        </p>
      </div>
      <div className='relative'>
        <textarea
          rows={1}
          className='p-6 text-gray-200 text-xs w-full border-0 bg-gray-800 font-mono'
          id='publicCode'
          readOnly
          value={pixelUrl}
          data-testid='pixel-script'
        />
        <Copy
          className='absolute top-0 right-0 mt-2 mr-4 text-white'
          el='publicCode'
        />
      </div>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <p>
          Refer to the <DocsLink href='/pixel'>Solve Pixel docs</DocsLink> for
          more information about how to use it, and to the{' '}
          <DocsLink href='/api/event-schemas/identify'>
            <code>identify</code> event schema
          </DocsLink>{' '}
          for parameters that can be added.
        </p>
        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='button'
            color='primary'
            className='px-8 uppercase'
            onClick={onClose}
            data-testid='confirm-pixel'
          >
            ok
          </Button>
        </div>
      </div>
    </>
  );
};

export default Pixel;
