import { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import { policyDeleteMutation, allPoliciesQuery } from 'queries/policy';

import Label from 'components/label';
import Loader from 'components/loader';
import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';

export type Props = {
  policyData: Policy;
  onClose: () => void;
};

const OUT_OF_SYNC_MSG =
  'Stored policy is out of sync with the received update_token. Please refetch the policy first.';

const setupScreen = (payload: PolicyPayload, onClose: Props['onClose']) => {
  return (
    <div className='mt-2 mb-4 mx-5'>
      <p className='mb-5'>
        API Policy <strong>{payload.name}</strong> has been successfully
        deleted.
      </p>
      <div className='flex justify-end'>
        <Button
          type='button'
          color='primary'
          onClick={onClose}
          className='w-20'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const stringifyJSONReadable = (object: any) => JSON.stringify(object, null, 4);

export default function DeleteForm({ policyData, onClose }: Props) {
  const DEFAULT_VALUES = {
    name: policyData.name,
    document: policyData.document,
    id: policyData.id,
    is_solve_managed: policyData.is_solve_managed
  };

  const [setup, setSetup] = useState(0);
  const [payload, setPayload] = useState(DEFAULT_VALUES);

  return (
    <Mutation mutation={policyDeleteMutation}>
      {(deletePolicy: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes(OUT_OF_SYNC_MSG)
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='Your version of this policy is out of date. Please refresh the page and try again.'
                className='m-2'
              />
            );
          }
          return <ErrorAlert error={error} className='m-2' />;
        }
        return setup === 0 ? (
          <div className='px-5 pt-2 pb-5 overflow-y-auto max-h-sm'>
            <span
              className='block text-red-600 pb-2 uppercase'
              data-testid='delete-caution'
            >
              Caution: this cannot be undone
            </span>
            <div className='mb-4'>
              <Label data-testid='policy-delete-name'>document</Label>
              <textarea
                className='border border-gray-300 rounded p-2 w-full h-48'
                data-testid='policy-delete-document'
                name='document'
                value={stringifyJSONReadable(JSON.parse(policyData.document))}
                readOnly
              />
            </div>
            <div className='flex flex-row-reverse'>
              <Button
                type='submit'
                color='danger'
                className='px-8 mt-2 uppercase'
                data-testid='submit-delete-policy'
                onClick={async _event => {
                  const gqlRes = await deletePolicy({
                    variables: {
                      id: policyData.id
                    },
                    // If we wait for refetch the delete button disappears before the modal closes
                    awaitRefetchQueries: false,
                    refetchQueries: [{ query: allPoliciesQuery }]
                  });

                  setPayload(gqlRes.data.delete_api_policy);
                  setSetup(1);
                  onClose();
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        ) : (
          setupScreen(payload, onClose) || null
        );
      }}
    </Mutation>
  );
}
