import * as Yup from 'yup';

export const shopifyIntegrationValidation = Yup.object().shape({
  storeLabel: Yup.string().required('Please enter a value'),
  storeDomain: Yup.string()
    .matches(
      /^((\*|[\w\d]+(-[\w\d]+)*)\.)*(myshopify.com+)$/,
      'Store domain should be like `store.myshopify.com'
    )
    .required('Please enter a value')
});
