import cn from 'classnames';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import { settingsPaths } from '../../routes.paths';

import { useFeatureFlagsContext } from 'context/featureFlags';

import APIKeysSection from './sections/apiKeySection';
import UsersSection from './sections/usersSection';
import PluginIntegrations from './sections/pluginIntegrations';

import ConfigurationsSection from './sections/configurationsSection';
import SdkSnippetSection from './sections/sdkSnippetsSection';
import PoliciesSection from './sections/policiesSection';

import Label from 'components/label';
import Panel from 'components/panel';

interface SectionsMenuProps {
  section: Page;
  onClick: (id: Page['id']) => void;
}
type Page = {
  label: string;
  child: React.ReactNode;
  group: Group;
  flag?: string;
  id: PageId;
};
type Group = {
  id: string;
  title: string;
};
type Groups = Record<string, Group>;

const groups: Groups = {
  accessControl: {
    id: 'accessControl',
    title: 'Access Control'
  },
  advancedSettings: {
    id: 'advancedSettings',
    title: 'Advanced settings'
  }
};

enum PageId {
  ApiIntegrations = 'apiIntegrations',
  Users = 'users',
  Configurations = 'configurations',
  SolveIntegrations = 'solveIntegrations',
  Customization = 'customization',
  AccessControl = 'accessControl'
}

export const pages: Page[] = [
  {
    id: PageId.ApiIntegrations,
    label: 'API Integrations',
    child: <APIKeysSection />,
    group: groups.accessControl
  },
  {
    id: PageId.Users,
    label: 'Users',
    child: <UsersSection />,
    group: groups.accessControl
  },
  {
    id: PageId.SolveIntegrations,
    label: 'Integrations',
    child: <PluginIntegrations />,
    flag: 'pluginIntegration',
    group: groups.advancedSettings
  },
  {
    id: PageId.Configurations,
    label: 'Configurations',
    child: <ConfigurationsSection />,
    group: groups.advancedSettings
  },
  {
    id: PageId.Customization,
    label: 'SDK Customization',
    child: <SdkSnippetSection />,
    group: groups.advancedSettings
  },

  {
    id: PageId.AccessControl,
    label: 'API Policies',
    child: <PoliciesSection />,
    group: groups.advancedSettings
  }
];

const SectionMenu = ({ section, onClick }: SectionsMenuProps) => {
  const match = useMatch(settingsPaths[section.id]);
  return (
    <div
      key={section.id}
      className={cn('px-2 py-1 cursor-pointer', {
        'bg-blue-50': match
      })}
      onClick={() => onClick(section.id)}
      data-testid={`${section.id}-section-select`}
    >
      <span className='text-base'>{section.label}</span>
    </div>
  );
};

const PanelGroup = ({
  group,
  onClick
}: {
  group: Group;
  onClick: SectionsMenuProps['onClick'];
}) => {
  const { flags } = useFeatureFlagsContext();
  return (
    <Panel className='flex-auto m-2'>
      <div className='border-b'>
        <Label className='m-2'>{group.title}</Label>
      </div>
      {pages
        .filter(p => p.group.id === group.id)
        .filter(p => (p.flag ? flags[p.flag] === true : true))
        .map(s => (
          <SectionMenu key={s.id} onClick={onClick} section={s} />
        ))}
    </Panel>
  );
};

const Settings = () => {
  const navigate = useNavigate();
  const { flags } = useFeatureFlagsContext();
  const onClickPage: SectionsMenuProps['onClick'] = id => {
    navigate(settingsPaths[id]);
  };

  return (
    <div className='flex'>
      <div className='flex-col w-1/5'>
        <PanelGroup group={groups.accessControl} onClick={onClickPage} />
        {flags.advancedSettings && (
          <PanelGroup group={groups.advancedSettings} onClick={onClickPage} />
        )}
      </div>
      <div className='flex-grow py-1 px-3'>
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
