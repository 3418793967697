import cn from 'classnames';

import Info from 'components/info';
import Button from 'components/button';

export function PostFormCacheNote({ className }: { className?: string }) {
  return (
    <div
      className={cn('flex flex-row', className)}
      data-testid='sdk-snippet-cache-note'
    >
      <div className='pr-2'>
        <Info className='leading-none' />
      </div>
      <span>
        Note that changes to SDK snippets can take up to 1 hour to roll out.
      </span>
    </div>
  );
}

export interface Props {
  name: string;
  action: string;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export default function PostFormInfo({ name, action, onClose }: Props) {
  return (
    <div className='my-2 mx-5'>
      <div className='mb-5' data-testid='sdk-snippet-post-form-info'>
        <p>
          The SDK Snippet{' '}
          <code className='font-bold' data-testid='sdk-snippet-post-form-name'>
            {name}
          </code>{' '}
          has been successfully {action}.
        </p>
      </div>
      <PostFormCacheNote className='mt-2 mb-4 mx-5' />
      <div className='flex flex-row-reverse'>
        <Button
          type='button'
          color='transparent'
          className='px-8 mt-2 uppercase'
          data-testid='sdk-snippet-post-form-close'
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </div>
  );
}
