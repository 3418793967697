import logger from 'utils/logger';

import Alert from 'components/alert';

export interface Props {
  error?: Error;
  message?: string;
  className?: string;
}

export const defaultMessage = 'Sorry, there was an unexpected error.';

const ErrorAlert = ({ error, message, className }: Props) => {
  if (error) {
    logger.error(error);
  }
  return (
    <Alert
      className={className}
      message={message || defaultMessage}
      type='error'
      showIcon
    />
  );
};

export default ErrorAlert;
