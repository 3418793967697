import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import possibleTypes from 'possibleTypes.json';
import { CORE_GRAPHQL_PATH, ADMIN_GRAPHQL_PATH } from 'routes.paths';

import logger from 'utils/logger';

export let client: ApolloClient<any> | null = null;
const errorLink = onError(({ graphQLErrors, networkError }) => {
  // using console.log as logger.error doesn't make it clear where it was called from
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const generateClient = (
  apiBaseUrl: string | null,
  auth: AuthInterface,
  useAdminApi?: false
) => {
  logger.debug(`Creating Apollo Client with apiBaseUrl ${apiBaseUrl}`);
  const ext = useAdminApi ? ADMIN_GRAPHQL_PATH : CORE_GRAPHQL_PATH;
  const uri = apiBaseUrl ? `${apiBaseUrl}${ext}` : ext;

  const httpLink = createHttpLink({
    uri
  });

  const buildSolveAuthLink = setContext(async (_: any, { headers }: any) => {
    let token: string | undefined;

    try {
      token = await auth.getJwtToken();
    } catch (err) {
      logger.debug('generateClient: error getting JWT', err);
    }

    return {
      headers: {
        ...headers,
        authorization: token ? `Solve ${token}` : ''
      }
    };
  });

  client = new ApolloClient({
    link: from([errorLink, buildSolveAuthLink.concat(httpLink)]),
    cache: new InMemoryCache({
      possibleTypes
    })
  });

  return client;
};

export default generateClient;
