import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
  size?: 'large' | 'default';
  children: ReactNode;
  'data-testid'?: string;
}

const Title = ({
  children,
  className,
  size,
  'data-testid': dataTestId
}: Props) => {
  const sizeClass = size === 'large' ? 'text-lg' : 'text-md';

  return (
    <h1
      data-testid={dataTestId}
      className={cn(`${sizeClass} mb-3 font-normal`, className)}
    >
      {children}
    </h1>
  );
};

export default Title;
