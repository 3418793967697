import { RawGraphData } from 'utils/graph';

export interface ProfileData {
  full_name: string | null;
  first_name: string | null;
  last_name: string | null;
}
export const derivedFullName = (data: ProfileData) => {
  const fullName = data.full_name ? data.full_name.trim() : data.full_name;
  const firstName = data.first_name || '';
  const lastName = data.last_name || '';

  return fullName || `${firstName} ${lastName}`.trim();
};

// formula:
// total - 100%
// part - x
// x = (part * 100)/total
export const ratioTotal = (part: number, total: number) => {
  return (part * 100) / total;
};

// formula explanation https://www.storyofmathematics.com/percentage-change/
const comparisonDifference = (weekOneTotal: number, weekTwoTotal: number) => {
  return ((weekTwoTotal - weekOneTotal) / weekOneTotal) * 100;
};

// calculate difference between two weeks
export const calculateDifference = (
  data: RawGraphData,
  type: string,
  comparisonType?: string
) => {
  let weekOneTotalSum: number;
  let weekTwoTotalSum: number;
  let weekOneTotal: number;
  let weekTwoTotal: number;

  if (!data || data.length !== 14) return 0;

  const weekOne = data.slice(0, 7);
  const weekTwo = data.slice(7, 14);

  const weekOneTotalByType = calculateTotalByType(weekOne, type);
  const weekTwoTotalByType = calculateTotalByType(weekTwo, type);

  if (comparisonType) {
    weekOneTotalSum = calculateTotalSum(weekOne, type, comparisonType);
    weekTwoTotalSum = calculateTotalSum(weekTwo, type, comparisonType);
    weekOneTotal = ratioTotal(weekOneTotalByType, weekOneTotalSum);
    weekTwoTotal = ratioTotal(weekTwoTotalByType, weekTwoTotalSum);
  } else {
    weekOneTotal = weekOneTotalByType;
    weekTwoTotal = weekTwoTotalByType;
  }

  return comparisonDifference(weekOneTotal, weekTwoTotal);
};

// calculate total for one type
// Example:
// [
//   {
//     time: '2022-02-02T05:00:00.000000Z',
//     value: {
//       identified: 5,
//       unidentified: 20
//     }
//   },
//   {
//     time: '2022-02-03T05:00:00.000000Z',
//     value: {
//       identified: 7,
//       unidentified: 14
//     }
//   },
//   ...
// ]
// Calculate by type identified
// Result: 12
export const calculateTotalByType = (
  data: RawGraphData,
  type: string,
  days = 7
) => {
  let sliceStart = 0;
  if (data?.length >= days) {
    sliceStart = data.length - days;
  }

  return data
    ?.slice(sliceStart, sliceStart + days)
    ?.reduce((total: number, item: { value: { [type: string]: number } }) => {
      const value = item.value[type] || 0;

      return total + value;
    }, 0);
};

// calculate total for all types
// Example:
// [
//   {
//     time: '2022-02-02T05:00:00.000000Z',
//     value: {
//       identified: 5,
//       unidentified: 20
//     }
//   },
//   {
//     time: '2022-02-03T05:00:00.000000Z',
//     value: {
//       identified: 7,
//       unidentified: 14
//     }
//   },
//   ...
// ]
// Result: 46
export const calculateTotalSum = (
  data: RawGraphData,
  type: string,
  comparisonType: string,
  days = 7
) => {
  let sliceStart = 0;
  if (data?.length >= days) {
    sliceStart = data.length - days;
  }

  return data
    ?.slice(sliceStart, sliceStart + days)
    ?.reduce((total: number, item: { value: { [type: string]: number } }) => {
      const value = (item.value[type] || 0) + (item.value[comparisonType] || 0);

      return total + value;
    }, 0);
};
