import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { AuthProvider } from 'context/auth';
import { EventsProvider } from 'context/events';

import migrateQueryEngineLocalStorage from 'stores/queryEngine/migrateLocalStorage';

import OutdatedVersionToaster from 'components/outdatedRevision';

import Routes from './routes';
import generateClient from './apolloClient';

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  clientConfig: ConfigRetrieved;
}

const App = ({ clientConfig }: Props) => {
  const flags = useFlags();

  const { auth, error, config, baseUrls } = clientConfig;
  const apiBaseUrl = baseUrls ? baseUrls.api : null;
  const client = generateClient(apiBaseUrl, auth!, flags.adminApi);

  // When the app has started up, hydrate anything we need or migrate old localstorage
  // values if we need
  migrateQueryEngineLocalStorage();

  dayjs.tz.setDefault(config?.solve.timezone);

  const displayName = config?.solve.stackDisplayName;

  useEffect(() => {
    document.title = displayName
      ? `${config?.solve.stackDisplayName} - Solve admin`
      : 'Solve admin';
  }, []);

  return (
    <>
      <AuthProvider
        auth={auth!}
        error={error}
        config={config}
        baseUrls={baseUrls}
      >
        <EventsProvider>
          <ApolloProvider client={client}>
            <Routes config={config} />
          </ApolloProvider>
        </EventsProvider>
      </AuthProvider>
      <Toaster
        position='top-center'
        containerStyle={{
          marginTop: '4rem'
        }}
        toastOptions={{
          style: {
            maxWidth: '100%'
          }
        }}
      />
      <OutdatedVersionToaster />
    </>
  );
};

export default App;
