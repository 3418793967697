/*
  Copy Text to Clipboard
*/

export function copyToClipboard(el: string) {
  const copyText: any = document.getElementById(el);
  // if we copy not from input or textarea we need to create one
  if (
    copyText.tagName !== 'INPUT' &&
    copyText.getAttribute('type') !== 'text' &&
    copyText.tagName !== 'TEXTAREA'
  ) {
    const textArea = document.createElement('textarea');
    textArea.className = 'copyArea';
    textArea.textContent = copyText.textContent;
    document.body.append(textArea);
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
  } else {
    copyText.select();
    document.execCommand('copy');
  }
}
