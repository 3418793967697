import { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client/react';

import { allSdkSnippetsQuery } from 'queries/sdkSnippet';

import Title from 'components/title';
import ErrorAlert from 'components/errorAlert';
import Button from 'components/button';
import Modal from 'components/modal';

import Setup from './forms/create';
import Skeleton from './skeleton';
import SdkSnippetsTable from './sdkSnippetsTable';

const SdkSnippetsSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleNewSdkSnippetModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <div className='flex justify-between'>
        <div>
          <Title size='large'>SDK Customization</Title>
        </div>
        <div className='relative'>
          <Button
            type='button'
            color='primary'
            className='uppercase'
            onClick={handleToggleNewSdkSnippetModal}
            data-testid='new-snippet'
          >
            New SDK Snippet
          </Button>
        </div>
      </div>
      <Query query={allSdkSnippetsQuery} fetchPolicy='cache-and-network'>
        {(props: QueryResult) => {
          const { data, error, loading } = props;
          if (loading) {
            return <Skeleton />;
          }

          if (error) {
            return <ErrorAlert error={error} />;
          }

          return <SdkSnippetsTable sdkSnippets={data.sdkSnippets} />;
        }}
      </Query>
      <Modal
        ariaLabel='New SDK Snippet'
        title='New SDK Snippet'
        className='w-4/6 h-5/6 overflow-y-auto max-h-[892px] [@media(min-width:796px)_and_(max-width:978px)]:max-h-[872px] [@media(min-width:978px)]:max-h-[848px]'
        isOpen={isModalOpen}
        onRequestClose={handleToggleNewSdkSnippetModal}
      >
        <Setup onClose={handleToggleNewSdkSnippetModal} />
      </Modal>
    </>
  );
};

export default SdkSnippetsSection;
