import { gql } from '@apollo/client';

const policyFields = `
id
name
document
is_solve_managed
`;

export const allPoliciesQuery = gql`
query allPolicies {
  api_policies {
    nodes {
      ${policyFields}
    }
    page_info {
        end_cursor
        has_next_page
        page_index
    }
  }
}
`;

export const policyCreateMutation = gql`
mutation createPolicy($input: ApiPolicyCreateInput!) {
  create_api_policy(input: $input) {
    ${policyFields}
  }
}
`;

export const policyUpdateMutation = gql`
mutation updatePolicy($id: ID!, $input: ApiPolicyUpdateInput!) {
  update_api_policy(id: $id, input: $input) {
    ${policyFields}
  }
}
`;

export const policyDeleteMutation = gql`
mutation deletePolicy($id: ID!) {
  delete_api_policy(id: $id) {
    ${policyFields}
  }
}
`;
