import { createContext, useContext } from 'react';

export interface Context {
  flags: Record<string, boolean>;
  isLoading: boolean;
}

const flags = {};

const FeatureFlagsContext = createContext({
  flags,
  isLoading: false
});

export default FeatureFlagsContext;
export const useFeatureFlagsContext: () => Context = () =>
  useContext(FeatureFlagsContext);
