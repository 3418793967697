import { Formik, Form } from 'formik';
import { useMutation } from '@apollo/client';

import { Sentry } from 'utils/sentry';

import { generateShopifyInstallUrlMutation } from 'queries/pluginIntegration';

import { shopifyIntegrationValidation } from 'validation/shopify';

import Button from 'components/button';
import ErrorAlert from 'components/errorAlert';
import FormTextField from 'components/formComponents/formTextField';
import Loader from 'components/loader';

import RedirectToShopify from './redirectToShopify';
import FailedToStartInstall from './failedToStartInstall';

const AddPluginForm = () => {
  return (
    <Form>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <div>
          <FormTextField
            name='storeLabel'
            label='store label'
            placeholder='Give this store a label'
            type='text'
          />
        </div>
        <div>
          <FormTextField
            name='storeDomain'
            label='store domain'
            placeholder='store.myshopify.com'
            type='text'
          />
        </div>
        <div className='flex flex-row-reverse'>
          <Button
            type='submit'
            color='primary'
            className='px-8 mt-2 uppercase'
            data-testid='create-api-key'
          >
            create
          </Button>
        </div>
      </div>
    </Form>
  );
};

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const Setup = ({ onClose }: Props) => {
  const [generateUrlMutation, { data, loading, error }] = useMutation(
    generateShopifyInstallUrlMutation
  );

  const onSubmit = ({
    storeDomain,
    storeLabel
  }: {
    storeDomain: string;
    storeLabel: string;
  }) => {
    generateUrlMutation({
      variables: {
        storeDomain,
        storeLabel,
        adminUrl: window.location.href
      }
    });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    Sentry.addBreadcrumb(error);
    return (
      <ErrorAlert
        error={new Error('Generate shopify auth url failed.')}
        message='Unable proceed with integration setup. Please try again.'
      />
    );
  }

  if (
    data?.startShopifyPluginInstall?.__typename ===
    'StartShopifyPluginInstallSuccess'
  ) {
    return (
      <RedirectToShopify
        onClose={onClose}
        shopifyUrl={data.startShopifyPluginInstall.shopifyUrl}
      />
    );
  }

  if (
    data?.startShopifyPluginInstall?.__typename ===
    'StartShopifyPluginInstallError'
  ) {
    Sentry.captureException('Start shopify install returned errors', data);
    return <FailedToStartInstall onClose={onClose} />;
  }

  return (
    <Formik
      initialValues={{ storeDomain: '', storeLabel: '' }}
      validationSchema={shopifyIntegrationValidation}
      onSubmit={onSubmit}
    >
      <AddPluginForm />
    </Formik>
  );
};

export default Setup;
