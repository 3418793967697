import { ReactElement } from 'react';
import { Tooltip as Tippy, Trigger, Position, Theme } from 'react-tippy';

import 'react-tippy/dist/tippy.css';
import './styles.css';

export declare type TooltipPosition = Position | undefined;

export declare type TooltipTrigger = Trigger | undefined;

export declare type TooltipTheme = 'solve' | Theme;

interface Props {
  title?: string;
  children: ReactElement;
  // Specifies which direction to position the tooltip on the element.
  position: TooltipPosition;
  // specifies which type of events will trigger a tooltip to show.
  trigger: TooltipTrigger;
  // will not close when the user hovers over or clicks on the tooltip
  interactive?: boolean;
  // specifies the size of the invisible border around an interactive tooltip that will prevent it from closing
  // for mouseenter triggered tooltips only
  interactiveBorder?: number;
  // tooltip content. If you don't define html, the title will be used
  html?: ReactElement;
  // adds an arrow pointing to the tooltipped element
  arrow?: boolean;
  // specifies how big the tooltip's arrow is.
  arrowSize?: 'small' | 'regular' | 'big';
  // specifies how far away the tooltip is from its element.
  distance?: number;
  // specifies how big the tooltip is.
  size?: 'small' | 'regular' | 'big';
  // the CSS styling theme.
  theme?: TooltipTheme;
  // className of container
  className?: string;
  // callback when the tooltip has been triggered and has started to transition in
  onShow?: () => void;
  // show/hide a tooltip manually.
  open?: boolean;
  // use only with open prop. This event is fired when you click outside of your tooltip.
  onRequestClose?: () => void;
  // it will be unmounted whenever tooltip is hidden by default
  unmountHTMLWhenHide?: boolean;
  // specifies the type of transition animation a tooltip has.
  animation?: 'shift' | 'perspective' | 'fade' | 'scale' | 'none';
  // specifies how long the transition animation takes to complete when showing a tooltip.
  duration?: number;
  // specifies how long it takes after a trigger event is fired for a tooltip to show.
  delay?: number;
  // additional tooltip options, for example fixing a tooltip position
  popperOptions?: any;
  // define that you're using context in your tooltip content (or html props).
  // It's useful when you want your tooltip content can connect to redux store
  useContext?: boolean;
  // Specifies the HTML element used to wrap the content that triggers the tooltip.
  tag?: string;
  disabled?: boolean;
}

const Tooltip = ({
  title,
  position,
  trigger,
  interactive = true,
  html,
  children,
  interactiveBorder, // default: 2px
  arrow = true,
  arrowSize, // default: regular
  distance, // default: 10px,
  size, // default: regular,
  theme = 'light',
  className,
  onShow,
  open,
  onRequestClose,
  unmountHTMLWhenHide = true,
  animation, // default: shift
  duration, // default: 375 in milliseconds
  delay, // default: 0 in milliseconds
  popperOptions,
  useContext, // default: undefined,
  tag, // default div
  disabled = false
}: Props) => {
  return (
    <Tippy
      disabled={disabled}
      title={title}
      position={position}
      trigger={trigger}
      interactive={interactive}
      html={html}
      interactiveBorder={interactiveBorder}
      arrow={arrow}
      arrowSize={arrowSize}
      distance={distance}
      size={size}
      // Cast `theme` so typescript doesn't complain about the custom theme
      //  that isn't present in the typing
      theme={theme as Theme}
      className={className}
      style={{ display: 'default' }}
      // Incomplete type definition. Tracked by: https://github.com/tvkhoa/react-tippy/issues/156
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onShow={onShow}
      open={open}
      onRequestClose={onRequestClose}
      unmountHTMLWhenHide={unmountHTMLWhenHide}
      animation={animation}
      duration={duration}
      delay={delay}
      useContext={useContext}
      popperOptions={popperOptions}
      tag={tag}
    >
      {children}
    </Tippy>
  );
};

export default Tooltip;
