import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuthContext } from 'context/auth';

import { Pulses } from 'components/asyncLoader';

const PrivateRoute = ({ requiresAdmin }: { requiresAdmin?: boolean }) => {
  const { isAuthenticated, isAdmin, isLoaded } = useAuthContext();
  const location = useLocation();

  const userPermitted = requiresAdmin
    ? isAuthenticated && isAdmin
    : isAuthenticated;

  if (!isLoaded) {
    return <Pulses />;
  }

  if (!userPermitted) {
    return (
      <Navigate
        to={requiresAdmin && !isAdmin ? '/' : '/auth/login'}
        state={{ from: location }}
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoute;
