import { ReactElement, Suspense } from 'react';

import 'three-dots/dist/three-dots.css';
import './styles.css';

interface Props {
  component: ReactElement;
}

export const Pulses = () => (
  <div
    className='absolute top-2/4 left-2/4 transform -translate-y-2/4 -translate-x-2/4'
    data-testid='loading'
  >
    <div className='dot-pulse'></div>
  </div>
);

const AsyncLoader = ({ component }: Props) => (
  <Suspense fallback={<Pulses />}>{component}</Suspense>
);

export default AsyncLoader;
