/*

Tailwind - The Utility-First CSS Framework

A project by Adam Wathan (@adamwathan), Jonathan Reinink (@reinink),
David Hemphill (@davidhemphill) and Steve Schoger (@steveschoger).

Welcome to the Tailwind config file. This is where you can customize
Tailwind specifically for your project. Don't be intimidated by the
length of this file. It's really just a big JavaScript object and
we've done our very best to explain each section.

View the full documentation at https://tailwindcss.com.

*/
import colors from 'tailwindcss/colors';

/** @type {import('tailwindcss').Config} */
export default {
  theme: {
    extend: {
      colors: {
        white: '#FFFFFF',
        current: 'currentColor',
        gray: {
          ...colors.slate,
          '700': '#2D3748',
          '800': '#1A202C',
          '850': '#131923',
          '900': '#0A0F17'
        },
        orange: colors.orange,
        green: colors.green,
        teal: colors.teal,
        purple: colors.violet
      },
      borderWidth: {
        '3': '3px'
      },
      fontSize: {
        xs: ['12px', { lineHeight: '16px' }],
        sm: ['14px', { lineHeight: '20px' }],
        base: ['16px', { lineHeight: '24px' }],
        caption: ['18px', { lineHeight: '24px' }],
        md: ['20px', { lineHeight: '24px' }],
        lg: ['24px', { lineHeight: '32px' }],
        xl: ['32px', { lineHeight: '40px' }],
        '2xl': ['64px', { lineHeight: '80px' }],
        '3xl': ['80px', { lineHeight: '96px' }]
      },
      // Note one spacing unit is equal to 0.25rem, which translates to 4px by default in common browsers
      // Refer to https://tailwindcss.com/docs/customizing-spacing/#default-spacing-scale
      spacing: {
        '0.5': '0.125rem',
        '58': '14.5rem',
        '60': '15rem',
        '72': '18rem',
        '76': '20rem',
        '80': '22rem',
        '96': '24rem',
        '116': '29rem',
        '128': '32rem',
        '140': '35rem',
        '156': '39rem'
      },
      minWidth: {
        '32': '8rem',
        '40': '10rem',
        '44': '11rem'
      },
      minHeight: {
        '12': '3rem'
      },
      maxHeight: {
        xs: '20rem',
        '100': '26rem',
        sm: '30rem',
        md: '40rem',
        lg: '50rem',
        xl: '60rem',
        '2xl': '70rem',
        '3xl': '80rem',
        '4xl': '90rem',
        '5xl': '100rem',
        full: '100%',
        screen: '100vh'
      },
      maxWidth: {
        xxs: '15rem',
        xs: '20rem',
        sm: '30rem',
        md: '40rem',
        lg: '50rem',
        xl: '60rem',
        '2xl': '70rem',
        '3xl': '80rem',
        '4xl': '90rem',
        '5xl': '100rem',
        full: '100%',
        container: '1440px'
      },
      animation: {
        'fade-in': 'fade-in 0.5s ease-out'
      },
      keyframes: {
        'fade-in': {
          '0%': {
            opacity: '0'
          },
          '100%': {
            opacity: '1'
          }
        }
      }
    }
  },
  /*
  |-----------------------------------------------------------------------------
  | Core Plugins                      https://tailwindcss.com/docs/core-plugins
  |-----------------------------------------------------------------------------
  |
  | Control which core utility plugins should have responsive variants and
  | pseudo-class variants generated.
  |
  */
  corePlugins: {
    container: false,
    objectFit: true,
    objectPosition: false
  },

  /*
  |-----------------------------------------------------------------------------
  | Plugins                                 https://tailwindcss.com/docs/plugins
  |-----------------------------------------------------------------------------
  |
  | The plugins section allows you to register third-party plugins with Tailwind that
  | can be used to generate extra utilities, components, base styles, or custom variants.
  | for example require('tailwindcss-border-gradients')
  |
  */

  plugins: [
    function ({ addVariant }) {
      addVariant('important', ({ container }) => {
        container.walkRules(rule => {
          rule.selector = `.\\!${rule.selector.slice(1)}`;
          rule.walkDecls(decl => {
            decl.important = true;
          });
        });
      });
    }
  ]
};
