import { ReactNode, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as Error } from 'resources/icons/alert-octagon.svg';
import { ReactComponent as Warning } from 'resources/icons/alert-triangle.svg';
import { ReactComponent as Info } from 'resources/icons/info-circle.svg';
import { ReactComponent as Smile } from 'resources/icons/face-smile.svg';
import { ReactComponent as Close } from 'resources/icons/x-close.svg';

export type AlertType = 'error' | 'warning' | 'info' | 'success';
interface Props {
  className?: string;
  type: AlertType;
  message: ReactNode;
  showIcon?: boolean;
  closable?: boolean;
  'data-testid'?: string;
}

interface IconProps {
  type: 'error' | 'warning' | 'info' | 'success';
  showIcon?: boolean;
}

const classes = {
  error: 'bg-red-100 border-red-300',
  warning: 'bg-orange-100 border-orange-300',
  info: 'bg-blue-100 border-blue-300',
  success: 'bg-green-100 border-green-300'
};

const icons = {
  error: Error,
  warning: Warning,
  info: Info,
  success: Smile
};

const iconClasses = {
  error: 'text-red-400',
  warning: 'text-orange-400',
  info: 'text-blue-400',
  success: 'text-green-400'
};

const Icon = ({ type, showIcon }: IconProps) => {
  if (!showIcon) {
    return null;
  }
  const Cmp = icons[type];
  // notice that accordingly tailwind docs it should be stroke-current class
  // but it doesn't work for some reason as expected
  const classes = cn(iconClasses[type], 'w-6 h-6 current-stroke mr-6');
  return <Cmp data-testid={`${type}-icon`} className={classes} />;
};

const Alert = ({
  className,
  type,
  message,
  showIcon,
  closable,
  'data-testid': dataTestId
}: Props) => {
  const [closed, setClosed] = useState(false);

  if (closed) {
    return null;
  }

  return (
    <div
      data-testid={dataTestId || `${type}-alert`}
      className={cn(
        classes[type],
        'border border-solid rounded py-3 px-6 flex relative',
        className
      )}
    >
      {closable && (
        <Close
          data-testid='alert-close'
          onClick={() => setClosed(true)}
          className='absolute right-0 top-0 cursor-pointer w-4 h-4 m-3 text-gray-800'
        />
      )}
      <Icon showIcon={showIcon} type={type} />
      <p className='p-0 flex-grow'>{message}</p>
    </div>
  );
};

export default Alert;
