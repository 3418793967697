import { useCallback, useState } from 'react';

import ContextMenu from 'components/contextMenu';
import Modal from 'components/modal';

import EditForm from '../forms/edit';
import EnableDisableForm from '../forms/enableDisable';

interface RowContextMenuProps {
  apiKey: ApiKey;
}

interface ModalProps {
  apiKey: ApiKey;
  isOpen: boolean;
  onRequestClose: () => void;
}

const EditModal = ({ apiKey, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      className='w-140 overflow-y-auto'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title='Edit API Integration'
      ariaLabel='Edit API Integration'
    >
      <EditForm apiKeyData={apiKey} onClose={onRequestClose} />
    </Modal>
  );
};

const EnableDisableModal = ({ apiKey, isOpen, onRequestClose }: ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={
        apiKey.is_disabled
          ? 'Enable API Integration'
          : 'Disable API Integration'
      }
      ariaLabel={
        apiKey.is_disabled
          ? 'Enable API Integration'
          : 'Disable API Integration'
      }
    >
      <EnableDisableForm apiKey={apiKey} onClose={onRequestClose} />
    </Modal>
  );
};

const RowContextMenu = ({ apiKey }: RowContextMenuProps) => {
  const [editIsOpen, setEditIsOpen] = useState(false);
  const [enableDisableIsOpen, setEnableDisableIsOpen] = useState(false);

  const editHandlerToggle = useCallback(() => {
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);
  }, []);

  const enableDisableHandlerToggle = useCallback(() => {
    setEnableDisableIsOpen(prevEnableDisableIsOpen => !prevEnableDisableIsOpen);
  }, []);

  return (
    <div data-testid={`${apiKey.name}-context-menu`}>
      <ContextMenu
        data-testid={`${apiKey.id}-context-menu`}
        type='vdots'
        menu={[
          {
            label: 'Edit',
            onClick: editHandlerToggle
          },
          {
            label: apiKey.is_disabled ? 'Enable' : 'Disable',
            onClick: enableDisableHandlerToggle
          }
        ]}
      />
      <EditModal
        apiKey={apiKey}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
      <EnableDisableModal
        apiKey={apiKey}
        isOpen={enableDisableIsOpen}
        onRequestClose={enableDisableHandlerToggle}
      />
    </div>
  );
};

export default RowContextMenu;
