import { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
  'data-testid'?: string;
  className?: string;
  children: ReactNode;
  darkMode?: boolean;
}

const Panel = ({
  className,
  children,
  darkMode,
  'data-testid': dataTestId
}: Props) => {
  return (
    <div
      data-testid={dataTestId}
      className={cn(className, {
        'rounded border border-solid border-gray-200': !darkMode
      })}
    >
      {children}
    </div>
  );
};

export default Panel;
