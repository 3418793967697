import { gql } from '@apollo/client';

export const allSavedQueriesQuery = gql`
  query allSavedQueriesQuery(
    $order_by: QueriesOrderBy
    $is_visible: Boolean
    $after: Cursor
    $first: Int
    $search_term: String
    $ids: [Int!]
  ) {
    queries(
      order_by: $order_by
      is_visible: $is_visible
      after: $after
      first: $first
      search_term: $search_term
      ids: $ids
    ) {
      nodes {
        id
        name
        description
        query
        is_visible
        is_scheduled
        generate_event
        schedule
        timezone
        updated_at
      }
      page_info {
        end_cursor
        has_next_page
        page_index
      }
    }
  }
`;

export const singleSavedQuery = gql`
  query singleSavedQuery($id: Int!, $is_visible: Boolean) {
    query(id: $id, is_visible: $is_visible) {
      id
      name
      description
      query
      is_visible
      is_scheduled
      generate_event
      schedule
      timezone
      updated_at
    }
  }
`;

export const createQuery = gql`
  mutation createQuery($input: QueryCreateInput!) {
    createQuery: create_query(input: $input) {
      id
      name
      description
      query
      is_scheduled
      generate_event
      is_visible
      schedule
      timezone
      updated_at
    }
  }
`;

export const updateQuery = gql`
  mutation updateQuery($id: Int!, $input: QueryUpdateInput!) {
    updateQuery: update_query(id: $id, input: $input) {
      id
      name
      description
      query
      is_scheduled
      generate_event
      is_visible
      schedule
      timezone
      updated_at
    }
  }
`;

export const deleteQuery = gql`
  mutation deleteQuery($id: Int!) {
    deleteQuery: delete_query(id: $id) {
      id
    }
  }
`;
