import { ReactNode } from 'react';

interface Props {
  className?: string;
  href?: string;
  children: ReactNode;
}
const DocsLink = ({ className, children, href }: Props) => {
  const docsVersion = 'latest';
  const path = href ? (href.startsWith('/') ? href : `/${href}`) : '/';

  return (
    <a
      href={`https://docs.solvedata.app/${docsVersion}${path}`}
      target='_blank'
      rel='noopener noreferrer'
      className={className || 'underline text-purple-600 hover:text-purple-400'}
    >
      {children}
    </a>
  );
};

export default DocsLink;
