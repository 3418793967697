import { useState, useEffect } from 'react';
import cn from 'classnames';

import { copyToClipboard } from 'utils/clipboard';

import { ReactComponent as CopyIcon } from 'resources/icons/copy.svg';
import { ReactComponent as TickIcon } from 'resources/icons/check-circle.svg';

interface Props {
  className?: string;
  el: string;
  position?: 'bottom' | 'right';
  showText?: boolean;
}

const Copy = ({
  className,
  el,
  position = 'bottom',
  showText = true
}: Props) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibility(false);
    }, 3000);
    return () => window.clearTimeout(timeout);
  }, [visibility]);

  const onClick = () => {
    copyToClipboard(el);
    setVisibility(true);
  };

  const classes = {
    bottom: 'mt-5 -ml-8',
    right: 'ml-6'
  };

  return (
    <>
      <span className={cn('cursor-pointer', className)} onClick={onClick}>
        <CopyIcon className={cn('w-5 h-5', { hidden: visibility })} />
      </span>
      {visibility && (
        <div className={className}>
          <div className='relative'>
            <TickIcon className='w-5 h-5 text-green-300' />
            <div
              className={cn(
                'absolute top-0.5 bg-gray-600 rounded text-white w-14 px-1 pt-0.5 pb-1 text-center text-xs',
                classes[position],
                {
                  hidden: !showText
                }
              )}
            >
              Copied
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Copy;
