/*
  Pluralize words when necessary,
  the logic let us not just add 's' in the end, but
  use a correct plural for a noun

  Example 1: with s in the end
  pluralize(0, 'apple'); // 'apples'
  pluralize(1, 'apple'); // 'apple'
  pluralize(2, 'apple'); // 'apples'

  Example 2: with a correct plural word
  pluralize(2, 'person', 'people'); // 'people'
*/

export function pluralize(
  val: number,
  word: string,
  plural: string = word + 's'
) {
  const _pluralize = (num: number, word: string, plural: string = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === 'object') {
    return (num: number, word: string) => _pluralize(num, word, val[word]);
  }
  return _pluralize(val, word, plural);
}

export const pluralRules = (locale: string, number: number) => {
  const pr = new Intl.PluralRules(locale, { type: 'ordinal' });

  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th']
  ]);

  const rule = pr.select(number);
  const suffix = suffixes.get(rule);
  return `${number}${suffix}`;
};
