import Button from 'components/button';
import DocsLink from 'components/docsLink';

import WebSdkCode from './webSdkCode';

interface Props {
  payload: {
    key: string;
  };
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

const WebSDK = ({ payload, onClose }: Props) => {
  return (
    <>
      <WebSdkCode data={payload} />
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <p>
          Refer to the <DocsLink href='/web-sdk'>Solve Web SDK docs</DocsLink>{' '}
          for more information
        </p>
        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='button'
            color='primary'
            className='px-8 uppercase'
            onClick={onClose}
            data-testid='confirm-web-sdk-key'
          >
            ok
          </Button>
        </div>
      </div>
    </>
  );
};

export default WebSDK;
