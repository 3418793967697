import * as Sentry from '@sentry/browser';
import { ERROR, LogStream } from 'browser-bunyan';

import logger from './logger';
import { GIT_REVISION } from './revision';

const ignoreErrors = [
  'Error: Failed to fetch',
  'Incorrect username or password.',
  'Password does not conform to policy: Password must have symbol characters'
];

/**
 * Custom stream that should only be used for error logger events. Will forward
 * errors onto Sentry
 */
export class SentryErrorStream implements LogStream {
  public write(rec: Error | any) {
    Sentry.setExtra('Raw error capture', rec);
    if (rec instanceof Error) {
      Sentry.captureException(rec);
    }
    Sentry.captureException(new Error(rec.msg));
  }
}

export function initSentry(uiUrlInfo: UiBaseUrlInfo) {
  const sentryDsn = getSentryDsn(uiUrlInfo);
  if (sentryDsn) {
    const initConfig: Sentry.BrowserOptions = {
      dsn: sentryDsn,
      release: GIT_REVISION,
      environment: process.env.NODE_ENV,
      ignoreErrors
    };
    Sentry.init(initConfig);

    Sentry.setTags({
      application: 'admin-ui',
      git_revision: GIT_REVISION,
      url_revision: uiUrlInfo.urlRevision,
      // This is overridden once we've loaded the config.
      stack_id: 'pre-config'
    });

    logger.addStream({
      level: ERROR,
      stream: new SentryErrorStream()
    });

    logger.debug(`Sentry logger setup correctly with DSN ${sentryDsn}`);
  }
}

export function updateSentryWithStackConfig(config: ConfigJson) {
  Sentry.setTags({
    stack_id: config.solve.stackId,
    stack_version: config.solve.version_tag,
    stack_revision: config.solve.version_rev
  });
}

export function getSentryDsn(uiUrlInfo: UiBaseUrlInfo): string | undefined {
  if (process.env.NODE_ENV === 'production') {
    // If we are accessing a revision-ed URL(`/_/...`) use sandbox
    // If we are *not* on a `solvedata.app` subdomain use sandbox
    // If we are on a `sandbox.solvedata.app` subdomain, use sandbox
    // Otherwise use production.
    if (
      uiUrlInfo.isLatest &&
      uiUrlInfo.domainInfo &&
      uiUrlInfo.domainInfo.clientName !== 'sandbox'
    ) {
      // Production Admin UI Sentry DSN;
      return 'https://27125b01295a429eacd4a569eb259e40@o222961.ingest.sentry.io/5943821';
    } else {
      // Sandbox Admin UI Sentry DSN;
      return 'https://3dd5cb59ebd64e3f8153fc50e4a986db@o222961.ingest.sentry.io/5956104';
    }
  }
  return undefined;
}

// Re-export Sentry to make it easy to access some of the additional Sentry functionality, if needed:
export { Sentry };
