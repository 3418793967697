import { useCallback, useState } from 'react';

import DateCell from 'components/dateCell';
import Button from 'components/button';
import Modal from 'components/modal';

import EditForm from '../forms/edit';

interface ModalProps {
  configuration: Configuration;
  isOpen: boolean;
  onRequestClose: () => void;
}

interface Props {
  configurations: ConfigurationsList;
}

const EditModal = ({ configuration, isOpen, onRequestClose }: ModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    title={`Edit Configuration: ${configuration.id}`}
    ariaLabel={`Edit Configuration: ${configuration.id}`}
  >
    <EditForm configurationData={configuration} onClose={onRequestClose} />
  </Modal>
);

const EditButton = ({ configuration }: { configuration: Configuration }) => {
  const [editIsOpen, setEditIsOpen] = useState(false);

  const editHandlerToggle = useCallback(() => {
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);
  }, []);
  return (
    <>
      <Button
        className='m-2'
        type='button'
        color='primary'
        onClick={editHandlerToggle}
        data-testid={`configuration-edit-${configuration.id}`}
      >
        Edit
      </Button>
      <EditModal
        configuration={configuration}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
    </>
  );
};

const ConfigurationsTable = ({ configurations }: Props) => {
  if (configurations.nodes.length === 0) {
    return <div>No configurations found.</div>;
  }

  const columnNames: string[] = ['ID', 'Last Updated'];

  return (
    <>
      <table className='mt-6 w-full' data-testid='configurations-table'>
        <thead>
          <tr>
            {columnNames.map((name: string, idx: number) => {
              return (
                <td
                  key={`header-column-${idx}`}
                  className='whitespace-nowrap p-2 font-bold border-b'
                >
                  {name}
                </td>
              );
            })}
            <td className='border-b' />
          </tr>
        </thead>
        <tbody>
          {configurations.nodes &&
            configurations.nodes.map((configuration: Configuration) => {
              return (
                <tr
                  key={`${configuration.id}-table-row`}
                  className='configuration-item'
                  data-testid={`configuration-${configuration.id}`}
                >
                  <td
                    className='p-2 border-b'
                    data-testid='configuration-item-name'
                  >
                    {configuration.id}
                  </td>
                  <td className='border-b'>
                    <DateCell
                      data={{
                        id: configuration.id,
                        date: configuration.updated_at
                      }}
                      isTimeDiff
                      className='p-2'
                      interactive={false}
                    />
                  </td>
                  <td className='pl-2 border-b'>
                    <EditButton configuration={configuration} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default ConfigurationsTable;
