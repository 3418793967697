import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import {
  allSdkSnippetsQuery,
  sdkSnippetDeleteMutation
} from 'queries/sdkSnippet';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';

import { PostFormCacheNote } from './postFormInfo';

export type Props = {
  sdkSnippet: SdkSnippet;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function DeleteForm({ sdkSnippet, onClose }: Props) {
  const { name } = sdkSnippet;

  return (
    <Mutation mutation={sdkSnippetDeleteMutation}>
      {(deleteSnippet: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }
        if (error) {
          return <ErrorAlert error={error} className='m-2' />;
        }

        return (
          <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
            <div data-testid='sdk-snippet-delete-info'>
              Please confirm that you want to{' '}
              <span className='text-red-500 font-bold'>delete</span> the SDK
              Snippet{' '}
              <code className='font-bold' data-testid='sdk-snippet-delete-name'>
                {name}
              </code>
              .
            </div>
            <PostFormCacheNote />
            <div className='flex flex-row-reverse mt-2'>
              <Button
                type='button'
                color='danger'
                data-testid='sdk-snippet-delete-button'
                onClick={async event => {
                  await deleteSnippet({
                    variables: {
                      id: sdkSnippet.id
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [{ query: allSdkSnippetsQuery }]
                  });
                  onClose(event);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        );
      }}
    </Mutation>
  );
}
