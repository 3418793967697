import cn from 'classnames';
import { ErrorMessage, Field, FieldProps } from 'formik';

import Label from 'components/label';

const FormError = ({
  name,
  dataTestId
}: {
  name: string;
  dataTestId?: string;
}) => (
  <ErrorMessage
    name={name}
    component='p'
    className='flex-auto self-end mh-2 pl-1 text-red-500'
    data-testid={dataTestId}
  />
);

const FormTextField = ({
  name,
  placeholder,
  label,
  dataTestId,
  errorDataTestId,
  type
}: {
  name: string;
  placeholder: string;
  label: string;
  dataTestId?: string;
  errorDataTestId?: string;
  type: string;
}) => (
  <Field name={name} type={type}>
    {({ meta, field }: FieldProps) => (
      <>
        <Label
          htmlFor={name}
          className={cn({
            '!text-red-500': meta.error && meta.touched
          })}
        >
          {label}
        </Label>
        <div className='min-h-full pt-1'>
          <input
            id={name}
            data-testid={dataTestId}
            className={cn('border border-solid focus:outline-none p-2 w-full', {
              'border-gray-300 rounded focus:border-blue-400': !(
                meta.error && meta.touched
              ),
              'border-red-500 rounded focus:border-red-600':
                meta.error && meta.touched
            })}
            type={type}
            placeholder={placeholder}
            {...field}
          />
          <FormError name={name} dataTestId={errorDataTestId} />
        </div>
      </>
    )}
  </Field>
);

export default FormTextField;
