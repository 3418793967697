/**
 * This store interacts with the query engine itself. It stores the current state of queries as well
 * as periodically checking whether any running queries have been completed. It does not deal with
 * saving it to the DB (it would call through to the saved query store for that) or rending this in
 * the UI (thats the job of the query engine UI store)
 */

/**
 * A note on `authenticatedFetch` - this store needs to have it's authenticatedFetch method added by
 * something external. In our case, this is actually set by the auth context. Why? Well, this is currently
 * the only place that has access to the credentials that are needed to hit our API with the
 * correct credentials. And because it lives in context, only React components can use this functionality.
 * This means that a store like this has no mechanism to do an authenticated API call unless something
 * that knows how to hit the API correctly calls into this store to let it know how to do it.
 *
 * Its really crappy but is the only method we have to get around this that I can think of until we
 * remove the auth context altogether and create, maybe, another MobX store that allows external
 * calls.
 */

import cloneDeep from 'lodash/cloneDeep';
import { makeObservable, observable, action, runInAction } from 'mobx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import logger from 'utils/logger';

import { v4 as uuid } from 'uuid';

import { savedQueryStore, CreateInput } from './savedQuery';

dayjs.extend(utc);
dayjs.extend(timezone);

export const defaultQueryText =
  '-- Example Solve Query \n \nSELECT * FROM events LIMIT 1;';
export const errorMessageCouldNotConnect =
  'Sorry, there was an issue connecting to the Query Engine';

export type LocalStorageItem = {
  queries: { [key: string]: QueryEngineQuery };
};

export const localStorageKey = 'solve-query-engine-queries';
const saveToLocalStorage = (val: LocalStorageItem) =>
  window.localStorage.setItem(localStorageKey, JSON.stringify(val));
const fetchFromLocalStorage: () => LocalStorageItem = () => {
  const stored = JSON.parse(
    window.localStorage.getItem(localStorageKey) as string
  ) as LocalStorageItem;

  if (!stored?.queries) {
    // If there are no saved queries, return empty object as a default
    return { queries: {} };
  }

  const convertedQueries = {};
  // We have to convert the date strings into date objects or else we'll have some
  // queries with dates as strings, some with dates as Date objects
  Object.keys(stored.queries).forEach(queryId => {
    const query = stored.queries[queryId];
    if (typeof query.currentExecution?.startTime === 'string') {
      query.currentExecution.startTime = new Date(
        query.currentExecution.startTime
      );
    }
    if (typeof query.currentExecution?.finishTime === 'string') {
      query.currentExecution.finishTime = new Date(
        query.currentExecution.finishTime
      );
    }

    // Also ensure that the id is on the query record. An initial version of this did not have this here
    // which made some pieces of the code harder to manage. This fixes it up for backwards compatibility
    query.id = queryId;

    convertedQueries[queryId] = query;
  });

  return { queries: convertedQueries };
};

// There are a couple of options that can be set to do with how often the store checks for the
// results of any running queries. Currently these are only used for tests because in production
// a single store is shared across every UI container which does not set these
type Options = {
  doExecutionChecks?: boolean; // Whether to check at all for currently executing queries
  executionCheckMs?: number; // Milliseconds between checks
};
export const defaultOptions: Options = {
  doExecutionChecks: true,
  executionCheckMs: 1000
};
export default class Store {
  queryIdsToIgnoreResultsInLocalStorage: string[] = [];
  queries: { [key: string]: QueryEngineQuery } = {};
  // TODO
  // checkInterval?: number = undefined;
  checkInterval?: any;
  public authenticatedFetch: any = () => {};

  constructor(opts: Options) {
    const options = {
      ...defaultOptions,
      ...opts
    };
    makeObservable(this, {
      queries: observable,
      startQuery: action,
      cancelQuery: action,
      addBlankQuery: action,
      addSavedQuery: action,
      updateQueryText: action,
      updateSavedQueryId: action,
      updateInitialQueryText: action,
      syncWithLocalStorage: action
    });

    if (options.doExecutionChecks) {
      this.checkInterval = setInterval(() => {
        this.doPeriodicExecutionCheck();
      }, options.executionCheckMs);
    }
  }

  hydrateQueries() {
    this.syncWithLocalStorage();
  }

  /**
   * Synchronizes any `queries with localstorage. It both saves to and retrieves from depending on whether there
   * are any queries. The use of the empty query object is used as a proxy to tell whether this has been called
   * on initialisation or whether this function has been called from elsewhere.
   *
   * Will also inspect the string array at `this.queryIdsToIgnoreResultsInLocalStorage` and omit any results
   * from queries whose ids are in there
   *
   * If there are queries, the data is saved to localstorage, overriding anything that is already there.
   * If there are no queries, the data is retrieved from localstorage. If there is data there, the store inherits
   * it.
   */
  syncWithLocalStorage() {
    if (!Object.keys(this.queries).length) {
      const currentQueries = fetchFromLocalStorage();
      this.queries = currentQueries.queries;
    } else {
      const queriesToSave = cloneDeep(this.queries);
      this.queryIdsToIgnoreResultsInLocalStorage.forEach(id => {
        // For each id that we are ignoring results for, remove them from `this.queries` and then save
        // to localstorage. Also set status as `PENDING` so that on page refresh we don't get a `no
        // results found' message
        if (queriesToSave[id]) {
          queriesToSave[id].currentExecution.status = 'PENDING';
          queriesToSave[id].currentExecution.results = undefined;
        }
      });

      saveToLocalStorage({
        queries: queriesToSave
      });
    }
  }

  /**
   * The function that is periodically called to check any running executions
   */
  doPeriodicExecutionCheck = () => {
    Object.keys(this.queries)
      .filter(
        (queryId: any) =>
          // Only check the status for `RUNNING` and `UPLOADING` queries. No point checking others
          ['RUNNING', 'UPLOADING'].indexOf(
            this.queries[queryId].currentExecution.status
          ) !== -1
      )
      .forEach((queryId: any) => {
        this.checkQuery(queryId);
      });
  };

  /**
   * Adds a new query to the query engine. If a saved query Id is passed through, the
   * query engine query will have a reference to that saved query and will pull in the query text
   * from that saved query.
   *
   * If no query id is given then the query text will be a default example query
   */
  addQuery = (queryId?: any) => {
    if (queryId) {
      return this.addSavedQuery(queryId);
    }

    return this.addBlankQuery();
  };

  /**
   * Adds a query engine query with the default example query
   */
  addBlankQuery = () => {
    // Create a new query engine query
    const newId = uuid();
    const query: QueryEngineQuery = {
      id: newId,
      hasChanged: true,
      initialQuery: '', // This is a blank query where the default state is an empty query
      query: defaultQueryText,
      currentExecution: {
        status: 'PENDING'
      }
    };
    this.queries[newId] = query;

    this.syncWithLocalStorage();

    return newId;
  };

  /**
   * Adds a query engine query that references a query that has previously been saved
   */
  addSavedQuery = (queryId: number) => {
    const savedQuery = savedQueryStore.queries.find(q => {
      return q.id === queryId;
    });

    if (!savedQuery) {
      // Throw an error if there is no saved query in the saved query store. It's the responsibility of
      // the UI to deal with this failure case
      throw new Error(`No saved query with id: ${queryId}`);
    }

    const newId = uuid();
    this.queries[newId] = {
      id: newId,
      savedQueryId: queryId,
      hasChanged: false,
      initialQuery: savedQuery.query,
      query: savedQuery.query,
      currentExecution: {
        status: 'PENDING'
      }
    };

    this.syncWithLocalStorage();

    return newId;
  };

  /**
   * Adds a fetched saved query
   */
  addFetchedQuery = (savedQuery: Query, queryId: number) => {
    const newId = uuid();
    this.queries[newId] = {
      id: newId,
      savedQueryId: queryId,
      hasChanged: false,
      initialQuery: savedQuery!.query,
      query: savedQuery!.query,
      currentExecution: {
        status: 'PENDING'
      },
      name: savedQuery!.name
    };

    this.syncWithLocalStorage();

    return {
      id: newId,
      savedQueryId: queryId
    };
  };

  /**
   * Updates an existing saved query in the DB
   */
  async updateSavedQuery(queryId: string, values: CreateInput) {
    const query = this.queries[queryId];

    if (!query.savedQueryId) {
      throw new Error(
        `This query has no saved query so cannot be updated: ${queryId}`
      );
    }
    // Make sure that we update with the current query value
    const valuesWithQuery: CreateInput = {
      ...values,
      query: query.query
    };

    const updatedQuery = await savedQueryStore.update(
      query.savedQueryId,
      valuesWithQuery
    );

    // And update the initialQuery in the store here
    this.queries[queryId] = {
      ...this.queries[queryId],
      initialQuery: query.query,
      hasChanged: false
    };

    this.syncWithLocalStorage();

    return updatedQuery;
  }

  /**
   * Given the id of the query engine query, update the query text.
   */
  updateQueryText = (queryId: string, query: string) => {
    this.queries[queryId].query = query;
    this.queries[queryId].hasChanged =
      this.queries[queryId].initialQuery !== this.queries[queryId].query;

    this.syncWithLocalStorage();
  };

  /**
   * Given the id of the query engine query, set the initial query text to whatever the current
   * query text is and reset the `hasChanged` value. This would be used after a successful
   * update to the DB for an existing saved query
   */
  updateInitialQueryText = (queryId: string) => {
    this.queries[queryId].initialQuery = this.queries[queryId].query;
    this.queries[queryId].hasChanged = false;

    this.syncWithLocalStorage();
  };

  /**
   * Given the id of the query engine query, tell the query engine to start calculating the
   * results of the query's text. It will set the `status` of the query to `RUNNING` which
   * means it'll be periodically checked for execution status changes.
   */
  startQuery = async (
    queryId: any,
    autoFill?: boolean,
    queryParameters?: { timezone?: string }
  ) => {
    // First update `currentExecution` to say we're starting a new query
    this.queries[queryId].currentExecution = {
      status: 'STARTING',
      startTime: new Date()
    };

    // Then hit the API. Once the API returns, set the status of the current
    // execution for the given id to `RUNNING`
    let { query } = this.queries[queryId];

    this.queries[queryId].currentExecution = {
      status: 'RUNNING',
      progress: 0,
      startTime: new Date()
    };

    if (autoFill && queryParameters && queryParameters.timezone) {
      const startTime = dayjs(new Date())
        .tz(queryParameters?.timezone)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss.SSS [UTC]');
      const endTime = dayjs(new Date())
        .tz(queryParameters.timezone)
        .endOf('day')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss.SSS [UTC]');

      const timeZoneStr = `'${queryParameters.timezone}'`;
      const startTimeStr = `'TIMESTAMP ${startTime}'`;
      const endTimeStr = `'TIMESTAMP ${endTime}'`;

      query = query.replaceAll('$timezone', timeZoneStr);
      query = query.replaceAll('$start_time', startTimeStr);
      query = query.replaceAll('$end_time', endTimeStr);
    }

    try {
      await this.authenticatedFetch('query-engine/executions', {
        method: 'POST',
        body: JSON.stringify({ query })
      }).then((result: QueryEngineResults) => {
        runInAction(() => {
          if (result.error) {
            // We have an error message and could not start.
            this.queries[queryId].currentExecution.status = 'ERROR';
            this.queries[queryId].currentExecution.errorMessage =
              result.error.message;
            this.queries[queryId].currentExecution.finishTime = new Date();
          } else {
            // The query was started successfully
            this.queries[queryId].currentExecution.executionId =
              result.execution_id;
          }

          this.syncWithLocalStorage();
        });
      });
    } catch (err) {
      // Something unexpected happened. Set this query as error'd
      runInAction(() => {
        this.queries[queryId].currentExecution = {
          ...this.queries[queryId].currentExecution,
          status: 'ERROR',
          finishTime: new Date(),
          errorMessage: errorMessageCouldNotConnect
        };

        this.syncWithLocalStorage();
      });
    }
  };

  /**
   * Given the id of the query engine query, tell the query engine to stop calculating the
   * results of the query's text.
   */
  cancelQuery = (queryId: any) => {
    const queryExecutionId = this.queries[queryId].currentExecution.executionId;
    this.queries[queryId].currentExecution = {
      ...this.queries[queryId].currentExecution,
      executionId: undefined,
      status: 'CANCELED',
      finishTime: new Date(),
      progress: 0,
      results: undefined
    };

    return this.authenticatedFetch(
      `query-engine/executions/${queryExecutionId}`,
      {
        method: 'DELETE'
      }
    )
      .catch((err: Error) => {
        // The query has been already set to `CANCELED` so we don't need to do anything here.
        // Simply log for any developers
        logger.error(err, 'Error cancelling execution execution');
      })
      .finally(() => {
        this.syncWithLocalStorage();
      });
  };

  /**
   * Given the id of the query engine query, go and get hte current status of the query from
   * the query engine
   */
  checkQuery = (queryId: any) => {
    const { currentExecution } = this.queries[queryId];

    if (!currentExecution.executionId) {
      return;
    }

    try {
      this.authenticatedFetch(
        `query-engine/executions/${currentExecution.executionId}`
      ).then((result: any) => {
        if (result.state === 'ERROR') {
          runInAction(() => {
            this.queries[queryId].currentExecution = {
              status: 'ERROR',
              finishTime: new Date(),
              errorMessage: `${result.error.type}: ${result.error.message}`
            };
          });
        } else if (result.state !== 'RUNNING' && result.state !== 'UPLOADING') {
          // The query is no longer running. Save the status and any results, if any
          runInAction(() => {
            this.queries[queryId].currentExecution = {
              ...this.queries[queryId].currentExecution,
              status: result.state,
              progress: result.progress,
              finishTime: new Date(),
              results: result
            };
            try {
              // The query has completed. Attempt to sync with local storage
              this.syncWithLocalStorage();
            } catch (err) {
              logger.error(
                `Error syncing with localstorage. Try again while ignoring results from ${queryId}`,
                queryId
              );
              this.queryIdsToIgnoreResultsInLocalStorage =
                this.queryIdsToIgnoreResultsInLocalStorage.concat(queryId);
              this.queries[queryId].resultsOmittedFromLocalstorage = true;

              this.syncWithLocalStorage();
            }
          });
        } else {
          // Else we're still running. As we may have results when we're uploading, map over the
          // progress and the results
          runInAction(() => {
            this.queries[queryId].currentExecution.progress = result.progress;
            this.queries[queryId].currentExecution.results = result.results;

            this.syncWithLocalStorage();
          });
        }
      });
    } catch (err) {
      // Something unexpected happened. Set as errored
      this.queries[queryId].currentExecution = {
        status: 'ERROR',
        finishTime: new Date(),
        errorMessage: errorMessageCouldNotConnect
      };

      this.syncWithLocalStorage();
    }
  };

  /**
   * Allows the removal of a query via the query engine query id
   */
  removeQuery(queryEngineId: string) {
    if (this.queries[queryEngineId]) {
      delete this.queries[queryEngineId];
      this.syncWithLocalStorage();
    }
  }

  /**
   * Fetches the query engine record by the corresponding saved query id
   */
  fetchQueryIdBySavedQueryId(savedQueryId: number) {
    const queryEntries = Object.entries(this.queries);
    for (let i = 0; i < queryEntries.length; i++) {
      const entry = queryEntries[i];
      const query = entry[1];
      if (query.savedQueryId === savedQueryId) {
        return entry[0];
      }
    }

    return null;
  }

  /**
   * For a query engine execution, go and fetch a link to the CSV that contains all results
   */
  async getDownloadUrl(executionId: string) {
    const url = await this.authenticatedFetch(
      `query-engine/executions/${executionId}/downloads/`
    ).then((value: any) => value.csv);
    return url;
  }

  /**
   * If a query engine query has been saved to the DB, this can be called to make sure
   * that the corresponding query here as a reference to the saved query id.
   */
  updateSavedQueryId(queryId: string, savedQueryId: number) {
    this.queries[queryId].savedQueryId = savedQueryId;
    this.updateInitialQueryText(queryId);
  }
}

export const queryEngineStore = new Store({});
