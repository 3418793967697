import ButtonLink from 'components/buttonLink';
import Button from 'components/button';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
  shopifyUrl: string;
}

const RedirectToShopify = ({ onClose, shopifyUrl }: Props) => {
  return (
    <div className='px-5 pt-2 pb-5'>
      <div className='flex flex-col justify-center items-center	py-10'>
        <p className='pt-2 pb-2'>
          This will redirect you to Shopify to add Solve to your shop.
        </p>
        <div>
          <ButtonLink
            color='success'
            className='px-8 mt-2 uppercase'
            href={shopifyUrl}
          >
            Connect Solve to Shopify
          </ButtonLink>
        </div>
      </div>

      <div className='flex flex-row-reverse'>
        <Button
          type='button'
          color='primary'
          className='px-8 uppercase'
          onClick={onClose}
          data-testid='confirm-server-api-key'
        >
          close
        </Button>
      </div>
    </div>
  );
};

export default RedirectToShopify;
