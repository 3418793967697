import { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

import logger from 'utils/logger';
import theme from 'utils/tailwind';

import { useAuthContext } from 'context/auth';

import SearchLoader from './loader';
import ExampleSearch from './exampleSearch';
import ProfileItem from './profileItem';
import OrderItem from './orderItem';

import { ReactComponent as SearchIcon } from 'resources/icons/search.svg';

import './styles.css';

const AsyncSearchBar = () => {
  const { authenticatedFetch } = useAuthContext();
  const [results, setResults] = useState([]);
  const [input, setInput] = useState('');
  const navigate = useNavigate();

  const handleOnChange = (value: any, { action }: any) => {
    if (action === 'select-option') {
      if (value.type === 'profile') {
        navigate(`/profiles/${value.id}`, { replace: true });
      }
      if (value.type === 'order') {
        const store = encodeURIComponent(value.store);
        const id = encodeURIComponent(value.id);
        navigate(`/orders/${store}/${id}`, { replace: true });
      }
    }
  };

  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      position: 'absolute'
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      marginTop: '-6px',
      padding: theme.padding['1'],
      width: theme.width['80'],
      backgroundColor: theme.colors.white,
      border: 0,
      color: theme.colors.gray['400'],
      boxShadow:
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: theme.colors.gray['700'],
      borderRadius: '6px',
      color: theme.colors.gray['500'],
      height: '2.5rem',
      padding: '2px 0 3px 0',
      marginTop: '12px',
      backgroundColor: theme.colors.gray['700'],
      border: 0,
      boxShadow: 'none',
      ':hover': {
        backgroundColor: theme.colors.white
      },
      ':active': {
        backgroundColor: theme.colors.white
      },
      ':focus-within': {
        backgroundColor: theme.colors.white,
        color: theme.colors.gray['700']
      }
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      position: 'absolute',
      height: '20px',
      width: '20px'
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.gray['400']
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: '34px'
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      margin: '2px',
      color: 'inherit',
      cursor: 'text',
      display: 'flex',
      input: {
        opacity: '1 !important'
      } // fix: https://github.com/JedWatson/react-select/issues/3068#issuecomment-613793684
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.colors.gray['200'] : null,
      ':hover': {
        backgroundColor: theme.colors.gray['200']
      }
    }),
    menuList: (provided: any, state: any) => ({
      ...provided,
      maxHeight: '30rem',
      overflowY: 'auto'
    })
  };

  const CustomOption = (props: any) => {
    const { innerProps, innerRef, data } = props;
    switch (data.type) {
      case 'profile': {
        return (
          <ProfileItem
            data={data}
            innerProps={innerProps}
            innerRef={innerRef}
            props={props}
          />
        );
      }
      case 'order': {
        return (
          <OrderItem
            data={data}
            innerProps={innerProps}
            innerRef={innerRef}
            props={props}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const CustomDropdownIndicator = () => {
    return (
      <SearchIcon className='absolute top-2.5 left-0 ml-3 w-4 h-4 text-gray-400' />
    );
  };

  const CustomNoOption = () => {
    if (input.length === 0) {
      return <ExampleSearch />;
    }

    if (results.length === 0 && input.length > 0) {
      return (
        <div
          className='px-4 py-2 text-gray-800'
          data-testid='empty-search-results'
        >
          We couldn’t find <strong>{input}</strong> — Try broadening your search
          terms or searching for something else.
        </div>
      );
    }
    return <></>;
  };

  const getAsyncOptions = async (inputText: any) => {
    try {
      const data = await authenticatedFetch('search?query=', {
        method: 'POST',
        body: JSON.stringify({
          query: inputText.trim()
        })
      });

      if (data.error) {
        logger.error(data.error);
      } else {
        setResults(data.results);
        return data.results;
      }
    } catch (error) {
      logger.error(error, 'getAsyncOptions error');
    }
  };

  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options: any) => callback(options));
    }, 1000),
    []
  );

  return (
    <AsyncSelect
      defaultOptions={results}
      classNamePrefix='react-select'
      className='search-bar self-center h-16 left-36'
      placeholder='Search'
      styles={customStyles}
      components={{
        Option: CustomOption,
        DropdownIndicator: CustomDropdownIndicator,
        IndicatorSeparator: () => null,
        LoadingIndicator: () => null,
        LoadingMessage: SearchLoader,
        NoOptionsMessage: CustomNoOption
      }}
      loadOptions={loadOptions}
      onChange={handleOnChange}
      inputValue={input}
      onInputChange={(value, action) => {
        // only set the input when the action that caused the
        // change equals to "input-change" and ignore the other
        // ones like: "set-value", "input-blur", and "menu-close"
        if (action.action === 'input-change') setInput(value);
      }}
    />
  );
};

export default AsyncSearchBar;
