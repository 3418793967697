import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';

import { removeLettersAfterLast } from 'utils/dataFormat';
import { GIT_REVISION } from 'utils/revision';

import { useAuthContext } from 'context/auth';
import { useSolveUiBaseUrlContext } from 'context/solveBaseUrl';

import Tooltip from 'components/tooltip';

import { ReactComponent as AWS } from 'resources/icons/aws.svg';

interface Props {
  className?: string;
}

export const InfrastructureTooltip = () => {
  const { config } = useAuthContext();
  const baseUrlInfo = useSolveUiBaseUrlContext();
  const location = useLocation();

  const timezone = config?.solve.timezone.replace(/_/g, ' ');
  const stackSize = config?.solve.stackSize;

  const uiUrlVersion = baseUrlInfo.isLatest
    ? 'latest'
    : baseUrlInfo.urlRevision.slice(0, 7);
  const uiVersion = `${GIT_REVISION}`.slice(0, 7);

  const latestUrl = location.pathname + location.search + location.hash;

  return (
    <div className='text-left' data-testid='infra-dropdown'>
      <div className='border-b'>
        <div className='p-3 pb-2'>
          <p className='uppercase pb-2 text-xs font-semibold'>Infrastructure</p>
          <div className='flex flex-row' data-testid='infra-aws'>
            <AWS className='h-full w-8 mr-2' />
            <div>
              <p className='text-sm font-bold leading-none mt-1'>
                Amazon Web Services
              </p>
              <p className='text-xs'>
                <span data-testid='infra-region'>{config?.solve.region}</span>
                {stackSize && (
                  <span
                    className='pl-1 inline-block'
                    data-testid='infra-stack-size'
                  >
                    ({stackSize})
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='text-sm p-3'>
        <p data-testid='infra-api-version'>
          API version <span>{config?.solve.version_rev.slice(0, 7)}</span>
          {/* tagged version of code under condition (need to solve
              https://solvedata.atlassian.net/browse/SOLV-1579 to populate
              this one) */}
          {config?.solve.version_tag && (
            <>
              {' '}
              <span className='h-1 w-1 rounded bg-gray-500 inline-block align-middle'></span>{' '}
              <span data-testid='infra-api-version-tag'>
                {removeLettersAfterLast(config?.solve.version_tag, '-')}
              </span>
            </>
          )}
        </p>
        <p data-testid='infra-ui-version'>
          UI version <span>{uiVersion}</span> <span>({uiUrlVersion})</span>
        </p>

        <p data-testid='infra-timezone'>
          Timezone <span>{timezone}</span>
        </p>
        <p>
          Current date{' '}
          <span className='whitespace-nowrap'>
            {dayjs.tz(dayjs()).format('YYYY-MM-DD hh:mm A')}
          </span>
        </p>
      </div>
      {baseUrlInfo.isLatest ? (
        <></>
      ) : (
        <div className='border-t' data-testid='infra-go-to-latest-ui'>
          {/*
          Need to use a `a` tag rather than a react-router `Link`
           because we're changing versions and need a complete reload.
          */}
          <a
            className='block text-sm p-3 text-center text-blue-400 underline'
            href={latestUrl}
          >
            Go to the latest version of the UI
          </a>
        </div>
      )}
    </div>
  );
};

const Infrastructure = ({ className }: Props) => {
  const baseUrlInfo = useSolveUiBaseUrlContext();

  return (
    <div className={cn('relative', className)}>
      <Tooltip
        position='bottom-end'
        trigger='click'
        className='h-full justify-center lg:flex lg:flex-col'
        useContext
        html={<InfrastructureTooltip />}
      >
        <div
          data-testid='infra-info'
          className={cn(
            'transition duration-500 cursor-pointer text-gray-400 hover:text-gray-300',
            'border-gray-600 hover:border-gray-400 uppercase px-2 border-2 rounded',
            { 'bg-purple-600': !baseUrlInfo.isLatest }
          )}
        >
          aws
        </div>
      </Tooltip>
    </div>
  );
};

export default Infrastructure;
