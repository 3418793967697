export function joinWithComma(...data: string[]) {
  return data.filter((val: string) => !!val && val.trim()).join(', ');
}

// Converts snake or space-separated words to pascal case
export const humanize = (data: string, firstLetterOnly?: boolean): string => {
  if (!data?.length) return '';
  return firstLetterOnly
    ? data.charAt(0).toUpperCase() + data.slice(1).replace(/_/g, ' ')
    : data.replace(/(?:_| |\b)(\w)/g, $1 => {
        return $1.toUpperCase().replace('_', ' ');
      });
};

export const primitiveType = (value: any) =>
  typeof value === 'boolean' ? JSON.stringify(value, null, 2) : value;

export const beautifyNumber = (value: number | string, countryCode: string) =>
  Intl.NumberFormat(countryCode).format(Number(value));

// example: remove letters after last '_', or '-', or any other symbol/letter that you like
export const removeLettersAfterLast = (
  value: string | undefined,
  symbol: string
) => {
  if (!value) return '';
  return value.replace(new RegExp(`${symbol}[^${symbol}]+$`), '');
};
