import Button from 'components/button';
import Logo from 'components/logo';

const AuthError = () => (
  <div data-testid='auth-error' className='h-screen w-screen bg-gray-800'>
    <div className='auth-container mx-auto flex flex-col min-h-screen'>
      <div className='flex-grow'>&nbsp;</div>
      <div className='items-center flex-none'>
        <Logo className='mx-auto mb-12 w-64' />
        <div className='text-center'>
          <p className='text-white pb-12'>
            Sorry, there was an error getting the authentication details.
          </p>
          <Button
            type='button'
            color='primary'
            onClick={() => window.location.reload()}
          >
            Reload the page and try again
          </Button>
          <p className='mt-4'>
            <a className='text-blue-400' href='https://solvedata.app'>
              Or reselect your company alias
            </a>
          </p>
        </div>
      </div>
      <div className='flex-grow'>&nbsp;</div>
    </div>
  </div>
);

export default AuthError;
