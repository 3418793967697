import isPlainObject from 'lodash/isPlainObject';

const PROPERTIES_JSON_ERROR = { properties: 'Invalid JSON object' };

export const validateProperties = (values: { properties: string }) => {
  if (!values.properties) {
    return { properties: 'Properties cannot be empty' };
  }
  try {
    // jump to catch catch if invalid JSON
    const properties = JSON.parse(values.properties);
    // since we only want object literals (maps), but JSON could be an array too:
    if (isPlainObject(properties)) {
      return {};
    }
  } catch (error) {}
  return PROPERTIES_JSON_ERROR;
};

export const validateCreateConfigurationValues = (values: {
  id: string;
  properties: string;
}) => {
  const errors: { id?: string; properties?: string } = {};

  if (values.id === '') {
    errors.id = 'An id is required for a new configuration';
  }

  return { ...errors, ...validateProperties(values) };
};
