import { CurrencyCodeRecord } from 'currency-codes';

import logger from 'utils/logger';

export interface Props {
  countryCode?: string;
  // Show a large number as 4K rather than 4,000. This will only 'work' on browsers
  //  that support it, otherwise it will fall back to rendering `4,000`.
  notation?: 'compact';
  // Drop `.00` from short numbers(doesn't work reliably when notation === 'compact')
  dropZero?: boolean;
  isMinor?: boolean;
  displayCurrencyCode?: boolean;
}

export function buildFormatValue({
  supportsNarrowSymbol,
  numberFormatConstructor
}: {
  supportsNarrowSymbol: boolean;
  numberFormatConstructor?: (
    locales?: string | string[] | undefined,
    options?: Intl.NumberFormatOptions | undefined
  ) => Intl.NumberFormat;
}) {
  // Create a function to use as formatting. We do this once at
  //  startup to speed up rendering.
  return (
    price: number,
    countryCode: string | undefined,
    displayCurrencyCode: boolean | undefined,
    config: {
      maximumFractionDigits: number;
      minimumFractionDigits: number;
      currency: string;
      notation?: 'compact';
    }
  ) => {
    const fmtConfig: Record<string, any> = {
      style: 'currency',
      maximumFractionDigits: config.maximumFractionDigits,
      minimumFractionDigits: config.minimumFractionDigits,
      currency: config.currency
    };

    const currencyDisplay = displayCurrencyCode ? config.currency + ' ' : '';

    if (!numberFormatConstructor) {
      // toLocaleString with options has very good browser
      //  support(IE>=11, Safari>=10), so we'll fall back
      //  to this if all else fails.
      return (
        currencyDisplay +
        price.toLocaleString(countryCode, {
          maximumFractionDigits: config.maximumFractionDigits,
          minimumFractionDigits: config.minimumFractionDigits
        })
      );
    } else if (supportsNarrowSymbol) {
      if (config?.notation === 'compact') {
        // All browsers that support `narrowSymbol` also support
        //  the notation and compactDisplay options
        fmtConfig.notation = 'compact';
        fmtConfig.compactDisplay = 'short';
      }
      // We support using narrowSymbol, which will only include
      //  `$` rather than `US$`. This means we should add the
      //  currency code to the start.
      return (
        currencyDisplay +
        numberFormatConstructor(countryCode, {
          ...fmtConfig,
          currencyDisplay: 'narrowSymbol'
        } as Intl.NumberFormatOptions).format(price)
      );
    } else {
      // We don't support `narrowSymbol`, meaning the value will
      //  likely contain the country(like `US$`). We should just
      //  leave it as-is and not try to add anything.
      return numberFormatConstructor(countryCode, {
        ...fmtConfig,
        currencyDisplay: 'symbol'
      } as Intl.NumberFormatOptions).format(price);
    }
  };
}

export function detectFormatValue(
  // Allow passing in parameter for testing.
  numberFormatConstructor = Intl.NumberFormat
) {
  const baseCfg = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currency: 'USD'
  };

  // Check the base-level of support. All browsers we
  //  support will have this functionality.
  numberFormatConstructor('en-US', {
    ...baseCfg,
    currencyDisplay: 'symbol'
  }).format(12.345);

  let supportsNarrowSymbol = false;
  try {
    // Check support for narrowSymbol
    numberFormatConstructor('en-US', {
      ...baseCfg,
      currencyDisplay: 'narrowSymbol',
      // These options convert `1000` into `1K`
      notation: 'compact',
      compactDisplay: 'short'
    } as Intl.NumberFormatOptions).format(12.345);
    supportsNarrowSymbol = true;
  } catch (e) {}
  return {
    numberFormatConstructor,
    supportsNarrowSymbol
  };
}

const formatValue = buildFormatValue(detectFormatValue());

export default function formatCurrency(
  rawPrice: number | string | undefined,
  currency: CurrencyCodeRecord | undefined,
  {
    countryCode,
    notation,
    dropZero,
    isMinor,
    displayCurrencyCode = true
  }: Props = {}
): string | null {
  const price = Number(rawPrice);
  if (isNaN(price) || currency === undefined) {
    return null;
  }

  const factor = Math.pow(10, currency.digits);
  // Remove unnecessary precision by discarding the fractional part of the minor.
  // We discard the sign so the floor acts as a truncation on a negative numbers.
  // As: Math.floor(-1.2) === -2

  const priceMinor = isMinor ? price : Math.floor(Math.abs(price * factor));
  const priceMajor = isMinor ? price / factor : price;
  const hasDecimals = priceMinor % factor !== 0;

  const priceHasDecimal = priceMajor % 1 !== 0;

  // TODO: Support `dropZero` when notation === 'compact'.
  // This will require some work to support as we need to handle 4001.00 -> `4.00K`
  const digits =
    !hasDecimals && dropZero && notation !== 'compact' ? 0 : currency.digits;

  try {
    return formatValue(priceMajor, countryCode, displayCurrencyCode, {
      maximumFractionDigits: digits,
      minimumFractionDigits: priceHasDecimal ? digits : 0,
      currency: currency.code,
      notation
    });
  } catch (e) {
    logger.error(e, {
      rawPrice,
      currency,
      countryCode,
      notation,
      dropZero,
      price
    });
    return `${currency.code} ${priceMinor / factor}`;
  }
}
