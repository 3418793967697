/**
 * In order to not mess with people when the query engine refactor goes out, we
 * migrate the current local storage solution into the new refactor solution
 * so that the saved state (apart from the unsaved query)
 */

import { v4 as uuid } from 'uuid';

import {
  queryEngineUiStore,
  Tab,
  localStorageKey as uiLocalStorageKey
} from './ui';
import { queryEngineStore } from './engine';

export const v1LocalStorageKey = 'SolveQueryEngineTabs';

export type V1LocalStorage = {
  id?: number;
  query: string;
  name?: string;
  description?: string;
  index: number;
};

const migrateFromQueryEngineLocalstorageV1 = () => {
  const v1Values = window.localStorage.getItem(v1LocalStorageKey);
  const uiValues = window.localStorage.getItem(uiLocalStorageKey);

  // If we have something already in the UI local storage, do nothing. This user has
  // already gone through the migration
  if (uiValues) {
    return;
  }

  // First see if there is anything in v1 local storage. If nothing, do nothing
  if (!v1Values) {
    return;
  }

  // At this point we have v1 values and no ui values. Parse the existing data
  const parsedV1Values: {
    tabs: V1LocalStorage[];
    activeTabIdx: number;
  } = JSON.parse(v1Values);

  // And now move across from v1 local storage to the correct store
  const queryEngineQueries: { [key: string]: QueryEngineQuery } = {};
  const tabs: Tab[] = [];

  let unsavedQueryCounter = 1;

  parsedV1Values.tabs.forEach(v1Tab => {
    // Create a new query engine query
    const engineQueryId = uuid();
    const engineQuery: QueryEngineQuery = {
      id: engineQueryId,
      currentExecution: {
        status: 'PENDING'
      },
      hasChanged: false,
      initialQuery: v1Tab.query,
      query: v1Tab.query
    };

    // And create a new tab referring to this query
    const tab: Tab = {
      queryEngineId: engineQueryId,
      queryId: null
    };

    if (v1Tab.id) {
      // This has a record as a saved query. Reference that in the engine query
      engineQuery.savedQueryId = v1Tab.id;
    } else {
      // Else it has not been saved yet. Show this in the tab record
      tab.unsavedQueryIdx = v1Tab.index;
      // And increment this for saving to localstorage later
      unsavedQueryCounter = unsavedQueryCounter + 1;
    }

    queryEngineQueries[engineQueryId] = engineQuery;
    tabs.push(tab);
  });

  // Update the store values directly and then sync them with localstorage
  queryEngineUiStore.tabs = tabs;
  queryEngineUiStore.activeTabIdx = parsedV1Values.activeTabIdx || 0;
  queryEngineUiStore.nextUnsavedQueryIdx = unsavedQueryCounter;
  queryEngineStore.queries = queryEngineQueries;

  queryEngineUiStore.syncWithLocalStorage();
  queryEngineStore.syncWithLocalStorage();
};

export default migrateFromQueryEngineLocalstorageV1;
