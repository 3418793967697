import { createContext, useContext } from 'react';

// This context is set up in the `src/index.ts` file using the
//  `getUrlRevisionInformation` function in `src/config/index.ts`. It
//  contains information about the URL that the Admin UI is running on.
export const SolveUiBaseUrlContext = createContext<UiBaseUrlInfo>({
  isLatest: false,
  isPr: false,
  rootPath: '',
  urlRevision: ''
});
SolveUiBaseUrlContext.displayName = 'SolveBaseUrlContext';

export const useSolveUiBaseUrlContext: () => UiBaseUrlInfo = () =>
  useContext(SolveUiBaseUrlContext);
