import {
  sdkSnippetUpdateMutation,
  allSdkSnippetsQuery
} from 'queries/sdkSnippet';

import SdkSnippetForm from './snippetForm';

interface Props {
  sdkSnippet: SdkSnippet;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export default function EditForm({ sdkSnippet, onClose }: Props) {
  return (
    <SdkSnippetForm
      mutation={sdkSnippetUpdateMutation}
      refetchQuery={allSdkSnippetsQuery}
      sdkSnippetId={sdkSnippet.id}
      onClose={onClose}
      initialValues={sdkSnippet}
      formMode='update'
    />
  );
}
