import { useCallback, useState } from 'react';

import Title from 'components/title';
import Button from 'components/button';
import Modal from 'components/modal';
import Tooltip from 'components/tooltip';

import Settings from './settings';
import APIKeysTable from './apiKeysTable';
import Setup from './forms/setup';

interface State {
  ariaLabel: string;
  title: string;
  type?: 'PROTECTED' | 'PUBLIC' | 'PIXEL';
}

const APIKeysSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal state
  const [modalProps, setModalProps] = useState<State>({
    ariaLabel: '',
    title: '',
    type: undefined
  });

  const tooltipToggle = useCallback(
    () => setIsOpen(prevIsOpen => !prevIsOpen),
    []
  );
  const modalToggle = useCallback(
    () => setIsModalOpen(prevIsModalOpen => !prevIsModalOpen),
    []
  );

  const onSetup = (type: 'PROTECTED' | 'PUBLIC' | 'PIXEL') => {
    tooltipToggle();
    modalToggle();

    let title: string;
    switch (type) {
      case 'PROTECTED':
        title = 'Server API Setup';
        break;
      case 'PUBLIC':
        title = 'Web SDK Setup';
        break;
      case 'PIXEL':
        title = 'Tracking Pixel Setup';
        break;
      default:
        throw new Error(`Unsupported API Key modal type: ${type}`);
    }

    setModalProps({ ariaLabel: title, title, type });
  };

  return (
    <>
      <div className='flex'>
        <Title size='large' className='flex-grow'>
          API Access
        </Title>
        <Tooltip
          className='flex-none'
          position='bottom-end'
          trigger='manual'
          open={isOpen}
          onRequestClose={tooltipToggle}
          html={
            <div
              className='text-left text-sm py-2 w-72'
              data-testid='profile-dropdown'
            >
              <div
                onClick={() => onSetup('PROTECTED')}
                data-testid='PROTECTED'
                className='py-2 px-4 cursor-pointer hover:bg-gray-200 border-b border-gray-200'
              >
                <h4 className='font-bold m-0 p-0'>
                  <span className='mr-2'>Server API</span>
                  <span className='text-xs bg-blue-100 text-blue-400 py-0.5 px-1 rounded uppercase antialiased font-semibold'>
                    Protected
                  </span>
                </h4>
                <p>
                  Grant read/write access to a trusted server/app to access your
                  data
                </p>
              </div>
              <div
                onClick={() => onSetup('PUBLIC')}
                data-testid='PUBLIC'
                className='py-2 px-4 cursor-pointer hover:bg-gray-200'
              >
                <h4 className='font-bold'>
                  <span className='mr-2'>Web SDK</span>
                  <span className='text-xs bg-blue-100 text-blue-400 py-0.5 px-1 rounded uppercase antialiased font-semibold'>
                    Public
                  </span>
                </h4>
                <p>Integrate the Solve JavaScript Web SDK into your website</p>
              </div>
              <div
                onClick={() => onSetup('PIXEL')}
                data-testid='PIXEL'
                className='py-2 px-4 cursor-pointer hover:bg-gray-200'
              >
                <h4 className='font-bold'>
                  <span className='mr-2'>Pixel URL</span>
                  <span className='text-xs bg-blue-100 text-blue-400 py-0.5 px-1 rounded uppercase antialiased font-semibold'>
                    Public
                  </span>
                </h4>
                <p>An image pixel for email templates and third party sites</p>
              </div>
            </div>
          }
        >
          <Button
            type='button'
            color='primary'
            className='uppercase'
            onClick={tooltipToggle}
            data-testid='create-new-integration'
          >
            New API Integration
          </Button>
        </Tooltip>
      </div>
      <Settings />
      <APIKeysTable />
      <Modal {...modalProps} isOpen={isModalOpen} onRequestClose={modalToggle}>
        <Setup type={modalProps.type} onClose={modalToggle} />
      </Modal>
    </>
  );
};

export default APIKeysSection;
