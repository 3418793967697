import cn from 'classnames';
import sample from 'lodash/sample';

import { Table } from '../apiKeySection/apiKeysTable/skeleton';

const Header = () => (
  <>
    {/* Name */}
    <td className='p-2 w-[600px]'>
      <div className='loader-box rounded w-10 h-4'></div>
    </td>
    {/* Priority */}
    <td className='p-2 w-44'>
      <div className='loader-box rounded w-12 h-4'></div>
    </td>
    {/* Updated At */}
    <td className='p-2'>
      <div className='loader-box rounded w-[72px] h-4'></div>
    </td>
  </>
);

const Row = () => {
  const firstRowWidth = sample(['w-64', 'w-36', 'w-24']);

  return (
    <>
      {/* Name */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className={cn('loader-box rounded h-4', firstRowWidth)}></div>
      </td>
      {/* Priority */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className='loader-box rounded w-5 h-4'></div>
      </td>
      {/* Updated At */}
      <td className='px-2 pt-[11px] pb-2.5'>
        <div className='loader-box rounded w-12 h-4'></div>
      </td>
    </>
  );
};

const Skeleton = () => (
  <div data-testid='skeleton-loader'>
    <div className='relative mt-7 pt-px'>
      <Table headerComponent={<Header />} rowComponent={<Row />} />

      <div className='absolute bg-gradient-to-t from-white top-0 w-full h-full z-50'></div>
    </div>
  </div>
);

export default Skeleton;
