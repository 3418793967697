import { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { DocumentNode } from 'graphql';

import { useMutation } from '@apollo/client';

import { validateSdkSnippet } from 'validation/sdk';

import Button from 'components/button';
import Loader from 'components/loader';
import HelpText from 'components/helpText';
import Label from 'components/label';
import FormTextField from 'components/formComponents/formTextField';
import FormCustomCodeEditor from 'components/formComponents/formCustomCodeEditor';

import PostFormInfo from './postFormInfo';

export type Props = {
  mutation: DocumentNode;
  refetchQuery: DocumentNode;
  sdkSnippetId?: number;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  initialValues: FormData;
  formMode: 'create' | 'update';
};

export type FormData = {
  name: string;
  content: string;
  priority: number;
  enabled: boolean;
};

export const SnippetForm = ({
  submitDisplayName
}: {
  submitDisplayName: string;
}) => (
  <Form data-testid='sdk-snippet-form'>
    <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
      <div data-testid='snippet-name-section'>
        <FormTextField
          name='name'
          placeholder='Choose a name. Something short, unique, and identifiable'
          label='name'
          dataTestId='sdk-snippet-name-input'
          type='text'
        />
      </div>
      <div data-testid='snippet-content-section'>
        <FormCustomCodeEditor
          name='content'
          placeholder='The JavaScript to include in the Web SDK.'
          label='content'
          dataTestId='sdk-snippet-content-input'
          errorDataTestId='sdk-snippet-content-error'
          language='js'
          height='400px'
        />
        <HelpText>
          Ensure the JavaScript is valid, and that it has wide browser support.
          Invalid JavaScript can cause the Web SDK to fail. Failure means that
          sessions will not be tracked in the Solve platform.
        </HelpText>
      </div>
      <div data-testid='snippet-priority-section'>
        <FormTextField
          name='priority'
          placeholder='Priority'
          label='priority'
          type='number'
        />
        <HelpText>
          The snippets are ordered by priority (smallest priorities appearing
          first), and within the same priority by name (names beginning with
          &apos;<code>A</code>&apos; appearing first).
        </HelpText>
      </div>
      <div>
        <Label htmlFor='enabled'>
          Enabled{' '}
          <Field
            name='enabled'
            className='border border-solid border-gray-300 rounded focus:border-blue-400 focus:outline-none p-2'
            type='checkbox'
            data-testid='sdk-snippet-enabled-input'
          />
        </Label>
      </div>
      <div className='flex flex-row-reverse mt-2'>
        <Button
          type='submit'
          color='primary'
          className='px-8 uppercase'
          data-testid='sdk-snippet-submit'
        >
          {submitDisplayName}
        </Button>
      </div>
    </div>
  </Form>
);

const SDK_SNIPPET_NAME_ERROR =
  'A SDK Snippet with this name already exists. Please choose a different name.';

type FormDataState = false | FormData;

export default function SdkSnippetForm({
  mutation,
  refetchQuery,
  sdkSnippetId,
  onClose,
  initialValues,
  formMode
}: Props) {
  const [mutate, { loading }] = useMutation(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: refetchQuery }],
    errorPolicy: 'all'
  });
  const submitDisplayName = formMode === 'update' ? 'Update' : 'Create';
  const postFormAction = formMode === 'update' ? 'updated' : 'created';

  const [setup, setSetup] = useState(false as FormDataState);
  if (setup !== false) {
    return (
      <PostFormInfo
        name={setup.name}
        action={postFormAction}
        onClose={onClose}
      />
    );
  }

  return (
    <Formik
      validationSchema={validateSdkSnippet}
      initialValues={initialValues}
      onSubmit={async (values: FormData, formikHelpers) => {
        const { errors } = await mutate({
          variables: {
            id: sdkSnippetId,
            input: {
              name: values.name,
              content: values.content,
              priority: values.priority,
              enabled: values.enabled
            }
          }
        });
        if (errors) {
          if (
            errors?.some((gqlError: any) =>
              gqlError.message.includes('name: has already been taken')
            )
          ) {
            formikHelpers.setFieldError('name', SDK_SNIPPET_NAME_ERROR);
          }
        } else {
          setSetup(values);
        }
      }}
    >
      {loading ? (
        <Loader className='m-1' />
      ) : (
        <SnippetForm submitDisplayName={submitDisplayName} />
      )}
    </Formik>
  );
}
