import { useState } from 'react';
import Button from 'components/button';
import ButtonLink from 'components/buttonLink';

import Modal from 'components/modal';
import ErrorAlert from 'components/errorAlert';

import { ReactComponent as SuccessIcon } from 'resources/icons/check.svg';
import { ReactComponent as WarningIcon } from 'resources/icons/alert-triangle.svg';

interface ModalProps {
  plugin: EnabledIntegration;
  isOpen: boolean;
  onRequestClose: () => void;
}

const Success = () => <SuccessIcon className='w-4 h-4 mr-3 text-green-300' />;

const Warning = () => <WarningIcon className='w-4 h-4 mr-3 text-orange-300' />;

const ShopifyDetails = ({
  plugin,
  onCloseModal
}: {
  plugin: EnabledIntegration;
  onCloseModal: () => void;
}) => {
  return (
    <div className='px-5 py-5'>
      <ul>
        <li className='flex flex-row justify-around	pt-2'>
          <span className='flex flex-col'>
            <span className='text-center text-gray-500 text-sm'>
              Store Label
            </span>
            <span className='text-center capitalize text-md'>
              {plugin.storeLabel}
            </span>
          </span>
          <span className='flex flex-col'>
            <span className='text-center text-gray-500 text-sm'>
              Store Domain
            </span>
            <span className='text-center text-md'> {plugin.storeDomain}</span>
          </span>
        </li>

        <li className='border-b mt-4' />
        <li className='flex flex-row mt-4'>
          <span className='flex flex-col justify-center'>
            {plugin.sdkInstalled ? <Success /> : <Warning />}
          </span>
          <span className='flex flex-col'>
            <p>Solve SDK Installed</p>
            {!plugin.sdkInstalled && (
              <p className='text-gray-500 text-xs'>
                Please check with your Solve contact
              </p>
            )}
          </span>
        </li>
        <li className='flex flex-row pt-2'>
          <span className='flex flex-col justify-center'>
            {plugin.webhookInstalled ? <Success /> : <Warning />}
          </span>
          <span className='flex flex-col'>
            <p>Shopify Webhooks Configured</p>
            {!plugin.webhookInstalled && (
              <p className='text-gray-500 text-xs'>
                Please check with your Solve contact
              </p>
            )}
          </span>
        </li>
      </ul>
      {plugin.active && (
        <div className='text-gray-600 bg-orange-100 rounded p-2 mt-4 items-center'>
          <p className='text-black-500 text-base'>
            To uninstall the Solve integration from this store.
          </p>
          <p className='text-gray-500 text-xs mt-2'>
            <a href={`https://${plugin.storeDomain}/admin/apps`}>
              Login to your Shopify admin
            </a>{' '}
            and delete &quot;Solve&quot; from the Shopify apps list.
          </p>
          <p className='text-gray-500 text-xs'>
            Re-install the shop by clicking &quot;New Integration&quot; in the
            Solve admin integration page.
          </p>
        </div>
      )}
      {!plugin.active && plugin.completeLinkingUrl && (
        <div className='text-gray-600 bg-red-100 rounded p-4 mt-4 items-center'>
          <p className='text-gray-800 text-base'>
            Your store hasn&apos;t finished linking with Solve yet
          </p>
          <ButtonLink
            color='danger'
            className='px-8 mt-2 uppercase'
            href={plugin.completeLinkingUrl}
          >
            complete linking
          </ButtonLink>
        </div>
      )}
      <div className='flex flex-row-reverse mt-4'>
        <Button
          type='button'
          color='gray'
          className='px-8 uppercase'
          onClick={onCloseModal}
        >
          close
        </Button>
      </div>
    </div>
  );
};
const DetailsModal = ({ plugin, isOpen, onRequestClose }: ModalProps) => {
  const tile = 'Integration Details';
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={tile}
      ariaLabel={tile}
    >
      {plugin.__typename === 'ShopifyPluginIntegration' && (
        <ShopifyDetails plugin={plugin} onCloseModal={onRequestClose} />
      )}
    </Modal>
  );
};

const EditButton = ({ plugin }: { plugin: EnabledIntegration }) => {
  const [editIsOpen, setEditIsOpen] = useState(false);

  const editHandlerToggle = () =>
    setEditIsOpen(prevEditIsOpen => !prevEditIsOpen);

  return (
    <>
      <Button
        type='button'
        color='primary'
        onClick={editHandlerToggle}
        data-testid={`plugin-edit-${plugin.storeDomain}`}
      >
        View
      </Button>
      <DetailsModal
        plugin={plugin}
        isOpen={editIsOpen}
        onRequestClose={editHandlerToggle}
      />
    </>
  );
};
interface Props {
  plugins: PluginIntegration['enabledIntegrations'];
}
const PluginsTable = ({ plugins }: Props) => {
  if (plugins.length === 0) {
    return <div>No integrations found.</div>;
  }

  const columnNames = ['Type', 'Label', 'Domain', 'Active', 'Details'];

  return (
    <>
      <table className='mt-6 w-full' data-testid='configurations-table'>
        <thead>
          <tr>
            {columnNames.map((name: string, idx: number) => {
              return (
                <td
                  key={`header-column-${idx}`}
                  className='whitespace-nowrap p-2 font-bold border-b'
                >
                  {name}
                </td>
              );
            })}
            <td className='border-b' />
          </tr>
        </thead>
        <tbody>
          {plugins.map((plugin, index: number) => {
            if (plugin.__typename === 'ShopifyPluginIntegration') {
              return (
                <tr
                  key={`${plugin.storeDomain}-table-row`}
                  className='my-2'
                  data-testid={`plugin-${plugin.storeDomain}`}
                >
                  <td className='p-2 border-b' data-testid='plugin-item-name'>
                    Shopify
                  </td>
                  <td
                    className='p-2 border-b'
                    data-testid='plugin-item-storeLabel'
                  >
                    {plugin.storeLabel}
                  </td>
                  <td
                    className='p-2 border-b'
                    data-testid='plugin-item-storeDomain'
                  >
                    {plugin.storeDomain}
                  </td>
                  <td className='p-2 border-b' data-testid='plugin-item-active'>
                    {plugin.active === true ? <Success /> : <Warning />}
                  </td>
                  <td className='p-2 border-b'>
                    <EditButton plugin={plugin} />
                  </td>
                </tr>
              );
            }
            return (
              <ErrorAlert
                key={index}
                error={
                  new Error(`Unsupported integration ${plugin.__typename}`)
                }
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default PluginsTable;
