import { code } from 'currency-codes';

import formatCurrency from './utils';

import { useLocaleContext } from 'context/locale';

interface Props {
  price: number | string | undefined;
  currency: string | undefined;
  className?: string;
  // Show a large number as 4K rather than 4,000. This will only 'work' on browsers
  //  that support it, otherwise it will fall back to rendering `4,000`.
  notation?: 'compact';
  // Drop `.00` from short numbers(doesn't work reliably when notation === 'compact')
  dropZero?: boolean;
  // we have some data that returns only minor amount - e.x.: campaign reports
  isMinor?: boolean;
  displayCurrencyCode?: boolean;
  'data-testid'?: string;
}

const CurrencyCell = ({
  price,
  currency,
  className,
  notation,
  dropZero,
  isMinor,
  displayCurrencyCode,
  'data-testid': dataTestId
}: Props) => {
  const { countryCode } = useLocaleContext();
  if (!price || !currency) {
    return <></>;
  }

  const formatted = formatCurrency(price, code(currency), {
    countryCode: countryCode || 'en-US',
    notation,
    dropZero,
    isMinor,
    displayCurrencyCode
  });
  const formattedString = formatted === null ? '--' : formatted;

  return (
    <span className={className} data-testid={dataTestId}>
      {dropZero
        ? formattedString.replace(/\.0+([^\d])/g, '$1')
        : formattedString}
    </span>
  );
};

export default CurrencyCell;
