import { useState } from 'react';
import { Formik, Form } from 'formik';
import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import {
  allConfigsQuery,
  configCreateOrUpdateMutation
} from 'queries/configuration';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';
import FormTextField from 'components/formComponents/formTextField';
import FormCustomCodeEditor from 'components/formComponents/formCustomCodeEditor';

import { validateCreateConfigurationValues } from './configurationFormUtils';

interface Props {
  onClose(event: React.MouseEvent<HTMLElement>): void;
}

interface FormValuesProps {
  id: string;
  properties: string;
}

const EXISTING_CONFIG_MSG =
  'Cannot upsert existing configuration without a valid update token. Refetch the configuration to get an update token.';

const DEFAULT_VALUES = {
  id: '',
  properties: '',
  created_at: new Date()
};

const AddConfigurationForm = () => {
  return (
    <Form>
      <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
        <div>
          <FormTextField
            name='id'
            label='id'
            placeholder='Choose an id. This will be used to retrieve the configuration later.'
            dataTestId='create-configuration-id-input'
            errorDataTestId='create-configuration-id-errors'
            type='text'
          />
        </div>
        <div>
          <FormCustomCodeEditor
            name='properties'
            placeholder='Give this configuration some properties. Must be a JSON object. Defaults to "{}" if left empty.'
            label='properties'
            dataTestId='create-configuration-properties-input'
            errorDataTestId='create-configuration-properties-errors'
            language='json'
          />
        </div>

        <div className='flex flex-row-reverse mt-2'>
          <Button
            type='submit'
            color='primary'
            className='px-8 uppercase'
            data-testid='create-configuration'
          >
            create
          </Button>
        </div>
      </div>
    </Form>
  );
};

const setupScreen = (payload: ConfigPayload, onClose: Props['onClose']) => {
  return (
    <div className='mt-2 mb-4 mx-5' data-testid='configuration-form-info'>
      <div className='mb-5' data-testid='configuration-form-id'>
        <p>
          Configuration <strong>{payload.id}</strong> has been successfully
          created.
        </p>
      </div>
      <div className='flex justify-end'>
        <Button
          type='button'
          color='primary'
          onClick={onClose}
          className='w-20'
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

const Setup = ({ onClose }: Props) => {
  const [setup, setSetup] = useState(0);
  const [payload, setPayload] = useState(DEFAULT_VALUES);

  return (
    <Mutation mutation={configCreateOrUpdateMutation}>
      {(createConfiguration: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }

        if (error) {
          if (
            error.graphQLErrors?.some((gqlError: any) =>
              gqlError.message.includes(EXISTING_CONFIG_MSG)
            )
          ) {
            return (
              <ErrorAlert
                error={error}
                message='A configuration with this id already exists. Please choose a different id.'
                className='m-2'
              />
            );
          }
          return <ErrorAlert error={error} className='m-2' />;
        }

        return setup === 0 ? (
          <Formik
            initialValues={payload}
            validate={validateCreateConfigurationValues}
            onSubmit={async (values: FormValuesProps) => {
              const gqlRes = await createConfiguration({
                variables: {
                  input: {
                    id: values.id,
                    properties: values.properties || '{}'
                  }
                },
                awaitRefetchQueries: true,
                refetchQueries: [{ query: allConfigsQuery }]
              });
              setPayload(gqlRes.data.createOrUpdateConfiguration);
              setSetup(1);
            }}
          >
            <AddConfigurationForm />
          </Formik>
        ) : (
          setupScreen(payload, onClose) || null
        );
      }}
    </Mutation>
  );
};

export default Setup;
