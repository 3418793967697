import { useState } from 'react';
import cn from 'classnames';

import { useKeyDown } from 'hooks/keyboard';

import Tooltip, { TooltipTheme } from 'components/tooltip';

import styles from './styles.module.css';

interface MenuItem {
  label: string;
  description?: string;
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

interface Props {
  'data-testid'?: string;
  menu: MenuItem[];
  darkMode?: boolean;
  type: 'hdots' | 'vdots';
  className?: string;
  theme?: TooltipTheme;
}

interface ItemProps {
  'data-testid'?: string;
  data: MenuItem;
}

const ContextMenu = ({
  menu,
  darkMode,
  theme,
  'data-testid': dataTestId,
  type,
  className
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const openTooltip = () => setIsOpen(true);
  const closeTooltip = () => setIsOpen(false);

  useKeyDown(27, closeTooltip);

  const Item = ({ data, 'data-testid': dataTestId }: ItemProps) => (
    <div
      data-testid={dataTestId}
      className={`cursor-pointer px-4 py-1 block ${
        darkMode
          ? 'text-gray-300 hover:text-white hover:bg-gray-800'
          : 'text-gray-800 hover:bg-gray-200'
      }`}
      onClick={(...args) => {
        closeTooltip();
        data.onClick(...args);
      }}
    >
      <li>{data.label}</li>
      {data.description && (
        <li
          className={`text-xs ${
            darkMode
              ? 'text-gray-500 hover:text-white hover:bg-gray-800'
              : 'text-gray-500 hover:bg-gray-200'
          }`}
        >
          {data.description}
        </li>
      )}
    </div>
  );

  return (
    <Tooltip
      position='bottom-end'
      trigger='manual'
      theme={theme}
      open={isOpen}
      onRequestClose={closeTooltip}
      animation='scale'
      html={
        <div className='py-2 text-left'>
          <ul className='min-w-32'>
            {menu.map((item: MenuItem, idx: number) => {
              return (
                <Item
                  data-testid={`${dataTestId}-${item.label.toLowerCase()}`}
                  key={idx}
                  data={item}
                />
              );
            })}
          </ul>
        </div>
      }
    >
      <div
        data-testid={dataTestId}
        data-tooltip-open={`${isOpen}`}
        className={cn(
          'cursor-pointer text-center relative',
          styles[`${type}`],
          className
        )}
        onClick={e => {
          e.stopPropagation();
          openTooltip();
        }}
      ></div>
    </Tooltip>
  );
};

export default ContextMenu;
