import { ReactElement } from 'react';

interface Props {
  condition: boolean;
  wrap: (el: ReactElement) => ReactElement;
  children: ReactElement;
}

const Wrapper = ({ condition, wrap, children }: Props) =>
  condition ? wrap(children) : children;

export default Wrapper;
