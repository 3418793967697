import { Link } from 'react-router-dom';

import AsyncSearchBar from 'containers/search/AsyncSearchBar';

import Logo from 'components/logo';
import Menu from 'components/menu';
import ProfileDropdown from 'components/profileDropdown';

import Infrastructure from './infrastructure';

import './styles.css';

const Header = () => {
  return (
    <header
      data-testid='header'
      className='z-50 bg-gray-800 px-6 items-center w-full flex flex-wrap shadow-md top-0'
    >
      <div className='flex order-first flex-grow md:grow-0 h-16'>
        <Link
          data-testid='dashboard-menu-link'
          to='/'
          className='order-first z-20'
        >
          <Logo className='w-24 mr-6 h-16' />
        </Link>
        <AsyncSearchBar />
      </div>
      <Menu
        className='header-menu flex-none items-center flex-grow h-16 w-full md:w-auto order-last md:order-2'
        dropdownClassName='menu-dropdown absolute'
      />
      <Infrastructure className='flex-none items-center text-gray-700 order-2 relative py-2 ml-auto mr-2 z-10 sm:z-20' />
      <ProfileDropdown className='profile-dropdown flex-none items-center order-3 md:order-last py-2 group z-10 sm:z-20' />
    </header>
  );
};

export default Header;
