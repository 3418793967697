import Info from 'components/info';
import Tooltip from 'components/tooltip';

interface Props {
  apiKey: ApiKey;
}

const InfoCell = ({ apiKey }: Props) => {
  const { id, name, key, notes } = apiKey;
  return (
    <>
      <span className='pr-2'>{name}</span>
      {notes && (
        <span className='relative' data-testid={`info-tooltip-${id}`}>
          <Tooltip
            position='right'
            trigger='mouseenter'
            className='inline'
            distance={14}
            html={
              <div className='text-left text-xs p-2'>
                <span data-testid={`notes-${id}`}>{notes}</span>
              </div>
            }
          >
            <Info
              data-testid={`info-tooltip-${id}`}
              className={`info-tooltip leading-none ${key}`}
            />
          </Tooltip>
        </span>
      )}
    </>
  );
};

export default InfoCell;
