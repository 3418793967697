import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

/*
  Show difference between 2 dates, in this case in days
  for more examples check: https://day.js.org/docs/en/display/difference

  When type is not specified it would show difference in milliseconds.

  Example:
  now: dayjs()
  created: 2019-02-11 00:20:24 +0000
  type: 'days'
  Result: 47
*/

export function timeDiff(created: Date | string, type?: 'days' | 'years') {
  return Math.round(dayjs().diff(dayjs(created), type, true));
}

/*
  Check if time between 2 dates is the same

  Example:
  created: 2019-02-11 00:20:24 +0000
  updated: 2019-02-11 00:20:24 +0000

  Result: false
*/
export function isTimeUpdated(updated: Date, created: Date) {
  return !!dayjs(updated).diff(dayjs(created));
}

export const formatDateToTz = (
  dateTime: Date | string,
  format?: string,
  timeZone?: string
) => {
  // UTC time: 2020-03-16T21:11:30.000000Z
  // function return: 2020-03-17 10:11 AM

  return dayjs
    .utc(dateTime)
    .tz(timeZone || dayjs.tz.guess())
    .format(format || 'YYYY-MM-DD hh:mm A');
};
