import { ReactComponent as Link } from 'resources/icons/link.svg';

interface Props {
  urls: {
    channels?: string;
    conversions?: string;
    campaigns: string;
    biDashboards?: string;
  };
}

const ReportsSubNav = ({ urls }: Props) => (
  <div
    className='text-sm text-left rounded-t overflow-hidden'
    data-testid='reports-dropdown'
  >
    {urls.channels && (
      <div className='px-4 py-2 hover:bg-gray-200'>
        <a href={urls.channels}>Channels Report</a>
      </div>
    )}
    {urls.conversions && (
      <div className='px-4 py-2 hover:bg-gray-200'>
        <a href={urls.conversions}>Conversions Report</a>
      </div>
    )}
    <div className='border-b px-4 py-2 hover:bg-gray-200'>
      <a href={urls.campaigns}>Campaign Reports</a>
    </div>
    {urls.biDashboards && (
      <a
        href={urls.biDashboards}
        target='_blank'
        rel='noopener noreferrer'
        className='flex flex-row items-center justify-between group px-4 py-2'
      >
        <span className='group-hover:underline group-hover:text-gray-500'>
          BI Dashboards
        </span>
        <Link className='w-3 h-3 text-gray-800 group-hover:text-gray-500' />
      </a>
    )}
  </div>
);

export default ReportsSubNav;
