import { Link } from 'react-router-dom';
import { components } from 'react-select';

import CurrencyCell from 'components/currencyCell';

import MatchItem from './matchItem';

import { ReactComponent as OrderCartIcon } from 'resources/icons/cart.svg';
import { ReactComponent as ShopifyIcon } from 'resources/icons/shopify.svg';

const OrderItem = ({ data, innerProps, innerRef, props }: any) => {
  const {
    order_id,
    store,
    item_count,
    total_price,
    currency,
    shipping_address
  }: OrderResultRecord = data.record;

  const {
    order_id: highlight_order_id,
    store: highlight_store,
    shipping_address: highlight_shipping_address
  }: OrderResultHighlight = data.highlightHtml;

  // build highlighted result strings
  const highlightOrderId = highlight_order_id || order_id || '';
  const highlightStore = highlight_store || store || '';

  const highlightShippingAddress =
    highlight_shipping_address || shipping_address || '';

  return (
    <components.Option {...props}>
      <div
        className='p-1 custom-option'
        ref={innerRef}
        {...innerProps}
        data-testid={
          props.isFocused
            ? `active-order-item-${innerProps.id.replace(/.*-option-/, '')}`
            : 'non-active-order-item'
        }
      >
        <Link
          to={`/orders/${encodeURIComponent(store)}/${encodeURIComponent(
            order_id
          )}`}
          className='flex flex-row gap-2'
        >
          <div>
            <OrderCartIcon className='w-5 h-5' />
          </div>
          <div>
            <div className='flex'>
              <span className='text-gray-600 pr-1'>{`Order`}</span>

              <MatchItem
                data-testid={
                  props.isFocused
                    ? `active-order-store-${innerProps.id.replace(
                        /.*-option-/,
                        ''
                      )}`
                    : 'non-active-order-store'
                }
                resultItem={order_id}
                highlightItem={highlightOrderId}
                className='font-bold text-gray-700 pr-1'
              />
              <span className='text-gray-600 pr-1'>{` via `}</span>

              <MatchItem
                resultItem={store}
                highlightItem={highlightStore}
                className='font-bold text-gray-700 pr-1 capitalize'
              />

              {/shopify/.test(store) && (
                // that classname makes the icon's colour the same as the store MatchItem text
                <ShopifyIcon
                  className='text-gray-700'
                  data-testid='search-order-item-shopify-icon'
                />
              )}
            </div>
            <p className='text-gray-600 pr-2'>
              <span>{`${item_count} items - `}</span>
              <span>
                <CurrencyCell currency={currency} price={total_price} />
              </span>
            </p>
            <MatchItem
              resultItem={shipping_address}
              highlightItem={highlightShippingAddress}
              className='text-gray-600 pr-2'
            />
          </div>
        </Link>
      </div>
    </components.Option>
  );
};

export default OrderItem;
