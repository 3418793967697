import { Mutation } from '@apollo/client/react/components';
import { MutationResult } from '@apollo/client/react';

import {
  allSdkSnippetsQuery,
  sdkSnippetUpdateMutation
} from 'queries/sdkSnippet';

import Button from 'components/button';
import Loader from 'components/loader';
import ErrorAlert from 'components/errorAlert';

import { PostFormCacheNote } from './postFormInfo';

export type Props = {
  sdkSnippet: SdkSnippet;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function EnableDisableForm({ sdkSnippet, onClose }: Props) {
  const { name, enabled } = sdkSnippet;

  return (
    <Mutation mutation={sdkSnippetUpdateMutation}>
      {(updateSnippet: any, { loading, error }: MutationResult) => {
        if (loading) {
          return <Loader className='m-1' />;
        }
        if (error) {
          return <ErrorAlert error={error} className='m-2' />;
        }

        return (
          <div className='flex flex-col gap-4 px-5 pt-2 pb-5'>
            <div data-testid='sdk-snippet-enable-disable-info'>
              Please confirm that you want to
              {enabled ? ' disable ' : ' enable '}
              the SDK Snippet{' '}
              <code
                className='font-bold'
                data-testid='sdk-snippet-enable-disable-name'
              >
                {name}
              </code>
              .
            </div>
            <PostFormCacheNote />
            <div className='flex flex-row-reverse mt-2'>
              <Button
                type='button'
                color='primary'
                data-testid='sdk-snippet-enable-disable-button'
                onClick={async event => {
                  await updateSnippet({
                    variables: {
                      id: sdkSnippet.id,
                      input: {
                        enabled: !sdkSnippet.enabled
                      }
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [{ query: allSdkSnippetsQuery }]
                  });
                  onClose(event);
                }}
              >
                {enabled ? 'Disable' : 'Enable'}
              </Button>
            </div>
          </div>
        );
      }}
    </Mutation>
  );
}
