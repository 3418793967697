interface Props {
  resultItem: Array<string> | string | null;
  highlightItem: Array<string> | string | null;
  className: string;
  'data-testid'?: string;
}

const MatchItem = ({
  resultItem,
  highlightItem,
  className,
  'data-testid': dataTestId
}: Props) => {
  if (typeof highlightItem === 'string') {
    return (
      <p
        className={className}
        data-testid={`${highlightItem} ${dataTestId}`}
        dangerouslySetInnerHTML={{ __html: highlightItem }}
      ></p>
    );
  }
  if (Array.isArray(highlightItem)) {
    return (
      <>
        {highlightItem.map((item: string, idx: number) => (
          <p
            key={idx}
            className={className}
            data-testid={`${item} ${dataTestId}`}
            dangerouslySetInnerHTML={{ __html: item }}
          ></p>
        ))}
      </>
    );
  }

  if (resultItem === null) {
    return null;
  }

  if (Array.isArray(resultItem)) {
    return (
      <>
        {resultItem.map((item: string, idx: number) => (
          <p
            key={idx}
            className={className}
            data-testid={`${item} ${dataTestId}`}
          >
            {item}
          </p>
        ))}
      </>
    );
  }
  return (
    <p className={className} data-testid={resultItem}>
      {resultItem}
    </p>
  );
};

export default MatchItem;
