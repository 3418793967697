export const settingsPaths = {
  root: '/settings',
  apiIntegrations: '/settings/api-integrations',
  users: '/settings/users',
  solveIntegrations: '/settings/solve-integrations',
  configurations: '/settings/configurations',
  customization: '/settings/customization',
  accessControl: '/settings/access-control'
};

const paths = {
  pageViewGraph: '/pageview-graph',
  mauGraph: '/mau-graph',
  totalSalesGraph: '/total-sales-graph',
  revenueGraph: '/revenue-graph',
  cartConversionGraph: '/cart-conversion-graph',
  userSummaryGraph: '/users-summary-graph',
  account: '/account',
  profiles: '/profiles',
  profile: '/profiles/:id',
  orders: '/orders',
  orderProvider: '/orders/:orderProvider/:orderId',
  reports: '/reports',
  channels: '/reports/channels',
  conversions: '/reports/conversions',
  campaigns: '/reports/campaigns',
  settings: settingsPaths.root,
  queryEngineSavedQueryId: '/query-engine/:savedQueryId',
  queryEngine: '/query-engine',
  notFound: '/not-found'
};
export const ADMIN_GRAPHQL_PATH = '/admin-graphql';
export const CORE_GRAPHQL_PATH = '/graphql';

export default paths;
